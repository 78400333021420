import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem } from '@material-ui/core';
import { usePhrases } from '@frontend/utils/usePhrases';
import { ROUTES } from '@frontend/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 900,
  },
}));

export default function ViewSelection({ callback }) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const structureViewInUse = useSelector((state) => state.structureViewInUse);
  const selectionDrawerOpen = useSelector((state) => state.selectionDrawerOpen);
  const [expanded, setExpanded] = useState(false);
  const [selectedView, setSelectedView] = useState('');

  const views = [{
    name: phrases.nav.oee,
    route: ROUTES.STRUCTURE_OVERVIEW,
  },
  // {
  //     name: 'COMPARISON',
  //     route: '/comparison'
  // },
  {
    name: phrases.nav.report,
    route: ROUTES.REPORTING_VIEW,
  }, {
    name: phrases.nav.schedule,
    route: ROUTES.SCHEDULES,
  }, {
    name: phrases.nav.andon,
    route: ROUTES.ANDON_BOARD,
  }, {
    name: phrases.nav.orders,
    route: ROUTES.ORDERS,
  }];

  useEffect(() => {
    setSelectedView(views.find((view) => view.route === structureViewInUse) || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectionDrawerOpen) {
      setExpanded(false);
      setSelectedView(views.find((view) => view.route === structureViewInUse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionDrawerOpen]);

  useEffect(() => {
    if (!selectionDrawerOpen) {
      setSelectedView(views.find((view) => view.route === structureViewInUse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureViewInUse]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>View:</Typography>
          <Typography color="primary" className={classes.secondaryHeading}>{selectedView ? selectedView.name : ''}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block' }}>
          {views.map((view) => {
            return (
              <MenuItem
                key={uuid()}
                onClick={() => {
                  setSelectedView(view);
                  setExpanded(false);
                  if (callback) {
                    callback(view.route);
                  }
                }}
              >
                {view.name}
              </MenuItem>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}