import React, { useState } from 'react';
import _ from 'lodash';
import {
    makeStyles,
} from '@material-ui/core/styles';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
} from '@material-ui/core';
import loader                 from '@frontend/loader/loader';
import EasyScrapModal         from '@frontend/modules/quality/components/EasyScrapModal';
import { usePhrases }         from '@frontend/utils/usePhrases';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      position: 'relative',
    },
    easyScrapButton: {
      float: 'right',
      marginRight: theme.spacing(1),
    },
  }));

const EasyScrapButton = (props) => {
    
    const {
        machine,
        machineStates, 
        refreshPulse
    } = props
    
    const { moduleLoaded } = loader();  
    const [isEasyScrapModalOpen, setEasyScrapModalOpen] = useState(false);
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    
    const easyScrapEnabled = () => {
        return moduleLoaded('quality') && moduleLoaded('machine') && machine.config?.easyScrap === "true"
    }
    
    return ( <>
    {easyScrapEnabled() &&
    (<Button 
        onClick={() => {
            setEasyScrapModalOpen(true)
        }}
        size="small"    
        className={classes.easyScrapButton}
        variant="outlined"
        disableElevation
        startIcon={
            <FontAwesomeIcon
            icon={faRecycle}
            size="2x"
          />
        }
     >
        {phrases.modules.quality.easyScrap}
    </Button> 
    )}
    <EasyScrapModal
        handleCloseModal={()=>{
          setEasyScrapModalOpen(false);
        }}
        selectedState={_.last(machineStates[machine.id]?.states)}
        machine={machine}
        refreshPulse={refreshPulse}
        open={isEasyScrapModalOpen}
        
    />
      </>);
}
 
export default EasyScrapButton;