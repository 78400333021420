// import React, { useState } from 'react';

import moment from "moment";
import { usePhrases }           from '@frontend/utils/usePhrases';
const useReloadTimes = () => {
    const phrases = usePhrases().phrases();
    const canReloadNow = (lastReloadTime, reloadInterval, key) => {
        const specificTime = lastReloadTime[key];
        if(!specificTime) {
            return true;
        }
        if(moment().diff(moment(specificTime), 'seconds') >= reloadInterval) {
            return true;
        }
        return false;   
    }

    const formatTime = (time) => {
        let nameForTime = phrases.nav.reloadTimes[time];
        if(nameForTime) {
            return nameForTime;
        }
        // Else just print out the time
        if (time < 60) {
            return `${time} s`;
        } else if (time < 3600) {
            return `${Math.floor(time / 60)} min`;
        } else if (time < 86400) {
            return `${Math.floor(time / 3600)} h`;
        } 
        return `${time} s`;
    }
    
    return {
        formatTime,
        canReloadNow
    };
}
 
export default useReloadTimes;