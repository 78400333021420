/* eslint-disable react/jsx-key */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles }     from '@material-ui/core/styles';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { faRunning }        from '@fortawesome/free-solid-svg-icons';

import { usePhrases }   from '@frontend/utils/usePhrases';
import useJob           from '@frontend/modules/orders/hooks/useJob';
import JobTotalCount    from '@frontend/modules/terminal/components/JobTotalCount';
import JobBasicInfo     from '@frontend/modules/terminal/components/JobBasicInfo';
import JobSpeed         from '@frontend/modules/terminal/components/JobSpeed';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  loadingReasonsIndicator: {
    marginLeft: theme.spacing(1),
  },
  jobTopContainer: {
    padding: theme.spacing(2),
  },
  jobInfo: {
    marginLeft: theme.spacing(1),
  },
  jobCountHeading: {
    marginLeft: theme.spacing(1),
    textAlign: 'center',
  },
  orange: {
    color: theme.palette.primary.main,
  },
}));

const TopJobs = (props) => {
  const {
    jobTracker,
    machine
  } = props;
  
  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { 
    loadingPropsObject,
    addNote
  } = useJob(jobTracker?.businessJobId, jobTracker?.structure?.id);

  // ===== Return ===== 
  return (
    <Paper className={classes.paper} variant="outlined" square>
      <List>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <FontAwesomeIcon
              icon={faRunning}
              size="2x"
            />
          </ListItemAvatar>
          <Typography variant="body1" color="textPrimary">
            {phrases.modules.orders.activeJobs}
          </Typography>
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>
        <Grid container spacing={2} className={classes.jobTopContainer}>
          <Grid item xs="12">
            <Typography display="inline" color="textSecondary" >
              {phrases.modules.orders.jobId}:
            </Typography>
            <Typography display="inline" className={classes.jobInfo}>
              <Link to={`/orders/job/${jobTracker?.businessJobId}`} key={`${jobTracker?.businessJobId}-lnk`} className={classes.orange}>
                  {jobTracker?.businessJobId || '---'}             
              </Link>
            </Typography>
          </Grid>
          <JobBasicInfo 
              addNote={addNote}
              jobTracker={jobTracker}
              loadingPropsObject={loadingPropsObject}
            />  
            <Grid item xs="12"> 
              <JobTotalCount 
                jobTracker={jobTracker}
              />    
            </Grid>
            <Grid item xs="12"> 
              <JobSpeed
                jobTracker={jobTracker}
                machineToEngage={machine}
              />
            </Grid>
        </Grid>
    </Paper>
  );
}
 
export default TopJobs;