/* eslint-disable react/jsx-key */
import React from 'react';
import {
  Grid,
  Paper,
  Typography } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import useUnitCalculation     from '@frontend/hooks/useUnitCalculation';
import { usePhrases }         from '@frontend/utils/usePhrases';
import useDateTimeFormat      from '@frontend/utils/useDateTimeFormat';
import useJobSpeed            from '@frontend/modules/terminal/hooks/useJobSpeed';

const useStyles = makeStyles(() => ({
  verticalContainer: {
    display: 'table',
  },
  verticallyAlignedText: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
}));

const JobSpeed = (props) => {  
  const { 
    jobTracker,
    machineToEngage,
  } = props;
  // ===== State Objects =====
  // ===== Redux Selectors =====
  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { toLocaleNumber } = useDateTimeFormat();
  const { 
    renderUnit,
    renderUnitName
  } = useUnitCalculation();

  const {
    avgSpeed,
    // currentSpeed,    // Could replace the jobTracker speed for machine 
  } = useJobSpeed(machineToEngage);
  // ===== Effects =====
  // ===== Helper Methods =====
  // ===== Return =====
  return (
    <>
      <Grid container component={Paper} spacing={1} variant="outlined" square>
        <Grid item xs={12}>
          <Typography align="center">{phrases.modules.orders.speed}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="right"
                variant="h6"
                className={classes.verticallyAlignedText}
              >
                {jobTracker.speedPerMachine !== undefined &&
                jobTracker.speedPerMachine[machineToEngage?.id] !== undefined 
                  ? `${renderUnit(jobTracker.speedPerMachine[machineToEngage?.id])} ${renderUnitName()}`
                  : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="center"
                color="textSecondary"
                className={clsx(
                  classes.verticallyAlignedText,
                  classes.lowercase,
                )}
              >
                {phrases.modules.orders.actual}
                {/* {` (${phrases.modules.orders.average})`} */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="right"
                variant="h6"
                className={classes.verticallyAlignedText}
              >
                {avgSpeed !== undefined
                  ? `${renderUnit(avgSpeed)} ${renderUnitName()}`
                  : 'N/Aa'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="center"
                color="textSecondary"
                className={clsx(
                  classes.verticallyAlignedText,
                  classes.lowercase,
                )}
              >
                {phrases.modules.orders.average}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="right"
                variant="h6"
                className={classes.verticallyAlignedText}
              >
                {jobTracker?.targetSetupTime <= 0 || jobTracker.targetCycleTime !== 0 ? // Pick TargetCycleTime instead
                  jobTracker?.targetCycleTime
                    ? `${renderUnit(jobTracker.targetCycleTime)} ${renderUnitName()}`
                    : '---'
                  : `${toLocaleNumber(jobTracker?.targetSetupTime)} ${phrases.timespanSelection.Minutes}`}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="center"
                color="textSecondary"
                className={clsx(
                  classes.verticallyAlignedText,
                  classes.lowercase,
                )}
              >
                {jobTracker?.targetSetupTime <= 0 || jobTracker.targetCycleTime !== 0 ? // Pick TargetCycleTime instead
                phrases.modules.orders.target
                : phrases.modules.orders.setup}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default JobSpeed;
