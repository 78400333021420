/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

import EditIcon     from '@material-ui/icons/Edit';
import DeleteIcon   from '@material-ui/icons/Delete';
import { 
  makeStyles,
  useTheme
} from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal  from '@frontend/components/ConfirmationModal';
import LoadingIndicator   from '@frontend/components/LoadingIndicator';
import { usePhrases }     from '@frontend/utils/usePhrases';
import useDateTimeFormat  from '@frontend/utils/useDateTimeFormat';
import useApi             from '@frontend/utils/useApi';
import useAlert        from '@frontend/hooks/useAlert';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import useQualityReports      from '@frontend/modules/quality/hooks/useQualityReports';
import QualityReportEditModal from '@frontend/modules/quality/QualityReportEditModal';
import QualityReport          from '@frontend/modules/quality/components/QualityReport';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%'
  },
  loadingReasonsIndicator: {
    marginLeft: theme.spacing(1),
  },
}));

export default function QualityReportsList(props) {
  const {
    machine,
    date,
    parts,
    refreshPulse,
    addButton,
    actualCount,
    setTotalReports,
    disabledHook,
    customData

  } = props;
  // ===== State Objects =====
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  // ===== Redux Selectors =====
  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const api = useApi();
  const { createAlert } = useAlert();
  const theme = useTheme();
  const {
    qualityReports,
    loadingQualityReports,
    setQualityReports
  } = useQualityReports(machine.id, undefined, setTotalReports, disabledHook);

  useEffect(() => {
    if(disabledHook){
      // Use the data that is passed in the props
      setQualityReports(customData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customData, disabledHook, setQualityReports]);

  const {
    scrapTypeText,
  } = useStatesFilter();
  const { 
    formatDate
  } = useDateTimeFormat();

  // ===== Effects =====
  // ===== Helper Methods =====
  const handleOpenConfirmationModal = (_report) => {
    setReportToDelete(_report);
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setReportToDelete(null);
    setConfirmationModalOpen(false);
  };

  const wasteCountForType = (state, scrapType) => {
    return state[`quality.${scrapType}.wasteCount`];
  };

  const scrapTypeProductionPeriod = (state, scrapType) => {
    return `${formatDate(state[`quality.${scrapType}.startTime`])} - ${formatDate(state[`quality.${scrapType}.endTime`])}`;
  };

  const handleEditReport = (_report) => {
    setSelectedReport({
      report: _report,
      machine,
    });
    setEditModalOpen(true);
  };

  const handleDeleteReport = (_selectedReport = selectedReport) => {
    const scrapType = _selectedReport['quality.scrapType'];
    api('/api/quality/delete-quality-report', {
      data: {
        machine: machine.id,
        oldQualityReportData: {
          scrapType,
          machineId: machine.id,
          wasteCount: _selectedReport[`quality.${scrapType}.wasteCount`],
          noteId: _selectedReport[`quality.${scrapType}.note`],
          startTime: _selectedReport[`quality.${scrapType}.startTime`],
          endTime: _selectedReport[`quality.${scrapType}.endTime`],
        },
        qualityReportData: null,
      },
      method: 'post',
    })
      .then((response) => {
        if (response.status === 204) {
          createAlert(phrases.modules.quality.qualityReportDeleted, 'success');
          refreshPulse();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmationModalText = () => {
    if (!reportToDelete) return '';

    return (
      <>
        <Typography>
          {phrases.modules.quality.areYouSureDeleteQualityReport}
        </Typography>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography>{scrapTypeText(reportToDelete['quality.scrapType'])}</Typography>
              </TableCell>
              {date && (
                <TableCell align="center">
                  <Typography className={classes.headingProductionPeriod}>{scrapTypeProductionPeriod(reportToDelete, reportToDelete['quality.scrapType'])}</Typography>
                </TableCell>
              )}
              {parts && (
                <TableCell align="center">
                  <Typography className={classes.headingCount}>
                    {wasteCountForType(reportToDelete, reportToDelete['quality.scrapType'])}
                    {' '}
                    {phrases.modules.quality.parts}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };

  // ===== Return =====
  return (
    <Paper className={classes.paper} variant="outlined" square>
      <List disablePadding>
        <ListItem alignItems="center">
          <ListItemAvatar>
            {!loadingQualityReports ? (
              <FontAwesomeIcon
                icon={faClipboardList}
                size="2x"
              />
            ) : <LoadingIndicator size={20} />}
          </ListItemAvatar>
          <Table>
            <TableRow>
              <TableCell padding="none" variant="body" size="small" align="left" style={{ borderBottom: 'none' }}>
                <Typography variant="body1">
                  {phrases.modules.quality.qualityReports}
                </Typography>
              </TableCell>
              <TableCell padding="none" variant="body" size="small" align="right" style={{ borderBottom: 'none' }}>
                {addButton && (
                  <QualityReport
                    refreshPulse={refreshPulse}
                    machine={machine}
                    actualCount={actualCount}
                    allQualityReports={qualityReports}
                  />
                )}
              </TableCell>
            </TableRow>
          </Table>
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>
      <Table aria-label="simple table">

        <TableBody>
          {qualityReports?.length ? qualityReports.map((report) => (
            <TableRow>
              <TableCell align="left">
                <Typography>{scrapTypeText(report['quality.scrapType'])}</Typography>
              </TableCell>
              {date && (
                <TableCell align="center">
                  <Typography className={classes.headingProductionPeriod}>{scrapTypeProductionPeriod(report, report['quality.scrapType'])}</Typography>
                </TableCell>
              )}
              {parts && (
                <TableCell align="center">
                  <Typography className={classes.headingCount}>
                    {wasteCountForType(report, report['quality.scrapType'])}
                    {' '}
                    {phrases.modules.quality.parts}
                  </Typography>
                </TableCell>
              )}
              <TableCell align="right">
                <IconButton
                  color="primary"
                  onClick={() => handleEditReport(report)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenConfirmationModal(report)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )) : <Typography style={{ marginTop: theme.spacing(1) }} variant="body2">{phrases.modules.quality.noQualityReportsSubmitted}</Typography>}
        </TableBody>
      </Table>
      <ConfirmationModal
        title={phrases.modules.quality.deleteQualityReportModalTitle}
        text={confirmationModalText()}
        openModal={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        callback={() => handleDeleteReport(reportToDelete)}
      />
      {editModalOpen && selectedReport && (
        <QualityReportEditModal
          machine={machine}
          actualCount={actualCount}
          open={editModalOpen}
          handleCloseModal={() => setEditModalOpen(false)}
          selectedReport={selectedReport}
          refreshPulse={refreshPulse}
          allQualityReports={qualityReports}
        />
      )}
    </Paper>
  );
}
