import React from 'react';
import { useDispatch } from 'react-redux';
import {
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setLastReloadTime }    from '@frontend/utils/UIActions';

import Replay from '@material-ui/icons/Replay';

const useStyles = makeStyles((theme) => ({
    
    reloadDataBtn: {
      borderRadius: '10%',
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    lightText: {
        marginLeft: theme.spacing(1),
      fontWeight: 100,
      fontSize: '1.2rem',
    },
  }));
const ReloadDataButton = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleReloadData = (event) => {
        dispatch(setLastReloadTime({}));
    }
    return (<>
     <IconButton
        aria-label="Reload data"
        aria-controls="menu-appbar"
        aria-haspopup="false"
        className={classes.reloadDataBtn}
        onClick={handleReloadData}
        color="inherit"
      >
        <Replay />
      </IconButton>      
      </>
    );
}
 
export default ReloadDataButton;