import React, { useState, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, CircularProgress } from '@material-ui/core';

import useSecurity from '@frontend/utils/useSecurity';
import { usePhrases } from '@frontend/utils/usePhrases';
import LOADING_PROPS from '@frontend/modules/orders/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(1),
  },

  inputField: {
    width: '100%',
    margintTop: theme.spacing(2),
  },
  addNote: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function NotesForm(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { addNote, loadingPropsObject } = props;
  const { getProfile } = useSecurity();
  const [noteText, setNoteText] = useState('');

  const handleNoteTextChange = (e) => {
    setNoteText(e.target.value);
  };

  const handleSubmitNote = useCallback(() => {
    setNoteText('');
    addNote({
      text: noteText,
      user: getProfile().id,
    });    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteText]);

  return (
    <>
      <TextField
        id="note-field"
        name="note"
        label={phrases.modules.orders.enterNoteText}
        multiline
        variant="outlined"
        fullWidth
        value={noteText}
        onChange={handleNoteTextChange}
        className={classes.inputField}
        margin="dense"
        rows={4}
        required
      />
      <div className={classes.formButtons}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSubmitNote}
          disabled={loadingPropsObject[LOADING_PROPS.ADDING_NOTE]}
          className={classes.formBtn}
        >
          {phrases.modules.orders.addNote}
          {loadingPropsObject[LOADING_PROPS.ADDING_NOTE] && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>
    </>
  );
}
