import { useState, useEffect } from 'react';

const useOrdersAutocomplete = (orders) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  /**
   * Sort products by name.
   *
   * @param {Object[]} _orders Unsorted list of products.
   */
  const sortOrders = (_orders) => {
    if (Array.isArray(_orders)) {
      return _orders.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }    
    return _orders;
  };

  /**
   * Gets trigerred when a new products prop is passed.
   */
  useEffect(() => {
    setDropdownOptions(sortOrders(orders));
  }, [orders]);

  return {
    dropdownOptions,
  };
};

export default useOrdersAutocomplete;
