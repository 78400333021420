/* eslint-disable react/jsx-key */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import { usePhrases } from '@frontend/utils/usePhrases';
import LoadingIndicator from '@frontend/components/LoadingIndicator';
import usePastActiveJobs from '@frontend/modules/orders/hooks/usePastActiveJobs';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  pastJobHeading: {
    color: theme.palette.text.secondary,
  },
  loadingJobsIndicator: {
    marginLeft: theme.spacing(1),
  },
  orange: {
    color: theme.palette.primary.main,
  },
}));

export default function PastActiveJobs(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const {
    machines,
  } = props;
  const {
    pastJobs,
    loadingJobs,
  } = usePastActiveJobs(machines);
  const {
    formatDate,
    formatHoursMinutesDuration
  } = useDateTimeFormat();
  return (
    <Paper className={classes.paper} variant="outlined" square>
      <List>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <FontAwesomeIcon
              icon={faClipboardCheck}    // Change that
              size="2x"
            />
          </ListItemAvatar>
          <Typography variant="body1">
            {phrases.modules.orders.pastActiveJobs}
          </Typography>
          <Typography variant="body1" className={classes.loadingJobsIndicator}>
            {loadingJobs ? <LoadingIndicator size={20} /> : ''}
          </Typography>
        </ListItem>
        <Divider variant="inset" component="li" />

      </List>
      <Table aria-label="simple table">

        <TableBody>
          <TableRow>
            <TableCell align="left">
              <Typography variant="body1" className={classes.pastJobHeading}>
                {phrases.modules.orders.jobId}                
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body1" className={classes.pastJobHeading}>
                {phrases.modules.performance.partsProduced}                
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body1" className={classes.pastJobHeading}>
                {phrases.modules.orders.duration}                
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" className={classes.pastJobHeading}>
                {phrases.modules.orders.actualStartDate}                
              </Typography>
            </TableCell>
          </TableRow>
          {pastJobs.map((job, i) => {
            return(
            <TableRow>
              <TableCell align="left">
                <Typography variant="body2">{`${i + 1}. `}
                  <Link to={`/orders/job/${job?.businessJobId}`} key={`${job?.businessJobId}-lnk`} className={classes.orange}>
                    {job?.businessJobId || '---'}             
                  </Link>
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body2">                 
                    {job?.partsProduced || '---'}           
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body2">                 
                    {formatHoursMinutesDuration(job?.duration)}           
                </Typography>
              </TableCell>
              <TableCell align="right">
                <span>
                {formatDate(job.actualStartDate, true)}
                </span>
              </TableCell>
            </TableRow>
          )})}
          {!pastJobs.length && !loadingJobs ? <Typography>{phrases.modules.orders.noPastActiveJobsInTimeframe}</Typography> : ''}

        </TableBody>

      </Table>
    </Paper>
  );
}
