import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpandMore from '@material-ui/icons/ExpandMore';
import Replay from '@material-ui/icons/Replay';
import ReloadIntervalMenu from '@frontend/components/ReloadIntervalMenu';
import useSecurity from '@frontend/utils/useSecurity';
import useReloadTimes           from '@frontend/hooks/useReloadTimes';

const useStyles = makeStyles((theme) => ({
    
    reloadIntervalBtn: {
      borderRadius: '10%',
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    boldText: {
      fontWeight: 800,
    },
    lightText: {
        marginLeft: theme.spacing(1),
      fontWeight: 100,
      fontSize: '1.0rem',
    },
  }));
const ReloadIntervalButton = () => {

    const classes = useStyles();
    const [reloadInterval, setReloadIntervalAnchorEl] = useState(null);
    const {
        formatTime,
    } = useReloadTimes();
    const isReloadIntervalMenuOpen = () => !!reloadInterval;
    const interval = useSelector((state) => state.reloadInterval);
    
    const { loggedIn } = useSecurity();

    const handleReloadInterval = (event) => {
        setReloadIntervalAnchorEl(event.currentTarget);
    }
    return (<>
     <IconButton
        aria-label="Reload interval"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        className={classes.reloadIntervalBtn}
        onClick={handleReloadInterval}
        color="inherit"
      >
        <ExpandMore />
        <Replay />
            <span className={classes.lightText}>{formatTime(interval)}</span>
      </IconButton>
      {loggedIn() && (
        <ReloadIntervalMenu
          open={isReloadIntervalMenuOpen()}
          reloadIntervalAnchorEl={reloadInterval}
          setReloadIntervalAnchorEl={setReloadIntervalAnchorEl}
        />
        
      )}
      </>
    );
}
 
export default ReloadIntervalButton;