import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
    List,
    ListItem,
    Divider,
    Typography,
    ListItemText,
    Button,
} from '@material-ui/core';
import { ROUTES } from '@frontend/constants';
import { makeStyles } from '@material-ui/core/styles';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import useConditionalRender from '@frontend/utils/useConditionalRender';
import { usePhrases } from '@frontend/utils/usePhrases';
import loader from '@frontend/loader/loader';
import CustomLink from '@frontend/components/CustomLink';
import utils from '@frontend/utils/utils'
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import {
    setTimespanStart,
    setTimespanEnd,
    setTimespanDuration,
    setIsRelativeTimespan,
    setSelectedTimespanText,
    setSelectedRelativeTimespan,
} from '@frontend/utils/UIActions';
const useStyles = makeStyles((theme) => ({
    bold: {
      fontWeight: 900,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    listItemText: {
      marginRight: theme.spacing(1),
    },
    popupListItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemButton: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  }));

const MachineStatePopperGrouped = (props) => {
    const { conditionalRender } = useConditionalRender();
    const { 
        machine,
        selectedState
    } = props
    const dispatch = useDispatch();
    const { moduleLoaded } = loader();
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    const { 
        formatDate, 
        formatHoursMinutesDuration,
        toLocaleNumber
    } = useDateTimeFormat();

    /**
     * Callback for the Drilldown. Currently it will set the Timeframe according to the state time 
     * and drill down the states on the Machine Overview.
     * IMPORTANT: Always set the Timespan using the moment API.
     * @param {*} event 
     */
    const onClickDrillDown = (event) => {
        
        dispatch(setTimespanStart(moment(selectedState.startTime).toDate()));
        dispatch(setTimespanEnd(moment(selectedState.endTime).toDate()));
        dispatch(setTimespanDuration(moment(selectedState.endTime).diff(moment(selectedState.startTime), 'hours')));
        dispatch(setIsRelativeTimespan(false));
        dispatch(setSelectedRelativeTimespan({ amount: 0, unit: '' }));
        dispatch(setSelectedTimespanText(`${formatDate(selectedState.startTime)} - ${formatDate(selectedState.endTime)}`));
        
    };
    const {
        stateColor,
    } = useStatesFilter();
    
    const calculateStateDuration = (state, units = 'minutes') => Math.round(
        moment(state.endTime
        ? state.endTime
        : new Date())
        .diff(moment(state.startTime), units, true),
    );
    return ( 
        <List disablePadding dense>
            <ListItem dense className={classes.popupListItem}>
            <Typography
                align="center"
                style={{ color: stateColor(selectedState.state) }}
                className={`${classes.bold} ${classes.uppercase}`}
            >
                {utils.getGroupedState(selectedState.state).name}
            </Typography>
            </ListItem>
            
            <Divider variant="middle" />
            <ListItem dense className={classes.popupListItem}>
                <ListItemText 
                    style={{
                            flex: 1,
                            display: 'block',
                        }
                    }
                >
                    <Typography 
                        variant="subtitle2"
                        className={classes.listItemText}
                    >
                    {phrases.modules.performance.oee}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {selectedState.state.oee ? Math.round(selectedState.state.oee) : ' - '}%
                </Typography>
            </ListItem>
            <Divider variant="middle" />
            {conditionalRender(moduleLoaded('performance'),
            <>
                <ListItem dense className={classes.popupListItem}>
                    <ListItemText style={{
                        flex: 1,
                        display: 'block',
                    }}
                    >
                        <Typography variant="subtitle2" className={classes.listItemText}>
                        {phrases.modules.performance.partsProduced}
                        :
                        </Typography>
                    </ListItemText>
                    <Typography variant="subtitle2">
                        {selectedState.state ? toLocaleNumber(Math.round(selectedState.state.actualCount)) || 0 : 0}
                    </Typography>
                </ListItem>
                <ListItem dense className={classes.popupListItem}>
                    <ListItemText style={{
                        flex: 1,
                        display: 'block',
                    }}
                    >
                        <Typography variant="subtitle2" className={classes.listItemText}>
                        {selectedState.state && selectedState.state.deviation <= 0 ? phrases.modules.performance.partsMissed : phrases.modules.performance.partsSurplus}
                        :
                        </Typography>
                    </ListItemText>
                    <Typography variant="subtitle2">
                        {selectedState.state ? toLocaleNumber(Math.abs(Math.round(selectedState.state.deviation))) || 0 : 0}
                    </Typography>
                </ListItem>
                <Divider variant="middle" />
            </>)
            }            
            <ListItem dense className={classes.popupListItem}>
                <ListItemText 
                    style={{
                            flex: 1,
                            display: 'block',
                        }
                    }
                >
                    <Typography 
                        variant="subtitle2"
                        className={classes.listItemText}
                    >
                    {phrases.modules.performance.availability}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {selectedState.state.availability ? Math.round(selectedState.state.availability) : ' - '}%
                </Typography>
            </ListItem>
            <ListItem dense className={classes.popupListItem}>
                <ListItemText 
                    style={{
                            flex: 1,
                            display: 'block',
                        }
                    }
                >
                    <Typography 
                        variant="subtitle2"
                        className={classes.listItemText}
                    >
                    {phrases.modules.performance.performance}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {selectedState.state.performance ? Math.round(selectedState.state.performance) : ' - '}%
                </Typography>
            </ListItem>            
            <ListItem dense className={classes.popupListItem}>
                <ListItemText 
                    style={{
                            flex: 1,
                            display: 'block',
                        }
                    }
                >
                    <Typography 
                        variant="subtitle2"
                        className={classes.listItemText}
                    >
                    {phrases.modules.performance.quality}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {selectedState.state.quality ? Math.round(selectedState.state.quality) : ' - '}%
                </Typography>
            </ListItem>
            <Divider variant="middle" />
            <ListItem dense className={classes.popupListItem}>
                <ListItemText style={{
                    flex: 1,
                    display: 'block',
                }}
                >
                    <Typography variant="subtitle2" className={classes.listItemText}>
                    {phrases.timespanSelection.from}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {selectedState.startTime ? formatDate(selectedState.startTime) : ' - '}
                </Typography>
            </ListItem>
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.timespanSelection.to}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {selectedState.endTime ? formatDate(selectedState.endTime) : ' - '}
                </Typography>
            </ListItem>
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.modules.machine.duration}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {formatHoursMinutesDuration(calculateStateDuration(selectedState))}
                </Typography>
            </ListItem>            
            <ListItem dense className={classes.popupListItem}>
                <Button
                    size="small"
                    className={classes.listItemButton}
                    onClick={onClickDrillDown}
                    variant="contained"
                    disableElevation
                >
                    <CustomLink id={machine.id} object="machine" to={ROUTES.MACHINE_OVERVIEW}>
                        {phrases.modules.machine.drillDown}
                    </CustomLink>                    
                </Button>
            </ListItem>
      </List>
    );
}
 
export default MachineStatePopperGrouped;