import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon }from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';

import CheckBoxOutlineBlankIcon     from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon                 from '@material-ui/icons/CheckBox';
import { CustomCompletionStatusEnum } from '@frontend/constants';
import ConfirmationModal  from '@frontend/components/ConfirmationModal';
import StyledSelect             from '@frontend/components/StyledSelect';
import useOrdersExportActivationModal from '@frontend/modules/orders/hooks/useOrderExportActivationModal';
import useDateTimeFormat        from '@frontend/utils/useDateTimeFormat';
import { usePhrases }           from '@frontend/utils/usePhrases';
import HandCountCalculator from '@frontend/modules/terminal/components/HandCountCalculator';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  calculatorBtn: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),  
    paddingLeft: '4px'
  },

  fieldset: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    color: '#757575',
    borderColor: '#F0F0F0',
    borderRadius: '5px',
  },
  helperText: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  legend: {
    marginTop: theme.spacing(4),
    color: '#757575',
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    color: '#757575',
    cursor: 'pointer',
  },
  terminalForm: {
    marginLeft: theme.spacing(3),
  },
}));

const OrderExportActivationModal = (props) => {
    const {
        activationToEdit,
        handleCloseModal,
        machines,
        reloadData
    } = props;
    const isEdit = !_.isEmpty(activationToEdit);
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const { getLocale } = useDateTimeFormat();
    const [handCountCalculatorOpen, setHandCountCalculatorOpen] = useState(false);
    
    const closeModal = () => {
      reloadData();
      handleCloseModal();
    }

    const {
        handleChange,
        handleJobChange,
        handleChangeStartTime,
        handleChangeEndTime,
        handleClearForm,
        handleSubmit,
        formSubmitting,
        errors,
        activationValues,
        toDateDisabled,
        machineIdNames,
        selectedMachine,
        jobInformation,
        handleChangeHandCount,
        confirmationModalOpen,
        handleCloseConfirmationModal,
        handleConfirmIntendedMachine
    } = useOrdersExportActivationModal(activationToEdit, machines, closeModal, isEdit);
    
    const handleCloseHandCountCalculator = () => {
      setHandCountCalculatorOpen(false);
    }

    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          {isEdit ?
          <TextField
            error={errors.machine}
            helperText={errors.machine}
            id="machine-field"
            name="machine"
            label={phrases.modules.order_export.machine}
            variant="outlined"
            type="text"
            fullWidth
            onChange={handleChange}
            value={activationValues.machine.name}
            margin="dense"
            disabled={isEdit}
          />:         
          <StyledSelect
              label={phrases.modules.order_export.machine}
              id="machine-field"
              error={errors.machine}
              helperText={errors.machine}
              name="machine"
              value={selectedMachine}
              onChange={handleChange}
              data={machineIdNames}
              required
              plain
          />
          }
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {isEdit ? 
              <TextField
                  error={errors.activationStatus}
                  helperText={errors.activationStatus}
                  id="activationStatus-field"
                  name="activationStatus"
                  label={phrases.modules.order_export.activationStatus}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={phrases.modules.orders[activationValues.activationStatus]}
                  margin="dense"
                  disabled={isEdit}
              /> :
              <StyledSelect
                  label={phrases.modules.order_export.activationStatus}
                  id="activationStatus-field"
                  error={errors.activationStatus}
                  helperText={errors.activationStatus}
                  name="activationStatus"
                  value={phrases.modules.orders[activationValues.activationStatus]}
                  onChange={handleChange}
                  data={Object.keys(CustomCompletionStatusEnum).map((status) => {
                    return {
                      name: phrases.modules.orders[status],
                      value: status
                    }
                  })}
                  required
                  plain
              />
              }
            </Grid>
            <Grid item xs={12} sm={2} style={{margin: 'auto'}}>
                <FormControlLabel
                  label={phrases.modules.order_export.isSetup}
                  control={<Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={activationValues.isSetup}
                    onChange={handleChange}
                    color="primary"
                    name="isSetup"
                  />}
                />
            </Grid>
            {selectedMachine?.config?.handCountStartEnd === "true" ? 
            <>
            <Grid item xs={12} sm={3}>
              <TextField
                error={errors.handCount}
                helperText={errors.handCount}
                id="handCount-field"
                name="handCount"
                label={phrases.modules.order_export.handCount}
                variant="outlined"
                type="number"
                fullWidth
                onChange={handleChangeHandCount}
                value={activationValues.handCount}
                margin="dense"
                required={!isEdit}
              />
            </Grid>
            <div className={classes.calculatorBtn}>
            <Grid item xs={12} sm={1} style={{display: 'flex', marginRight: '8px'}} >              
              <Button
                onClick={()=>{setHandCountCalculatorOpen(true)}}                
                size="medium"
                color="primary"
                variant="contained"
              >
                <FontAwesomeIcon
                  icon={faCalculator}
                  size="lg"
                />
              </Button>
            </Grid>
              </div>
          </>: 
          <Grid item xs={12} sm={4}>
            <TextField
              error={errors.handCount}
              helperText={errors.handCount}
              id="handCount-field"
              name="handCount"
              label={phrases.modules.order_export.handCount}
              variant="outlined"
              type="number"
              fullWidth
              onChange={handleChange}
              value={activationValues.handCount}
              margin="dense"
              required={!isEdit}
            />
          </Grid>}
          </Grid>
          <TextField
              error={errors.user}
              helperText={errors.user}
              id="operator-field"
              name="user"
              label={phrases.modules.order_export.operator}
              variant="outlined"
              type="text"
              fullWidth
              onChange={handleChange}
              value={activationValues.user.email}
              margin="dense"
              required
          />       
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                      autoOk
                      ampm={false}
                      disableFuture
                      openTo="hours"
                      inputVariant="outlined"
                      margin="dense"
                      format={phrases.timespanSelection.timeFormat}
                      fullWidth
                      minutesStep={1}
                      maxDate={activationValues.time}
                      InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                              <AccessTimeIcon />
                          </InputAdornment>
                      ),
                      }}
                      value={activationValues.time}
                      onChange={handleChangeStartTime}
                      label={phrases.timespanSelection.from}
                      error={errors.time}
                      helperText={errors.time}
                      disabled={isEdit}
                      required={!isEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                      autoOk
                      ampm={false}
                      disableFuture
                      openTo="hours"
                      inputVariant="outlined"
                      margin="dense"
                      format={phrases.timespanSelection.timeFormat}
                      fullWidth
                      minutesStep={1}
                      maxDate={activationValues.endTime}
                      InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                              <AccessTimeIcon />
                          </InputAdornment>
                      ),
                      }}
                      value={activationValues.endTime}
                      onChange={handleChangeEndTime}
                      label={phrases.timespanSelection.to}
                      error={errors.endTime ?? errors.activationPresent}
                      helperText={errors.endTime ?? errors.activationPresent}
                      required={!isEdit}
                      disabled={toDateDisabled}
                  />
                  </Grid>
              </Grid>
          </MuiPickersUtilsProvider>     
          <Grid container spacing={2}>
            {!isEdit &&
            <Grid item xs={12} sm={6}>  
              <TextField
                error={errors.job}
                helperText={errors.job}
                id="selectedJob-field"
                name="selectedJob"
                label={phrases.modules.order_export.job}
                variant="outlined"
                type="text"
                fullWidth
                onChange={handleJobChange}
                value={jobInformation.job.businessJobId}
                margin="dense"
                disabled={isEdit}
                required={!isEdit}
                notched={_.isEmpty(jobInformation.job) ? false : true}
                InputLabelProps={{
                  shrink: _.isEmpty(jobInformation.job) ? false : true,
                }}
              />
            </Grid>
            }
            <Grid item xs={12} sm={isEdit? 12: 6}>
              <TextField
                id="job-field"
                name="job"
                label={phrases.modules.order_export.selectedJob}
                variant="outlined"
                type="text"
                fullWidth
                value={activationValues.job.businessJobId}
                margin="dense"
                disabled={true}
                notched={_.isEmpty(activationValues.job) ? false : true}
                InputLabelProps={{
                  shrink: _.isEmpty(activationValues.job) ? false : true,
                }}
              />
            </Grid>            
          </Grid>
          
          <div className={classes.formButtons}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formBtn}
              disabled={formSubmitting}
            >
            {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
            {formSubmitting
                && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
            </Button>
            {!isEdit && (
            <Button
                variant="outlined"
                className={classes.formBtn}
                onClick={handleClearForm}
            >
                {phrases.forms.shared.buttons.clear}
            </Button>
            )}
            {isEdit
            && (
                <Button
                variant="outlined"
                className={classes.formBtn}
                onClick={closeModal}
                >
                {phrases.forms.shared.buttons.close}
                </Button>
            )}
          </div>
          <HandCountCalculator
            openModal={handCountCalculatorOpen}
            handleClose={handleCloseHandCountCalculator}
            setHandCount={handleChangeHandCount}
          />
          <ConfirmationModal
            title={phrases.modules.order_export.intendedMachineNotEqualtoMachineTitle}
            text={phrases.modules.order_export.intendedMachineNotEqualtoMachine}
            openModal={confirmationModalOpen}
            handleClose={handleCloseConfirmationModal}
            callback={handleConfirmIntendedMachine}
          />
        </form>
        
    );
}
 
export default OrderExportActivationModal;