/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  FormControl,
  Select,
  FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
}));

const MultipleSelect = (props) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const {
    error,
    label,
    data,
    helperText,
  } = props;

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div>
      <FormControl margin="dense" {...props} error={!!error} variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="mutiple-chip-label">{label}</InputLabel>
        <Select
          labelId="mutiple-chip-label"
          id="mutiple-chip"
          multiple
          {...props}
          labelWidth={labelWidth}
        >
          {data}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : ''}
      </FormControl>
    </div>
  );
};

MultipleSelect.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  helperText: PropTypes.string.isRequired,
};

MultipleSelect.defaultProps = {
  error: false,
};

export default MultipleSelect;
