import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Paper,
  CssBaseline,
  Typography,
  Grid,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import { usePhrases } from '@frontend/utils/usePhrases';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';

const useStyles = makeStyles((theme) => ({
  statusBar: {
    display: 'flex',
    height: theme.spacing(4.5),

  },
  statusBarText: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
  },

  bold: {
    fontWeight: 'bold',
  },

}));

const StickyBar = (props) => {
  const { state, speed } = props;
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { stateText, stateColor } = useStatesFilter();
  const { formatHoursMinutesDuration } = useDateTimeFormat();
  const [currentStateDuration, setCurrentStateDuration] = useState(0);

  useEffect(() => {
    setCurrentStateDuration(Math.round(moment(new Date()).diff(state.time, 'minutes', true)));
  }, [state]);

  return (
    <>
      <CssBaseline />
      <Paper
        className={classes.statusBar}
        style={{ backgroundColor: stateColor(state) }}
        square
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} align="center">
            {stateText(state) !== 'Not Running'
              ? (
                <Typography className={classes.statusBarText} variant="body1">
                  <span className={classes.bold}>
                    {stateText(state)}
                  </span>
                  {' '}
                  {phrases.misc.for}
                  <span className={classes.bold}>
                    {` ${formatHoursMinutesDuration(currentStateDuration)}`}
                  </span>
                  {' '}
                  {phrases.misc.with}
                  {' '}
                  <span className={classes.bold}>
                    {state.actualCount ? Math.abs(Math.round(state.actualCount)) : Math.abs(Math.round(state.deviation))}
                    {' '}
                    {state.actualCount === 0 ? phrases.modules.performance.partsMissed : phrases.modules.performance.partsProduced}
                    {' '}
                  </span>
                  {state.actualCount > 0 &&
                    <Hidden xsDown>
                      {phrases.misc.average}
                      {' '}
                      <span className={classes.bold}>
                        {speed}
                      </span>
                    </Hidden>
                    }
                </Typography>
              ) : (
                <Typography className={classes.statusBarText} variant="body1">
                  <span className={classes.bold}>
                    {stateText(state)}
                  </span>
                  {' '}
                  {phrases.misc.for}
                  <span className={classes.bold}>
                    {` ${formatHoursMinutesDuration(currentStateDuration)}`}
                  </span>
                  {' '}
                  {phrases.misc.with}
                  {' '}
                  <span className={classes.bold}>
                    {state.actualCount ? Math.abs(Math.round(state.actualCount)) : Math.abs(Math.round(state.deviation))}
                    {' '}
                    {state.actualCount === 0 ? phrases.modules.performance.partsMissed : phrases.modules.performance.partsProduced}
                    {''}
                  </span>
                </Typography>
              )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

StickyBar.propTypes = {
  machineRunning: PropTypes.instanceOf(Object).isRequired,
};

export default StickyBar;
