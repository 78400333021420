import { useSnackbar } from 'notistack';

const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const createAlert = (message, variant, options) => enqueueSnackbar(message, {
    variant,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
      preventDuplicate: true,
      autoHideDuration: 100,
    },
    ...options,
  });

  return {
    createAlert,
  };
};

export default useAlert;
