import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  TextField,
} from '@material-ui/core';

import StyledSwitch   from '@frontend/components/StyledSwitch';
import useAlert       from '@frontend/hooks/useAlert';
import useApi         from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import useMachineShiftForm from '@frontend/modules/machine_shift/hooks/useMachineShiftForm';
import MachinesMultiselect from '@frontend/modules/machine/MachinesMultiselect';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
}));

export default function MachineShiftForm(props) {
    const {
      shiftToEdit
    } = props;
    const [formSubmitting, setFormSubmitting] = useState(false);

    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();
    const api = useApi();
    const {
        machineShift,
        handleChangeShift,
        handleSwitch,
        handleCheckboxChange,
        handleChangeMachine,
        handleSubmit,
        selectedMachines,
        handleClearForm,
        errors,
        machines
    } = useMachineShiftForm(submitForm, shiftToEdit);

    const isEdit = !!props.isEdit;
    function submitForm() {
      setFormSubmitting(true);
      console.log(machineShift);
      api(`/api/machine_shift/${isEdit ? shiftToEdit.id: ""}`, {
        data: machineShift,
        method: isEdit ? 'put' : 'post',
      })
        .then((response) => {
          if (response.status === 201) {
            props.onCreate();
            createAlert(phrases.modules.machine_shift.forms.machineShiftAdded, 'success');
            setFormSubmitting(false);
            handleClearForm();
          } else if (response.status === 200) {
            if (isEdit) {
              props.onEdit(response.data);
              createAlert(phrases.modules.machine_shift.forms.machineShiftUpdated, 'success');
              setFormSubmitting(false);
              props.handleCloseModal();
            }
          }
        })
        .catch((error) => {
          const errorMessage = phrases.modules.machine_shift.forms.machineShiftError;
          if (error.response) {
            if (error.response.status === 404) {
              createAlert(errorMessage, 'error');
              setFormSubmitting(false);
            } else if (error.response.status === 403) {
              setFormSubmitting(false);
              createAlert(phrases.errorMessages.notAuthorized, 'error');
            } else {
              createAlert(errorMessage, 'error');
              setFormSubmitting(false);
            }
          }
        });
      
    }

    return (
      <form className={classes.root} noValidate onSubmit={handleSubmit} autoComplete="off">
        <TextField
            id="shiftName"
            name="shiftName"
            label={phrases.modules.machine_shift.forms.name}
            variant="outlined"
            fullWidth
            className={classes.inputField}
            onChange={handleChangeShift}
            value={machineShift.shiftName}
            error={errors.shiftName}
            margin="dense"
            helperText={errors.shiftName}
            required
        />        
        <TextField
            id="startTime"
            name="startTime"
            type='time'
            label={phrases.modules.machine_shift.forms.startTime}
            variant="outlined"
            inputProps={{step: 2}}
            fullWidth
            className={classes.inputField}
            value={machineShift.startTime}
            onChange={handleChangeShift}
            error={errors.endTime}
            margin="dense"
            helperText={errors.startTime}
            required
        /> 
        <TextField
            id="endTime"
            name="endTime"
            type='time'
            inputProps={{step: 2}}
            label={phrases.modules.machine_shift.forms.endTime}
            variant="outlined"
            fullWidth
            className={classes.inputField}
            onChange={handleChangeShift}
            value={machineShift.endTime}
            error={errors.endTime}
            margin="dense"
            helperText={errors.endTime}
            required
        />
        <div>
          
            <StyledSwitch
            id="isActive"
            name="isActive"
            checked={machineShift.isActive}
            onChange={handleSwitch}
            value={machineShift.enabled}
            error={errors.isActive}
            helperText={errors.isActive}
            onLabel={phrases.forms.shared.fields.active}
            offLabel={phrases.forms.shared.fields.inactive}
            />
        </div>
        <MachinesMultiselect
            multiple
            limitTags={5}
            label={phrases.modules.machine_shift.forms.intendedMachine}
            options={machines}
            onChange={handleChangeMachine}
            value={selectedMachines}
            renderTags={(value, getTagProps) => value.map((option, index) => (
              <Chip color="primary" key={option.id} className={classes.chip} label={option.name} {...getTagProps({ index })} />
            ))}
            disableCloseOnSelect={true}
            error={errors.machines}
            // renderTags={(value, getTagProps) => value.map((option, index) => (
            //   <Chip color="primary" key={option.id} className={classes.chip} label={option.name} {...getTagProps({ index })} />
            // ))}
            helperText={errors.machines}
            required
        />
        <FormGroup row>
            <FormControlLabel 
            control={<Checkbox
                        id="monday"
                        name="monday"
                        label={phrases.modules.machine_shift.forms.monday}
                        checked={machineShift.monday}
                        onChange={handleCheckboxChange}
                        value={machineShift.monday}
                    />} 
            label={phrases.modules.machine_shift.forms.monday} 
            />
            <FormControlLabel 
            control={<Checkbox
                        id="tuesday"
                        name="tuesday"
                        label={phrases.modules.machine_shift.forms.tuesday}
                        checked={machineShift.tuesday}
                        onChange={handleCheckboxChange}
                        value={machineShift.tuesday}
                    />} 
            label={phrases.modules.machine_shift.forms.tuesday}
            />
            <FormControlLabel 
            control={<Checkbox
                        id="wednesday"
                        name="wednesday"
                        label={phrases.modules.machine_shift.forms.wednesday}
                        checked={machineShift.wednesday}
                        onChange={handleCheckboxChange}
                        value={machineShift.wednesday}
                    />} 
            label={phrases.modules.machine_shift.forms.wednesday}
            />
            <FormControlLabel 
            control={<Checkbox
                        id="thursday"
                        name="thursday"
                        label={phrases.modules.machine_shift.forms.thursday}
                        checked={machineShift.thursday}
                        onChange={handleCheckboxChange}
                        value={machineShift.thursday}
                    />} 
            label={phrases.modules.machine_shift.forms.thursday}
            />
            <FormControlLabel 
            control={<Checkbox
                        id="friday"
                        name="friday"
                        label={phrases.modules.machine_shift.forms.friday}
                        checked={machineShift.friday}
                        onChange={handleCheckboxChange}
                        value={machineShift.friday}
                    />} 
            label={phrases.modules.machine_shift.forms.friday}
            />
            <FormControlLabel 
            control={<Checkbox
                        id="saturday"
                        name="saturday"
                        label={phrases.modules.machine_shift.forms.saturday}
                        checked={machineShift.saturday}
                        onChange={handleCheckboxChange}
                        value={machineShift.saturday}
                    />} 
            label={phrases.modules.machine_shift.forms.saturday}
            />
            <FormControlLabel 
            control={<Checkbox
                        id="sunday"
                        name="sunday"
                        label={phrases.modules.machine_shift.forms.sunday}
                        checked={machineShift.sunday}
                        onChange={handleCheckboxChange}
                        value={machineShift.sunday}
                    />} 
            label={phrases.modules.machine_shift.forms.sunday}
            />
        </FormGroup>
      <div className={classes.formButtons}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.formBtn}
          disabled={formSubmitting}
        >
          {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
          {formSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
        </Button>
        {!isEdit && <Button
          variant="outlined"
          className={classes.formBtn}
          onClick={handleClearForm}
        >
          {phrases.forms.shared.buttons.clear}
        </Button>}
        {isEdit
          && (
            <Button
              variant="outlined"
              className={classes.formBtn}
              onClick={props.handleCloseModal}
            >
              {phrases.forms.shared.buttons.close}
            </Button>
          )}
      </div>
    </form>
    );
}
