import { useState, useCallback } from 'react';
import useApi from '@frontend/utils/useApi';

const useCsvUpload = () => {
  const api = useApi();
  const [csvFile, setCsvFile] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [uploadFeedback, setUploadFeedback] = useState(null);

  const onFileChange = useCallback((e) => {
    setCsvFile(e.target.files[0]);
  }, []);

  const handleClearFile = useCallback(() => {
    setCsvFile(null);
  }, []);

  const handleClearUploadFeedback = useCallback(() => {
    setUploadFeedback(null);
  }, []);

  const uploadFile = useCallback(() => {
    setUploading(true);
    const formData = new FormData();
    formData.append(
      'file',
      csvFile,
      csvFile.name,
    );
    api('/api/orders/csv-import', {
      method: 'post',
      data: formData,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setUploadFeedback(response.data);
          setUploading(false);
          setCsvFile(null);
        }
      })
      .catch((err) => {
        console.log(err)
        setUploading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvFile]);

  return {
    csvFile,
    uploading,
    onFileChange,
    uploadFile,
    handleClearFile,
    handleClearUploadFeedback,
    uploadFeedback,
  };
};

export default useCsvUpload;
