import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ThemeToggle from '@frontend/components/ThemeToggle';
import UserMenu from '@frontend/modules/user/UserMenu';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton
} from '@material-ui/core';
import useApi from '@frontend/utils/useApi';

import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { mdiRobotIndustrial } from '@mdi/js';
import useIcon from '@frontend/hooks/useIcon';
import { MATERIAL_DESIGN_ICON } from '@frontend/constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useSecurity from '@frontend/utils/useSecurity';
import { setMachineInUse, setStructureInUse } from '@frontend/utils/UIActions';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';
import StructuresDropdown from '@frontend/modules/structure/StructuresDropdown';
import { usePhrases } from '@frontend/utils/usePhrases';
import ReloadDataButton from '@frontend/components/ReloadDataButton';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#F67030',
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  userMenuBtn: {
    borderRadius: '10%',
    padding: theme.spacing(1),

  },
  endAdornment: {
    right: 0,
    top: 'calc(50% - 14px)',
    position: 'absolute',
    fill: "white",
    color: "white",
    "& .MuiAutocomplete-popupIndicator": {
      color: "white",
      fill: "white"
    },
  },
  whiteColor: {
    color: '#FFFFFF'
  },
  root: {
    '& div.Mui-Autocomplete-endAdornment': {
      color: 'white',
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 11px) scale(1);"
    },
  },
  inputRoot: {
    color: "white",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey
    }
  },
  machineSelection: {
    marginTop: theme.spacing(0.5),
  },
  selection: {
    paddingRight: theme.spacing(2),
  },
}));


const TerminalAppbar = (props) => {
  const classes = useStyles();
  const { location } = props;
  const [machines, setMachines] = useState([]);
  // let cancelAxios = null;
  const api = useApi();
  const [machine, setMachine] = useState({});
  const machineInUse = useSelector((state) => state.machineInUse);
  const structureInUse = useSelector((state) => state.structureInUse);
  const { loggedIn } = useSecurity();
  const { setUser } = props;
  const renderIcon = useIcon();
  const dispatch = useDispatch();
  const phrases = usePhrases().phrases();
  const [structures, setStructures] = useState([]);
  const [structure, setStructure] = useState({});
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const isUserMenuOpen = () => !!userMenuAnchorEl;

  const handleChange = (event, machine) => {
    dispatch(setMachineInUse(machine.id));
  };

  function getActiveMachines(machines) {
    const activeMachines = [];

    for (let i = 0; i < machines.length; i++) {
      const currentMachine = machines[i];
      if (currentMachine.isActive) {
        activeMachines.push(currentMachine);
      }
    }

    return activeMachines;
  }

  const handleChangeStructure = (event, structure) => {
    if (structure) {
      dispatch(setStructureInUse(structure.id));
      // This is a fix for #700. The machine shall not be reset after a structure has been changed
      // setMachine({});

    }
  };

  const handleUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/structures/?populate=machines structure', {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setStructures(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api(`/api/machines/${machineInUse}?populate=structure`, {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      if (response.status === 200 || response.status === 304) {
        setMachine(response.data);
      }
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineInUse]);


  useEffect(() => {
    if (structureInUse.trim() !== '') {
      const cancelAxios = axios.CancelToken.source();
      api(`/api/structures/${structureInUse}?populate=machines`, {
        method: 'get',
        cancelToken: cancelAxios.token,
      }).then((response) => {
        if (response.status === 200 || response.status === 304) {
          setStructure(response.data);
          setMachines(getActiveMachines(response.data.machines));
        }
      }).catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureInUse]);

  if (location.pathname.match(/terminal-overview/) || location.pathname.match(/app-terminal-view/)) {
    return (

      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>

          <Grid container spacing={1} className={classes.selection}>
            <Grid item xs={6}>
              <Grid container spacing={1} alignItems="center" >
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    icon={faIndustry}
                    size="2x"
                  />
                </Grid>
                <Grid item xs={10}>
                  <StructuresDropdown
                    style={{ width: '100%' }}
                    options={structures}
                    fullWidth
                    label={phrases.modules.structure.selectStructure}
                    onChange={handleChangeStructure}
                    value={structure}
                    customClasses={classes}
                    disableClearable
                    noAutoClose={false}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={1} alignItems="flex-end" className={classes.machineSelection}>
                <Grid item xs={1}>
                  {renderIcon(MATERIAL_DESIGN_ICON, mdiRobotIndustrial, {
                    size: 1.2,
                  })}
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    id="machineSelect"
                    options={machines}
                    value={machine}
                    style={{ width: '100%' }}
                    fullWidth
                    classes={classes}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    disableClearable
                    onChange={(e, option) => handleChange(e, option)}
                    renderInput={(params) => {
                      let inputLabelProps = params.InputLabelProps;
                      inputLabelProps.className = inputLabelProps.className + " " + classes.whiteColor;
                      
                      return (
                      <TextField
                        {...params}
                        label={phrases.modules.machine.selectMachine}
                        variant="outlined"
                      />
                    )}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <ReloadDataButton/>
          <ThemeToggle />
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              className={classes.userMenuBtn}
              onClick={handleUserMenu}
              color="inherit"
            >
              <AccountCircle />
              <ExpandMore />
            </IconButton>

            {loggedIn() && (
              <UserMenu
                setUser={setUser}
                open={isUserMenuOpen()}
                userMenuAnchorEl={userMenuAnchorEl}
                setUserMenuAnchorEl={setUserMenuAnchorEl}
              />
            )}
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  return null;
};

export default withRouter(TerminalAppbar);
