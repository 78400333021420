/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import StyledSwitch from '@frontend/components/StyledSwitch';
import StructuresDropdown from '@frontend/modules/structure/StructuresDropdown';
import useStructureForm from '@frontend/modules/structure/hooks/useStructureForm';
import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
}));

const StructureForm = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  const {
    isEdit,
    handleCloseModal,
  } = props;

  const timezones = ['', ...moment.tz.names()];

  const {
    handleChange,
    handleStructureChange,
    handleSubmit,
    handleSwitch,
    handleClearForm,
    structureValues,
    dropdownStructures,
    isSubmitting,
    errors,
  } = useStructureForm(props);

  return (
    <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
      <div>
        <TextField
          id="name-field"
          name="name"
          label={phrases.forms.shared.fields.name}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={structureValues.name}
          error={errors.name}
          margin="dense"
          helperText={errors.name}
          required
        />
        <TextField
          id="business-id-field"
          name="businessId"
          label={phrases.forms.shared.fields.businessID}
          variant="outlined"
          fullWidth
          className={classes.inputField}
          onChange={handleChange}
          value={structureValues.businessId}
          error={errors.businessId}
          margin="dense"
          helperText={errors.businessId}
        />
        <TextField
          id="description-field"
          name="description"
          label={phrases.forms.shared.fields.description}
          variant="outlined"
          fullWidth
          className={classes.inputField}
          onChange={handleChange}
          value={structureValues.description}
          error={errors.description}
          margin="dense"
          helperText={errors.description}
        />
        <StructuresDropdown
          label={phrases.forms.structure.fields.parentStructure}
          options={dropdownStructures}
          onChange={handleStructureChange}
          value={structureValues.structure}
          error={errors.structure}
          helperText={errors.structure}
        />
        <TextField
          id="city"
          name="city"
          label={phrases.forms.shared.fields.city}
          variant="outlined"
          fullWidth
          className={classes.inputField}
          onChange={handleChange}
          value={structureValues.city}
          error={errors.city}
          margin="dense"
          helperText={errors.city}
          required
        />
        <TextField
          id="country"
          name="country"
          label={phrases.forms.structure.fields.country}
          variant="outlined"
          fullWidth
          className={classes.inputField}
          onChange={handleChange}
          value={structureValues.country}
          margin="dense"
          error={errors.country}
          helperText={errors.country}
          required
        />
        <div>
          <StyledSwitch
            name="isActive"
            checked={structureValues.isActive}
            onChange={handleSwitch}
            value={structureValues.isActive}
            onLabel={phrases.forms.shared.fields.active}
            offLabel={phrases.forms.shared.fields.inactive}
          />
        </div>
        <Autocomplete
          id="timezone"
          name="timezone"
          className={classes.inputField}
          options={timezones}
          onBlur={handleChange}
          value={structureValues.timezone}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={phrases.forms.shared.fields.timezone}
              name="timezone"
              className={classes.inputField}
              error={errors.timezone}
              helperText={errors.timezone}
              variant="outlined"
              margin="dense"
              required
            />
          )}
        />
        <div className={classes.formButtons}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className={classes.formBtn}
            disabled={isSubmitting}
          >
            {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
            {isSubmitting
              && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
          </Button>
          {!isEdit && <Button
            variant="outlined"
            className={classes.formBtn}
            onClick={handleClearForm}
          >
            {phrases.forms.shared.buttons.clear}
          </Button>}
          {isEdit
            && (
              <Button
                variant="outlined"
                className={classes.formBtn}
                onClick={handleCloseModal}
              >
                {phrases.forms.shared.buttons.close}
              </Button>
            )}
        </div>
      </div>
    </form>
  );
};

StructureForm.propTypes = {
  structure: PropTypes.instanceOf(Object),
  isEdit: PropTypes.bool,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  handleCloseModal: PropTypes.func,
};

StructureForm.defaultProps = {
  structure: null,
  isEdit: false,
  onCreate: () => { },
  onEdit: () => { },
  handleCloseModal: () => { },
};

export default StructureForm;
