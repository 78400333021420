import React, { useState, useRef, useEffect, useCallback } from 'react';
import _ from 'lodash';
import {
    Badge,
    Typography,
    Button,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Avatar,
  } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CommentIcon from '@material-ui/icons/Comment';
import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import useSecurity from '@frontend/utils/useSecurity';
import LOADING_PROPS from '@frontend/modules/orders/constants';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      position: 'static',
    },
    topCardsContainer: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    jobControlBtn: {
      width: theme.spacing(30),
    },
    verticalContainer: {
      display: 'table',
    },
    verticallyAlignedText: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    jobInfo: {
      marginLeft: theme.spacing(1),
    },
    submitButton: {
      marginTop: theme.spacing(1.5),
    },
    buttonProgress: {
      color: theme.palette.primary[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));
const JobNotesDialog = (props) => {
    const classes = useStyles();
    const lastNote = useRef(null);
    const { jobTracker,
            addNote,
            loadingPropsObject } = props;
    const phrases = usePhrases().phrases();
    const { formatDate } = useDateTimeFormat();
    const [notesDialogOpen, setNotesDialogOpen] = useState(false);
    const [noteText, setNoteText] = useState('');
    const { getProfile } = useSecurity();
    const handleNoteTextChange = (e) => {
        setNoteText(e.target.value);
    };
    const scrollToBottom = () => {
        if (lastNote && lastNote.current)
          lastNote.current.scrollIntoView({ behavior: 'smooth' });
      };
    const handleClickOpen = () => {
        setNotesDialogOpen(true);
    };
    
    const handleClose = () => {
        setNotesDialogOpen(false);
    };

    const handleSubmitNote = useCallback(() => {
        setNoteText('');
        addNote({
            text: noteText,
            user: getProfile().id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobTracker, noteText]);

    useEffect(() => {
        scrollToBottom();
    }, [noteText]);
    return ( 
        <>
        <Typography color="textSecondary">
          {phrases.modules.orders.operatorNotes}:
          <IconButton
            style={{ marginLeft: 10 }}
            disabled={_.isEmpty(jobTracker)}
            onClick={handleClickOpen}
          >
            <Badge badgeContent={jobTracker?.notes?.length} color="primary">
              <CommentIcon />
            </Badge>
          </IconButton>
        </Typography>
        <Dialog
          onEntered={scrollToBottom}
          fullWidth
          open={notesDialogOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {phrases.modules.orders.operatorNotes}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <List>
              {jobTracker?.notes?.map((note, i) => {
                return (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar variant="rounded" alt={note?.user?.name}>
                          {note?.user?.name[0]}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={note.text}
                        secondary={
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textSecondary"
                          >
                            {`${formatDate(note.createdAt)} - ${
                              note?.user?.name
                            }`}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                );
              })}
              {loadingPropsObject[LOADING_PROPS.ADDING_NOTE] ? (
                <>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={40}
                        height={40}
                      >
                        <Avatar />
                      </Skeleton>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton width="100%">
                          <Typography>.</Typography>
                        </Skeleton>
                      }
                      secondary={
                        <Skeleton width="50%">
                          <Typography>.</Typography>
                        </Skeleton>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              ) : (
                ''
              )}
              <div ref={lastNote} />
            </List>
          </DialogContent>
          <DialogContent style={{ overflow: 'visible' }}>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              multiline
              rows={3}
              id="name"
              label="Note text"
              type="text"
              fullWidth
              value={noteText}
              onChange={handleNoteTextChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              {phrases.forms.shared.buttons.cancel}
            </Button>
            <Button
              disabled={!noteText}
              onClick={handleSubmitNote}
              color="primary"
            >
              {phrases.forms.shared.buttons.submit}
            </Button>
          </DialogActions>
        </Dialog>
        </>
     );
}
 
export default JobNotesDialog;