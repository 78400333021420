import React, {useLayoutEffect, useRef} from 'react';
import { useParams }                from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme }         from '@material-ui/core';
import {
    Button,
    Divider,
    Grid,
    Hidden,
    Paper,
    Typography
} from '@material-ui/core';
import BreadcrumbsNavigation from '@frontend/components/BreadcrumbsNavigation';
import TimespanSelection      from '@frontend/components/TimespanSelection';
import { usePhrases } from '@frontend/utils/usePhrases';
import useMachine       from '@frontend/modules/machine/hooks/useMachine';
import LoadingIndicator     from '@frontend/components/LoadingIndicator';
import useMachinePerformance from '@frontend/modules/machine_performance/hooks/useMachinePerformance';

import * as am5             from '@amcharts/amcharts5';
import * as am5xy           from "@amcharts/amcharts5/xy";
import am5themes_Animated   from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

import TheoreticalMaximumOutputCard from './TheoreticalMaximumOutputCard';
import ScheduledOutputCard from './ScheduledOutputCard';
import PlannedOutputCard from './PlannedOutputCard';
import DowntimeCard from './DowntimeCard';
import SpeedLossCard from './SpeedLossCard';
import QualityLossCard from './QualityLossCard';
import ArrowBackIcon  from '@material-ui/icons/ArrowBack';
import CustomLink             from '@frontend/components/CustomLink';
import { ROUTES }         from '@frontend/constants'; 

am5.addLicense("AM5C377488971");

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
      },
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    machinePulsesContainer: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    pushDiv: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(9),
      },
      marginTop: theme.spacing(5),
    },
    topCardsContainer: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    cardCell: {
      padding: theme.spacing(1.5),
    },
    cardCellWithIcon: {
      padding: theme.spacing(1),
    },
    progress: {
      textAlign: 'center',
      margin: 'auto',
    },
    backdrop: {
      zIndex: 1,
      position: 'fixed',
      // left: 0,
      width: 'calc(100% - 56px)',
      textAlign: 'center',
    },
}));

const MachinePerformanceOverview = () => {
    
    const { machineId } = useParams();
    const {
        machine,
        isLoadingMachines,
        getMachineStatesTimeframe,
        getMachineStatesTimeframeRelative,
      } = useMachine(machineId);
    const classes = useStyles();
    const exportingRef = useRef(null);
    const seriesRef = useRef(null);
    const theme = useTheme();
    const phrases = usePhrases().phrases();

    // // ==== States ====
    // const [selectedMachine, setSelectedMachine] = useState(machineId);

    const { 
        performance, 
        isLoadingPerformance 
    } = useMachinePerformance(machineId);
    
    // useEffect(() => {
    //     setSelectedMachine(machineId);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [machineId]);

    useLayoutEffect(() => {
        var root = am5.Root.new("chartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root.interfaceColors.set("grid", am5.color(theme.palette.text.primary));
        root.interfaceColors.set("text", am5.color(theme.palette.text.primary));
        
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            pinchZoomX: false
        }));
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({
            rotation: -90,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15
        });

        xRenderer.grid.template.setAll({
            location: 1
        })

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "name",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            })
        }));


        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            sequencedInterpolation: true,
            categoryXField: "name",
            description: "description",
            tooltip: am5.Tooltip.new(root, {
                labelText: `{valueY} ${phrases.modules.machinePerformance.unit}`
            })
        }));

        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
        series.columns.template.adapters.add("fill", function(fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function(stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });
        let data = [{
            "key": "theoreticalOutput",
            "name": phrases.modules.machinePerformance.theoreticalOutput,
            "value": 0,
            "description": phrases.modules.machinePerformance.theoreticalOutputDesc,
            "comment": null
        }, {
            "key": "plannedOutput",
            "name": phrases.modules.machinePerformance.workingOutput,
            "value": 0,
            "description": phrases.modules.machinePerformance.workingOutputDesc,
            "comment": null
        }, {
            "key": "scheduledOutput",
            "name": phrases.modules.machinePerformance.scheduledOutput,
            "value": 0,
            "description": phrases.modules.machinePerformance.scheduledOutputDesc,
            "comment": null
        }, {
            "key": "downtimeLoss",
            "name": phrases.modules.machinePerformance.downtimeLoss,
            "value": 0,
            "description": phrases.modules.machinePerformance.downtimeLossDesc,
            "comment": null
        }, {
            "key": "speedLoss",
            "name": phrases.modules.machinePerformance.speedLoss,
            "value": 0,
            "description": phrases.modules.machinePerformance.speedLossDesc,
            "comment": null
        }, {
            "key": "qualityLoss",
            "name": phrases.modules.machinePerformance.qualityLoss,
            "value": 0,
            "description": phrases.modules.machinePerformance.qualityLossDesc,
            "comment": null
        }];

        xAxis.data.setAll(data);
        series.data.setAll(data);
        seriesRef.current = series;
        let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: data, 
            pdfOptions: {
                includeData: true,
                pageSize: "A4",
                pageOrientation: "landscape",
                pageMargins: [20, 20, 20, 40]
            }
        });
        exportingRef.current = exporting;
        series.appear(1000);
        chart.appear(1000, 100);
        return () => {
            chart.dispose();
        };


        // let chart = am4core.create("chartdiv", am4charts.SlicedChart);
        // chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
        
        // var series = chart.series.push(new am4charts.FunnelSeries());
        // series.colors.step = 2;
        // series.dataFields.value = "value";
        // series.dataFields.category = "name";
        // series.dataFields.description = "description";
        // series.orientation = "horizontal";
        // series.labels.template.disabled = false;
        // series.labels.template.text = `{value} ${phrases.modules.machinePerformance.unit}`
        // series.alignLabels = true;
        // series.labels.template.fill = am4core.color(theme.palette.text.secondary)
        // series.slices.template.tooltipText = `{value} ${phrases.modules.machinePerformance.unit}`;

        // chart.legend = new am4charts.Legend();
        // chart.legend.position = "left";
        // chart.legend.valign = "middle";
        // chart.legend.margin(5,5,20,5);
        // chart.legend.labels.template.fill = am4core.color(theme.palette.text.secondary);
        // chart.legend.valueLabels.template.fill = am4core.color(theme.palette.text.secondary);
        // chart.legend.labels.template.text = "[bold]{category}:";
        // chart.legend.valueLabels.template.text = `{value} ${phrases.modules.machinePerformance.unit}`;
        // chart.legend.maxWidth = undefined;

        // chart.exporting.menu = new am4core.ExportMenu();
        // chart.exporting.filePrefix = `${selectedMachine}`;
        // // chart.height = "400px";
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        let series = seriesRef.current;
        series.data.setAll([{
            ...series.data.getIndex(0),
            "value": parseFloat(performance.theoreticalOutput.value),
            "comment": performance.theoreticalOutput.comment
        }, {
            ...series.data.getIndex(1),
            "value": parseFloat(performance.plannedOutput.value),
            "comment": performance.plannedOutput.comment
        }, {
            ...series.data.getIndex(2),
            "value": parseFloat(performance.scheduledOutput.value),
            "comment": performance.scheduledOutput.comment
        }, {
            ...series.data.getIndex(3),
            "value": parseFloat(performance.downtimeLoss.value),
            "comment": performance.downtimeLoss.comment
        }, {
            ...series.data.getIndex(4),
            "value": parseFloat(performance.speedLoss.value),
            "comment": performance.speedLoss.comment
        }, {
            ...series.data.getIndex(5),
            "value": parseFloat(performance.qualityLoss.value),
            "comment": performance.qualityLoss.comment
        }]);
        am5.array.each(series.dataItems, function (dataItem) {
            var category = dataItem.dataContext["key"];
            var value = parseFloat(performance[category]["value"]);
      
            dataItem.animate({
              key: "valueY",
              to: value,
              duration: 500,
              easing: am5.ease.linear
            });
            dataItem.animate({
                key: "valueYWorking",
                to: value,
                duration: 500,
                easing: am5.ease.linear
            });
          });
        
        exportingRef.current.set("dataSource", series.data.values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performance]);
    // ==== Return ====
    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={2} className={classes.root}>
                    <Hidden xsDown>
                        <Grid item xs={12}>
                        {machine.structure && (
                            <BreadcrumbsNavigation
                            selectedStructure={machine.structure}
                            selectedMachine={machine}
                            />
                        )}
                        </Grid>
                    </Hidden>
                    {isLoadingMachines ? 
                    <LoadingIndicator
                        size={60}
                        thickness={3.5}
                        text={phrases.modules.machine.loadingMachine}
                    /> : 
                    ""               
                    }
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Button
                                startIcon={
                                    <ArrowBackIcon style={{'vertical-align': "sub"}}>
                                    {phrases.modules.machinePerformance.backToMachineView}
                                    </ArrowBackIcon>
                                }
                                variant="contained"
                                component={CustomLink}
                                to={ROUTES.MACHINE_OVERVIEW}
                                id={machineId} object="machine"
                                >
                                {phrases.modules.machinePerformance.backToMachineView}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Typography variant="button" display="block" color="textSecondary">
                            {phrases.modules.machinePerformance.machinePerformance}
                        </Typography>
                        <Divider />
                    </Grid>  
                    <Grid item xs={12}>
                        <Paper variant="outlined" square>
                            <div id="chartdiv" style={{ height: "550px"}} className={classes.cardCell}></div>
                        </Paper>
                    </Grid>
                    {isLoadingMachines || performance.default ? " " :<>
                    <TheoreticalMaximumOutputCard
                        theoreticalOutputData={performance.theoreticalOutput}
                        isLoadingPerformance={isLoadingPerformance} 
                    />
                    <ScheduledOutputCard 
                        scheduledOutputData={performance.scheduledOutput}
                        isLoadingPerformance={isLoadingPerformance}
                    />
                    <PlannedOutputCard
                        plannedOutputData={performance.plannedOutput}
                        isLoadingPerformance={isLoadingPerformance}
                    />
                    <DowntimeCard
                        downtimeData={performance.downtimeLoss}
                        isLoadingPerformance={isLoadingPerformance}
                    />
                    <SpeedLossCard 
                        speedLossData={performance.speedLoss}
                        isLoadingPerformance={isLoadingPerformance}
                    />
                    <QualityLossCard 
                        qualityLossData={performance.qualityLoss}
                        isLoadingPerformance={isLoadingPerformance}
                    />
                    </>}
                </Grid>          
            </div>
            <TimespanSelection
                selectTimeframe={getMachineStatesTimeframe}
                selectTimeframeRelative={getMachineStatesTimeframeRelative}
                selectTimeframeCustom={getMachineStatesTimeframe}
                disabled={isLoadingMachines}
            />
        </>
    );
}
 
export default MachinePerformanceOverview;