import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Divider,
    Backdrop,
} from '@material-ui/core'
import useStructureOverview from '@frontend/modules/structure/hooks/useStructureOverview';
import ARS from './advanced_reporting.service'
import useMachineKPIs from '@frontend/hooks/useMachineKPIs';
import AverageOverviewCard from './AverageOverviewCard';
import MachineRankingCard from './MachineRankingCard';
import ReasonRankingCard from './ReasonRankingCard';
import { usePhrases } from '@frontend/utils/usePhrases';
import TimespanSelector from './TimespanSelector';
import LoadingIndicator from '@frontend/components/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
    lastTableCell: {
      borderBottom: 'none',
    },
    cardCell: {
      padding: theme.spacing(1.5),
    },
    chartlabel: {
      color: theme.palette.primary.main,
    },

}));

const StructureInsightsView = (props) => {
    const structure = useSelector((state) => state.structure);
    const structureId = structure.id
    const [hours, setHours] = useState(1);
    const {
      runningCalculations,
      isStructureLoading,
      isLoadingMachines,
    } = useStructureOverview(structureId);
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    // TODO refactor to constant strings in the file
    const machineStates = useSelector((state) => state.machineStates);
    const {
      getMachinePerformance,
      getMachineOEE,
      getMachineRuntime,
      getMachineDowntime
    } = useMachineKPIs();

    // Calculate the data
    var avgDowntime = ARS.getAVGMachineData(getMachineDowntime, props.machines, machineStates);
    var performances = ARS.getMachineRanking(3, getMachinePerformance, props.machines, machineStates);
    var avgRuntime = ARS.getAVGMachineData(getMachineRuntime, props.machines, machineStates);
    var avgOEE = ARS.getAVGMachineData(getMachineOEE, props.machines, machineStates);
    const theme = useTheme();
    return (
        <>
          <TimespanSelector
            setHours={setHours}
            hours={hours}
            toText={phrases.modules.advanced_reporting.toText}
            fromText={phrases.modules.advanced_reporting.fromText}
            heading={phrases.modules.advanced_reporting.timeSelectorHeading}
          />
          <Grid item xs={12}>
            <Typography variant="button" display="block" color="textSecondary">
              Structure Insights
            </Typography>
            <Divider />
          </Grid>
          <MachineRankingCard
            color={theme.palette.text}
            chart_id={phrases.modules.advanced_reporting.machineRankingChartID}
            machines={props.machines}
            performances={performances}
            // heading="LOL"
            heading={phrases.modules.advanced_reporting.machineRankingHeadingText}
            chart_value_id={phrases.modules.advanced_reporting.machineRankingValue}
            chart_category_id={phrases.modules.advanced_reporting.machineRankingCategory}
            no_performance_value={phrases.modules.advanced_reporting.noPerformanceValue}
            no_performance_text={phrases.modules.advanced_reporting.noPerformanceText}
          />
          <ReasonRankingCard
            color={theme.palette.text}
            chart_id={phrases.modules.advanced_reporting.reasonRankingChartID}
            hours={hours}
            newStates={machineStates}
            heading={phrases.modules.advanced_reporting.reasonRankingHeadingText}
            chart_value_id={phrases.modules.advanced_reporting.reasonRankingValue}
            chart_category_id={phrases.modules.advanced_reporting.reasonRankingCategory}
            no_reasons_text={phrases.modules.advanced_reporting.noReasonsText}
            no_reasons_value={phrases.modules.advanced_reporting.noReasonsValue}
          />

          <AverageOverviewCard
            color={theme.palette.text}
            chart_id={phrases.modules.advanced_reporting.averageChartID}
            avgOEE={avgOEE}
            avgRuntime={avgRuntime}
            avgDowntime={avgDowntime}
            heading={phrases.modules.advanced_reporting.averageHeadingText}
            chart_value_id={phrases.modules.advanced_reporting.averageValue}
            chart_category_id={phrases.modules.advanced_reporting.averageCategory}
          />
          <Backdrop
            classes={{ root: classes.backdrop }}
            open={isStructureLoading || runningCalculations || isLoadingMachines}
          >
          {isStructureLoading ? (
            <LoadingIndicator
              size={60}
              thikness={3.5}
              text={phrases.modules.structure.structureLoading}
            />
            ) : runningCalculations ? (
            <LoadingIndicator
              size={60}
              thikness={3.5}
              text={phrases.modules.structure.runningCalculations}
            />
            ) : isLoadingMachines ? (
            <LoadingIndicator
              size={60}
              thikness={3.5}
              text={phrases.modules.reporting.loadingMachines}
            />
            ) : (
                ''
              )}
          </Backdrop>
        </>
     );
}

export default StructureInsightsView;
