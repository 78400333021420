import React from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';

import Modal                  from '@frontend/components/Modal';
import { usePhrases }         from '@frontend/utils/usePhrases';
import PartsChartModalContent from '@frontend/modules/machine/PartsChartModalContent';
const useStyles = makeStyles((theme) => ({
    
  }));
const PartsChartModal = (props) => {

    const {
        machineId,
        modalOpen,
        targetCycleTime,
        setModalOpen 
    } = props;
    
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const classes = useStyles();
    const phrases = usePhrases().phrases();
    return ( 
        <Modal
            modalTitle={phrases.modules.performance.chartPartsTitle}
            open={
                modalOpen
            }
            className={classes.statusesModal}
            handleClose={handleCloseModal}
            maxWidth="lg"
            content={(
                
                <>
                <PartsChartModalContent
                    machineId = {machineId}
                    fullyFeatured = {2}
                    isPartsPerMinute = {true}
                    targetCycleTime = {targetCycleTime}
                />
                </>
                               
            )}
        />);
}
 
export default PartsChartModal;