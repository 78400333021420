import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// eslint-disable-next-line import/no-unresolved
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';

const Time = (props) => {
  const { timezone } = props;
  const { formatTimeWithSeconds } = useDateTimeFormat();
  const [currentTime, setCurrentTime] = useState(formatTimeWithSeconds(moment().tz(timezone)));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(formatTimeWithSeconds(moment().tz(timezone)));
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  return (
    <>{currentTime}</>
      
  );
};

Time.propTypes = {
  timezone: PropTypes.string.isRequired,
};

export default Time;
