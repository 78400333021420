import useSecurity from '@frontend/utils/useSecurity';
import { usePhrases } from '@frontend/utils/usePhrases';
import useDateTimeFormat      from '@frontend/utils/useDateTimeFormat';


const useUnitCalculation = (override=null) => {
    const { getProfile } = useSecurity();
    const phrases = usePhrases().phrases();
    const { toLocaleNumber } = useDateTimeFormat();
    const units = [
        {
        short: 'SPP', 
        name: phrases.modules.user.units["SPP"]
        }, {
        short: 'MPP', 
        name: phrases.modules.user.units["MPP"]
        }, {
        short: 'PPS', 
        name: phrases.modules.user.units["PPS"]
        }, {
        short: 'PPM', 
        name: phrases.modules.user.units["PPM"]
        }, {
        short: 'PPH', 
        name: phrases.modules.user.units["PPH"]
        }
    ]
    
    const _AnytoSPP = (value, bu) => {
        switch (bu) {
            case "SPP":
                return value;                
            case "MPP":
                return value * 60;
            case "PPS":
                return parseFloat(1 / value).toFixed(2);
            case "PPM":
                return parseFloat(60 / value).toFixed(2);
            case "PPH":
                return parseFloat(60 * 60 / value).toFixed(2);
            default:
                return value;
        }
    }

    // Each Value is provided in Seconds Per Part
    const _toMPP = (value, baseUnit="SPP") => {
        let sspValue = _AnytoSPP(value, baseUnit)
        return parseFloat(sspValue / 60).toFixed(2);
    }

    const _toPPS = (value, baseUnit = "SPP") => {
        let sspValue = _AnytoSPP(value, baseUnit)
        return parseFloat(1 / sspValue).toFixed(2);
    }

    const _toPPM = (value, baseUnit = "SPP") => {
        let sspValue = _AnytoSPP(value, baseUnit)
        return parseFloat(60/sspValue).toFixed(2);
    }

    const _toPPH = (value, baseUnit = "SPP") => {
        let sspValue = _AnytoSPP(value, baseUnit)
        return parseFloat(60*60/sspValue).toFixed(2);
    }

    /**
     * This function will handle the conversion of the value to the unit preference in the user profile
     * @param {The value that should be converted to the unit preference in the user profile} value 
     * @param {The unit of the value, default Seconds Per Part} baseUnit 
     * @returns float, 2 decimal places in correct unit
     */
    const renderUnit = (value, baseUnit = "SPP", plain=false) => {
        let result = 0;
        if (parseFloat(value) === 0) {
            return "0";
        }
        switch (override === null || override === undefined ? getProfile().unit: override) {
            case "SPP":
                result = _AnytoSPP(value, baseUnit);
                break;
            case "MPP":
                result = _toMPP(value, baseUnit);
                break;
            case "PPS":
                result = _toPPS(value, baseUnit);
                break;
            case "PPM":
                result = _toPPM(value, baseUnit);
                break;
            case "PPH":
                result = _toPPH(value, baseUnit);
                break;
            default:
                result = _AnytoSPP(value, baseUnit);
                break;
        }
        return plain ? result : toLocaleNumber(result)
    }
    
    const renderUnitName = (caps=false) => {
        const unit = override === null || override === undefined ? getProfile().unit : override;
        return caps ? phrases.modules.user.units.short_caps[unit] : phrases.modules.user.units.short[unit];
    }
    return {
        renderUnit,
        renderUnitName,
        units
    };
}
 
export default useUnitCalculation;