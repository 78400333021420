import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPaletteType } from '@frontend/utils/UIActions';
import Toggle from '@frontend/components/Toggle';

export default function ThemeToggle() {
  const getLightTheme = () => JSON.parse(localStorage.getItem('lightTheme'));

  const setLightTheme = (value) => {
    localStorage.setItem('lightTheme', JSON.stringify(value));
  };

  const dispatch = useDispatch();
  const [lightThemeValue, setLightThemeValue] = useState(!!getLightTheme());
  const [toggleChecked, setToggleChecked] = useState(!!getLightTheme());

  useEffect(() => {
    if (lightThemeValue) {
      dispatch(setPaletteType('light'));
    } else {
      dispatch(setPaletteType('dark'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightThemeValue]);

  const handleThemeChange = () => {
    setLightTheme(!lightThemeValue);
    setLightThemeValue(!lightThemeValue);
    setToggleChecked(!toggleChecked);
  };

  return (

    <Toggle darkTheme={!lightThemeValue} handleThemeChange={handleThemeChange} />

  );
}
