import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateStructureForm = () => {
  const phrases = usePhrases().phrases();
  const validateStructureFrom = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = phrases.forms.structure.errors.structureNameRequired;
    }

    if (!values.city) {
      errors.city = phrases.forms.structure.errors.structureCityRequired;
    }

    if (!values.country) {
      errors.country = phrases.forms.structure.errors.structureCountryRequired;
    }

    if (!values.timezone) {
      errors.timezone = phrases.forms.structure.errors.structureTimezoneRequired;
    }

    return errors;
  };

  return {
    validateStructureFrom,
  };
};

export default useValidateStructureForm;
