import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import BlockIcon from '@material-ui/icons/Block';
import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles(() => ({
  iconButton: {
  },
  iconContainer: {
    marginRight: '24px',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
}));

const SensorMultiselectToolbar = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  const handleClickDeselectAll = () => {
    props.setSelectedRows([]);
  };

  const handleDeactivateSensors = () => {
    props.deactivateSensors(props.selectedRows);
    props.setSelectedRows([]);
  };

  return (
    <div className={classes.iconContainer}>
      <Tooltip title={phrases.misc.deselectAll}>
        <IconButton className={classes.iconButton} onClick={handleClickDeselectAll}>
          <IndeterminateCheckBoxIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title={phrases.modules.sensor.deactivateSensors}>
        <IconButton className={classes.iconButton} onClick={handleDeactivateSensors}>
          <BlockIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default SensorMultiselectToolbar;
