import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import {
  faTabletAlt,
  faTh,
  faChartArea
} from '@fortawesome/free-solid-svg-icons';

import CustomTabs         from '@frontend/components/Tabs';
import { CUSTOMER_LOGO }  from '@frontend/constants';
import logo             from '@frontend/static/logo.png';
import customerLogo     from '@frontend/static/customerLogo.png';
import backgroundImage  from '@frontend/static/background.png';
import { usePhrases }   from '@frontend/utils/usePhrases';
import TerminalLoginForm  from '@frontend/modules/terminal/TerminalLoginForm';
import UserLoginForm      from '@frontend/modules/user/UserLoginForm';

const useStyles = makeStyles((theme) => ({

  logoLogin: {
    marginTop: theme.spacing(8),
    alignItems: 'center',
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },

  bodyLogin: {
    minHeight: '100vh',
    backgroundImage: `url(${backgroundImage})`,
  },

  cardLogin: {
    maxWidth: 800,
  },

  customerLogo: {    
    marginTop: theme.spacing(8),
    alignItems: 'center',
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
    },
  },

}));

const Login = ({ tab }) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, [])

  
  return (

    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.bodyLogin}
    >
      <Card
        raised
        className={classes.cardLogin}
      >

        <Grid
          container
          justify="center"
        >
          <Hidden xsDown>
            <img
              src={logo}
              alt="LeanFocus logo"
              width="15%"
              height="15%"
              className={classes.logoLogin}
            />
            {customerLogo && CUSTOMER_LOGO.enabled &&
              <img
                src={customerLogo}
                alt={CUSTOMER_LOGO.altText}
                width="20%"
                height="20%"
                className={classes.customerLogo}
              />
            }
          </Hidden>
          
        </Grid>
        <CustomTabs
          variant="fullWidth"
          value={tab || 0}
          tabs={[
            {
              label: phrases.tabs.AppLogin,
              icon: faTh,
            },
            {
              label: phrases.tabs.TerminalLogin,
              icon: faTabletAlt,
            },
            {
              label: phrases.tabs.SimpleView2Login,
              icon: faChartArea,
            },
          ]}
          panels={[
            {
              content: <UserLoginForm />,
            },
            {
              content: <TerminalLoginForm />,
            },
            {
              content: <TerminalLoginForm isSimpleMachineLogin={true}/>,
            },
          ]}
        />

      </Card>

    </Grid>

  );
};

export default Login;
