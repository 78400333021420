import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MoonIcon } from '@frontend/static/moon.svg';
import { ReactComponent as SunIcon } from '@frontend/static//sun.svg';

const Toggle = (props) => {
  const { darkTheme, handleThemeChange } = props;

  const ToggleContainer = styled.button`
  background: ${darkTheme ? '#212121' : '#aeaeae'};
  border: 2px solid ${darkTheme ? '#707070' : '#FFF'};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem;
  overflow: hidden;
  position: relative;
  height: 2.0rem;

  svg {
    height: 1.4rem;
    width: 1.7rem;
    transition: all 0.3s linear;
    
    // sun icon
    &:first-child {
      transform: ${darkTheme ? 'translateY(100px)' : 'translateY(0)'};
    }
    
    // moon icon
    &:nth-child(2) {
      transform: ${darkTheme ? 'translateY(0)' : 'translateY(-100px)'};
    }
  }
`;

  return (
    <ToggleContainer onClick={handleThemeChange} darkTheme={darkTheme}>
      <SunIcon />
      <MoonIcon />
    </ToggleContainer>
  );
};

export default Toggle;
