
/**
 * Author: Julian Dreyer
 * This function will generate the average machine data, calculated by the
 * provided machines and their states. The dataFunc parameter shall be an 
 * existing hook for machine data retrieval, like OEE.
 * Return
 * - the average of the machine metric
 */
 const getAVGMachineData = (dataFunc, machines, machineStates) => {
  let avg = 0;
  let validEntries = 0;
  // Iterate over each machine present in the current view and calculate the average
  machines.forEach((machine) => {    
    let data = dataFunc(machine, machineStates);
    if(data !== "N/A"){
      avg += data;
      validEntries += 1;
    }
  });
  // There might be no values, then we return 0
  return validEntries === 0 ? 0 : Math.round(avg/validEntries) ;
}

/**
 * Author: Julian Dreyer
 * This function will generate the machine Ranking in descenting order. The best
 * performing machine, depending on its performance, will be first in the list
 * Parameters:
 * - numRanks               How many ranks shall be included (default 3)
 * - getPerformance         Hook for the performance retrieval
 * - machines               all Machines in the current overview
 * - machineStates          all Machienestates in the current overview
 * Return:
 * - List of Machines and their performances in percent
 */
const getMachineRanking = (numRanks, getPerformance, machines, machineStates) => {
  
  let returnData = []
  machines.forEach((machine) => {
    let perf = getPerformance(machine, machineStates)
    // Cap the performance at 100%
    perf = perf > 100.0 ? 100 : perf
    let machineInfo = {
      "name": machine.name,
      "performance": perf === "N/A" ? 0 : perf,
    }
    returnData.push(machineInfo)
  })
  // Sort the array by their performance
  returnData.sort((a, b) => {
    return a.performance - b.performance
  }).reverse()

  // Cut the array at numRanks
  return returnData.slice(0, numRanks)
}

const filterStatesByDate = (hours, states) => {
  /*
  States: {
    <machine_id>: {
      states: [
        {state1},
        {state2}
      ]
    }
  }
  */
  let currentTime = new Date()
  let newMachineStates = {};
  Object.assign(newMachineStates, states);

  console.log(states);
  for (let [key, value] of Object.entries(states))
  {
    newMachineStates[key].states.length = 0
    let statesOfMachine = value.states;
    console.log(statesOfMachine);
    statesOfMachine.forEach(state => {
      const stateDate = new Date(state.time)
      const diffHours = (currentTime.getTime() - stateDate.getTime()) / (1000*60*60)
      if(diffHours <= hours){
        // console.log(diffHours, state)
        newMachineStates[key].states.push(state)
      }          
    })
  }
  console.log(newMachineStates)
  // let newStates = {}
  // // Get the current time
  // let currentTime = new Date()
  // Object.keys(states).forEach((machine_id) => {
  //   // For each machine sort the states
  //   const machine = states[machine_id]  
  //   newStates[machine_id] = machine
  //   newStates[machine_id].states = []
  //   // Machine contains the states
  //   machine.states.forEach((state) => {
  //     const stateDate = new Date(state.time)
  //     const diffHours = (currentTime.getTime() - stateDate.getTime()) / (1000*60*60)
  //     if(diffHours <= hours){
  //       newStates[machine_id].push(state);
  //     }
  //   })
  // })
  // console.log(states)
  // console.log(newStates)
  return newMachineStates
}


/**
 * Author: Julian Dreyer
 * This function will filter all the machine states and count their appearances. It will
 * then return a list with the three most occurring failure reasons and their number of 
 * occurrances.
 */
const filterAndRankMachineStates = (numRanks, states, hours) => {
    const currentDate = new Date();
    // Alternatively useTopReasons?
    // What does the response indicate? the Number id?
    let reasonDict = {}
    // Store the reasons in the dict
    for (let [, value] of Object.entries(states))
    {
       let statesOfMachine = value.states;
      //  console.log(statesOfMachine);
       statesOfMachine.forEach(state => {
         // the field "presentation.reason" holds the failure reason of the machine state
         const reason = state["presentation.reason"];
         const stateDate = new Date(state.time)
         const diffHours = (currentDate.getTime() - stateDate.getTime()) / (1000*60*60)
         if(diffHours <= hours){
          if(reason !== null){
            if (reasonDict[reason] === undefined){
              reasonDict[reason] = 1
            } else{
              reasonDict[reason] += 1
            }
          }  
         }               
       })
    }

    // Now sort them depending on their frequency of appearance
    let listReasons = []
    var keys = Object.keys(reasonDict);
    keys.forEach(function(key){
        let myob = {};
        myob["name"] = key;
        myob["count"] = reasonDict[key];
        listReasons.push(myob)
    });
    listReasons.sort((a, b) => {
      return a.count - b.count
    }).reverse()
    return listReasons.slice(0, numRanks);
}

export default {
  getAVGMachineData,
  filterStatesByDate,
  getMachineRanking,
  filterAndRankMachineStates
}