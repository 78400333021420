import { useState, useEffect } from 'react';
import _ from 'lodash';

import useStructure from '@frontend/modules/structure/hooks/useStructure';
import useAlert from '@frontend/hooks/useAlert';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import { useSelector } from 'react-redux';

const useOrdersExportValidation = (structureId) => {

    // ===== States =====
    const machineInUse = useSelector((state) => state.machineInUse);
    const globTimespanStart = useSelector((state) => state.timespanStart);
    const globTimespanEnd = useSelector((state) => state.timespanEnd);
    const [timespanStart, setTimespanStart] = useState(globTimespanStart);
    const [timespanEnd, setTimespanEnd] = useState(globTimespanEnd);

    // Machines that have been selected 
    const [selectedMachines, setSelectedMachines] = useState([]);
    // Substructure that has been selected
    const [selectedSubStructure, setSelectedSubStructure] = useState({});
    // All activations for the selected machines
    const [selectedActivations, setSelectedActivations] = useState([]);
    const [machines, setMachines] = useState([]);
    const [structures, setStructures] = useState([]);
    const [errors, setErrors] = useState({});
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [activationToEdit, setActivationToEdit] = useState({});
    const [selectedMachine, setSelectedMachine] = useState({})


    const selectedStructure = useSelector((state) => state.structureInUse);
    const [modalOpen, setModalOpen] = useState(false);
    const [qrModalOpen, setQrModalOpen] = useState(false); 
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    // ===== Imports =====    
    const api = useApi();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();
    const { structure } = useStructure(structureId?? selectedStructure);


    const handleDeleteModalOpen = (activation) => {
        setActivationToEdit(activation)
        setDeleteModalOpen(true);
    }

    const handleCloseQRModal = () => {
        setQrModalOpen(false);
        reloadData();
    };

    const handleOpenQRModal = (activation) => {
        setSelectedMachine(activation.machine)
        setActivationToEdit(activation)
        setQrModalOpen(true);        
    };

    const handleCloseConfirmationModal = () => {
        setDeleteModalOpen(false);
        reloadData();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        reloadData();
    };

    const handleEditActivation = (activation) => {
        setActivationToEdit(activation);
        setModalOpen(true);
    };

    const handleDeleteActivation = () => {
        deleteActivation(activationToEdit)
        handleCloseConfirmationModal();
    };

    const handleAddActivationButton = () => {
        setActivationToEdit({});
        setModalOpen(true);
    };
    const handleChangeMachine = (event, value) => {
        if(value && value.props){
            let newMachine = value.props.value;
            let foundMachine = selectedMachines.find((machine) => machine.id === newMachine.id);
            if(foundMachine){
                let tmpMachines = selectedMachines.filter((machine) => machine.id !== newMachine.id);
                setSelectedMachines(tmpMachines);
            } else {
                setSelectedMachines([...selectedMachines, newMachine]);
            }
        } 
    };

    const handleChangeSubStructure = (event, value) => {
        if(value && value.props){
            setSelectedSubStructure(value.props.value);
        } 
        
    }

    const handleUpdateTime = (tsStart, tsEnd) => {
        setTimespanStart(tsStart);
        setTimespanEnd(tsEnd);
    }

    const reloadData = (_tsStart, _tsEnd) => {
        // Reload the data
        setLoadingOrders(true);
        if(selectedMachines && !_.isEmpty(selectedMachines)){
            // Just get the IDs of the Machines
            const selectedMachineIds = selectedMachines.map((machine) => machine.id);
            api(
                `/api/orders/jobs/machineActivations`, 
                {
                    params: {
                        timespanStart,
                        timespanEnd,
                        machines: selectedMachineIds
                    },
                    method: 'get',
                },
            )
            .then((response) => {
                // Have to assign a row id to the data
                let _activations = [];
                response.data.forEach((activation, idx) => {
                    const _machine = machines.find((machine) => machine.id === activation.machine);
                    _activations.push({...activation, id: idx, machine: _machine});
                });                
                setLoadingOrders(false);
                setSelectedActivations(_activations);
            })
            .catch((err) => {
                console.log("Error loading order export data:", err);              
            });
        } else {
            // Reset the Orders
            setSelectedActivations([]);            
        }
        setLoadingOrders(false);
    }

    // ===== Effects =====
    useEffect(() => {
        reloadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMachines, timespanStart, timespanEnd]);    

    useEffect(() => {
        // We have periodic updates of the structure. Thus we need to check, if the substructure is contained within the superstructure
        if (structure !== undefined && !_.isEmpty(structure.structures) && !_.isEmpty(selectedSubStructure)){
            // Check if the selected substructure is contained within the structure
            let foundSubStructure = (structure.id === selectedSubStructure.id ? selectedSubStructure : structure.structures.find((subStructure) => subStructure.id === selectedSubStructure.id));
            if(!foundSubStructure){
                // Reset the structure
                setSelectedSubStructure({});
                setStructures(structure.structures)
            } else {
                // Set the structure
                setSelectedSubStructure(foundSubStructure);
                setStructures(structure.structures)
            }
        } else if (structure !== undefined && _.isEmpty(structure.structures)){
            // "Leaf" Structure
            if(selectedSubStructure && selectedSubStructure.id !== structure.id){
                setSelectedSubStructure(structure);
            }
        }
        else if(structure !== undefined && !_.isEmpty(structure.structures)){
            setSelectedSubStructure(structure); // Reset the structure
            setStructures(structure.structures)
        }
        else{
            setSelectedSubStructure({});
            setStructures([])
        }
        setErrors({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [structure]);

    useEffect(() => {
        // Check if the machine in use is within the selected substructure
        let foundMachine = selectedSubStructure.machines?.find((machine) => machine.id === machineInUse) ;
        if (selectedSubStructure && !_.isEmpty(selectedSubStructure.machines)) {
            setMachines(selectedSubStructure.machines.map((machine) => ({...machine, selected: false})));
            setSelectedMachines(foundMachine === undefined ? []: [foundMachine]);
        }
        else if (selectedSubStructure && selectedSubStructure.machines !== undefined) {
            setSelectedMachines(foundMachine === undefined ? []: [foundMachine]);
            setMachines(selectedSubStructure.machines.map((machine) => ({...machine, selected: false})));
        }
        else {
            setSelectedMachines([]);
            setMachines([])      
            setSelectedActivations([]);  
        }
        setSelectedActivations([]); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubStructure]);

    const deleteActivation = (activationValues) => {
        api('/api/orders/jobs/machineActivations', {
            data: { 
                    activationStatus: activationValues.activationStatus,
                    job: activationValues.job?.businessJobId,
                    endTime: activationValues.endTime,
                    handCount: activationValues.handCount,               
                    time: activationValues.time,
                    machine: activationValues.machine.id,
                    order: activationValues.order.businessOrderId                
            },
            method: 'delete',
        })
        .then((response) => {
            if (response.status === 204) {
                createAlert(phrases.modules.order_export.activationDeleted, 'success');                
                setLoadingOrders(false);
                reloadData();
            } else {
                const errorMessage = phrases.modules.order_export.deleteError;
                createAlert(errorMessage, 'error');
                setLoadingOrders(false);
            }
        }).catch((error) => {
            if (error.response.status === 404){
                const errorMessage = phrases.modules.order_export.userNotFound;
                setLoadingOrders(false);
                createAlert(errorMessage, 'error');
            } else {
                const errorMessage = phrases.modules.order_export.genericError;
                createAlert(errorMessage, 'error');
                setLoadingOrders(false);
                console.log(error);
            }
        });
    }
    

    return {
        structure,
        structures,
        selectedSubStructure,
        selectedMachines,
        selectedActivations,
        machines,
        handleChangeMachine,
        handleChangeSubStructure,
        errors,
        loadingOrders,
        reloadData,
        deleteActivation,
        handleDeleteModalOpen,
        handleCloseConfirmationModal,
        handleEditActivation,
        handleDeleteActivation,
        handleAddActivationButton,
        modalOpen,
        handleCloseModal,
        qrModalOpen,
        handleCloseQRModal,
        handleOpenQRModal,
        selectedMachine,
        activationToEdit,
        deleteModalOpen,
        handleUpdateTime


    }

}
 
export default useOrdersExportValidation;