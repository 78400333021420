/* eslint-disable react/jsx-key */
import React from 'react';

import { Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import useDateTimeFormat    from '@frontend/utils/useDateTimeFormat';

const useStyles = makeStyles((theme) => ({
  verticalContainer: {
    display: 'table',
  },
  verticallyAlignedText: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
}));

const JobTotalCount = (props) => {
  const classes = useStyles();
  const { jobTracker } = props;
  const { toLocaleNumber } = useDateTimeFormat();
  const phrases = usePhrases().phrases();
  return (
    <>
      <Grid container component={Paper} spacing={1} variant="outlined" square>
        <Grid item xs={12}>
          <Typography align="center">
            {phrases.modules.orders.jobTotalCount}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="right"
                variant="h4"
                className={classes.verticallyAlignedText}
              >
                {toLocaleNumber(jobTracker?.sensorCount - jobTracker?.scrapCount) || '---'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="center"
                color="textSecondary"
                className={clsx(
                  classes.verticallyAlignedText,
                  classes.lowercase,
                )}
              >
                {phrases.modules.orders.goodCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="right"
                variant="h4"
                className={classes.verticallyAlignedText}
              >
                {toLocaleNumber(jobTracker?.scrapCount) || '---'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="center"
                color="textSecondary"
                className={clsx(
                  classes.verticallyAlignedText,
                  classes.lowercase,
                )}
              >
                {phrases.modules.orders.scrapCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="right"
                variant="h4"
                className={classes.verticallyAlignedText}
              >
                {toLocaleNumber(jobTracker?.targetQuantity) || '---'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="center"
                color="textSecondary"
                className={clsx(
                  classes.verticallyAlignedText,
                  classes.lowercase,
                )}
              >
                {phrases.modules.orders.targetCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="right"
                variant="h5"
                className={classes.verticallyAlignedText}
              >
                {toLocaleNumber(jobTracker?.handCount) || '---'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.verticalContainer}>
              <Typography
                align="center"
                color="textSecondary"
                className={clsx(
                  classes.verticallyAlignedText,
                  classes.lowercase,
                )}
              >
                {phrases.modules.orders.handCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default JobTotalCount;
