import useApi from '@frontend/utils/useApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const usePulseOrders = (machineId, overview) => {

    const [pulseOrders, setPulseOrders] = useState([])
    const api = useApi(); 
    const timespanStart = useSelector((state) => state.timespanStart);
    const timespanEnd = useSelector((state) => state.timespanEnd);

    useEffect(() => {
        api('/api/orders/jobs/pulseOrders', {
            method: 'get',      
            params: {
                timespanStart,
                timespanEnd,
                machineId,
                overview
            },
        }).then((response) => {
            setPulseOrders(response.data);
        }).catch((error) => {
            console.log(error);
        });   
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [machineId, timespanStart, timespanEnd]);

    return { 
        pulseOrders
    };
}
 
export default usePulseOrders;