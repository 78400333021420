/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  useTheme,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { faCog, faCogs } from '@fortawesome/free-solid-svg-icons';

import MachineForm from '@frontend/modules/machine/MachineForm';
import Table from '@frontend/components/Table';
import Modal from '@frontend/components/Modal';
import CustomTabs from '@frontend/components/Tabs';
import CustomLink from '@frontend/components/CustomLink';
import useApi from '@frontend/utils/useApi';
import useAlert from '@frontend/hooks/useAlert';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity from '@frontend/utils/useSecurity';
import { usePhrases } from '@frontend/utils/usePhrases';
import { persistTableState } from '@frontend/utils/UIActions';
import { setTitle } from '@frontend/utils/usePageTitle';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import CustomMultiselectToolbar from '@frontend/modules/machine/MachineMultiselectToolbar';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function MachinesManagement() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const api = useApi();
  const phrases = usePhrases()
    .phrases();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const { createAlert } = useAlert();
  const { formatDate } = useDateTimeFormat();
  // let cancelAxios = null;

  const [machineToEdit, setMachineToEdit] = useState({});
  const [machines, setMachines] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const tableState = useSelector((state) => state.tableState);
  setTitle(phrases.pageTitles.machinesMangement);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    dispatch(persistTableState({}));
    return () => {
      cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editMachine = (machine) => {
    setMachineToEdit(machine);
    handleOpenModal();
  };

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: false,
        download: false,
        display: 'excluded',
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.shared.fields.businessID,
      name: 'businessId',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'businessId' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.shared.fields.name,
      name: 'name',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'name' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value ? (
              <CustomLink
                style={{ color: theme.palette.primary.main }}
                id={machines[tableMeta.rowIndex].id}
                object="machine"
              >
                {value}
              </CustomLink>
            ) : phrases.forms.shared.fields.none}
          </div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.description,
      name: 'description',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'description' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.machine.fields.manufacturer,
      name: 'manufacturer',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'manufacturer' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.machine.fields.placeNumber,
      name: 'placeNumber',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'placeNumber' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.shared.fields.alias,
      name: 'alias',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'alias' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.shared.fields.structure,
      name: 'structure',
      options: {
        filter: true,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'structure' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[7] : [],
        filterOptions: {
          names: _.uniq(_.map(machines, 'structure.name')),
          logic: (structure, filters, row) => {
            if (filters.length) return !filters.includes(structure.name);
            return false;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {machines[tableMeta.rowIndex].structure
              ? (
                <CustomLink
                  style={{ color: theme.palette.primary.main }}
                  id={machines[tableMeta.rowIndex].structure.id}
                  object="structure"
                >
                  {value.name}

                </CustomLink>
              ) : 'None'}
          </div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.type,
      name: 'type',
      options: {
        filter: true,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'type' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[8] : [],
        filterOptions: {
          names: _.uniq(_.map(machines, 'type.name')),
          logic: (type, filters, row) => {
            if (filters.length) return !filters.includes(type.name);
            return false;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value.name}
          </div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.sensors,
      name: 'sensors',
      options: {
        filter: false,
        filterList: tableState.filterList ? tableState.filterList[9] : [],
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value.map((sensor, i) => {
              if (i === value.length - 1) {
                return `${sensor.sensorId} - ${sensor.alias}`;
              }
              return `${sensor.sensorId} - ${sensor.alias},`;
            })}
          </div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.active,
      name: 'isActive',
      options: {
        filter: true,
        filterList: tableState.filterList ? tableState.filterList[10] : ['Active'],
        display: 'excluded',
        filterType: 'checkbox',
        filterOptions: {
          names: ['Active', 'Inactive'],
          logic: (isActive, filters) => {
            if (filters.includes('Active') && filters.includes('Inactive')) return false;
            if (filters.includes('Active')) return !isActive;
            if (filters.includes('Inactive')) return isActive;
            return false;
          },
        },
      },
    },
    ...conditionalElement(isAuthorized('machines', ['update:any', 'update:own']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => editMachine(machines.find((machine) => machine.id === value))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    }),
  ];

  useEffect(() => {
    api('/api/machines?populate=structure sensors', {
      method: 'get',
    })
      .then((response) => {
        setMachines(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeactivateMachines = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const machine = machines[index];
      api('/api/machines', {
        method: 'put',
        data: {
          ...machine,
          isActive: false,
        },
        cancelToken: cancelAxios.token,
      })
        .then((response) => {
          if (response.status === 200) {
            handleEdit();
            createAlert(phrases.forms.machine.success.machineUpdated, 'success');
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
        });
    });
    return false;
  };

  const handleResendMachineConfig = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    const allMachines = obj.data.map((dataObj) => {
      const index = dataObj.dataIndex;
      const machine = machines[index];
      return machine;
    });
    // cancelAxios = axios.CancelToken.source();
    api('/api/machines/send-config', {
      method: 'post',
      data: allMachines,
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        if (response.status === 200) {
          handleEdit();
          createAlert(phrases.forms.machine.success.machineConfigSent, 'success');
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
        else createAlert(phrases.forms.machine.errors.genericError, 'error');
      });
    return false;
  };

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines?populate=structure sensors', {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setMachines(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
  };

  const handleCreate = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines?populate=structure sensors', {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setMachines(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
  };

  const getDirection = (direction) => {
    switch (direction) {
      case 'ascending':
        return 'asc';
      case 'descending':
        return 'desc';
      default:
        return 'asc';
    }
  };

  const customOptions = {
    filterType: 'checkbox',
    print: false,
    download: true,
    elevation: 0,
    selectableRows: isAuthorized('machines', ['delete:any', 'delete:own']) ? 'multiple' : 'none',
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({
        ...tableState,
        filterList,
      }));
    },
    downloadOptions: {
      filename: `${phrases.tables.shared.machines} - ${formatDate(new Date())}.csv`,
      separator: ',',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      data.map((machineArray) => {
        machineArray.data[7] = machineArray.data[7] ? machineArray.data[7].name : '';
        machineArray.data[8] = machineArray.data[8] ? machineArray.data[8].name : '';
        // 9 is the index of a sesnor array in this machineArray.data object.
        machineArray.data[9] = machineArray.data[9].map((sensor) => `${sensor.sensorId}|${sensor.alias}`).join(',');
        return [...machineArray.data];
      });
      return buildHead(columns) + buildBody(data);
    },
    onChangePage: (page) => {
      dispatch(persistTableState({
        ...tableState,
        page,
      }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomMultiselectToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        resendMachineConfig={handleResendMachineConfig}
        deactivateMachines={handleDeactivateMachines}
      />
    ),
  };

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          ...conditionalElement(isAuthorized('machines', ['read:any', 'read:own']), {
            label: phrases.tabs.ExistingMachines,
            icon: faCogs,
          }),
          ...conditionalElement(isAuthorized('machines', ['create:any', 'create:own']), {
            label: phrases.tabs.CreateMachine,
            icon: faCog,
          }),
        ]}
        panels={[
          ...conditionalElement(isAuthorized('machines', ['read:any', 'read:own']), {
            content: <Table
              modalTitle={phrases.tables.shared.machines}
              data={machines}
              resource="machines"
              columns={columns}
              customOptions={customOptions}
            />,
          }),
          ...conditionalElement(isAuthorized('machines', ['create:any', 'create:own']), {
            content: <MachineForm onCreate={handleCreate} />,
          }),
        ]}
      />
      <Modal
        modalTitle={phrases.modal.EditMachine}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <MachineForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            machine={machineToEdit}
          />
        )}
      />
    </div>
  );
}
