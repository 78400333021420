const LOADING_PROPS = {
  ALREADY_ENGAGED_JOB_LOADING: 'ALREADY_ENGAGED_JOB_LOADING',
  FETCHING_JOB: 'FETCHING_JOB',
  STARTING_JOB: 'STARTING_JOB',
  STOPPING_JOB: 'STOPPING_JOB',
  UPDATING_JOB: 'UPDATING_JOB',
  ADDING_NOTE: 'ADDING_NOTE',
};

export default LOADING_PROPS;
