import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateMachineForm = () => {
  const phrases = usePhrases().phrases();

  const validateMachineForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = phrases.forms.machine.errors.machineNameRequired;
    }

    if (!values.structure) {
      errors.structure = phrases.forms.machine.errors.structureRequired;
    }

    if (!values.type) {
      errors.type = phrases.forms.machine.errors.machineTypeRequired;
    }

    return errors;
  };

  return {
    validateMachineForm,
  };
};

export default useValidateMachineForm;
