import { useEffect, useState }  from 'react';
import { useSelector }          from 'react-redux';
import axios from 'axios';
import useApi                   from '@frontend/utils/useApi';

/**
 * 
 * @param {MachineObject} machine This may or may not be provided. If a proper machine Object is provided,
 *                                it will be chosen before the machines of the given structure.
 * @returns 
 */
const useMachineStatusChart = (machine) => {
    // ===== Imports =====
    const api = useApi()

    // ===== States =====
    const [machineIds, setMachineIds] = useState([]);
    const [statusKPIs, setStatusKPIs] = useState([]);
    const [machineNames, setMachineNames] = useState([]); // {machineId: {name: string, structure: <StructureObject>}}

    // ===== Redux Selectors =====
    const structure         = useSelector((state) => state.structure);
    const timespanStart     = useSelector((state) => state.timespanStart);
    const timespanEnd       = useSelector((state) => state.timespanEnd);
    const machineStatuses   = useSelector((state) => state.machineStatuses);

    // ===== Effects =====
    useEffect(() => {
        // Here we match the given Machines to their IDs as an array to pass them to the API
        if(machine !== undefined){
            // we got a single machine
            setMachineIds([machine.id]);
            setMachineNames({[machine.id]: {name: machine.name, structure: machine.structure}})
        } else {

            setMachineIds(structure?.machines.map(machine => machine.id));
            let newMachineNames = {};
            structure.machines.forEach(machine => {
                newMachineNames[machine.id] = {name: machine.name, structure: machine.structure}
            });
            setMachineNames(newMachineNames);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [structure, machine]);
    
    useEffect(() => {
        /**
         * This function calls the API to query all the machine KPI Statuses in a compressed format.
         * Expect something like
         * {
         *  "not_running": 1234,
         *  "running": 5678,
         *  "creep": 0,
         *  ...
         * }
         */
        const cancelAxios = axios.CancelToken.source();
        api(`/api/structures/calculate-structure-machine-statuses`, {
            method: 'post',
            data: {
                machines: machineIds,
                from: timespanStart,
                to: timespanEnd,
                machineStatuses
            },
            cancelToken: cancelAxios.token,
        }).then((response) => {
            if(response.status === 200 || response.status === 304){
                setStatusKPIs(response.data);
            }
        }).catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineIds, timespanStart, timespanEnd])

    return {
        machineStatuses,
        statusKPIs,
        machineIds,
        machineNames,
        structure
    };
}
 
export default useMachineStatusChart;