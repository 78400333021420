import React from 'react';
import { usePhrases } from '@frontend/utils/usePhrases';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NewReleasesIcon  from '@material-ui/icons/NewReleases';
import PartsChartModalContent from '@frontend/modules/machine/PartsChartModalContent';
import GaugeProgressIndicator from '@frontend/modules/simple_machine/GaugeProgressIndicator';
import useSimpleMachine from '@frontend/modules/simple_machine/hooks/useSimpleMachine';
import GaugeSpeedIndicator from './GaugeSpeedIndicator';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    green: {
      color: '#3bb273',
    },
    red: {
      color: '#d62828',
    },
    yellow: {
      color: '#FED766',
    },
    chart: {
      padding: theme.spacing(2),
    },
    
    topCardsContainer: {
      marginTop: theme.spacing(0),
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0),
    },     

    cardsContainer: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0),
    },

    alert: {
      float: "center",
      textAlign: "center",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    backdrop: {
      zIndex: 1,
      position: 'fixed',
      margin: 'auto',
    },
  }));
const SimpleMachineOverview = () => {
    const { machineId } = useParams();
    const phrases = usePhrases().phrases(); 
    const classes = useStyles();
    const calculationFactor = 60;     // Parts per Hour
    const  {
      shiftKPIs,
      partsProduced,  
      currentSpeed,     
    } = useSimpleMachine(machineId);

    return ( 
    <>
    {shiftKPIs.error !== undefined ? (
    <Grid item xs={12} className={classes.alert} alignItems="center" justifyContent="center">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Alert style={{width:'50%'}}
                    className={classes.alert}
                    variant="filled"
                    severity="warning"
                    icon={
                      <NewReleasesIcon/>
                    }
                    >
                    {phrases.modules.machine_shift.errors.noShiftFound}
                </Alert>
            </Box>
        </Grid> ) : (
    <div className={classes.root}>
        <Grid container spacing={4} className={classes.topCardsContainer} component={Paper} variant="outlined" square>
            <Grid item xs={12}>
                <Typography align='center' variant="h1" display="block" color="textSecondary">                    
                    {partsProduced}
                    {" "}
                    {phrases.modules.simple_machine.parts}
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.topCardsContainer} component={Paper} variant="outlined" square>
            <Grid item xs={12}>                
                <div style={{marginRight: "2em"}}>
                  {shiftKPIs.config?.partsChartSimple &&
                    <PartsChartModalContent 
                        machineId={machineId}
                        fullyFeatured={1}
                        height={250}
                        isPartsPerMinute={true}
                        calculationFactor={calculationFactor}
                        targetCycleTime={shiftKPIs.config?.targetCycleTime}
                        customTimespanStart={shiftKPIs.startTimeMoment? new Date(shiftKPIs.startTimeMoment): new Date()}
                        customTimespanEnd={shiftKPIs.endTimeMoment? new Date(shiftKPIs.endTimeMoment): new Date()}
                    />
                  }
                </div>
                
            </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.topCardsContainer} component={Paper} variant="outlined" square>
            <Grid item xs={6}>
                <Typography align='center' variant="h3" display="block" color="textSecondary">
                    <b>{phrases.modules.simple_machine.timeGoal}</b>
                    {": "}
                    {shiftKPIs.timeTargetNumber}
                    {" "}
                    {phrases.modules.simple_machine.unit}
                </Typography>
                <Typography align='center' variant="h3" display="block" color="textSecondary">                    
                    <b>{phrases.modules.simple_machine.shiftGoal}</b>
                    {": "}
                    {shiftKPIs.targetNumber}
                    {" "}
                    {phrases.modules.simple_machine.unit}
                </Typography>
            </Grid>
            <Grid item xs={6} style={{"margin": "auto"}}>
                <Typography align='center' variant="h3" display="block" color="textSecondary">
                  <b>{currentSpeed*calculationFactor}</b> {phrases.modules.simple_machine.partsPerHour}
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.topCardsContainer} component={Paper} variant="outlined" square>
          
            <Grid item xs={6}>                
                <div style={{marginRight: "2em"}}>
                    {shiftKPIs.error === undefined &&
                    <GaugeProgressIndicator 
                      machineId={machineId} 
                      targetNumber={shiftKPIs.targetNumber} 
                      actualPartsProduced={partsProduced} 
                      timeTargetNumber={shiftKPIs.timeTargetNumber}
                    />                    
                    }
                </div>
            </Grid>
            <Grid item xs={6}>                
                <div style={{marginRight: "2em"}}>
                    {shiftKPIs.error === undefined &&
                    <GaugeSpeedIndicator 
                      machineId={machineId} 
                      targetCycleTime={(60/parseInt(shiftKPIs.config?.targetCycleTime))*calculationFactor}                        
                      currentSpeed={currentSpeed*calculationFactor}
                    />                    
                    }
                </div>
            </Grid>
          </Grid>          
      </div>        
    )}
    </> );
}
 
export default SimpleMachineOverview;
