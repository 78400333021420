import React from 'react';
import { 
    Grid,
    Paper,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import {usePhrases} from '@frontend/utils/usePhrases';
import LoadingIndicator from '@frontend/components/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    loadingReasonsIndicator: {
      marginLeft: theme.spacing(1),
    },
    bold: {
        fontWeight: 'bold',
    }
  }));


const TheoreticalMaximumOutputCard = (props) => {
    const {
        theoreticalOutputData,
        isLoadingPerformance 
    } = props;
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    const { formatHoursMinutesDuration } = useDateTimeFormat();
    return (<>
        <Grid item xs={12}>
            <Paper variant="outlined" square>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <List>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <FontAwesomeIcon
                                    icon={faCrosshairs}
                                    size="2x"
                                    />
                                </ListItemAvatar>
                                <Typography variant="body1">
                                    {phrases.modules.machinePerformance.theoreticalOutput}
                                </Typography>
                                <Typography variant="body1" className={classes.loadingReasonsIndicator}>
                                    {isLoadingPerformance ? <LoadingIndicator size={20} /> : ''}
                                </Typography>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </List> 
                        <Table aria-label="simple table">                    
                            <TableBody>
                                {theoreticalOutputData ? 
                                <>                                
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{`${phrases.modules.machinePerformance.maxCycleTime}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span>
                                        {parseFloat(theoreticalOutputData.details.maxCycleTime).toFixed(2)}
                                        {' '}
                                        {phrases.modules.machinePerformance.unitMaxCycleTime}
                                        </span>
                                    </TableCell>
                                </TableRow> 
                                </>    
                                :

                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>{phrases.modules.machinePerformance.noPerformance}</Typography>
                                    </TableCell>
                                </TableRow>  
                                }              
                            </TableBody>                    
                        </Table>  
                    </Grid>
                    <Grid item xs={4}>
                        <Table aria-label="simple table">                    
                            <TableBody>
                                {theoreticalOutputData ? 
                                <>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{`${phrases.modules.machinePerformance.totalTime}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span>
                                        {formatHoursMinutesDuration(theoreticalOutputData.details.totalTime)}
                                        </span>
                                    </TableCell>
                                </TableRow>                                 
                                </>    
                                :

                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>{phrases.modules.machinePerformance.noPerformance}</Typography>
                                    </TableCell>
                                </TableRow>  
                                }              
                            </TableBody>                    
                        </Table>
                    </Grid>
                    <Grid item xs={4}>
                        <Table aria-label="simple table">                    
                            <TableBody>
                                {theoreticalOutputData ? 
                                <>                                
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{` `}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body2" className={classes.bold}>
                                        <span>
                                        &nbsp;
                                        </span>
                                        </Typography>
                                    </TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2" className={classes.bold}>{`${phrases.modules.machinePerformance.total}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body2" className={classes.bold}>
                                            <span>
                                            {parseFloat(theoreticalOutputData.value).toFixed(2)}
                                            {' '}
                                            {phrases.modules.machinePerformance.unit}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow>  
                                </>    
                                :

                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>{phrases.modules.machinePerformance.noPerformance}</Typography>
                                    </TableCell>
                                </TableRow>  
                                }              
                            </TableBody>                    
                        </Table>
                    </Grid>
                </Grid>
            </Paper>            
        </Grid>
    </>);
}
 
export default TheoreticalMaximumOutputCard;