import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Time from "@frontend/components/Time";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    clockTextMargin: {
        marginRight: theme.spacing(6),
    }
}));
const Clock = (props) => {
    const { timeLocation} = props;
    const classes = useStyles();
    return (<>
            <Typography className={classes.clockTextMargin} variant="h4" align='right' display="inline">            
                <b>{timeLocation.machineName}</b> <br/>
            </Typography>
            <Typography className={classes.clockTextMargin} variant="h2" align='right' display="inline">
                <Time timezone={timeLocation.timezone}/>
            </Typography>
        </>
    );
}
 
export default Clock;
