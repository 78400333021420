/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';

import useApi from '@frontend/utils/useApi';
import useValidateMachineForm from '@frontend/modules/machine/hooks/useValidateMachineForm';
import { storeDropdownStructures } from '@frontend/modules/structure/actions';
import { storeListOfMachineTypes } from '@frontend/modules/machine/actions';

const emptyMachine = {
  name: '',
  businessId: '',
  placeNumber: '',
  manufacturer: '',
  alias: '',
  description: '',
  isActive: true,
  config: {},
  structure: null,
  type: null,
  sensors: [],
  timezone: '',
};

const useMachineForm = (callback, machine) => {
  const dispatch = useDispatch();
  const api = useApi();
  const { validateMachineForm } = useValidateMachineForm();
  const [machineValues, setMachineValues] = useState(machine || emptyMachine);
  const [sensors, setSensors] = useState([]);
  const [errors, setErrors] = useState({});
  const structures = useSelector((state) => state.dropdownStructures);
  const machineTypes = useSelector((state) => state.machineTypes);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // let cancelAxios = null;

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    } else { setIsSubmitting(false); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/structures?populate=structure', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      if (machine) {
        const machineStructure = machineValues.structure.id;
        setMachineValues({ ...machineValues, structure: response.data.find((structure) => machineStructure === structure.id) });
      }
      dispatch(storeDropdownStructures(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines/machine-types', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfMachineTypes(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateMachineForm(machineValues));
  };

  const handleChange = (event) => {
    event.persist();
    if (errors[event.target.name]) { delete errors[event.target.name]; }
    setMachineValues((machineValues) => ({ ...machineValues, [event.target.name]: event.target.value }));
  };

  const handleSwitch = (event) => {
    event.persist();
    const field = event.target.name;
    setMachineValues((machineValues) => ({ ...machineValues, [field]: !machineValues[field] }));
  };

  const handleClearForm = () => {
    setIsSubmitting(false);
    setMachineValues(emptyMachine);
    setErrors({});
  };

  const setMachineConfig = (config) => {
    setMachineValues((machineValues) => ({ ...machineValues, config }));
  };

  const setMachineType = (machineType) => {
    setMachineValues((machineValues) => ({ ...machineValues, type: machineType }));
  };

  const handleStructureChange = (event, value) => {
    event.persist();

    if (errors.structure) { delete errors.structure; }
    if (value) setMachineValues((machineValues) => ({ ...machineValues, structure: value, timezone: value.timezone }));
  };

  const handleSensorChange = (event, value) => {
    if (!value) return;
    event.persist();
    // Uncomment when support for multiple sensors is implemented
    // const newArr = filterOutDuplicates(value);

    if (errors.sensors) { delete errors.sensors; }
    // Uncomment when support for multiple sensors is implemented
    // setMachineValues(machineValues => ({ ...machineValues, sensors: [...newArr] }));
    setMachineValues((machineValues) => ({ ...machineValues, sensors: _.last(value) ? [_.last(value)] : [] }));
  };

  const sortSensors = (sensors) => sensors.sort((a, b) => {
    if (a.machine && !b.machine) return 1;
    if (!a.machine && b.machine) return -1;
    return 0;
  });

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors?populate=machine', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setSensors(sortSensors(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return {
    handleChange,
    handleSubmit,
    handleSwitch,
    handleClearForm,
    handleStructureChange,
    machineValues,
    machineTypes,
    structures,
    errors,
    setMachineType,
    setMachineConfig,
    sensors,
    handleSensorChange,
  };
};

export default useMachineForm;
