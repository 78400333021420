import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateSensorTypeForm = () => {
  const phrases = usePhrases().phrases();
  const validateSensorTypeForm = (values) => {
    const errors = {};
    if (values.name.trim() === '') {
      errors.name = phrases.forms.sensor.errors.sensorNameRequired;
    }

    if (values.measurementUnit.trim() === '') {
      errors.measurementUnit = phrases.forms.sensor.errors.measurementUnitRequired;
    }

    return errors;
  };

  return {
    validateSensorTypeForm,
  };
};

export default useValidateSensorTypeForm;
