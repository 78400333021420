import React from 'react';
import ReactError from '@frontend/components/ReactError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error) {
      // Error path
      return (
        // <div>
        //   <h2>Something went wrong.</h2>
        //   <details style={{ whiteSpace: 'pre-wrap' }}>
        //     {error && error.toString()}
        //     <br />
        //     {errorInfo.componentStack}
        //   </details>
        // </div>
        <ReactError error={error} errorInfo={errorInfo} />
      );
    }
    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
