import React, { Component} from 'react';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Grid,
    Paper,
} from '@material-ui/core'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

/**
 * Author: Julian Dreyer
 * This component will display a Bar Chart containing the Average Downtime, average Runtime and average OEE times 
 * of the different machines.  
 */
am4core.addLicense("AM5C377488971");
am4core.options.autoSetClassName = true;

class AverageOverviewCard extends Component {
    constructor(props) {
        super(props);
        this.state = {  }   
    }    

    /**
     * Author: Julian Dreyer
     * The lifecycle method componentDidUpdate will be called, whenever the data feeding the object has been
     * updated. In our case, we need to update the chart in here.
     * Within the props object, the component expects the fields "avgOEE", "avgRuntime" and "avgDowntime" to be present.
     * These will be used for the data display
     */
    componentDidUpdate(){
        // The data will be provided via props
        this.chart.data = [{
            "category": "Average Runtime",
            "value": this.props.avgRuntime,
            "color": am4core.color("#43E639")
        }, {
            "category": "Average Downtime",
            "color": am4core.color("#D9415F"),
            "value": this.props.avgDowntime
        }]
        this.chart1.data = [{
            "category": "Average OEE",
            "value": this.props.avgOEE,
            "color": am4core.color("#3C4DF0")
        }]
        // Update the data by invalidating the old data
        this.chart.invalidateData();
        this.chart1.invalidateData();
    }

    /**
     * Author: Julian Dreyer
     * Once the component is mounted, the chart can be loaded into the DOM. The data
     * will be provided in the update method.
     */
    componentDidMount() {
        // First lets create a Bar chart
        this.chart = am4core.create(this.props.chart_id, am4charts.XYChart);

        // Lets add the x-Axis
        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = this.props.chart_category_id;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.labels.template.fill = am4core.color(this.props.color.primary);

        // Now the y-Axis
        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.fill = am4core.color(this.props.color.primary);

        // Now the columns
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = this.props.chart_category_id;
        series.dataFields.valueY = this.props.chart_value_id;
        series.columns.template.strokeWidth = 0;
        series.tooltipText = "{valueY.value} h";
        series.columns.template.fill = am4core.color("#FFFFFF");
        series.columns.template.propertyFields.fill = "color";
        this.chart.cursor = new am4charts.XYCursor();


        this.chart1 = am4core.create(this.props.chart_id+"1", am4charts.XYChart);

        // Lets add the x-Axis
        let categoryAxis1 = this.chart1.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis1.dataFields.category = this.props.chart_category_id;
        categoryAxis1.renderer.grid.template.location = 0;
        categoryAxis1.renderer.minGridDistance = 60;
        categoryAxis1.renderer.labels.template.fill = am4core.color(this.props.color.primary);

        // Now the y-Axis
        let valueAxi1s = this.chart1.yAxes.push(new am4charts.ValueAxis());
        valueAxi1s.renderer.labels.template.fill = am4core.color(this.props.color.primary);
        valueAxi1s.min = 0
        valueAxi1s.max = 100
        // Now the columns
        let series1 = this.chart1.series.push(new am4charts.ColumnSeries());
        series1.dataFields.categoryX = this.props.chart_category_id;
        series1.dataFields.valueY = this.props.chart_value_id;
        series1.columns.template.strokeWidth = 0;
        series1.tooltipText = "{valueY.value} h";
        series1.columns.template.fill = am4core.color("#FFFFFF");
        series1.columns.template.propertyFields.fill = "color";
        this.chart1.cursor = new am4charts.XYCursor();

    }
    /**
    * Author: Julian Dreyer
    * This lifecycle method is called whenever the component is unmounted. It's important to
    * dispose the chart so that it will not be rendered on top of an old one.
    */
    componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }      

    render() {
        return (
            <Grid item xs={12} md={4}>
                <Paper variant="elevation" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="body1" align="center">
                                        Average Overview
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableRow>
                        <TableCell>
                        <div id={this.props.chart_id} style={{height: '300px', margin: '20px auto'}}>                        
                        </div>
                        </TableCell>
                        <TableCell style={{width: '30%'}}>
                        <div id={this.props.chart_id+"1"} style={{height: '300px', margin: '20px auto'}}>                        
                        </div>
                        </TableCell>
                        </TableRow>
                        
                    </Table>
                </Paper>
            </Grid>            
        );
    }
}
 
export default AverageOverviewCard;