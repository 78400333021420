
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import {
  Menu,
  Tooltip,
  FormControl,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import { usePhrases } from '@frontend/utils/usePhrases';
import useAlert from '@frontend/hooks/useAlert';

const useStyles = makeStyles((theme) => ({
  shareMenu: {
    padding: theme.spacing(1),
  },
  shareMenuList: {
    padding: 0,
  },
  urlInput: {
    padding: 0,
    paddingRight: theme.spacing(0.2),
    margin: 0,
  },
}));

const ShareButton = (props) => {
  const {
    id,
    route,
  } = props;

  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { createAlert } = useAlert();

  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [url, setUrl] = useState('');
  const [rootPath, setRootPath] = useState('');

  const timespanStart = useSelector((state) => state.timespanStart);
  const timespanEnd = useSelector((state) => state.timespanEnd);
  const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan);
  const selectedRelativeTimespan = useSelector((state) => state.selectedRelativeTimespan);

  useEffect(() => {
    if (isRelativeTimespan) {
      setUrl(`${rootPath}${route}/${id}?isRelativeTimespan=${isRelativeTimespan}&amount=${selectedRelativeTimespan.amount}&unit=${selectedRelativeTimespan.unit}`);
    } else {
      setUrl(`${rootPath}${route}/${id}?isRelativeTimespan=${isRelativeTimespan}&timespanStart=${new Date(timespanStart).toISOString()}&timespanEnd=${new Date(timespanEnd).toISOString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timespanStart, timespanEnd, rootPath, id]);

  useEffect(() => {
    setRootPath(window.location.origin);
  }, []);

  const shareLinkChange = (e) => e;

  const openShareMenu = (event) => {
    setShareAnchorEl(event.currentTarget);
  };

  const closeShareMenu = () => {
    setShareAnchorEl(null);
  };

  const copyToClipboard = () => {
    copy(url);
    createAlert(phrases.misc.urlCopySuccess, 'success');
    closeShareMenu();
  };

  return (
    <>
      <IconButton onClick={openShareMenu} variant="outlined" size="small">
        <ShareIcon fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={shareAnchorEl}
        keepMounted
        PaperProps={{
          square: true,
        }}
        classes={{
          paper: classes.shareMenu,
          list: classes.shareMenuList,
        }}
        open={Boolean(shareAnchorEl)}
        onClose={closeShareMenu}
      >
        <FormControl variant="outlined">
          <OutlinedInput
            onChange={shareLinkChange}
            id="input-with-icon-adornment"
            className={classes.urlInput}
            margin="dense"
            value={url}
            endAdornment={(
              <InputAdornment position="end">
                <Tooltip placement="top" arrow modalTitle={phrases.misc.copyUrl}>
                  <Button
                    variant="contained"
                    disableElevation
                    aria-label="clear"
                    size="large"
                    onClick={copyToClipboard}
                  >
                    <FontAwesomeIcon
                      icon={faCopy}
                      size="lg"
                    />
                  </Button>
                </Tooltip>
              </InputAdornment>
            )}
          />
        </FormControl>
      </Menu>
    </>
  );
};

ShareButton.propTypes = {
  id: PropTypes.string,
  route: PropTypes.string.isRequired,
  isRelative: PropTypes.bool,
  timespanStart: PropTypes.instanceOf(Date),
  timespanEnd: PropTypes.instanceOf(Date),
};

export default ShareButton;
