import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import clsx from 'clsx';

import {
  Typography,
  Tooltip,
  Divider,
  Button,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';

import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles(() => ({
  green: {
    color: '#3bb273',
  },
  red: {
    color: '#d62828CC',
  },
  yellow: {
    color: '#ffff00'
  },
  grey: {
    color: '#828181'
  },
  onlineIndicator: {
    cursor: 'default',
  },
}));

const SensorIndicator = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { sensor } = props;
  
  const getSensorTooltip = (_sensor) => (
    <>
      <Typography color="inherit" variant="body2">
        ID:
        {_sensor.sensorId ? _sensor.sensorId : '-'}
      </Typography>
      <Typography color="inherit" variant="body2">
        {' '}
        {phrases.forms.shared.fields.type}
        :
        {' '}
        {_sensor.type ? _sensor.type.name : '-'}
      </Typography>
      <Divider />
      <Typography color="inherit" variant="body2">{_sensor.online ? phrases.modules.machine.online : `${phrases.modules.machine.lastSeen} ${_sensor.lastSeenAt ? moment(_sensor.lastSeenAt).fromNow() : phrases.modules.machine.never} `}</Typography>
    </>
  );
  // TODO: remove responsive classes
  return (
    <Tooltip
      enterTouchDelay={50}
      leaveTouchDelay={3000}
      title={getSensorTooltip(sensor)}
      placement="top-start"
      arrow
    >

      <Button size="small" className={classes.onlineIndicator} disableRipple>
        <Hidden xsDown>
          <FontAwesomeIcon
            icon={faWaveSquare}
            size="lg"
            className={ sensor.online? classes.green: 
                          moment().diff(moment(sensor.lastSeenAt), "days") <= 7 ? 
                              classes.yellow
                            : moment().diff(moment(sensor.lastSeenAt), "days") <= 30 ? 
                                classes.red
                              : classes.grey
          }
          />
        </Hidden>

        <Hidden smUp>
          <FontAwesomeIcon
            icon={faWaveSquare}
            size="xs"
            className={clsx({
              [classes.red]: !sensor.online,
              [classes.green]: sensor.online,
            })}
          />
        </Hidden>

      </Button>

    </Tooltip>

  );
};

SensorIndicator.propTypes = {
  sensor: PropTypes.instanceOf(Object).isRequired,
};

export default SensorIndicator;
