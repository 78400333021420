/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ClickAwayListener,
  IconButton,
  Chip,
  Backdrop,
  Zoom,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  SpeedDialIcon,
  SpeedDial,
  SpeedDialAction,
} from '@material-ui/lab';
import AccessTimeIcon         from '@material-ui/icons/AccessTime';
import DateRangeIcon          from '@material-ui/icons/DateRange';
import CalendarViewWeekIcon   from '@material-ui/icons/CalendarViewDay';
import CloseIcon              from '@material-ui/icons/Close';
import RestoreIcon            from '@material-ui/icons/Restore';
import { 
  faCalendarAlt, 
  faHistory, 
  faBusinessTime 
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import CustomTabs                 from '@frontend/components/Tabs';
import TimespanCustomSelection    from '@frontend/components/TimespanCustomSelection';
import TimespanDateRangeSelection from '@frontend/components/TimespanDateRangeSelection';
import TimespanRelativeSelection  from '@frontend/components/TimespanRelativeSelection';
import {
  setIsRelativeTimespan,
  setSelectedTimespanText,
  setIsCustomPreset,
  setSelectedRelativeTimespan,
  setTimespanDuration,
} from '@frontend/utils/UIActions';
import { usePhrases }     from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionRight': {
      bottom: theme.spacing(2),
      left: theme.spacing(0),
    },
    alignItems: 'start',
  },
  speedDialChip: {
    position: 'fixed',
    bottom: theme.spacing(3.3),
    left: theme.spacing(9),
    padding: theme.spacing(1),
    fontWeight: 'bold',
    maxWidth: '60%',
    zIndex: 999,
  },
  restoreChipIcon: {
    height: 'auto',
  },
  dateSelection: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
  selectField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  dialogContent: {
    padding: 0,
    margin: 0,
  },
  modal: {
    width: '100%',
  },
  presetList: {
    width: '100%',
  },
  speedDialIcon: {
    marginRight: theme.spacing(1),
  },
  backDrop: {
    zIndex: 100,
  },
  listTitle: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 'bold',
  },
  selectedPreset: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const TimespanSelection = React.memo((props) => {
  const {
    selectTimeframe,
    selectTimeframeRelative,
    selectTimeframeCustom,
    disabled,
    onTimespanSelect,
  } = props;

  // ===== State Objects =====
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  // ===== Redux Selectors =====
  const selectedTimespanText = useSelector((state) => state.selectedTimespanText);
  const selectedRelativeTimespan = useSelector((state) => state.selectedRelativeTimespan);
  const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan);
  
  // ===== Imports =====
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const phrases = usePhrases().phrases();
  const machines = useSelector((state) => state.machines);


  // ===== Effects =====
  useEffect(() => {
    if (isRelativeTimespan) {
      dispatch(setSelectedTimespanText(formatSelectedTimespanText({
        amount: selectedRelativeTimespan.amount,
        unit: selectedRelativeTimespan.unit,
      })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phrases]);

  // ===== Helper Methods =====
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial);
  };

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleOpenDialog = () => {
    setTabValue(1);
    setOpenSpeedDial(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenSpeedDial(false);
    setOpenDialog(false);
    setTabValue(0);
  };

  const handleOpenPresetTab = () => {
    setTabValue(2);
    setOpenDialog(true);
    setOpenSpeedDial(false);
  }

  const formatSelectedTimespanText = ({ amount, unit }) => {
    // This has to be weak check since the value in the field is a String
    // eslint-disable-next-line eqeqeq
    if (amount == 1) {
      return `${phrases.timespanSelection.Last} ${getUnitLocalized({ amount, unit })}`;
    }
    return `${phrases.timespanSelection.Last} ${amount} ${getUnitLocalized({ amount, unit })}`;
  };

  const getUnitLocalized = ({ amount, unit }) => {
    let localizedUnit = unit;

    // This has to be weak check since the value in the field is a String
    // eslint-disable-next-line eqeqeq
    if (amount == 1) {
      switch (unit) {
        default: break;
        case 'minutes':
          localizedUnit = phrases.timespanSelection.Minute;
          break;
        case 'hours':
          localizedUnit = phrases.timespanSelection.Hour;
          break;
        case 'days':
          localizedUnit = phrases.timespanSelection.Day;
          break;
        case 'weeks':
          localizedUnit = phrases.timespanSelection.Week;
          break;
      }
    } else {
      switch (unit) {
        default: break;
        case 'minutes':
          localizedUnit = phrases.timespanSelection.Minutes;
          break;
        case 'hours':
          localizedUnit = phrases.timespanSelection.Hours;
          break;
        case 'days':
          localizedUnit = phrases.timespanSelection.Days;
          break;
        case 'weeks':
          localizedUnit = phrases.timespanSelection.Weeks;
          break;
      }
    }

    return localizedUnit;
  };

  const handleQuickRelativePresetClick = (amount, unit) => {
    dispatch(setSelectedRelativeTimespan({ amount, unit }));
    dispatch(setIsRelativeTimespan(true));
    dispatch(setIsCustomPreset(false));
    dispatch(setSelectedTimespanText(formatSelectedTimespanText({ amount, unit })));
    dispatch(setTimespanDuration(amount));
    if (selectTimeframeRelative) selectTimeframeRelative(machines, moment().subtract(amount, unit).toDate(), moment().toDate());
    if (onTimespanSelect) { onTimespanSelect(moment().subtract(amount, unit).toDate(), moment().toDate(), undefined, undefined, true); }
  };

  const resetToDefaultTimespan = () => {
    dispatch(setSelectedRelativeTimespan({ amount: 12, unit: 'hours' }));
    dispatch(setIsCustomPreset(false));
    dispatch(setSelectedTimespanText(formatSelectedTimespanText({ amount: 12, unit: 'hours' })));
    dispatch(setIsRelativeTimespan(true));
    dispatch(setTimespanDuration(12));
    if (selectTimeframeRelative) selectTimeframeRelative(machines, moment().subtract(12, 'hours').toDate(), moment().toDate());
    if (onTimespanSelect) { onTimespanSelect(moment().subtract(12, 'hours').toDate(), moment().toDate(), undefined, undefined, true); }
  };
  const defaultTimespanSelected = () => selectedRelativeTimespan.amount === 12 && selectedRelativeTimespan.unit === 'hours';

  // ===== Local Variables =====
  const actions = [
    { icon: <AccessTimeIcon className={classes.speedDialIcon} />, name: phrases.timespanSelection.LastHour, onClick: () => handleQuickRelativePresetClick(1, 'hours') },
    { icon: <AccessTimeIcon style={{ transform: 'rotate(45deg)' }} className={classes.speedDialIcon} />, name: phrases.timespanSelection.Last6Hours, onClick: () => handleQuickRelativePresetClick(6, 'hours') },
    { icon: <AccessTimeIcon style={{ transform: 'rotate(90deg)' }} className={classes.speedDialIcon} />, name: phrases.timespanSelection.Last12Hours, onClick: () => handleQuickRelativePresetClick(12, 'hours') },
    { icon: <AccessTimeIcon style={{ transform: 'rotate(135deg)' }} className={classes.speedDialIcon} />, name: phrases.timespanSelection.Last24Hours, onClick: () => handleQuickRelativePresetClick(24, 'hours') },
    { icon: <DateRangeIcon className={classes.speedDialIcon} />, name: phrases.timespanSelection.SelectTimespan, onClick: handleOpenDialog },
    { icon: <CalendarViewWeekIcon className={classes.speedDialIcon} />, name: phrases.timespanSelection.CustomTimePreset, onClick: handleOpenPresetTab },
 
  ];
  return (
    <div>
      <Backdrop className={classes.backDrop} open={openSpeedDial} />
      <ClickAwayListener onClickAway={handleCloseSpeedDial}>
        <div>
          <SpeedDial
            ariaLabel="Timespan"
            className={classes.speedDial}
            onClick={handleOpenSpeedDial}
            icon={<SpeedDialIcon icon={<AccessTimeIcon />} openIcon={<CloseIcon />} />}
            open={openSpeedDial}
            direction="up"
            FabProps={{
              disabled,
              style: {
                marginLeft: theme.spacing(1),
              },
            }}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                FabProps={{
                  variant: 'extended',
                  style: {
                    justifyContent: 'left',
                  },
                }}
                className={classes.lol}
                icon={(
                  <>
                    {action.icon}
                    {action.name}
                  </>
                )}
                // Hack not to show tooltip
                tooltipTitle=""
                tooltipPlacement="top"
                onClick={action.onClick}
                open={false}
              />
            ))}
          </SpeedDial>
          {!openSpeedDial
            && (
              <Zoom in={!openSpeedDial}>
                <Chip
                  className={classes.speedDialChip}
                  color={disabled ? 'default' : 'primary'}
                  disabled={disabled}
                  deleteIcon={(
                    <Tooltip title={phrases.timespanSelection.RestoreDefaultTimespan}>
                      <IconButton aria-label="delete" className={classes.restoreChipIcon}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  label={selectedTimespanText}
                  onDelete={defaultTimespanSelected() ? null : resetToDefaultTimespan}
                />
              </Zoom>
            )}
        </div>
      </ClickAwayListener>
      <Dialog
        open={openDialog}
        scroll="paper"
        fullWidth
        classes={{
          root: classes.modal,
        }}
        PaperProps={{
          square: true,
        }}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {phrases.timespanSelection.SelectTimespan}
        </DialogTitle>

        <DialogContent
          dividers
          classes={{
            root: classes.dialogContent,
          }}
        >
          <CustomTabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleChange}
            tabs={[
              { label: phrases.timespanSelection.Relative, icon: faHistory },
              { label: phrases.timespanSelection.DateRange, icon: faCalendarAlt },
              { label: phrases.timespanSelection.CustomTimePreset, icon: faBusinessTime },
            ]}
            panels={[{
              content: (
                <TimespanRelativeSelection
                  selectTimeframe={selectTimeframeRelative}
                  handleCloseDialog={handleCloseDialog}
                  onTimespanSelect={onTimespanSelect}
                />),
            },
            {
              content: (
                <TimespanDateRangeSelection
                  handleCloseDialog={handleCloseDialog}
                  selectTimeframe={selectTimeframe}
                  onTimespanSelect={onTimespanSelect}
                />
              ),
            },
            {
              content: (
                <TimespanCustomSelection
                  handleCloseDialog={handleCloseDialog}
                  selectTimeframe={selectTimeframeCustom}
                  onTimespanSelect={onTimespanSelect}
                />
              ),
            },
            ]}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}, (prevProps, newProps) => prevProps.disabled === newProps.disabled);

export default TimespanSelection;
