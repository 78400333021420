import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Grow,
  TextField,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Search,
  Close,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  textField: {
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
}));

const ToolbarSearch = (props) => {
  const {
    value,
    handleSearch,
    hideSearch,
  } = props;

  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState(value || '');
  const [open, setOpen] = useState(true);

  const handleChangeSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleHideSearch = () => {
    setOpen(false);
    hideSearch();
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchQuery);
  };

  return (
    <Grow in={open}>
      <form onSubmit={onFormSubmit}>
        <Grid container spacing={0} alignItems="flex-end">
          <Grid item xs={6}>
            <TextField
              id="input-with-icon-grid"
              autoFocus
              className={classes.textField}
              value={searchQuery}
              onChange={handleChangeSearchQuery}
              label="Search"
            />
          </Grid>
          <Grid item xs={1} className={classes.iconContainer}>
            <Tooltip title="Search">
              <IconButton
                className={classes.iconButton}
                color="primary"
                size="small"
                onClick={() => handleSearch(searchQuery)}
              >
                <Search />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton
                className={classes.iconButton}
                size="small"
                color="secondary"
                onClick={handleHideSearch}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </form>
    </Grow>
  );
};

export default ToolbarSearch;
