import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

// Inspired by the former Facebook spinners.
const useStyles = makeStyles((theme) => ({
  root: {
    // position: 'relative',
    flexGrow: 1,
    textAlign: 'center',
    width: '100%',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    position: 'absolute',
  },
  top: {
    color: theme.palette.primary.main,
    animationDuration: '650ms',
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

export default function LoadingIndicator(props) {
  const classes = useStyles();
  const { size, thickness, text, textVariant } = props;

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={size}
        thickness={thickness}
      />
      <Typography variant={textVariant || "body1"} color="textPrimary">{text}</Typography>
    </div>
  );
}
