/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import useMachinesMultiselect from './hooks/useMachinesMultiselect';

const useStyles = makeStyles(() => ({
  inputField: {
    width: '100%',
  },
  structureName: {
    fontWeight: 550,
  },
  indented: {
    marginLeft: 25,
  },
}));

const MachinesMultiselect = (props) => {
  const classes = useStyles();
  const {
    options,
    value,
    onChange,
    label,
    error,
    required,
    helperText,
  } = props;
  const { dropdownOptions } = useMachinesMultiselect(options);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <div>
      <Autocomplete
        id="structure"
        name="structure"
        className={classes.inputField}
        options={dropdownOptions}
        onChange={onChange}
        value={value}
        error={error}
        helperText={helperText}
        disableCloseOnSelect
        groupBy={(option) => option.structure?.path}
        getOptionLabel={(option) => option.name}
        renderOption={(option, { selected }) => (
          <div key={uuid()}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              color="primary"
            />
            {option.name}
          </div>
        )}

        {...props}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              {...props}
              label={label}
              name="structure"
              className={classes.inputField}
              error={!!error}
              helperText={helperText}
              variant="outlined"
              margin="dense"
              required={required}
            />
          </>
        )}
      />
    </div>
  );
};

MachinesMultiselect.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default MachinesMultiselect;
