import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import useApi from '@frontend/utils/useApi';
import { storeUser } from '@frontend/modules/user/actions';
import useValidateLoginForm from '@frontend/modules/user/hooks/useValidateLoginForm';

const useLogin = () => {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState('');
  const dispatch = useDispatch();
  const { validateLoginForm } = useValidateLoginForm();
  const api = useApi();
  // let cancelAxios = null;
  const history = useHistory();

  // useEffect(() => () => {
  //   if (cancelAxios) cancelAxios.cancel('Component unmounted');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function login() {
    setOpenAlert(false);
    setIsSubmitting(true);
    const cancelAxios = axios.CancelToken.source();

    const data = {
      email: values.email,
      password: values.password,
    };

    api('/api/public/login', {
      data,
      method: 'post',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        if (response.status === 200) {
          const { token, user } = response.data;
          setLoginErrorText('');
          localStorage.setItem('accessToken', token);
          dispatch(storeUser(user));
          history.push(user.views.homeView);
        }
        setIsSubmitting(false);
      }).catch((_error) => {
        if (_error.response) {
          const { error } = _error.response.data;
          setLoginErrorText(error);
          setOpenAlert(!!error);
          setIsSubmitting(false);
        }
      });
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      login();
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateLoginForm(values));
  };

  const handleChange = (event) => {
    event.persist();
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    loginErrorText,
    openAlert,
    setOpenAlert,
  };
};

export default useLogin;
