/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Typography, IconButton } from '@material-ui/core';

import { persistTableState } from '@frontend/utils/UIActions';
import useAlert from '@frontend/hooks/useAlert';
import UserForm from '@frontend/modules/user/UserForm';
import Table from '@frontend/components/Table';
import useApi from '@frontend/utils/useApi';
import Modal from '@frontend/components/Modal';
import { usePhrases } from '@frontend/utils/usePhrases';
import useSecurity from '@frontend/utils/useSecurity';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import { storeUser } from '@frontend/modules/user/actions';
import { setTitle } from '@frontend/utils/usePageTitle';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import UserMultiselectToolbar from './UserMultiselectToolbar';
import ConfirmationModal from '@frontend/components/ConfirmationModal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  usersList: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }
}));

export default function UsersView() {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { createAlert } = useAlert();
  const { getProfile, isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const { formatDate } = useDateTimeFormat();
  const api = useApi();
  const dispatch = useDispatch();
  const [userToEdit, setUserToEdit] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const tableState = useSelector((state) => state.tableState);
  // let cancelAxios = null;
  setTitle(phrases.pageTitles.usersManagement);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    dispatch(persistTableState({}));
    return () => {
      if (cancelAxios) { cancelAxios.cancel('Component unmounted'); }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmationModalOpen = (selectedRows) => {
    setSelectedRows(selectedRows);
    setConfirmModalOpen(true);
  }

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
  }

  const editUser = (user) => {
    setUserToEdit(user);
    handleOpenModal();
  };

  const columns = [
    {
      label: phrases.forms.shared.fields.name,
      name: 'name',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'name' ? tableState.sortOrder.direction : 'none',
      },
    },
    {
      label: phrases.forms.user.fields.email,
      name: 'email',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'email' ? tableState.sortOrder.direction : 'none',
      },
    },
    {
      label: phrases.forms.user.fields.language,
      name: 'lang',
      options: {
        filter: true,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'lang' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[2] : [],
      },
    },
    {
      label: phrases.forms.user.fields.role,
      name: 'role',
      options: {
        filter: true,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'role' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[3] : [],
        sortCompare: (order) => {
          return (item1, item2) => {
            if (!item1.data) return 1;
            if (!item2.data) return -1;
            const res = item1.data.name.localeCompare(item2.data.name);
            return res * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          names: _.uniq(_.map(users, 'role.name')),
          logic: (role, filters, row) => {
            if (filters.length) return !filters.includes(role.name);
            return false;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value.name}
          </div>
        ),
      },
    },
    ...conditionalElement(isAuthorized('users', ['update:any', 'update:own']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => editUser(users.find((user) => user.id === value))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    }),
    {
      label: 'Active',
      name: 'isActive',
      options: {
        filter: true,
        filterList: tableState.filterList ? tableState.filterList[5] : ['Active'],
        display: 'excluded',
        filterType: 'checkbox',
        filterOptions: {
          names: ['Active', 'Inactive'],
          logic: (isActive, filters) => {
            if (filters.includes('Active') && filters.includes('Inactive')) return false;
            if (filters.includes('Active')) return !isActive;
            if (filters.includes('Inactive')) return isActive;
            return false;
          },
        },
      },
    },
  ];

  const prepareModalText = (obj) => {
    if (!obj || !obj.data) return;
    return (
      <>
        {obj.data.length > 1 ?
          <Typography display="block" gutterBottom>{phrases.confirmationModal.areYouSureDeactivateUsers}:</Typography>
          : <Typography display="block" gutterBottom>{phrases.confirmationModal.areYouSureDeactivateUser}:</Typography>}
        {obj.data.map((dataObj) => {
          const index = dataObj.dataIndex;
          const user = users[index];
          return <Typography className={classes.usersList} display="block">{user.name}</Typography>
        })
        }
      </>
    )
  }

  useEffect(() => {
    setIsLoading(true);
    const cancelAxios = axios.CancelToken.source();
    api('/api/users?populate=structures role', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setUsers(response.data);
      setIsLoading(false);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleDeactivateUsers = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const user = users[index];
      api('/api/users', {
        method: 'put',
        data: {
          ...user,
          isActive: false,
        },
        cancelToken: cancelAxios.token,
      })
        .then((response) => {
          if (response.status === 200) {
            handleCreate();
            createAlert(phrases.forms.user.success.userUpdated, 'success');
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
        });
    });
    handleCloseConfirmationModal();
  };

  const handleEdit = (user) => {
    const cancelAxios = axios.CancelToken.source();
    const loggedInUser = getProfile();
    if (user.id === loggedInUser.id) {
      api(`/api/users/${user.id}?populate=role`, {
        method: 'get',
      }).then((response) => {
        dispatch(storeUser(response.data));
      }).catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    }
    api('/api/users?populate=structures role', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setUsers(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  const handleCreate = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/users?populate=structures role', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setUsers(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  const getDirection = (direction) => {
    switch (direction) {
      case 'ascending': return 'asc';
      case 'descending': return 'desc';
      default: return 'asc';
    }
  };

  const customOptions = {
    download: true,
    downloadOptions: {
      filename: `${phrases.modules.user.users} - ${formatDate(new Date())}.csv`,
      separator: ',',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({ ...tableState, filterList }));
    },
    onChangePage: (page) => {
      dispatch(persistTableState({ ...tableState, page }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <UserMultiselectToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        deactivateUsers={() => handleConfirmationModalOpen(selectedRows)}
      />
    ),
    onDownload: (buildHead, buildBody, columns, data) => {
      data.map((usersArray) => {
        usersArray.data[3] = usersArray.data[3] ? usersArray.data[3].name : phrases.forms.shared.fields.none;
        return [...usersArray.data];
      });
      return buildHead(columns) + buildBody(data);
    },
  };

  return (
    <div className={classes.root}>
      <Table
        modalTitle={phrases.modules.user.users}
        data={users}
        resource="users"
        columns={columns}
        customOptions={customOptions}
        isLoading={isLoading}
      />
      <Modal
        modalTitle={phrases.modal.EditUser}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <UserForm
            isEdit
            onCreate={handleCreate}
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            user={userToEdit}
          />
        )}
      />
      <ConfirmationModal
        modalTitle={phrases.confirmationModal.deactivateUsers}
        text={prepareModalText(selectedRows)}
        openModal={confirmModalOpen}
        handleClose={handleCloseConfirmationModal}
        callback={() => handleDeactivateUsers(selectedRows)}
      />
    </div>
  );
}
