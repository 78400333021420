import { useState, useEffect } from 'react';
import axios from 'axios';
import useApi         from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import useAlert       from '@frontend/hooks/useAlert';
import _ from 'lodash';
import useValidateJobEditForm from './useValidateJobEditForm';

const emptyJob = {
  scanCode: "",
  businessJobId: "",
  order: "",
  structure: "",    
  intendedMachine: "",
  previouslyEngagedMachines: [
    "",
  ],    
  currentlyEngagedMachines: [      
  ],    
  description: "",
  product: "",    
  targetQuantity: 0,
  targetCycleTime: 0,
  targetSetupTime: 0,
  notes: [
    "",
  ],    
  plannedExecutionDate: new Date(),    
  actualStartDate: new Date(),
  completionStatus: "OPEN",
  handCount: 0,
  MES: false,  
}

const useJobEditForm = (jobIndexToEdit, existingJobs, handleCloseModal, isEdit) => {

    const [formSubmitting, setFormSubmitting] = useState(false);
    const [machinesForJob, setMachinesForJob] = useState([]);
    const [productsForJob, setProductsForJob] = useState([]);
    const [structures, setStructures] = useState({});
    const [ordersForJob, setOrdersForJob] = useState([]);
    const [jobToEdit, setJobToEdit] = useState(emptyJob);
    const [errors, setErrors] = useState({})
    const [isLoadingMachines, setIsLoadingMachines] = useState(false);
    const api = useApi();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();
    const { validateJobEditForm } = useValidateJobEditForm();
    /**
     * These methods are necessary because the Multiselect does not offer a event.target.name value...
     * @param {*} event 
     * @param {*} value 
     */
    const handleMachinesChange = (event, value) => {        
      if (errors["intendedMachine"]) {
        delete errors["intendedMachine"];
      }
      setJobToEdit({
          ...jobToEdit,
          intendedMachine: value
      })
    }
    
    const handleProductsChange = (event, value) => {
      if (errors["product"]) {
        delete errors["product"];
      }
      setJobToEdit({
          ...jobToEdit,
          product: value
      })
    }

    const handleOrdersChange = (event, value) => {

      if (errors["order"]) {
        delete errors["order"];
      }
      setJobToEdit({
          ...jobToEdit,
          order: value
      })
    }
    const handleChange = (event) => {
        event.persist(); 
        if (errors[event.target.name]) {
          delete errors[event.target.name];
        } 

        let value = event.target.value;
        
        setJobToEdit((job) => ({
            ...job,
            [event.target.name]: value,
        }));
    };

    const handleSubmitValidate = (event) => {
      if (event) {
        event.preventDefault();
      }
      setFormSubmitting(true);
      setErrors(validateJobEditForm(jobToEdit));
    };

    const handleSubmit = () => {
        setFormSubmitting(true);
        const cancelAxios = axios.CancelToken.source();
        api('/api/orders/jobs?populate=machines product&complete=True', {
          method: 'put',
          cancelToken: cancelAxios.token,
          data: {
            ...jobToEdit
          }
        })
          .then((response) => {
            setFormSubmitting(false);
            handleCloseModal();
            createAlert(phrases.forms.jobs.jobEditSuccess, 'success');
          })
          .catch((error) => {
            setFormSubmitting(false);
            createAlert(phrases.forms.jobs.jobEditFailure, 'error');
          });
    }

    const getStructuresMachines = (_structures) => {
        return _.flatten(
            _structures.map((structure) => {
                if (structure.machines) {
                    return structure.machines.map((machine) => {
                        return {
                            ...machine,
                            structure,
                        };
                    });
                }
                return [];
            })
        );        
    }

    const mapStructureOrders = (orders) => {
      return orders.map((order) => {
        return {
          ...order,
          structure: structures[order.structure]          
        }
      })
    }

    useEffect(() => {
      if (Object.keys(errors).length === 0 && formSubmitting) {
        handleSubmit();
      } else {
        setFormSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        setIsLoadingMachines(true);
        const cancelAxios = axios.CancelToken.source();
        api('/api/structures/?mongoQuery={}&populate=machines', {
          method: 'get',
          cancelToken: cancelAxios.token,
        })
          .then((response) => {
            const tmpStructureDict = {};
            response.data.forEach((structure) => {
              tmpStructureDict[structure.id] = {...structure}
            })
            setStructures(tmpStructureDict);
            setMachinesForJob(getStructuresMachines(response.data));
            setIsLoadingMachines(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
            setIsLoadingMachines(false);
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoadingMachines(true);
        const cancelAxios = axios.CancelToken.source();
        api('/api/orders/products/', {
          method: 'get',
          cancelToken: cancelAxios.token,
        })
          .then((response) => {
            setProductsForJob(response.data);
            setIsLoadingMachines(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
            setIsLoadingMachines(false);
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if(!isEdit){
        setIsLoadingMachines(true);
        const cancelAxios = axios.CancelToken.source();
        api('/api/orders/', {
          method: 'get',
          cancelToken: cancelAxios.token,
        })
          .then((response) => {
            setOrdersForJob(mapStructureOrders(response.data));
            setIsLoadingMachines(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
            setIsLoadingMachines(false);
          });
      }      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structures]);

    useEffect(() => {
        if(jobIndexToEdit > -1){
            setJobToEdit(existingJobs[parseInt(jobIndexToEdit)]);
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobIndexToEdit])

    return {
        handleMachinesChange,
        handleProductsChange,
        handleOrdersChange,
        handleChange,
        handleSubmitValidate,
        machinesForJob,
        jobToEdit,
        ordersForJob,
        isLoadingMachines,
        productsForJob,
        formSubmitting,
        errors
    };
}
 
export default useJobEditForm;