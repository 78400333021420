import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { usePhrases } from '@frontend/utils/usePhrases';
import {
    Box,
    Typography,
    Grid,
    Divider,
    Table,
    TableRow,
    Paper,
    TableCell,
    TableHead,
    Button,
    Input
} from '@material-ui/core'
import TimespanClock from './TimespanClock';

// lets create some Styles with access to the overall theme
const useStyles = makeStyles((theme) => ({
    timespan: {
      display: 'block',
      width: '100%',
      fontWeight: 'bold',
    },
    timeSelectElement: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
    },
    timeSelectBox: {
        marginLeft: theme.spacing(1.25),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0),
    },
    inputTime: {
        width: "50px"
    },
    highlighted: {
        fontWeight: 900,
        color: theme.palette.primary.main,
    },
  }));

/*
A simple helper function to determine, whether the selected Hour button ID matches
the selected hour
*/
const isSelectedHour = (hour, hourButtonID) => {
    const re = new RegExp(hour, 'g');
    return hourButtonID.match(re);
}

const TimespanSelector = (props) => {
    const phrases = usePhrases().phrases();
    const timespanStart = useSelector((state) => state.timespanStart);
    const { formatTime } = useDateTimeFormat();
    const classes = useStyles();

    // State variable and setter for the hour input
    const [inputHours, setInputHours] = useState('1');

    // Onclick listener for the controlled input component
    const onInputChange = (event) => {
        setInputHours(event.target.value);
        props.setHours(parseInt(event.target.value))
    }
    return (
        <>
            <Grid item xs={12}>                
                <Typography variant="button" display="block" color="textSecondary">
                    {phrases.modules.advanced_reporting.timeSelectorHeading}
                </Typography>                
                <Divider />                
            </Grid>            
            <Grid item xs={4}>
                <Paper variant="elevation" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6" align="center">
                                        {phrases.modules.advanced_reporting.timeSelectorTimeframeSelect}     
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Box
                                    sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    border: (theme) => `1px solid ${theme.palette.divider}`,
                                    borderRadius: 1,
                                    bgcolor: 'background.paper',
                                    color: 'text.secondary',
                                    '& svg': {
                                        m: 1.5,
                                    },
                                    '& hr': {
                                        mx: 0.5,
                                    },
                                    }}
                                >                                                        
                                    <Button
                                        id="1h"
                                        variant="outlined"
                                        color={'primary'}
                                        size="large"
                                        onClick={()=>{
                                            console.log("1h");
                                            props.setHours(1);
                                        }}
                                        className={clsx({
                                            [classes.timeSelectElement]: true,
                                            [classes.timeSelectBox]: true,
                                            [classes.highlighted]: isSelectedHour(props.hours, "1h"),
                                            })}                        
                                    >
                                        {phrases.modules.advanced_reporting.oneh}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        id="2h"
                                        color={'primary'}
                                        size="large"
                                        onClick={()=>{
                                            console.log("2h");
                                            props.setHours(2);
                                        }}
                                        className={clsx({
                                            [classes.timeSelectElement]: true,
                                            [classes.timeSelectBox]: true,
                                            [classes.highlighted]: isSelectedHour(props.hours, "2h"),
                                            })}
                                        
                                    >
                                        {phrases.modules.advanced_reporting.twoh}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        id="3h"
                                        color={'primary'}
                                        size="large"
                                        onClick={()=>{
                                            console.log("3h");
                                            props.setHours(3);
                                        }}
                                        className={clsx({
                                            [classes.timeSelectElement]: true,
                                            [classes.timeSelectBox]: true,
                                            [classes.highlighted]: isSelectedHour(props.hours, "3h"),
                                            })}
                                        
                                    >
                                        {phrases.modules.advanced_reporting.threeh}
                                    </Button>
                                    <Divider orientation="vertical" variant="middle" flexItem />

                                    <Typography 
                                        variant="body1"
                                        color="textSecondary"
                                        className={clsx({
                                            [classes.timeSelectBox]: true,                                        
                                            })}
                                    >
                                        {phrases.modules.advanced_reporting.pickTime}
                                        <Input
                                        color="primary"
                                        value={inputHours}
                                        onChange={onInputChange}
                                        id="hourInput"
                                        className={clsx({
                                            [classes.timeSelectBox]: true,
                                            [classes.inputTime]: true,
                                            })}
                                        type="number"
                                        size="small"
                                        >
                                        </Input>
                                        h
                                    </Typography> 
                                    
                                </Box>
                            </TableCell>                            
                        </TableRow>
                    </Table>
                </Paper>
            </Grid>

            <Grid item xs={4}>
                <Paper variant="elevation" square>
                    <TimespanClock
                    heading="Local Time"
                    phrases={phrases}
                    classes={classes}
                    timespanStart={timespanStart}
                    formatTime={formatTime}
                    clockid="local-clock"

                    />
                </Paper>
            </Grid> 
            <Grid item xs={4}>
                <Paper variant="elevation" square>
                    <TimespanClock
                    heading="World Time"
                    phrases={phrases}
                    classes={classes}
                    timespanStart={timespanStart}
                    formatTime={formatTime}
                    clockid="world-clock"
                    />
                </Paper>
            </Grid> 
        </>);
    }
export default TimespanSelector;