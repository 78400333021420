import { useEffect, useState } from "react";
import useApi from '@frontend/utils/useApi';
import useAlert from '@frontend/hooks/useAlert';
import { usePhrases } from '@frontend/utils/usePhrases';
import { useSelector } from "react-redux";

const useMachinePerformance = (machineId) => {

    const api = useApi();
    const { createAlert } = useAlert();
    const phrases = usePhrases().phrases();
    const dummyPerformance = {
      default: true,
      theoreticalOutput: {
        "value": 0,
        "comment": null,
      },
      plannedOutput: {
        "value": 0,
        "comment": null
      }, 
      scheduledOutput: {
        "value": 0,
        "comment": null
      }, 
      downtimeLoss: {
        "value": 0,
        "comment": null
      }, 
      speedLoss: {
        "value": 0,
        "comment": null
      }, 
      qualityLoss: {
        "value": 0,
        "comment": null
    }};
    
    const [isLoadingPerformance, setIsLoadingPerformance] = useState(false);
    const [performance, setPerformance] = useState(dummyPerformance);
    const timespanStart = useSelector(state => state.timespanStart);
    const timespanEnd = useSelector(state => state.timespanEnd);
    useEffect(() => {
        setIsLoadingPerformance(true);
        if(machineId) {
        api(`/api/machine_performance?machineId=${machineId}&timespanStart=${timespanStart}&timespanEnd=${timespanEnd}`, {
            method: 'get',
          }).then((response) => {
            if (response.status === 200 || response.status === 304) {
                if(response.data.err){
                    createAlert(phrases.errorMessages.noMaxCycleTime, 'warning');
                    setIsLoadingPerformance(false);
                    setPerformance(dummyPerformance);
                } else {
                  setIsLoadingPerformance(false);
                  setPerformance(response.data);
                }
            }
          }).catch((error) => {
            createAlert(phrases.errorMessages.notAuthorizedToViewMachine, 'error');            
            setIsLoadingPerformance(false);
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineId, timespanStart, timespanEnd]);

    return {
        performance,
        isLoadingPerformance
    };
}
 
export default useMachinePerformance;