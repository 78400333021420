import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import useApi from '@frontend/utils/useApi';
import useReloadTimes     from '@frontend/hooks/useReloadTimes';
import socket             from '@frontend/utils/useSocket';
import moment from "moment"
import {
    setLastReloadTime,
  } from '@frontend/utils/UIActions';
const usePartsChartModal = (machineId, targetCycleTime, isPartsPerMinute=true, customTimespanStart, customTimespanEnd, calculationFactor=1, fullyFeatured=0) => {

    // ===== States =====
    const [parts, setParts] = useState([]);
    const [maxCount, setMaxCount] = useState(0);
    const [isLoadingParts, setIsLoadingParts] = useState(false);
    const [myTimeStart, setMyTimeStart] = useState(moment().toDate());
    const [myTimeEnd, setMyTimeEnd] = useState(moment().toDate());
    // ===== Imports =====
    const api = useApi()
    const dispatch = useDispatch();
    const { canReloadNow } = useReloadTimes();

    // ===== Redux Selectors =====    
    const timespanEnd = customTimespanEnd ?? useSelector(state => state.timespanEnd);
    const timespanStart = customTimespanStart ?? useSelector(state => state.timespanStart);
    const isRelativeTimespan = useSelector(state => state.isRelativeTimespan);
    const selectedRelativeTimespan = useSelector(state => state.selectedRelativeTimespan);
    const lastReloadTime = useSelector((state) => state.lastReloadTime);
    const reloadInterval = useSelector((state) => state.reloadInterval);
    const reloadIntervalRef = useRef();
    const lastReloadTimeRef = useRef();
  
    reloadIntervalRef.current = reloadInterval;
    lastReloadTimeRef.current = lastReloadTime;
    
    // ===== Effects =====
    useEffect(() => {
        if(isRelativeTimespan) {
            setMyTimeEnd(moment().toDate());
            setMyTimeStart(moment().subtract(selectedRelativeTimespan.amount, selectedRelativeTimespan.unit).toDate());
        } else {
            setMyTimeEnd(timespanEnd);
            setMyTimeStart(timespanStart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timespanStart, timespanEnd, isRelativeTimespan, selectedRelativeTimespan]);

    const updateParts = () => {
        setIsLoadingParts(true);
        api(`/api/performance/parts-detailed?from=${moment(myTimeStart)}&to=${moment(myTimeEnd)}&machine=${machineId}`, {
            method: 'get',
        }).then((response) => {
            if(response.status === 200 || response.status === 304){
                if(!isPartsPerMinute){
                    setParts(response.data.aggregatedResults.map((part) => {
                        return {
                            ...part,
                            actualCount: part.actualCount !== 0 ? 60/part.actualCount: 0,
                        }
                    }));
                } else {
                    setParts(response.data.aggregatedResults.map((part) => {
                        return {
                            ...part,
                            actualCount: part.actualCount * calculationFactor,
                        }
                    }));
                }
                setIsLoadingParts(false);
            }
        }).catch((error) => {
            setIsLoadingParts(false);
            if (!axios.isCancel(error)) console.log(error);
        });
    }

    useEffect(() => {
        updateParts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineId, isPartsPerMinute, myTimeStart, myTimeEnd]);
    // ===== Effects =====

    useEffect(() => {
        if (socket._callbacks['$STATES_UPDATE_PARTSCHART'] === undefined && fullyFeatured > 0) {
          socket.on('STATES_UPDATE', (data) => {
            if(canReloadNow(lastReloadTimeRef.current, reloadIntervalRef.current, 'STATES_UPDATE')){
                dispatch(setLastReloadTime({...lastReloadTimeRef.current, 'STATES_UPDATE': moment().format()}));
                updateParts()
            }
          });
        }
        return () => {
          socket.emit('STATE_REMOVE_LISTENER');          
          socket._callbacks['$STATES_UPDATE_PARTSCHART'] = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    useEffect(() => {
        const targetPartsPerMinute = isPartsPerMinute ? (parseInt(targetCycleTime) ? 60 / parseInt(targetCycleTime) : 0) : parseInt(targetCycleTime);
        if(parts.length >= 65536){
            // DO QUICKSORT
            const partsCopy = [...parts];
            partsCopy.sort((a, b) => a.actualCount - b.actualCount);
            setMaxCount( partsCopy[partsCopy.length-1] > targetPartsPerMinute ? partsCopy[partsCopy.length-1] : targetPartsPerMinute)
        }
        else if(parts.length > 0){
            const partsMax = Math.max(...parts.map((part) => part.actualCount !== null ? part.actualCount : 0));

            setMaxCount( partsMax > targetPartsPerMinute ? partsMax : targetPartsPerMinute);
        } 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parts, targetCycleTime, isPartsPerMinute])
    return {
        parts,
        maxCount,
        isLoadingParts
    };
}
 
export default usePartsChartModal;