import { useState, useEffect} from 'react';
import useApi from '@frontend/utils/useApi';

const useMachineJob = (machine, fullQuery=false, totalReports) => {
    const api = useApi();
    const [jobOfMachine, setJobOfMachine] = useState({})

    const getActiveJobOfMachine = () => {    
        if(machine !== undefined){  
            let populate = fullQuery ? "populate=currentlyEngagedMachines previouslyEngagedMachines intendedMachine order notes structure product&" : ""          
            api(`/api/orders/jobs/activeJob/${machine.id}?${populate}sensorData=${fullQuery}&qualityReports=true`, {
                method: 'get',        
            })
            .then((response) => {   
                if (response.status === 200 || response.status === 304) {                                                      
                    setJobOfMachine(response.data);
                }
            })
            .catch((error) => {
                // console.log(error);
            })
            
        }           
    };
  
    useEffect(()=>{
        getActiveJobOfMachine();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machine, totalReports])

    return {
        jobOfMachine
    }
}
 
export default useMachineJob;