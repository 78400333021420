export const storeUser = (user) => ({
  type: 'STORE_USER',
  user,
});

export const removeUser = () => ({
  type: 'REMOVE_USER',
});

export const storeListOfUsers = (users) => ({
  type: 'STORE_LIST_USERS',
  users,
});

export const removeListOfUsers = () => ({
  type: 'REMOVE_LIST_USERS',
});

export const storeListOfRoles = (roles) => ({
  type: 'STORE_LIST_ROLES',
  roles,
});

export const removeListOfRoles = () => ({
  type: 'REMOVE_LIST_ROLES',
});
