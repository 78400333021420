/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import EditIcon       from '@material-ui/icons/Edit';

import Modal from '@frontend/components/Modal';
import Table from '@frontend/components/Table';
import useAlert       from '@frontend/hooks/useAlert';
import { storeListOfSensorTypes } from '@frontend/modules/sensor/actions';
import SensorTypeForm             from '@frontend/modules/sensor/SensorTypeForm';
import utils                      from '@frontend/utils/utils';  
import { persistTableState }      from '@frontend/utils/UIActions';
import useApi                     from '@frontend/utils/useApi';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import { setTitle }               from '@frontend/utils/usePageTitle';
import { usePhrases }             from '@frontend/utils/usePhrases';
import useSecurity                from '@frontend/utils/useSecurity';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function SensorTypesView() {
  // ===== State Variables =====
  const [sensorTypeToEdit, setSensorTypeToEdit] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ===== Redux Selectors =====
  const tableState = useSelector((state) => state.tableState);
  const sensorTypes = useSelector((state) => state.sensorTypes);

  // ===== Imports =====
  const classes = useStyles();
  const dispatch = useDispatch();
  const phrases = usePhrases().phrases();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const { createAlert } = useAlert();
  const api = useApi();

  // ===== Effects =====
  setTitle(phrases.pageTitles.sensorTypesManagement);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    dispatch(persistTableState({}));

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors/sensor-types', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfSensorTypes(response.data));
      setIsLoading(false);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // ===== Helper Methods =====
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editSensorType = (sensorType) => {
    setSensorTypeToEdit(sensorType);
    handleOpenModal();
  };

  const handleDelete = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const sensorType = sensorTypes[index];
      api(`/api/sensors/sensor-types/${sensorType.id}`, {
        method: 'delete',
        cancelToken: cancelAxios.token,
      }).then((response) => {
        if (response.status === 204) {
          sensorTypes.splice(index, 1);
          dispatch(storeListOfSensorTypes([...sensorTypes]));
          createAlert(phrases.forms.sensor.success.sensorTypeDeleted, 'success');
        }
      }).catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    });
    return false;
  };

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors/sensor-types', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfSensorTypes(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  // ===== Local Variables =====
  const columns = [
    {
      label: phrases.forms.shared.fields.name,
      name: 'name',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'name' ? tableState.sortOrder.direction : 'none',
      },
    },
    {
      label: phrases.forms.sensor.fields.measurementUnit,
      name: 'measurementUnit',
      options: {
        filter: true,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'measurementUnit' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[1] : [],
      },
    },
    ...conditionalElement(isAuthorized('sensor-types', ['update:any']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => editSensorType(sensorTypes.find((sensorType) => sensorType.id === value))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    }),
  ];

  const customOptions = {
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({ ...tableState, filterList }));
    },
    onChangePage: (page) => {
      dispatch(persistTableState({ ...tableState, page }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: utils.getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
  };

  return (
    <div className={classes.root}>
      <Table
        modalTitle={phrases.forms.shared.fields.sensors}
        data={sensorTypes}
        resource="sensor-types"
        onDelete={handleDelete}
        columns={columns}
        customOptions={customOptions}
        isLoading={isLoading}
      />
      <Modal
        modalTitle={phrases.modal.EditSensorType}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <SensorTypeForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            sensorType={sensorTypeToEdit}
          />
        )}
      />

    </div>
  );
}
