import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateTerminalLoginForm = () => {
  const phrases = usePhrases().phrases();
  const validateLoginForm = (values) => {
    const errors = {};

    if (values.username.length < 3) {
      errors.username = phrases.forms.terminal.errors.terminalUsernameLength;
    }

    if (values.pin.length < 4) {
      errors.pin = phrases.forms.terminal.errors.terminalPinLength;
    }

    if (!/^\d+$/.test(values.pin)) {
      errors.pin = phrases.forms.terminal.errors.terminalInvalidPinFormat;
    }

    return errors;
  };

  return {
    validateLoginForm,
  };
};

export default useValidateTerminalLoginForm;
