import React from 'react';
import {
  Button,
} from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const useStyles = makeStyles((theme) => ({
    popupListItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemButton: {
      float: 'right',
      marginLeft: theme.spacing(1),
    },
    popupButton: {
      marginTop: theme.spacing(1),
    },
  }));

const StaticReason = (props) => {
    const {
        mb,
        setStaticReasonAssignModalOpen,
    } = props;
    const phrases = usePhrases().phrases();
    const theme = useTheme();
    const classes = useStyles();
    return ( 
        <Button
            size="small"
            onClick={() => {
              setStaticReasonAssignModalOpen(true);
            }}
            className={classes.listItemButton}
            style={mb ? { marginBottom: theme.spacing(1) } : {}}
            variant="outlined"
            disableElevation
            startIcon={<NewReleasesIcon />}
        >
        {phrases.modules.machine.staticReason}
        </Button>);
}
 
export default StaticReason;