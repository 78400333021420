import React, { Component } from 'react';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Grid,
    Paper,
} from '@material-ui/core'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ARS from "./advanced_reporting.service";
am4core.useTheme(am4themes_animated);
am4core.addLicense("AM5C377488971");

/**
 * Author: Julian Dreyer
 * This component will display a Pie Chart containing the top 3 worst failure reasons of the given Structure.
 * The provided prop data must include the worst reasons, at best in a sorted manner, and a unique chart_id. 
 */

class ReasonRankingCard extends Component {
    constructor(props) {
        super(props);
        this.state = {  }

        // A color gradient for the diagram...
        this.colorGradient = ["#56E856", "#F2BA5A", "#F0654F"]        
    }

    /**
     * Author: Julian Dreyer
     * The lifecycle method componentDidUpdate will be called, whenever the data feeding the object has been
     * updated. In our case, we need to update the chart in here.
     * The expected "worstReasons" array has entries in the following format:
     * 
        {
            name: <reason name>
            count: integer 
        }
     */
    componentDidUpdate(){
        // Now let's check if the Reasons Array is empty. If so, show that there are no reasons yet
        // Calc the reasons

        let reasons = ARS.filterAndRankMachineStates(3, this.props.newStates, this.props.hours) // this.props.worstReasons
        if(reasons.length === 0){
            // No reasons found. Just display an empty diagram
            this.chart.data = [{
                                "color": "green"
                              }]
            this.chart.data[0][this.props.chart_category_id] = this.props.no_reasons_text
            this.chart.data[0][this.props.chart_value_id] = this.props.no_reasons_value
        } else {
            // Now lets add one of the colors from the gradient to the chart entry
            reasons.forEach((element, i) => {
                element.color = this.colorGradient[i];
            });
            this.chart.data = reasons; 
        }
        // To update the chart, we need to invalidate the data.
        this.chart.invalidateData();
    }

    /**
     * Author: Julian Dreyer
     * Once the component is mounted, the chart can be loaded into the DOM. The data
     * will be provided in the update method.
     */
    componentDidMount() {
        
        // The chart object needs to have a unique Chart ID within a div element.
        this.chart = am4core.create(this.props.chart_id, am4charts.PieChart);
        this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        // Outer Radius of the Slices
        this.chart.radius = am4core.percent(70);
        // Inner Radius of the PieChart
        this.chart.innerRadius = am4core.percent(40);
        // Since this is a half circle pie chart, we need to start at 180 degr and stop at 360
        this.chart.startAngle = 180;
        this.chart.endAngle = 360;  

        // Lets display a legend to indicate the reasons
        this.chart.legend = new am4charts.Legend();
        this.chart.legend.labels.template.fill = am4core.color(this.props.color.primary);
        this.chart.legend.labels.template.text = "[bold]{"+this.props.chart_category_id+"}: {count}";

        // Lets add the series to it        
        var pieSeries = this.chart.series.push(new am4charts.PieSeries());
        pieSeries.slices.template.cornerRadius = 10;
        pieSeries.slices.template.innerCornerRadius = 7;
        
        // The data fields of the diagram are called count and name (s. above)
        pieSeries.dataFields.value = this.props.chart_value_id;
        pieSeries.dataFields.category = this.props.chart_category_id;

        // We dont want any labels on the data. The tooltips are fine
        pieSeries.labels.template.disabled = true;

        // The slices will take the color within the field "color"
        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.legendSettings.itemValueText = "[bold]{valueY}[/bold]";
      }
    
      /**
       * Author: Julian Dreyer
       * This lifecycle method is called whenever the component is unmounted. It's important to
       * dispose the chart so that it will not be rendered on top of an old one.
       */
      componentWillUnmount() {
        if (this.chart) {
          this.chart.dispose();
        }
      }

      render() {
        return (
            <Grid item xs={12} md={4}>                
                <Paper variant="elevation" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="body1" align="center">
                                        Worst failure reason Overview
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableRow>
                            <TableCell>
                            <div id={this.props.chart_id} style={{height: '300px', margin: '20px auto'}}>
                            </div>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Paper>
            </Grid>
        );
      }
}
 
export default ReasonRankingCard;