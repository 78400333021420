import React from 'react';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
} from '@material-ui/core';

import EditIcon     from '@material-ui/icons/Edit';
import DeleteIcon   from '@material-ui/icons/Delete';
import { 
    makeStyles,
    useTheme
} from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import ConfirmationModal  from '@frontend/components/ConfirmationModal';
import LoadingIndicator   from '@frontend/components/LoadingIndicator';
import useStatesFilter                  from '@frontend/hooks/useStatesFilter';
import useOrderExportQualityReportModal from '@frontend/modules/orders/hooks/useOrderExportQualityReportModal';
import QualityReportEditModal           from '@frontend/modules/quality/QualityReportEditModal';
import QualityReport                    from '@frontend/modules/quality/components/QualityReport';
import { usePhrases }           from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    loadingReasonsIndicator: {
      marginLeft: theme.spacing(1),
    },
  }));

const OrderExportQualityReportModal = (props) => {
    const {
        activation,
        handleCloseModal      
    } = props;
    
    
    // ===== Imports =====    
    const {
        qualityReports,
        loadingQualityReports,
        deleteModalOpen,
        selectedReport,
        editModalOpen,
        reloadQRData,
        selectedState,
        handleOpenConfirmationModal,
        handleCloseConfirmationModal,
        handleDeleteQualityReport,
        handleEditReport, 
        handleCloseEditModal,
    } = useOrderExportQualityReportModal(activation, handleCloseModal);
    const {
        scrapTypeText,
    } = useStatesFilter();
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const theme = useTheme();

    // ===== Helpers =====
    const wasteCountForType = (state, scrapType) => {
        return state[`quality.${scrapType}.wasteCount`];
    };
    
    return (
        <>
            <Paper className={classes.paper} variant="outlined" square>
                <List disablePadding>
                    <ListItem alignItems="center">
                    <ListItemAvatar>
                        {!loadingQualityReports ? (
                        <FontAwesomeIcon
                            icon={faClipboardList}
                            size="2x"
                        />
                        ) : <LoadingIndicator size={20} />}
                    </ListItemAvatar>
                    <Table>
                        <TableRow>
                        <TableCell padding="none" variant="body" size="small" align="left" style={{ borderBottom: 'none' }}>
                            <Typography variant="body1">
                            {phrases.modules.quality.qualityReports}
                            </Typography>
                        </TableCell>
                        <TableCell padding="none" variant="body" size="small" align="right" style={{ borderBottom: 'none' }}>                            
                            <QualityReport
                                selectedState={selectedState}
                                refreshPulse={reloadQRData}
                                machine={activation?.machine}
                                actualCount={activation?.partsProduced}
                                allQualityReports={qualityReports}
                                setParentModalOpen={(isOpen) => {if (!isOpen) handleCloseModal()}}
                            />
                        </TableCell>
                        </TableRow>
                    </Table>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </List>
                <Table aria-label="simple table">
                    <TableBody>
                    {qualityReports?.length ? qualityReports.map((report) => (
                        <TableRow>
                        <TableCell align="left">
                            <Typography>{scrapTypeText(report['quality.scrapType'])}</Typography>
                        </TableCell> 
                        <TableCell align="center">
                            <Typography className={classes.headingCount}>
                                {wasteCountForType(report, report['quality.scrapType'])}
                                {' '}
                                {phrases.modules.quality.parts}
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                color="primary"
                                onClick={() => handleEditReport(report)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => handleOpenConfirmationModal(report)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                        </TableRow>
                    )) : 
                        <Typography style={{ marginTop: theme.spacing(1) }} variant="body2">
                            {phrases.modules.quality.noQualityReportsSubmitted}
                        </Typography>}
                    </TableBody>
                </Table>
                <ConfirmationModal
                    title={phrases.modules.order_export.quality_report.deleteQualityReportModalTitle}
                    text={phrases.modules.order_export.quality_report.deleteQualityReportModalText}
                    openModal={deleteModalOpen}
                    handleClose={handleCloseConfirmationModal}
                    callback={() => handleDeleteQualityReport()}
                />
                {editModalOpen && selectedReport && (
                    <QualityReportEditModal
                        machine={activation?.machine}
                        actualCount={activation?.partsProduced}     // Have to adjust these a little here
                        open={editModalOpen}
                        handleCloseModal={handleCloseEditModal}
                        selectedReport={selectedReport}
                        refreshPulse={reloadQRData}
                        allQualityReports={qualityReports}
                        handleCloseModalAfterSubmit={handleCloseModal}
                    />
                )}
            </Paper>
            
        </>
    )
}
 
export default OrderExportQualityReportModal;