/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles }           from '@material-ui/core/styles';
import {
  axisBottom,
  scaleTime,
  select
} from 'd3';
import moment from 'moment';

import Modal              from '@frontend/components/Modal';
import useAlert             from '@frontend/hooks/useAlert';
import useCurrentBreakpoint from '@frontend/hooks/useCurrentBreakpoint';
import useStatesFilter      from '@frontend/hooks/useStatesFilter';
import useReasonAssignForm  from '@frontend/modules/reasons/hooks/useReasonAssignForm';
import useApi             from '@frontend/utils/useApi';
import { usePhrases }     from '@frontend/utils/usePhrases';
import ReasonsCollapse from '@frontend/modules/reasons/components/ReasonsCollapse';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  bold: {
    fontWeight: 900,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  statusTitle: {
    marginLeft: theme.spacing(1),
  },
  reasonTitle: {
    marginBottom: theme.spacing(1),
  },
  pulseWrapper: {
    marginTop: theme.spacing(1),
  },
}));

const ReasonAssignModal = (props) => {
  const {
    open,
    selectedState,
    handleCloseModal,
    isEdit,
    refreshPulse,
    setPopupOpen,
  } = props;

  // ===== State Objects =====
  const svgRef = useRef();
  const svgWrapperRef = useRef();
  // const [reasonFreeze, setReasonFreeze] = useState(true);

  // ===== Redux Selectors =====
  // ===== Imports =====
  const classes = useStyles();
  const api = useApi();
  const phrases = usePhrases().phrases();
  const currentBreakpoint = useCurrentBreakpoint();
  const {
    stateColor,
    stateText,
  } = useStatesFilter();
  const { createAlert } = useAlert();
  const {
    handleChangeReason,
    handleSubmit,
    handleClearForm,
    statusReasonValues,
    handleChangeNote,
    isSubmitting,
    setIsSubmitting,
    resetToDefault,
  } = useReasonAssignForm(submitStatusReasonForm, selectedState, open);

  // ===== Helper Methods =====
  const handleReasonClick = (_reason) => {
    // setReasonFreeze(false);
    handleChangeReason(_reason);
  };

  const handleCloseModalClick = () => {
    handleCloseModal();
  };

  const onModalClose = () => {
    handleCloseModal();
    handleClearForm();
  };

  const onModalOpen = () => {
    renderStatus();
  };

  const handleDeleteReason = () => {
    deleteStatusReasonNote();
  };

  const handleResetReason = () => {
    // In Theory, this should be replaced by "presentation.defaultReason" using the same identfier.    
    resetToDefault();
  }

  // will be called initially and on every data change
  const renderStatus = () => {
    const svg = select(svgRef.current);
    const svgWrapper = svgWrapperRef.current;
    let wrapperWidth = 0;
    if (svgWrapper) wrapperWidth = svgWrapper.clientWidth;
    // Erase chart so it can be redrawn with new data
    svg.selectAll('rect')
      .remove();
    // If the wrapper has no width an height
    if (!selectedState) return;

    // Min and Max time on the timeline
    const minTime = new Date(selectedState.startTime);
    const maxTime = new Date(selectedState.endTime);

    // x scale, time scale
    const xScale = scaleTime()
      .domain([minTime, maxTime])
      .range([0, 400]);

    // x axis
    const xAxisBottom = axisBottom(xScale)
      .tickValues([minTime, maxTime]);

    // position x axis
    const xAxis = svg.select('.x-axis')
      .transition()
      .duration(250)
      .style('transform', `translate(${wrapperWidth / 2 - 200}px,${50 - 7}px)`)
      .call(xAxisBottom);

    if (currentBreakpoint()
      .down('sm')) {
      xAxis
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-45)');
    } else if (currentBreakpoint()
      .up('sm')) {
      xAxis
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '1.46em')
        .attr('y', 9)
        .attr('dy', '0.71em')
        .attr('transform', 'rotate(0)');
    }

    // draw states
    svg.selectAll('rect')
      .data([selectedState])
      .enter()
      .append('rect')
      .attr('x', (state) => xScale(moment(state.startTime)))
      .attr('width', () => {
        const stateStart = xScale(new Date(minTime));
        const stateEnd = xScale(new Date(maxTime));
        const width = stateEnd - stateStart;
        return width < 0 ? 0 : width;
      })
      .style('transform', `translate(${wrapperWidth / 2 - 200}px, 0px)`)
      .attr('height', `${50 / 1.21}px`)
      .attr('fill', (state) => statusReasonValues.color || stateColor(state.state));
  };

  function submitStatusReasonForm() {
    const data = statusReasonValues.noteId ? {
      id: statusReasonValues.noteId,
      text: statusReasonValues.note,
    } : { text: statusReasonValues.note };
    api('/api/shared/notes', {
      data,
      method: statusReasonValues.noteId ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          submitStatusReason(response.data.id);
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  }

  const submitStatusReason = (noteId) => {
    api('/api/reasons/status-reason', {
      data: {
        MPUData: {
          state: {
            startTime: selectedState.startTime,
            endTime: selectedState.endTime,
            status: selectedState.state['presentation.status'],
          },
          reason: statusReasonValues,
          machine: selectedState.machineId,
        },
        reasonData: {
          machineId: selectedState.machineId,
          note: noteId,
          reason: statusReasonValues.identifier,
          timestamp: selectedState.state.time,
          endTime: selectedState.endTime,
        },
      },
      method: 'post',
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsSubmitting(false);
          handleCloseModal();
          createAlert(phrases.forms.reason.success.reasonAdded, 'success');
          api(`/api/machines/refresh-pulses?machine=${selectedState.machineId}`, {
            method: 'post'
          }).then((response) => {            
              refreshPulse();      
              setPopupOpen(false);
            }
          ).catch((err) => {
            console.log(err);
            refreshPulse();            
            setPopupOpen(false);
          })
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  };

  function deleteStatusReasonNote() {
    const { noteId } = statusReasonValues;
    if (noteId) {
      api(`/api/shared/notes/${noteId}`, {
        method: 'delete',
      })
        .then((response) => {
          if (response.status === 204) {
            deleteStatusReason(noteId);
          }
        })
        .catch((error) => {
          const errorMessage = phrases.forms.structure.errors.genericError;
          if (error.response) {
            if (error.response.status === 404) {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            } else if (error.response.status === 403) {
              setIsSubmitting(false);
              createAlert(phrases.errorMessages.notAuthorized, 'error');
            } else {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            }
          }
        });
    }
  }

  const deleteStatusReason = () => {
    api('/api/reasons/status-reason', {
      data: {
        MPUData: {
          state: {
            startTime: selectedState.startTime,
            endTime: selectedState.endTime,
            status: selectedState.state['presentation.status'],
          },
          reason: statusReasonValues,
          machine: selectedState.machineId,
        },
        reasonData: {
          machineId: selectedState.machineId,
          note: '',
          reason: '',
          timestamp: selectedState.state.time,
          endTime: selectedState.endTime,
        },
      },
      method: 'post',
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsSubmitting(false);
          handleCloseModal();
          createAlert(phrases.forms.reason.success.reasonDeleted, 'success');
          refreshPulse();
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  };

  // ===== Return =====
  return (
    <>
      <Modal
        modalTitle={isEdit ? phrases.forms.reason.misc.editReason : phrases.forms.reason.misc.assignReason}
        open={open}
        handleClose={onModalClose}
        onEnter={onModalOpen}
        content={(
          <Grid container spacing={1}>
            <form className={classes.root} noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid item xs={12}>
                <Typography variant="button" display="inline" color="textSecondary">
                  {phrases.forms.reason.misc.machineStatus}
                  :
                </Typography>

                <Typography
                  variant="button"
                  align="left"
                  display="inline"
                  style={{ color: stateColor(selectedState.state) }}
                  className={`${classes.bold} ${classes.uppercase} ${classes.statusTitle}`}
                >
                  {stateText(selectedState.state)}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.pulseWrapper} ref={svgWrapperRef}>
                <svg ref={svgRef} width="100%" height={60}>
                  <g className="x-axis" />
                </svg>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="button"
                  display="block"
                  color="textSecondary"
                  className={classes.reasonTitle}
                >
                  {phrases.forms.reason.misc.reason}
                  <Divider />

                </Typography>
              </Grid>
              <ReasonsCollapse
                machineId={selectedState.machineType.id}
                modalOpen={open}
                statusReasonValues={statusReasonValues}
                handleDeleteReason={handleDeleteReason}
                handleChangeNote={handleChangeNote}
                handleReasonClick={handleReasonClick}
                selectedState={selectedState}
              />
              <Grid item xs={12}>
                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.formBtn}
                    disabled={isSubmitting}
                  >
                    {phrases.forms.shared.buttons.submit}
                    {isSubmitting
                      && (
                        <CircularProgress
                          className={classes.loadingBtnIcon}
                          color="inherit"
                          size={15}
                        />
                      )}
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.formBtn}
                    onClick={handleCloseModalClick}
                  >
                    {phrases.forms.shared.buttons.close}
                  </Button>
                  <Button
                    variant="contained"
                    color='secondary'
                    className={classes.formBtn}
                    onClick={handleResetReason}
                  >
                    {phrases.forms.shared.buttons.reset}
                  </Button>
                </div>
              </Grid>
            </form>
          </Grid>
        )}
      />
    </>
  );
};

export default React.memo(
  ReasonAssignModal,
  (prevProps, nextProps) => prevProps.open === nextProps.open,
);
