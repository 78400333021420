import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import {
  createMuiTheme, MuiThemeProvider, useTheme,
} from '@material-ui/core/styles';

// eslint-disable-next-line import/no-unresolved
import useSecurity from '@frontend/utils/useSecurity';
import LoadingIndicator from './LoadingIndicator';
import { usePhrases } from '@frontend/utils/usePhrases';

const Table = (props) => {
  const { resource, onDelete, customOptions, isLoading } = props;
  const { isAuthorized } = useSecurity();
  const theme = useTheme();
  const phrases = usePhrases().phrases();
  const options = {
    
    filterType: 'checkbox',
    print: false,
    download: false,
    elevation: 0,
    selectableRows: isAuthorized(resource, ['delete:any', 'delete:own']) ? 'multiple' : 'none',
    onRowsDelete: (obj) => onDelete(obj),
    ...customOptions,
    textLabels: {
      ...phrases.tables.textLabels,
      body: {
        ...phrases.tables.textLabels.body,
        noMatch: isLoading ? <LoadingIndicator size={48} thickness={2} /> : `${phrases.tables.textLabels.body.noData}`,
      },
    },
  };

  const getMuiTheme = () => createMuiTheme({
    ...theme,
    overrides: {
      MUIDataTableFilter: {
        checkboxFormControlLabel: {
          marginLeft: 0,
          marginRight: theme.spacing(1),
        },
      },
    },
  });

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          options={{ ...options }}
        />
      </MuiThemeProvider>
    </>
  );
};

Table.propTypes = {
  resource: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  customOptions: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool.isRequired,
};

Table.defaultProps = {
  customOptions: {},
};

export default Table;
