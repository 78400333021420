/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';

import useApi from '@frontend/utils/useApi';
import useValidateStructureForm from '@frontend/modules/structure/hooks/useValidateStructureForm';
import useAlert from '@frontend/hooks/useAlert';
import { usePhrases } from '@frontend/utils/usePhrases';

const emptyStructure = {
  name: '',
  businessId: '',
  description: '',
  city: '',
  country: '',
  timezone: '',
  isActive: true,
  structure: null,
};
/**
 * Hook for the structure form.
 *
 * @param {Object} props
 */
const useStructureForm = (props) => {
  const {
    structure,
    isEdit,
    onCreate,
    onEdit,
    handleCloseModal,
  } = props;

  const [structureValues, setStructureValues] = useState(structure || emptyStructure);
  const [dropdownStructures, setDropdownStructures] = useState([]);
  const [structures, setStructures] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const api = useApi();
  const { validateStructureFrom } = useValidateStructureForm();
  const { createAlert } = useAlert();
  const phrases = usePhrases().phrases();
  // let cancelAxios = null;

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/structures?populate=structure', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setStructures(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getStructureChildStructures(structureId, _structures) {
    return _structures
      .filter((_structure) => (_structure.structure != null
        && String(_structure.structure.id)) === structureId);
  }

  function getStructuresRecursive(_structure, _structures) {
    const childStructures = getStructureChildStructures(_structure.id, _structures);
    if (childStructures && childStructures.length) {
      return [
        _structure,
        ..._.flatten(childStructures.map((s) => getStructuresRecursive(s, _structures))),
      ];
    }
    return [_structure];
  }

  const filterChildStructures = (parentStructure, _structures) => {
    const childStructures = getStructuresRecursive(parentStructure, _structures)
      .map((_structure) => _structure.id);
    const filteredStructures = _structures
      .filter((_structure) => !childStructures.includes(_structure.id));

    return filteredStructures;
  };

  const filterStructures = (_structures) => {
    const parentIndex = _structures.map((_structure) => _structure.id).indexOf(structureValues.id);
    const parentStructure = _structures[parentIndex];
    if (parentStructure) {
      return filterChildStructures(parentStructure, _structures
        .filter((_structure) => _structure.isActive && _structure.id !== parentStructure.id));
    }

    return _structures.filter((_structure) => _structure.isActive);
  };

  /**
   * Each time the structures(props) change, this effect will be trigerred.
   */
  useEffect(() => {
    if (structureValues.structure && structures.length) {
      const parentStructureId = structureValues.structure.id;
      setStructureValues({
        ...structureValues,
        structure: structures.find((_structure) => parentStructureId === _structure.id),
      });
    }
    if (structures && structures.length) {
      setDropdownStructures(filterStructures(structures));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structures]);

  /**
   * Handles a text field change.
   *
   * @param {Object} event
   */
  const handleChange = (event) => {
    event.persist();

    if (errors[event.target.name]) { delete errors[event.target.name]; }

    setStructureValues({
      ...structureValues,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Inherits fields from parent structure.
   *
   * @param {Object} _structure
   */
  const inheritStructure = (_structure) => {
    if (!_structure) return;
    structureValues.city = _structure.city;
    structureValues.country = _structure.country;
    structureValues.timezone = _structure.timezone;
  };

  /**
   * Handles a structure change.
   *
   * @param {Object} event
   * @param {Object} value Structure
   */
  const handleStructureChange = (event, value) => {
    event.persist();

    if (errors.structure) { delete errors.structure; }

    inheritStructure(value);

    setStructureValues({
      ...structureValues,
      structure: value,
    });
  };

  /**
   * Handles Active/Inactive toggle switch change.
   *
   * @param {Object} event
   */
  const handleSwitch = (event) => {
    event.persist();
    const field = event.target.name;
    setStructureValues({
      ...structureValues,
      [field]: !structureValues[field],
    });
  };

  /**
   * Clears form values and all errors.
   */
  const handleClearForm = () => {
    setIsSubmitting(false);
    setStructureValues(emptyStructure);
    setErrors({});
  };

  /**
   * Form submit handler.
   *
   * @param {Object} event
   */
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateStructureFrom(structureValues));
  };

  /**
   * Form submitting.
   */
  const submitForm = () => {
    api('/api/structures', {
      data: structureValues,
      method: isEdit ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 201) {
          onCreate();
          createAlert(phrases.forms.structure.success.structureAdded, 'success');
          setIsSubmitting(false);
          handleClearForm();
        } else if (response.status === 200) {
          if (isEdit) {
            onEdit(response.data);
            createAlert(phrases.forms.structure.success.structureUpdated, 'success');
            handleCloseModal();
          }
        }
      }).catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  };

  /**
   * On errors change, this effect gets trigerred. This is only going to be trigerred
   * when submitting a form.
   */
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitForm();
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return {
    handleChange,
    handleStructureChange,
    handleSubmit,
    handleSwitch,
    handleClearForm,
    structureValues,
    dropdownStructures,
    isSubmitting,
    errors,
  };
};

export default useStructureForm;
