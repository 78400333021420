import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
    TextField,
  } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },

  textFieldMid: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  textFieldStart: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

export default function HandCountCalculator({ openModal, handleClose, setHandCount }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [startHandCount, setStartHandCount] = useState(0);
  const [endHandCount, setEndHandCount] = useState(0);
  const [diff, setDiff] = useState(0);
  const phrases = usePhrases().phrases();
  useEffect(() => {
    setOpen(openModal);
  }, [openModal])


  const handleChangeStartHandCount = (e) => {
    if (Number.isNaN(Number(e.target.value))) {
      setStartHandCount('');
      return;
    }
    setStartHandCount(parseInt(e.target.value));
    setEndHandCount(parseInt(e.target.value));
    setDiff(0);
  };

  const handleChangeEndHandCount = (e) => {
    if (Number.isNaN(Number(e.target.value))) {
      setEndHandCount('');
      return;
    }    
    setEndHandCount(e.target.value);
    setDiff(parseInt(e.target.value) - startHandCount);
  };

  const handleSubmit = (e) => {
    let _diff = endHandCount - startHandCount;
    if (_diff < 0) {
        _diff = 0;
    }
    setHandCount(_diff);
    handleClose();
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{phrases.modules.orders.handCountCalculator}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
                <Grid item xs={4}>
                <Typography align="center">
                    {phrases.modules.orders.startHandCount}
                </Typography>
                </Grid>
                <Grid item xs={4}/>

                <Grid item xs={4}>
                    <Typography align="center">
                        {phrases.modules.orders.endHandCount}
                    </Typography>
                </Grid>
                <Grid item xs={12} alignContent="center">
                    <Grid container>
                    <Grid item xs={4}>
                        <TextField
                        className={classes.textFieldStart}
                        margin="dense"
                        fullWidth
                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        value={startHandCount}
                        type='number'
                        onChange={handleChangeStartHandCount}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        className={classes.textFieldMid}
                        disabled={true}
                        margin="dense"
                        fullWidth
                        InputLabelProps={{ shrink: true, style: { textAlign: 'center', paddingLeft: '20px'}}}
                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        id="outlined-basic"
                        label={phrases.modules.orders.handCount}
                        variant="outlined"
                        value={diff}
                        type='number'
                        onChange={null}
                        />  
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        className={classes.textFieldStart}
                        margin="dense"
                        fullWidth
                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        value={endHandCount}
                        type='number'
                        onChange={handleChangeEndHandCount}
                        />
                    </Grid>
                    </Grid>   
                </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="default">
            { phrases.confirmationModal.cancel}
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" autoFocus>
            {phrases.modules.orders.apply}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}