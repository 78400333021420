const emptyStructure = {
  name: '',
  businessId: '',
  description: '',
  city: '',
  country: '',
  timezone: '',
  isActive: false,
  structure: null,
  machines: [],
};

export const structuresReducer = (state = emptyStructure, action) => {
  switch (action.type) {
    case 'STORE_STRUCTURE':
      return action.structure;
    default:
      return state;
  }
};

export const dropdownStructuresReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_DROPDOWN_STRUCTURES':
      return action.structures;
    case 'REMOVE_DROPDOWN_STRUCTURES':
      return [];
    default:
      return state;
  }
};

export const selectionStructuresReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_SELECTION_STRUCTURES':
      return action.structures;
    default:
      return state;
  }
};
