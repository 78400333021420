import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useApi from '@frontend/utils/useApi';
import _ from 'lodash'
import {
    Box,
    Grid,
} from '@material-ui/core';
import NewReleasesIcon  from '@material-ui/icons/NewReleases';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { usePhrases }     from '@frontend/utils/usePhrases';
import useStaticReasonAssignForm from '@frontend/modules/reasons/hooks/useStaticReasonAssignForm';
import ConfirmationModal from '@frontend/components/ConfirmationModal';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    alert: {
      float: "center",
      textAlign: "center",
      alignItems: "center"
    },
}))

const StaticReasonAlertBox = (props) => {
    const {
        staticReason,
        setStaticReason,
        machine
    } = props

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const api = useApi()
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const {
        deleteStaticStatusReason
    } = useStaticReasonAssignForm(machine)

    /**
     * On Box mount, we load the last static-reason from Influx to determine, whether we need to
     * display the alert box or not
     */
    useEffect(() => {
        const cancelAxios = axios.CancelToken.source();
        if(machine.id){
            api(`/api/reasons/${machine.id}/static-reason`, {
                method: 'get',
                cancelToken: cancelAxios.token,
            }).then((response) => {
                if(!_.isEmpty(response.data) && response.data[0]?.staticReason !== ""){
                    setStaticReason(true);
                } else{
                    setStaticReason(false);
                }
                
            }).catch((error) => {
                if (!axios.isCancel(error)) console.log(error);
            });
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machine])

    const prepareModalText = () => {
        return `${phrases.confirmationModal.textDeleteStaticReason} "${machine.name}?"`
    }
    return (
    <>
    {staticReason ? 
        <Grid item xs={12} className={classes.alert} alignItems="center" justifyContent="center">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Alert style={{width:'50%'}}
                    className={classes.alert}
                    variant="filled"
                    severity="warning"
                    icon={
                    <NewReleasesIcon/>
                    }
                    onClose={() => {
                        setConfirmModalOpen(true);
                    }}>
                    {phrases.forms.reason.misc.staticReasonActive}
                </Alert>
            </Box>
    </Grid> 
    : ""}
    <ConfirmationModal
        modalTitle={phrases.confirmationModal.deleteStaticReason}
        text={prepareModalText()}
        openModal={confirmModalOpen}
        handleClose={()=> {
            setConfirmModalOpen(false)
        }}
        callback={() => {
            deleteStaticStatusReason();
            setStaticReason(false)
        }}
    />
    </>);
}
 
export default StaticReasonAlertBox;