import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  CardActions,
  IconButton,
  Collapse,
  CircularProgress,
  Button,
  TextField,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { usePhrases } from '@frontend/utils/usePhrases';
import useLogin from '@frontend/modules/user/hooks/useLogin';
import { setTitle } from '@frontend/utils/usePageTitle';

const useStyles = makeStyles((theme) => ({

  signInHeader: {
    paddingBottom: theme.spacing(0),
  },
  cardContent: {
    paddingTop: theme.spacing(0),
  },
  loginAlert: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(2),
  },
  buttonLogin: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  vertical: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

}));

const UserLoginForm = () => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    loginErrorText,
    openAlert,
    setOpenAlert,
    isSubmitting,
  } = useLogin();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  setTitle(phrases.pageTitles.signIn);

  useEffect(() => {
    if (values.email.trim() && values.password.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [values.email, values.password]);

  return (

    <form autoComplete="on" onSubmit={handleSubmit}>

      <Collapse in={openAlert} className={classes.loginAlert}>
        <Alert
          variant="filled"
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        >
          {loginErrorText}
        </Alert>
      </Collapse>

      <CardContent className={classes.cardContent}>
        <div className={classes.vertical}>

          <TextField
            error={!!errors.email} // !! converts value to boolean
            helperText={errors.email}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="email"
            label={phrases.forms.user.fields.email}
            name="email"
            type="email"
            autoFocus
            value={values.email}
            onChange={handleChange}
          />

          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password"
            label={phrases.forms.user.fields.password}
            type="password"
            id="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleChange}
          />
        </div>
      </CardContent>

      <CardActions>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonLogin}
          disabled={isButtonDisabled ? true : isSubmitting}
        >
          {phrases.modules.user.signIn}
          {isSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={17} />}
        </Button>

      </CardActions>

    </form>
  );
};

export default UserLoginForm;
