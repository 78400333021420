/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
  Chip,
  Collapse,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import useAlert from '@frontend/hooks/useAlert';
import StyledSwitch from '@frontend/components/StyledSwitch';
import StyledSelect from '@frontend/components/StyledSelect';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import useSecurity from '@frontend/utils/useSecurity';
import StructuresDropdown from '../structure/StructuresDropdown';
import useUserForm from './hooks/useUserForm';
import DefaultViewsForm from './DefaultViewsForm';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  fieldset: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    color: '#757575',
    borderColor: '#F0F0F0',
    borderRadius: '5px',
  },
  helperText: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  legend: {
    marginTop: theme.spacing(4),
    color: '#757575',
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    color: '#757575',
    cursor: 'pointer',
  },
  terminalForm: {
    marginLeft: theme.spacing(3),
  },
}));

export default function UserForm(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { createAlert } = useAlert();
  const {
    setUserValues,
    handleChange,
    handleCheck,
    handleSwitch,
    handleClearForm,
    handleSubmit,
    handleStructureChange,
    userValues,
    structures,
    roles,
    languages,
    units,
    errors,
  } = useUserForm(submitForm, props);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const isEdit = !!props.isEdit;
  const { isSystemAdmin } = useSecurity();
  const api = useApi();
  function submitForm() {
    setFormSubmitting(true);
    const postData = { ...userValues };
    if (isEdit && !postData.password) {
      delete postData.password;
      delete postData.password2;
    }
    if (isEdit && !postData.pin) {
      delete postData.pin;
      delete postData.pin2;
    }
    api('/api/users', {
      data: {
        ...postData,
        /**
         * If user is SystemAdmin structures are persisted as an empty array
         * since SystemAdmin has access to all structures, and there is no need to assign structure to SystemAdmin.
         */
        structures: isSystemAdmin(userValues) ? [] : userValues.structures,
      },
      method: isEdit ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 201) {
          createAlert(phrases.forms.user.success.userAdded, 'success');
          setFormSubmitting(false);
          handleClearForm();
        } else if (response.status === 200) {
          if (isEdit) {
            if (props.onEdit) props.onEdit(response.data);
            setFormSubmitting(false);
            createAlert(phrases.forms.user.success.userUpdated, 'success');
            props.handleCloseModal();
          }
        }
      }).catch((error) => {
        const errorMessage = phrases.forms.user.errors.genericError;
        if (error.response) {
          if (error.response.status === 409) {
            setFormSubmitting(false);
            createAlert(phrases.forms.user.errors.userAlreadyExists, 'error');
          } else if (error.response.status === 404) {
            setFormSubmitting(false);
            createAlert(errorMessage, 'error');
          } else if (error.response.status === 403) {
            setFormSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            setFormSubmitting(false);
            createAlert(errorMessage, 'error');
          }
        }
      });
  }

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        error={errors.name}
        helperText={errors.name}
        id="name-field"
        name="name"
        label={phrases.forms.user.fields.fullName}
        variant="outlined"
        fullWidth
        onChange={handleChange}
        value={userValues.name}
        margin="dense"
        required
      />
      <TextField
        error={errors.email}
        helperText={errors.email}
        id="email-field"
        name="email"
        label={phrases.forms.user.fields.email}
        variant="outlined"
        type="email"
        fullWidth
        onChange={handleChange}
        value={userValues.email}
        margin="dense"
        required
      />
      <TextField
        error={errors.businessUserId}
        helperText={errors.businessUserId}
        id="email-field"
        name="businessUserId"
        label={phrases.forms.user.fields.businessUserId}
        variant="outlined"
        type="text"
        fullWidth
        onChange={handleChange}
        value={userValues.businessUserId}
        margin="dense"
      />
      <TextField
        error={errors.password}
        helperText={errors.password}
        id="password-field"
        name="password"
        autoComplete="new-password"
        label={phrases.forms.user.fields.password}
        variant="outlined"
        type="password"
        fullWidth
        onChange={handleChange}
        value={userValues.password}
        margin="dense"
        required
      />
      <TextField
        error={errors.password2}
        helperText={errors.password2}
        id="confirm-password-field"
        name="password2"
        label={phrases.forms.user.fields.confirmPassword}
        variant="outlined"
        type="password"
        fullWidth
        onChange={handleChange}
        value={userValues.password2}
        margin="dense"
        required
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={userValues.isTerminalUser}
            onChange={handleCheck}
            name="isTerminalUser"
            color="primary"
          />
        )}
        classes={{
          label: classes.label,
        }}
        label="Terminal User"
      />
      <Collapse className={classes.terminalForm} in={userValues.isTerminalUser}>
        <TextField
          error={errors.username}
          helperText={errors.username}
          id="username-field"
          name="username"
          label={phrases.forms.user.fields.username}
          variant="outlined"
          type="text"
          fullWidth
          onChange={handleChange}
          value={userValues.username}
          margin="dense"
          required
        />
        <TextField
          error={errors.pin}
          helperText={errors.pin}
          id="pin-field"
          name="pin"
          label={phrases.forms.user.fields.pin}
          variant="outlined"
          type="password"
          fullWidth
          onChange={handleChange}
          value={userValues.pin}
          margin="dense"
          required
        />
        <TextField
          error={errors.pin2}
          helperText={errors.pin2}
          id="pin-confirm-field"
          name="pin2"
          label={phrases.forms.user.fields.confirmPin}
          variant="outlined"
          type="password"
          fullWidth
          onChange={handleChange}
          value={userValues.pin2}
          margin="dense"
          required
        />
      </Collapse>
      <StyledSwitch
        name="isActive"
        checked={userValues.isActive}
        onChange={handleSwitch}
        value={userValues.isActive}
        onLabel={phrases.forms.shared.fields.active}
        offLabel={phrases.forms.shared.fields.inactive}
      />
      <StyledSelect
        error={errors.lang}
        helperText={errors.lang}
        label={phrases.forms.user.fields.language}
        labelId="lang-label"
        id="lang-select"
        name="lang"
        value={userValues.lang}
        onChange={handleChange}
        data={languages}
        required
        plain
      />
      <StyledSelect
        error={errors.unit}
        helperText={errors.unit}
        label={phrases.forms.user.fields.unit}
        labelId="unit-label"
        id="unit-select"
        name="unit"
        value={phrases.modules.user.units[userValues.unit]}
        onChange={handleChange}
        data={units}
        required
        plain
      />
      <StyledSelect
        error={errors.role}
        helperText={errors.role}
        label={phrases.forms.user.fields.role}
        labelId="role-label"
        id="role-select"
        name="role"
        value={userValues.role}
        onChange={handleChange}
        data={roles}
        required
        plain
      />
      <StructuresDropdown
        multiple
        filterSelectedOptions
        label={phrases.forms.shared.fields.structure}
        options={structures}
        onChange={handleStructureChange}
        value={userValues.structures}
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip color="primary" key={option.id} className={classes.chip} label={option.path} {...getTagProps({ index })} />
        ))}
        disabled={isSystemAdmin(userValues)}
        error={errors.structures}
        helperText={errors.structures}
        required
      />
      {/* View selection */}
      <DefaultViewsForm
        setUserValues={setUserValues}
        userValues={userValues}
        errors={errors}
      />

      <div className={classes.formButtons}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.formBtn}
          disabled={formSubmitting}
        >
          {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
          {formSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
        </Button>
        {!isEdit && (
          <Button
            variant="outlined"
            className={classes.formBtn}
            onClick={handleClearForm}
          >
            {phrases.forms.shared.buttons.clear}
          </Button>
        )}
        {isEdit
          && (
            <Button
              variant="outlined"
              className={classes.formBtn}
              onClick={props.handleCloseModal}
            >
              {phrases.forms.shared.buttons.close}
            </Button>
          )}
      </div>
    </form>
  );
}
