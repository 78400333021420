import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@mdi/react';

import {
  MATERIAL_DESIGN_ICON,
  MATERIAL_UI_ICON,
  FONTAWESOME_ICON,
} from '@frontend/constants';


const useIcon = () => {
  const renderMaterialDesignIcon = (icon, iconProps) => (
    <Icon
      path={icon}
      size={1}
      {...iconProps}
    />
  );

  const renderMaterialUiIcon = (icon, iconProps) => {
    return icon;
  };

  const renderFontawesomeIcon = (icon, iconProps) => (
    <FontAwesomeIcon
      // className={clsx({ [classes.activeListItem]: openListDropdown[id] })}
      icon={icon}
      size="lg"
      {...iconProps}
    />
  );

  const renderIcon = (iconType, icon, iconProps = {}) => {
    switch (iconType) {
      case MATERIAL_DESIGN_ICON: return renderMaterialDesignIcon(icon, iconProps);
      case MATERIAL_UI_ICON: return renderMaterialUiIcon(icon, iconProps);
      case FONTAWESOME_ICON: return renderFontawesomeIcon(icon, iconProps);
      default: return renderFontawesomeIcon(icon);
    }
  };

  return renderIcon;
};

export default useIcon;
