/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import { faCog, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Table from '@frontend/components/Table';
import MachineTypeForm from '@frontend/modules/machine/MachineTypeForm';
import CustomTabs from '@frontend/components/Tabs';
import Modal from '@frontend/components/Modal';
import useAlert from '@frontend/hooks/useAlert';
import useApi from '@frontend/utils/useApi';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity from '@frontend/utils/useSecurity';
import { persistTableState } from '@frontend/utils/UIActions';
import { usePhrases } from '@frontend/utils/usePhrases';
import { storeListOfMachineTypes } from '@frontend/modules/machine/actions';
import { setTitle } from '@frontend/utils/usePageTitle';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function MachineTypesView() {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const dispatch = useDispatch();

  const api = useApi();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const { createAlert } = useAlert();

  const [machineTypeToEdit, setMachineTypeToEdit] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const machineTypes = useSelector((state) => state.machineTypes);
  const tableState = useSelector((state) => state.tableState);

  // let cancelAxios = null;
  setTitle(phrases.pageTitles.machineTypesManagement);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    dispatch(persistTableState({}));
    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editMachineType = (machineType) => {
    setMachineTypeToEdit(machineType);
    handleOpenModal();
  };

  const columns = [
    {
      label: phrases.forms.shared.fields.name,
      name: 'name',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'name' ? tableState.sortOrder.direction : 'none',
      },
    },
    ...conditionalElement(isAuthorized('machine-types', ['update:any']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => editMachineType(machineTypes.find((machineType) => machineType.id === value))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    }),
  ];

  const getDirection = (direction) => {
    switch (direction) {
      case 'ascending': return 'asc';
      case 'descending': return 'desc';
      default: return 'asc';
    }
  };

  const customOptions = {
    filter: false,
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({ ...tableState, filterList }));
    },
    onChangePage: (page) => {
      dispatch(persistTableState({ ...tableState, page }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
  };

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines/machine-types', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfMachineTypes(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleDelete = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const machineType = machineTypes[index];
      api(`/api/machines/machine-types/${machineType.id}`, {
        method: 'delete',
        cancelToken: cancelAxios.token,
      }).then((response) => {
        if (response.status === 204) {
          machineTypes.splice(index, 1);
          dispatch(storeListOfMachineTypes([...machineTypes]));
          createAlert(phrases.forms.machine.success.machineTypeDeleted, 'success');
        }
      }).catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    });
    return false;
  };

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines/machine-types', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfMachineTypes(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  const handleCreate = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines/machine-types', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfMachineTypes(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          ...conditionalElement(isAuthorized('machine-types', ['read:any']), { label: phrases.tabs.ExistingMachineTypes, icon: faCog }),
          ...conditionalElement(isAuthorized('machine-types', ['create:any']), { label: phrases.tabs.CreateMachineType, icon: faPlusCircle }),
        ]}
        panels={[
          ...conditionalElement(isAuthorized('machine-types', ['read:any']), {
            content: <Table
              modalTitle={phrases.tables.shared.machines}
              data={machineTypes}
              resource="machine-types"
              onDelete={handleDelete}
              columns={columns}
              customOptions={customOptions}
            />,
          }),
          ...conditionalElement(isAuthorized('machine-types', ['create:any']), { content: <MachineTypeForm onCreate={handleCreate} /> }),
        ]}
      />
      <Modal
        modalTitle={phrases.modal.EditMachineType}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <MachineTypeForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            machineType={machineTypeToEdit}
          />
        )}
      />
    </div>
  );
}
