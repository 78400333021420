import React, {useLayoutEffect, useRef} from 'react';
import * as am5             from '@amcharts/amcharts5';
import * as am5xy           from "@amcharts/amcharts5/xy";
import * as am5radar           from "@amcharts/amcharts5/radar";
import am5themes_Animated   from "@amcharts/amcharts5/themes/Animated";
import {
    useTheme
} from '@material-ui/core';
import { usePhrases }       from '@frontend/utils/usePhrases';

const GaugeSpeedIndicator = (props) => {
    const {
        machineId,
        targetCycleTime,
        currentSpeed,
    } = props;
    const handRef = useRef(null);
    const theme = useTheme();
    const phrases = usePhrases().phrases();

    useLayoutEffect(() => {
        var root = am5.Root.new("speedchartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root.interfaceColors.set("grid", am5.color(theme.palette.text.primary));
        root.interfaceColors.set("text", am5.color(theme.palette.text.primary));      
        
        
        var chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
              panX: false,
              panY: false,
              startAngle: -180,
              endAngle: 0,
              innerRadius: -35,
              layout: root.verticalLayout
            })
        );
        var axisRenderer = am5radar.AxisRendererCircular.new(root, {
            strokeOpacity: 0.1,
            minGridDistance: 50,
        });
          
        axisRenderer.ticks.template.setAll({
            visible: true,

            strokeOpacity: 0.5
        });
          
        axisRenderer.grid.template.setAll({
            visible: false
        });
          
        var axis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                max: targetCycleTime*1.20,
                strictMinMax: true,
                renderer: axisRenderer
            })
        );
          
        function createRange(start, end, color, label) {
            
            var rangeDataItem = axis.makeDataItem({
              value: start,
              endValue: end
            });
          
            axis.createAxisRange(rangeDataItem);
            
            rangeDataItem.get("axisFill").setAll({
              visible: true,
              fill: color,
              fillOpacity: 0.8
            });
            
            rangeDataItem.get("tick").setAll({
              visible: false
            });
            
            rangeDataItem.get("label").setAll({
              text: label,
              inside: true,
              radius: 150,
              textType: "radial",
              fontSize: "1.5em",
              fill: am5.color(0xffffff)
            });
          
        }
          
        createRange(0, targetCycleTime*0.2, am5.color(theme.palette.error.light), "");
        createRange(targetCycleTime*0.2, targetCycleTime, am5.color(theme.palette.warning.light), "");
        createRange(targetCycleTime, targetCycleTime*1.20, am5.color(theme.palette.success.light), phrases.modules.simple_machine.targetCycleTime);
        
        // Add clock hand
        var handDataItem = axis.makeDataItem({
            value: currentSpeed
        });
        
        var mainhand = handDataItem.set("bullet", am5xy.AxisBullet.new(root, {
            sprite: am5radar.ClockHand.new(root, {
                radius: am5.percent(90)
            })
        }));      
        handRef.current = handDataItem;  
        mainhand.get("sprite").hand.setAll({
            fill: am5.color(theme.palette.text.primary),
            fillOpacity: 0.9
          });
        
        var targetSpeedDataItem = axis.makeDataItem({
            value: targetCycleTime
        });
        
        var hand = targetSpeedDataItem.set("bullet", am5xy.AxisBullet.new(root, {
            sprite: am5radar.ClockHand.new(root, {
                topWidth: 15,
                innerRadius: am5.percent(102),
                radius: am5.percent(120),
                bottomWidth: 1,
            })
        }));
        hand.get("sprite").hand.setAll({
            fill: am5.color(theme.palette.error.dark),
            fillOpacity: 0.9
          });
        axis.createAxisRange(targetSpeedDataItem);
        axis.createAxisRange(handDataItem);
        
        handDataItem.get("grid").set("visible", false);
        handDataItem.get("tick").set("visible", false);

        var legend = chart.children.push(am5.Legend.new(root, {
            nameField: "name",
            fillField: "color",
            strokeField: "color",
            centerX: am5.percent(50),
            x: am5.percent(50),
            y: am5.percent(100),
            centerY: am5.percent(100)
        }));

        legend.data.setAll([{
            name: phrases.modules.simple_machine.targetCylceTime,
            color: am5.color(theme.palette.error.dark)
        }, {
            name: phrases.modules.simple_machine.currentSpeed,
            color: am5.color(theme.palette.text.primary)
        }]);
        return () => {
            root.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps          
    }, [machineId, targetCycleTime, currentSpeed]);

    useLayoutEffect(() => {
        if(handRef.current){
            handRef.current.animate({
                key: "value",
                to: currentSpeed,
                duration: 800,
                easing: am5.ease.out(am5.ease.cubic)
            });
        }
    }, [currentSpeed]);
    return ( 
        <>
            <div id="speedchartdiv" style={{"height": "400px"}}></div>
        </>
     );
}
 
export default GaugeSpeedIndicator;