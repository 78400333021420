import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setStructureViewInUse } from '@frontend/utils/UIActions';
import { ROUTES } from '@frontend/constants';

import useStructure from '@frontend/modules/structure/hooks/useStructure';

/**
 * Hook for the structure overview view.
 *
 * @param {String} structureId - MongoDB Structure ID
 */
const useStructureOverview = (structureId) => {
  const dispatch = useDispatch();
  const structureInUse = useSelector((state) => state.structureInUse);
  const user = useSelector((state) => state.user);

  /**
   * Loads structure according to the following priorities:
   * 1. Passed in structureId, which is usually the one from the URL
   * 2. Persisted structure aka structureInUse
   * 3. Default user structure
   */
  const {
    structure,
    structureCalculations,
    loadStructureCalculations,
    runningCalculations,
    isStructureLoading,
    isLoadingMachines,
    sensors,
    setMachineStates,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
    setDataLayer,
    dataLayer,
    fetchNextBatch,
    pageCount,
    refreshPulse,
  } = useStructure(structureId || structureInUse || user.views.structure.id);

  /**
   * Perists the current view, the StructureOverview as a view in use.
   */
  useEffect(() => {
    dispatch(setStructureViewInUse(ROUTES.STRUCTURE_OVERVIEW));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    structure,
    structureCalculations,
    loadStructureCalculations,
    runningCalculations,
    isStructureLoading,
    structureInUse,
    isLoadingMachines,
    sensors,
    setMachineStates,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
    setDataLayer,
    dataLayer,
    fetchNextBatch,
    pageCount,
    refreshPulse,
  };
};

export default useStructureOverview;
