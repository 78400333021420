import { useState, useEffect } from 'react';

import useValidateSensorTypeForm from '@frontend/modules/sensor/hooks/useValidateSensorTypeForm';

const useSensorTypeForm = (callback, sensorType) => {
  const emptySensorType = {
    name: '',
    measurementUnit: '',
  };
  // ===== State Variables =====
  const [sensorTypeValues, setSensorTypeValues] = useState(sensorType || emptySensorType);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ===== Imports =====
  const { validateSensorTypeForm } = useValidateSensorTypeForm();

  // ===== Effects =====
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // ===== Helper Methods =====
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateSensorTypeForm(sensorTypeValues));
  };

  const handleChange = (event) => {
    event.persist();

    if (errors[event.target.name]) { delete errors[event.target.name]; }

    // eslint-disable-next-line max-len
    setSensorTypeValues((sensorValues) => ({ ...sensorValues, [event.target.name]: event.target.value }));
  };

  const handleClearForm = () => {
    Object.keys(errors).forEach((key) => {
      delete errors[key];
    });
    setSensorTypeValues(emptySensorType);
  };

  return {
    handleChange,
    handleSubmit,
    handleClearForm,
    sensorTypeValues,
    errors,
  };
};

export default useSensorTypeForm;
