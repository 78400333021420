/* eslint-disable consistent-return */
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import useAlert           from '@frontend/hooks/useAlert';
import useReloadTimes     from '@frontend/hooks/useReloadTimes';
import useMachinesWithStates  from '@frontend/modules/machine/hooks/useMachinesWithStates';
import useMachineFilters      from '@frontend/modules/machine/hooks/useMachineFilters';
import { storeStructure } from '@frontend/modules/structure/actions';
import {
  openSelectionDrawer,
  setLastReloadTime,
  setStructureInUse,
} from '@frontend/utils/UIActions';
import useApi             from '@frontend/utils/useApi';
import { setTitle }       from '@frontend/utils/usePageTitle';
import { usePhrases }     from '@frontend/utils/usePhrases';
import socket             from '@frontend/utils/useSocket';

let CancelToken = axios.CancelToken;
let cancel1;
let cancel2;
let cancel3;

const useStructure = (structureId) => {
  const dispatch = useDispatch();
  
  const api = useApi();
  const { createAlert } = useAlert();
  const phrases = usePhrases().phrases();

  const { applyFilters, applySearch, applySort } = useMachineFilters();

  const [structureCalculations, setStructureCalculations] = useState({});
  const [isStructureLoading, setIsStructureLoading] = useState(false);
  const [runningCalculations, setRunningCalculations] = useState(false);
  const [machinesKPIs, setMachinesKPIs] = useState({});

  const filterList = useSelector(
    (state) => state.productionPulseFilters.filterList,
  );
  const searchQuery = useSelector(
    (state) => state.productionPulseFilters.searchQuery,
  );
  const sortType = useSelector(
    (state) => state.productionPulseFilters.sortType,
  );

  const structure = useSelector((state) => state.structure);
  const structureInUse = useSelector((state) => state.structureInUse);
  const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan);
  const timespanStart = useSelector((state) => state.timespanStart);
  const timespanEnd = useSelector((state) => state.timespanEnd);
  const location = useLocation();
  const lastReloadTime = useSelector((state) => state.lastReloadTime);
  const reloadInterval = useSelector((state) => state.reloadInterval);
  const reloadIntervalRef = useRef();
  const lastReloadTimeRef = useRef();

  reloadIntervalRef.current = reloadInterval;
  lastReloadTimeRef.current = lastReloadTime;

  const { canReloadNow } = useReloadTimes();
  const {
    setMachineStates,
    sensors,
    isLoadingMachines,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
    changeDataLayer,
    fetchNextBatch,
    pageCount,
    refreshPulse,
  } = useMachinesWithStates();

  useEffect(() => {
    if (socket._callbacks['$CALCULATIONS_UPDATE'] === undefined) {
      socket.on('CALCULATIONS_UPDATE', (data) => {
        if(canReloadNow(lastReloadTimeRef.current, reloadIntervalRef.current, 'CALCULATIONS_UPDATE')){
          dispatch(setLastReloadTime({...lastReloadTimeRef.current, 'CALCULATIONS_UPDATE': moment().format()}));
          console.log('CALCULATIONS_UPDATE');
          setStructureCalculations(data.structureKPIs);
          setMachinesKPIs(data.machinesKPIs);
        }
      });
    }
    if (socket._callbacks['$CALCULATIONS_FORCE_UPDATE'] === undefined) {
      socket.on('CALCULATIONS_FORCE_UPDATE', (data) => {
        if(canReloadNow(lastReloadTimeRef.current, reloadIntervalRef.current, 'CALCULATIONS_FORCE_UPDATE')){
          dispatch(setLastReloadTime({...lastReloadTimeRef.current, 'CALCULATIONS_FORCE_UPDATE': moment().format()}));
          console.log('CALCULATIONS_FORCE_UPDATE');
          setStructureCalculations(data.structureKPIs);
          setMachinesKPIs(data.machinesKPIs);
        }
      });
    }
    return () => {
      cancel1 && cancel1();
      cancel2 && cancel2();
      cancel3 && cancel3();
      cancel1 = undefined;
      cancel2 = undefined;
      cancel3 = undefined;
      socket._callbacks.$CALCULATIONS_UPDATE = undefined;
      socket._callbacks.$CALCULATIONS_FORCE_UPDATE = undefined;
      socket.emit('CALCULATIONS_REMOVE_LISTENER');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processMachines = (
    allMachines,
    _searchQuery,
    _filterList,
    _sortType,
  ) => {
    const sortedMachines = applySort(_sortType, allMachines);
    const searchedMachines = _searchQuery
      ? applySearch(_searchQuery, sortedMachines)
      : sortedMachines;
    const filteredMachines = _filterList
      ? applyFilters(_filterList, searchedMachines)
      : searchedMachines;

    return filteredMachines;
  };

  useEffect(() => {
    if (!_.isEmpty(structureCalculations) && !_.isEmpty(machinesKPIs)) {
      dispatch(
        storeStructure({
          ...structure,
          machines: structure.machines.map((machine) => ({
            ...machine,
            machineKPIs: machinesKPIs[machine.id],
          })),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinesKPIs]);

  const loadMachines = (_machines, from, to, _isRelativeTimespan) => {
    socket.emit('STATE_REMOVE_LISTENER');
    socket.emit('HEARTHBEAT_REMOVE_LISTENER');
    if (location.pathname.match(/structure-overview.*/g)) {
      setMachineStates(_machines, from, to, true, _isRelativeTimespan);
    } else {
      setMachineStates(_machines, from, to, false, _isRelativeTimespan);
    }
  };

  const getMachinesRecursive = (structures) =>
    _.flatten(
      structures.map((_structure) => {
        if (_structure.structures) {
          return [
            ..._structure.machines.map((machine) => ({
              ...machine,
              structure: _structure,
            })),
            ...getMachinesRecursive(_structure.structures),
          ];
        }
        return _structure.machines.map((machine) => ({
          ...machine,
          structure: _structure,
        }));
      }),
    );

  const filterActiveMachines = (machines) =>
    machines.filter((machine) => machine.isActive);

  const loadStructureCalculationsRelative = (
    _structure,
    _machines,
    _timespanStart = timespanStart,
    _timespanEnd = timespanEnd,
  ) => {
    cancel2 && cancel2();
    const duration = moment(_timespanEnd).diff(_timespanStart, 'minutes');
    api('/api/structures/calculations', {
      method: 'post',
      data: {
        structureId: _structure.id,
        machines: _machines.map((machine) => machine.id),
        duration,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel2 = c;
      }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setStructureCalculations(response.data.structureKPIs);
          setMachinesKPIs(response.data.machinesKPIs);
          const allMachines = _machines.map((machine, i) => ({
            ...machine,
            machineKPIs: response.data.machinesKPIs[machine.id],
          }));
          dispatch(storeStructure({ ..._structure, machines: allMachines }));
          socket.emit('CALCULATIONS_SET_LISTENER', {...response.data, timespanDuration: duration});
          setRunningCalculations(false);
          if (location.pathname.match(/structure-overview.*/g)) {
            loadMachines(
              processMachines(allMachines, searchQuery, filterList, sortType),
              _timespanStart,
              _timespanEnd,
              true,
            );
          } else {
            loadMachines(allMachines, _timespanStart, _timespanEnd, true);
          }
        }
      })
      .catch((error) => {
        setRunningCalculations(false);
      });
  };

  const loadStructureCalculationsAbsolute = (
    _structure,
    _machines,
    from = timespanStart,
    to = timespanEnd,
  ) => {
    cancel3 && cancel3();
    api('/api/structures/calculations', {
      method: 'post',
      data: {
        structureId: _structure.id,
        machines: _machines.map((machine) => machine.id),
        from,
        to,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel3 = c;
      }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          socket.emit('CALCULATIONS_REMOVE_LISTENER');
          setStructureCalculations(response.data.structureKPIs);
          setMachinesKPIs(response.data.machinesKPIs);
          const allMachines = _machines.map((machine, i) => ({
            ...machine,
            machineKPIs: response.data.machinesKPIs[machine.id],
          }));
          dispatch(storeStructure({ ..._structure, machines: allMachines }));
          setRunningCalculations(false);
          if (location.pathname.match(/structure-overview.*/g)) {
            loadMachines(
              processMachines(allMachines, searchQuery, filterList, sortType),
              from,
              to,
              false,
            );
          } else {
            loadMachines(allMachines, from, to, false);
          }
        }
      })
      .catch((error) => {
        setRunningCalculations(false);
      });
  };

  const loadStructureCalculations = (
    _timespanStart = timespanStart,
    _timespanEnd = timespanEnd,
    _structure = structure,
    _machines = structure.machines,
    _isRelativeTimespan = isRelativeTimespan,
  ) => {
    if (!runningCalculations) setRunningCalculations(true);
    if (_isRelativeTimespan) {
      loadStructureCalculationsRelative(
        _structure,
        _machines,
        _timespanStart,
        _timespanEnd,
      );
    } else {
      loadStructureCalculationsAbsolute(
        _structure,
        _machines,
        _timespanStart,
        _timespanEnd,
      );
    }
  };

  useEffect(() => {
    if (!structureId) {
      dispatch(openSelectionDrawer());
      createAlert(phrases.misc.selectStructureYouWantToView, 'info', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
          preventDuplicate: true,
          autoHideDuration: 100,
        },
      });
      setIsStructureLoading(false);
      return;
    }
    if (!isStructureLoading) setIsStructureLoading(true);
    cancel1 && cancel1();
    api(
      `/api/structures/${
      structureId ? structureId : structureInUse
      }?populate=machines structure`,
      {
        method: 'get',
        cancelToken: new CancelToken(function executor(c) {
          cancel1 = c;
        }),
      },
    )
      .then((response) => {
        if (response.status === 200) {
          const _machines = filterActiveMachines(
            getMachinesRecursive([response.data]),
          ).sort((a, b) => a.sortIndex - b.sortIndex);
          dispatch(storeStructure({ ...response.data, machines: _machines }));
          dispatch(setStructureInUse(response.data.id));
          setTitle(`${response.data.name} (${response.data.id})`);
          setIsStructureLoading(false);
          loadStructureCalculations(
            timespanStart,
            timespanEnd,
            response.data,
            _machines,
          );
        }
      })
      .catch((error) => {
        setIsStructureLoading(false);
        if (error.response) {
          if (error.response.status === 403) {
            createAlert(phrases.forms.structure.errors.notAuthorized, 'error');
          } else {
            createAlert(
              phrases.forms.structure.errors.structureDoesntExist,
              'warning',
            );
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureInUse, structureId]);

  return {
    structure,
    structureCalculations,
    loadStructureCalculations,
    runningCalculations,
    isStructureLoading,
    isLoadingMachines,
    sensors,
    setMachineStates,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
    changeDataLayer,
    fetchNextBatch,
    pageCount,
    refreshPulse,
  };
};

export default useStructure;
