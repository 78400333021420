import { useState, useEffect } from 'react';
import useApi from '@frontend/utils/useApi';
import axios from 'axios';
import { usePhrases } from '@frontend/utils/usePhrases';

let { CancelToken } = axios;
let cancelJobsRequest;
let cancelStructureRequest;

const useOrders = (structureInUse, fullTimeSelect, timeFrame) => {
  const api = useApi();
  const phrases = usePhrases().phrases();
  const [jobs, setJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [structure, setStructure] = useState(true);
  const [structurePaths, setStructurePaths] = useState({});

  function getOrdersTableData(_jobs) {
    const rows = [];
    for (let i = 0; i < _jobs.length; i++) {
      const row = {
        id: i,
        jobId: _jobs[i].businessJobId,
        orderId: _jobs[i].order.businessOrderId,
        status: phrases.modules.orders[_jobs[i].completionStatus],
        intendedMachine: _jobs[i].intendedMachine,
        structure: _jobs[i].structure,
        plannedStart: new Date(_jobs[i].plannedExecutionDate),
        actualStart: _jobs[i].actualStartDate ? new Date(_jobs[i].actualStartDate) : null,
        target: _jobs[i].targetQuantity,
        sensorCount: _jobs[i].sensorCount,
        handCount: _jobs[i].handCount,
        product: _jobs[i].product,
      };

      rows.push(row);
    }

    return rows;
  }

  function fetchJobs(_structures = []) {
    if (cancelJobsRequest){
      cancelJobsRequest();
    }
    // Check for Full time
    let query = "";
    if(fullTimeSelect){
      query = `/api/orders/jobs?populate=order structure intendedMachine product&sensorData=true&mongoQuery={"structure": {"$in": [${_structures.map((_s) => `"${_s.id}"`)}]}}`;
    } else{
      let d = new Date();
      let _t = 14;
      try{
        _t = parseInt(timeFrame);
      } catch(err){
        console.log("Error parsing int");
      }
      d.setDate(d.getDate()-_t);
      const mq = `{"structure": {"$in": [${_structures.map((_s) => `"${_s.id}"`)}]}, "plannedExecutionDate": {"$gt": "${d}"}}`;      
      query = `/api/orders/jobs?timeFrame=14&populate=order structure intendedMachine product&sensorData=true&mongoQuery=${mq}`;
    }
    api(query, {
      method: 'get',
      cancelToken: new CancelToken((c) => {
        cancelJobsRequest = c;
      }),
    })
    .then((response) => {
      if (response.status === 200 || response.status === 304) {
        setJobs(getOrdersTableData(response.data));
        setLoadingJobs(false);
      }
    })
    .catch(() => {
      setLoadingJobs(false);
    });
  }

  function fetchChildren(_structure) {
    api(`/api/structures/${_structure.id}/children?populate=structure`, {
      method: 'get',
    })
      .then((response) => {
        setStructurePaths(response.data.reduce((acc, _str) => {
          return {
            ...acc,
            [_str.id]: _str.path,
          };
        }, {}));
        fetchJobs(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (cancelStructureRequest) cancelStructureRequest();
    setLoadingJobs(true);
    api(`/api/structures/${structureInUse}?populate=structure`, {
      method: 'get',
      cancelToken: new CancelToken((c) => {
        cancelStructureRequest = c;
      }),
    })
      .then((response) => {
        setStructure(response.data);
        fetchChildren(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureInUse, fullTimeSelect, timeFrame]);

  return {
    jobs,
    loadingJobs,
    structure,
    structurePaths,
  };
};

export default useOrders;
