import React, { Component } from 'react';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Grid,
    Paper,
} from '@material-ui/core'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("AM5C377488971");
/**
 * Author: Julian Dreyer
 * This component will render the Machine Performance ranking chart. This is a Funnel Chart, due to its
 * resemblence to a funnel. The top performing machine will be on top, followed by two weaker performing 
 * machines. 
 */
class MachineRankingCard extends Component {
    constructor(props) {
        super(props);
        this.colorGradient = ["#56E856", "#F2BA5A", "#F0654F"]
        this.state = {  }
    }

    /**
     * Author: Julian Dreyer
     * The lifecycle method componentDidUpdate will be called, whenever the data feeding the object has been
     * updated. In our case, we need to update the chart in here.
     * The expected "performances" array has entries in the following format:
     * 
        {
            name: <machine_name>
            performance: integer percent
        }
     */
    componentDidUpdate() {
        // Lets add the color to the entries. You can adjust the color as you like in the constructor
        let performances = this.props.performances

        // This might be empty, lets cope for that
        if(performances === undefined || performances.length === 0){
            this.chart.data = [{
                "name": "N/A",
                "performance": 0
            }]
            this.chart.data[0][this.props.no_performance_text] = this.props.no_performance_value
        } else {
            performances.forEach((element, i) => {
                element.color = this.colorGradient[i];
            });    
            this.chart.data = performances;     
        }
        // To update the new data, we need to invalidate the previous data first
        this.chart.invalidateData();
    }

    /**
     * Author: Julian Dreyer
     * Once the component is mounted, the chart can be loaded into the DOM. The data
     * will be provided in the update method.
     */
    componentDidMount() {
        // Create a Funnel Chart
        this.chart = am4core.create(this.props.chart_id, am4charts.SlicedChart);
        var pieSeries = this.chart.series.push(new am4charts.FunnelSeries());

        // The data fields are "name" and "performance"
        pieSeries.dataFields.value = this.props.chart_value_id;;
        pieSeries.dataFields.category = this.props.chart_category_id;;
        // Set the labels on the left and next to the slices
        pieSeries.alignLabels = true;
        pieSeries.labelsOpposite = false;
        // Format the labels in shite
        pieSeries.labels.template.text = "{category}: [bold]{value}%[/]";
        pieSeries.labels.template.fill = am4core.color(this.props.color.primary);
        // Fill the Slices with their respective color in the "color" field
        pieSeries.slices.template.propertyFields.fill = "color";
    }
    /**
     * Author: Julian Dreyer
     * This lifecycle method is called whenever the component is unmounted. It's important to
     * dispose the chart so that it will not be rendered on top of an old one.
     */
    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
    
    render() {
        return (
            <Grid item xs={12} md={4}>                
                <Paper variant="elevation" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" align="center">
                                        Machine Performance Overview
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableRow>
                            <TableCell>
                            <div id={this.props.chart_id} style={{height: '300px', color: 'FFFFFF', margin: '20px auto'}}>
                            </div>
                            </TableCell>
                            </TableRow>
                    </Table>
                </Paper>
            </Grid>        
        );
    }
}
 
export default MachineRankingCard;