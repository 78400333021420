import React from 'react';
import { 
    Divider, 
    Grid,
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableRow, 
    Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useStatesFilter          from '@frontend/hooks/useStatesFilter';
import useStatusesModalContent  from '@frontend/modules/machine/machine.statuses/hooks/useStatusesModalContent';
import { usePhrases }           from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    cardHeader: {
        fontWeight: "bold",
        padding: theme.spacing(1.5),
        justifyContent: "center",
    },
    cardColorCell: {
        padding: theme.spacing(1.5),
        display: "flex",
        textAlign: "center",
        alignSelf: "center",
        justifyContent: "center"
    }, 
    cardCell: {
      padding: theme.spacing(1.5),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
  }));
const StatusesModalContent  = (props) => {
    const {
        machineId
    } = props;

    const {
        pulseReasons,
        pulseStates
    } = useStatusesModalContent(machineId);

    const {
        stateColor,
        stateText,
        reasonText,
        reasonColor,
    } = useStatesFilter();

    const phrases = usePhrases().phrases();
    const classes = useStyles();
    return (  
        <>
        <Grid container spacing={2} square>
            <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined" square>
                    <Table>
                        <TableBody>
                            <TableRow className={classes.row}>
                                <TableCell className={classes.cardHeader}>
                                    <Typography variant="body1" className={classes.cardHeader}>
                                    {phrases.machineStatusesText.machineStatusColorHeading}
                                    </Typography>
                                    
                                </TableCell>
                                <TableCell className={classes.cardHeader}>
                                    <Typography variant="body1" className={classes.cardHeader}>
                                    {phrases.machineStatusesText.machineStatusNameHeading}
                                    </Typography>
                                </TableCell>
                            <Divider variant='fullWidth'/>
                            </TableRow>
                                {pulseStates.map((statusKey) => {
                                    return (
                                    <TableRow  className={classes.row}>
                                        <TableCell className={classes.cardCell}>
                                            <svg width="150" height="30">
                                                <rect width="150" height="30" style={{
                                                    fill: stateColor(statusKey),
                                                    }
                                                } />
                                            </svg>
                                        </TableCell>
                                        <TableCell className={classes.cardCell}>
                                            <Typography variant="body1">
                                                {stateText(statusKey)}
                                            </Typography>
                                        </TableCell>                            
                                    </TableRow>
                                    )
                                })}                    
                        </TableBody>
                    </Table>           
                </Paper>     
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined" square>
                    <Table>
                        <TableBody>
                            <TableRow className={classes.row}>
                                <TableCell className={classes.cardHeader}>
                                    <Typography variant="body1" className={classes.cardHeader}>
                                    {phrases.machineStatusesText.machineReasonColorHeading}
                                    </Typography>
                                    
                                </TableCell>
                                <TableCell className={classes.cardHeader}>
                                    <Typography variant="body1" className={classes.cardHeader}>
                                    {phrases.machineStatusesText.machineReasonNameHeading}
                                    </Typography>
                                </TableCell>
                            <Divider variant='fullWidth'/>
                            </TableRow>
                                {pulseReasons.map((statusKey) => {
                                    return (
                                    <TableRow  className={classes.row}>
                                        <TableCell className={classes.cardCell}>
                                            <svg width="150" height="30">
                                                <rect width="150" height="30" style={{
                                                    fill: reasonColor(statusKey),
                                                    }
                                                } />
                                            </svg>
                                        </TableCell>
                                        <TableCell className={classes.cardCell}>
                                            <Typography variant="body1">
                                                {reasonText(statusKey)}
                                            </Typography>
                                        </TableCell>                            
                                    </TableRow>
                                    )
                                })}                    
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
            
        </>
    );
}
 
export default StatusesModalContent ;