import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateLoginForm = () => {
  const phrases = usePhrases().phrases();
  const validateLoginForm = (values) => {
    const errors = {};

    if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = phrases.forms.user.errors.invalidEmailFormat;
    }

    return errors;
  };

  return {
    validateLoginForm,
  };
};

export default useValidateLoginForm;
