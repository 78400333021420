import _ from 'lodash';
import Loader from '@frontend/loader/loader';

const useMachineStats = () => {
  const { moduleLoaded } = Loader();

  const _isKPIValueAbsent = (machine, states, module, kpi) => {
    if (!moduleLoaded(module)) return true;
    if (!states[machine.id]) return true;
    if (!states[machine.id].machineKPIs) return true;
    if (!states[machine.id].machineKPIs[kpi]) return true;
  };

  const getMachineRuntimePercentage = (machine, states) => {
    if (_isKPIValueAbsent(machine, states, 'availability', 'runtime')) return 0;
    return states[machine.id].machineKPIs.runtime;
  };

  const getMachineDowntimePercentage = (machine, states) => {
    if (_isKPIValueAbsent(machine, states, 'availability', 'downtime')) return 0;
    return states[machine.id].machineKPIs.downtime;
  };

  const _isStateAbsent = (machine, states) => {
    if (!states[machine.id]) return true;
    if (!states[machine.id].states) return true;
    if (!_.last(states[machine.id].states)) return true;
  };

  const whichState = (o = {}) => (Object
    .keys(o)
    .find((k) => new RegExp(/(\w+\.status)/g).test(k))
  );

  const _isMachineRunning = (lastState) => {
    const stateProperty = whichState(lastState);
    return lastState[stateProperty] !== 'not_running';
  };

  const getStateValue = (machine, states, stateName) => {
    if (_isStateAbsent(machine, states)) return 0;
    if (!states[machine.id].machineKPIs) return 0;
    return states[machine.id].machineKPIs[stateName];
  };

  const getMachineRunning = (machine, states) => {
    if (_isStateAbsent(machine, states)) return false;
    return _isMachineRunning(_.last(states[machine.id].states));
  };

  const getMachineLastState = (machine, states) => {
    if (_isStateAbsent(machine, states)) return {};
    return _.last(states[machine.id].states);
  };

  const getMachineActualCount = (machine, states) => {
    if (_isStateAbsent(machine, states)) return 'N/A';
    if (!states[machine.id].partsInfo) return 0;
    return Math.round(states[machine.id].partsInfo.actualCount);
  };

  const getMachineIdealCount = (machine, states) => {
    if (_isStateAbsent(machine, states)) return 'N/A';
    if (!states[machine.id].partsInfo) return 0;
    return Math.round(states[machine.id].partsInfo.targetCount);
  };

  return {
    getMachineRuntimePercentage,
    getMachineDowntimePercentage,
    getStateValue,
    getMachineRunning,
    getMachineLastState,
    getMachineActualCount,
    getMachineIdealCount,
  };
};

export default useMachineStats;
