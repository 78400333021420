import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fab,
  Zoom,
  useScrollTrigger,
} from '@material-ui/core';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

const useStyles = makeStyles((theme) => ({
  backToTop: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: '50%',
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: '5%',
    },
  },
}));

/**
 * Scroll to the top component.
 */
const ScrollToTop = (props) => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 50,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} color="primary" role="presentation" className={classes.backToTop}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <VerticalAlignTopIcon />
        </Fab>
      </div>
    </Zoom>
  );
};

export default ScrollToTop;
