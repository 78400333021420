export const storeStructure = (structure) => ({
  type: 'STORE_STRUCTURE',
  structure,
});

export const storeDropdownStructures = (structures) => ({
  type: 'STORE_DROPDOWN_STRUCTURES',
  structures,
});

export const removeDropdownStructures = (structures) => ({
  type: 'REMOVE_DROPDOWN_STRUCTURES',
  structures,
});

export const storeSelectionStructures = (structures) => ({
  type: 'STORE_SELECTION_STRUCTURES',
  structures,
});
