import { useState, useEffect } from 'react';

const useProductsAutocomplete = (products) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  /**
   * Sort products by name.
   *
   * @param {Object[]} _products Unsorted list of products.
   */
  const sortProducts = (_products) => {
    if (Array.isArray(_products)) {
      return _products.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }    
    return _products;
  };

  /**
   * Gets trigerred when a new products prop is passed.
   */
  useEffect(() => {
    setDropdownOptions(sortProducts(products));
  }, [products]);

  return {
    dropdownOptions,
  };
};

export default useProductsAutocomplete;
