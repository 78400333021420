/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DialogActions,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';

import {
  setIsRelativeTimespan,
  setSelectedTimespanText,
  setSelectedRelativeTimespan,
} from '@frontend/utils/UIActions';
import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
  },
  selectField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  presetList: {
    width: '100%',
  },
  listTitle: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 'bold',
  },
  selectedPreset: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const TimespanRelativeSelection = (props) => {
  const {
    onTimespanSelect,
    selectTimeframe,
    handleCloseDialog,
  } = props;

  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const dispatch = useDispatch();

  // ===== Redux Selectors =====
  const machines = useSelector((state) => state.machines);

  // ===== State Objects =====
  // Relative timeframe that is selected in the dialog
  const [relativeDialogTimeframe, setRelativeDialogTimeframe] = useState({ amount: 12, unit: 'hours' });

  // ===== Helper Methods =====
  const handleChangeRelativeTimeframeAmount = (event) => {
    const { value } = event.target;

    setRelativeDialogTimeframe({
      amount: value,
      unit: relativeDialogTimeframe.unit,
    });
  };

  const handleChangeRelativeTimeframeUnit = (event) => {
    const { value } = event.target;
    setRelativeDialogTimeframe({
      amount: relativeDialogTimeframe.amount,
      unit: value,
    });
  };

  const handleRelativePresetClick = (amount, unit) => {
    setRelativeDialogTimeframe({ amount, unit });
  };

  const selectedRelativeTimeframeIsEqual = (amount, unit) => relativeDialogTimeframe.amount === amount
    && relativeDialogTimeframe.unit === unit;

  const getUnitLocalized = ({ amount, unit }) => {
    let localizedUnit = unit;

    // This has to be weak check since the value in the field is a String
    // eslint-disable-next-line eqeqeq
    if (amount == 1) {
      switch (unit) {
        default: break;
        case 'minutes':
          localizedUnit = phrases.timespanSelection.Minute;
          break;
        case 'hours':
          localizedUnit = phrases.timespanSelection.Hour;
          break;
        case 'days':
          localizedUnit = phrases.timespanSelection.Day;
          break;
        case 'weeks':
          localizedUnit = phrases.timespanSelection.Week;
          break;
      }
    } else {
      switch (unit) {
        default: break;
        case 'minutes':
          localizedUnit = phrases.timespanSelection.Minutes;
          break;
        case 'hours':
          localizedUnit = phrases.timespanSelection.Hours;
          break;
        case 'days':
          localizedUnit = phrases.timespanSelection.Days;
          break;
        case 'weeks':
          localizedUnit = phrases.timespanSelection.Weeks;
          break;
      }
    }

    return localizedUnit;
  };

  const formatSelectedTimespanText = ({ amount, unit }) => {
    // This has to be weak check since the value in the field is a String
    // eslint-disable-next-line eqeqeq
    if (amount == 1) {
      return `${phrases.timespanSelection.Last} ${getUnitLocalized({ amount, unit })}`;
    }

    return `${phrases.timespanSelection.Last} ${amount} ${getUnitLocalized({ amount, unit })}`;
  };

  const updatePulse = () => {
    dispatch(setIsRelativeTimespan(true));
    dispatch(setSelectedRelativeTimespan({ amount: relativeDialogTimeframe.amount, unit: relativeDialogTimeframe.unit }));
    dispatch(setSelectedTimespanText(formatSelectedTimespanText({ amount: relativeDialogTimeframe.amount, unit: relativeDialogTimeframe.unit })));
    if (selectTimeframe) selectTimeframe(machines, moment().subtract(relativeDialogTimeframe.amount, relativeDialogTimeframe.unit).toDate(), moment().toDate());
    if (onTimespanSelect) { onTimespanSelect(moment().subtract(relativeDialogTimeframe.amount, relativeDialogTimeframe.unit).toDate(), moment().toDate(), undefined, undefined, true); }
    handleCloseDialog();
  };

  return (
    <div>
      <List>
        <ListItem>
          <TextField
            id="outlined-basic"
            margin="dense"
            label={phrases.timespanSelection.last}
            className={classes.inputField}
            value={relativeDialogTimeframe.amount}
            onChange={handleChangeRelativeTimeframeAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTimeIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <FormControl
            className={classes.selectField}
          >
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              variant="outlined"
              margin="dense"
              onChange={handleChangeRelativeTimeframeUnit}
              value={relativeDialogTimeframe.unit}
            >
              <MenuItem value="minutes">{phrases.timespanSelection.Minutes}</MenuItem>
              <MenuItem value="hours">{phrases.timespanSelection.Hours}</MenuItem>
              <MenuItem value="days">{phrases.timespanSelection.Days}</MenuItem>
              <MenuItem value="weeks">{phrases.timespanSelection.Weeks}</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <List className={classes.presetList}>
            <div className={classes.listTitle}>{phrases.timespanSelection.Presets}</div>

            <ListItem button onClick={(() => handleRelativePresetClick(1, 'hours'))}>
              <ListItemText classes={selectedRelativeTimeframeIsEqual(1, 'hours') ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.LastHour} secondary={phrases.timespanSelection.ShowLastHour} />
            </ListItem>

            <ListItem button onClick={(() => handleRelativePresetClick(6, 'hours'))}>
              <ListItemText classes={selectedRelativeTimeframeIsEqual(6, 'hours') ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.Last6Hours} secondary={phrases.timespanSelection.ShowLast6Hours} />
            </ListItem>

            <ListItem button onClick={(() => handleRelativePresetClick(12, 'hours'))}>
              <ListItemText classes={selectedRelativeTimeframeIsEqual(12, 'hours') ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.Last12Hours} secondary={phrases.timespanSelection.ShowLast12Hours} />
            </ListItem>

            <ListItem button onClick={(() => handleRelativePresetClick(24, 'hours'))}>
              <ListItemText classes={selectedRelativeTimeframeIsEqual(24, 'hours') ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.Last24Hours} secondary={phrases.timespanSelection.ShowLast24Hours} />
            </ListItem>

            <ListItem button onClick={(() => handleRelativePresetClick(7, 'days'))}>
              <ListItemText classes={selectedRelativeTimeframeIsEqual(7, 'days') ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.Last7Days} secondary={phrases.timespanSelection.ShowLast7Days} />
            </ListItem>
          </List>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="contained" color="default">
          {phrases.forms.shared.buttons.cancel}
        </Button>
        <Button onClick={updatePulse} variant="contained" color="primary">
          {phrases.forms.shared.buttons.updatePulse}
        </Button>
      </DialogActions>
    </div>
  );
};

export default TimespanRelativeSelection;
