/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useApi from '@frontend/utils/useApi';
import useValidateStatusReasonForm
  from '@frontend/modules/reasons/hooks/useValidateStatusReasonForm';
import useAlert from '@frontend/hooks/useAlert';
import { usePhrases } from '@frontend/utils/usePhrases';
import _ from 'lodash';
const emptyStatusReason = {
  name: '',
  identifier: '',
  description: '',
  color: '',
  note: '',
};

const useReasonAssignForm = (callback, selectedStatus, modalOpen) => {
  const api = useApi();
  const phrases = usePhrases()
    .phrases();
  const { createAlert } = useAlert();

  const { validateStatusReasonForm } = useValidateStatusReasonForm();
  const [statusReasonValues, setStatusReasonValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingReasons, setLoadinReasons] = useState(false);
  const [reasonsByCategory, setReasonsByCategory] = useState({});
  const [defaultReason, setDefaultReason] = useState("");
  const reasons = useSelector((state) => state.reasons);
  // let cancelAxios = null;

  function getReasonNote() {
    if (selectedStatus.state['presentation.note']) {
      api(`/api/shared/notes/${selectedStatus.state['presentation.note']}`, {
        method: 'get',
      })
        .then((response) => {
          if (response) {
            setStatusReasonValues({
              ...reasons[selectedStatus.state['presentation.reason']],
              note: response.data.text,
              noteId: response.data.id,
            });
          }
        })
        .catch((error) => {
          const errorMessage = phrases.forms.structure.errors.genericError;
          if (error.response) {
            if (error.response.status === 404) {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            } else if (error.response.status === 403) {
              setIsSubmitting(false);
              createAlert(phrases.errorMessages.notAuthorized, 'error');
            } else {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            }
          }
        });
    } else {
      setStatusReasonValues({
        ...reasons[selectedStatus.state['presentation.reason']],
        note: '',
        noteId: '',
      });
    }
  }

  useEffect(() => {
    if (selectedStatus && selectedStatus.state) {
      getReasonNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const divideReasonsByCategory = (_reasons) => {
    const uniqueCategories = Array.from(new Set(_reasons.map((reason) => reason.category.name)));
    return uniqueCategories.reduce((o, category) => Object.assign(o, {
      [category]: _reasons.filter((reason) => reason.category.name === category),
    }), {});
  };

  useEffect(() => {
    if (modalOpen) {
      setLoadinReasons(true);

      api(`/api/reasons?mongoQuery={"$or": [{"machineTypes": {"$in": ["${selectedStatus.machineType.id}"]}},{"machineTypes": {"$size": 0}}], "enabled": true }`, {
        method: 'get',
      })
        .then((response) => {
          setReasonsByCategory(divideReasonsByCategory(response.data));
          setLoadinReasons(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
          setLoadinReasons(false);
        });
      fetchDefaultReason();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const fetchDefaultReason = () => {
    api(`/api/machines/default-reason?machineId=${selectedStatus.machineId}&time=${(new Date(selectedStatus.state.time).valueOf())}`, {
      method: 'get',
    })
      .then((response) => {
        if(!_.isEmpty(response.data)) {
          setDefaultReason(response.data[0]["presentation.defaultReason"]);
        }
        setLoadinReasons(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
        setLoadinReasons(false);
      });
  }
  
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateStatusReasonForm(statusReasonValues));
  };

  const handleChangeReason = (reason) => {
    setStatusReasonValues({
      note: statusReasonValues.note,
      noteId: statusReasonValues.noteId,
      ...reason,
    });
  };

  const handleChangeNote = (event) => {
    event.persist();
    if (errors[event.target.name]) {
      delete errors[event.target.name];
    }
    setStatusReasonValues((reasonValues) => ({
      ...reasonValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClearForm = () => {
    setStatusReasonValues(emptyStatusReason);
    setErrors({});
  };

  const resetToDefault = () => {
    // Fetch the default reason
    if( defaultReason && defaultReason !== ''){
      api(`/api/reasons?mongoQuery={"identifier": "${defaultReason ?? 'not_defined'}" }`, {
        method: 'get',
      })
        .then((response) => {
          setStatusReasonValues(response.data[0]);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
          setLoadinReasons(false);
        });    
    } else {
      setStatusReasonValues(emptyStatusReason); 
    }
  }
  
  return {
    handleChangeReason,
    handleChangeNote,
    handleSubmit,
    handleClearForm,
    statusReasonValues,
    reasonsByCategory,
    errors,
    isSubmitting,
    loadingReasons,
    setIsSubmitting,
    resetToDefault,
  };
};

export default useReasonAssignForm;
