/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
  useTheme,
  MenuItem,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import useAlert from '@frontend/hooks/useAlert';
import MultipleSelect from '@frontend/components/MultipleSelect';
import StyledSelect from '@frontend/components/StyledSelect';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import useConditionalRender from '@frontend/utils/useConditionalRender';
import useSecurity from '@frontend/utils/useSecurity';
import useRoleForm from './hooks/useRoleForm';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: theme.spacing(0.5),
    fontWeight: 'bold',
  },
}));

export default function RoleForm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const phrases = usePhrases().phrases();
  const { isRoleSystemAdmin, getProfile, isSystemAdmin } = useSecurity();
  const { conditionalRender } = useConditionalRender();
  const { createAlert } = useAlert();
  const currentUser = getProfile();
  const api = useApi();
  const {
    handleChange,
    handleSubmit,
    handleClearForm,
    handlePermissionsChange,
    handleDeletePermission,
    handleRoleInheritance,
    parentRole,
    roles,
    permissions,
    roleValues,
    errors,
  } = useRoleForm(submitForm, props.role);
  
  const [formSubmitting, setFormSubmitting] = useState(false);
  const isEdit = !!props.isEdit;

  const chipMouseDown = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  function submitForm() {
    setFormSubmitting(true);

    const role = {
      ...roleValues,
    };

    api('/api/users/roles', {
      data: role,
      method: isEdit ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 201) {
          props.onCreate();
          setFormSubmitting(false);
          handleClearForm();
          createAlert(phrases.forms.user.success.roleAdded, 'success');
        } else if (response.status === 200) {
          if (isEdit) {
            props.onEdit(response.data);
            setFormSubmitting(false);
            createAlert(phrases.forms.user.success.roleUpdated, 'success');
            props.handleCloseModal();
          }
        }
      }).catch((error) => {
        const errorMessage = phrases.forms.user.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            setFormSubmitting(false);
            createAlert(errorMessage, 'error');
          } else if (error.response.status === 403) {
            setFormSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            setFormSubmitting(false);
            createAlert(errorMessage, 'error');
          }
        }
      });
  }

  return (
    <>
      {conditionalRender(isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser) && !isSystemAdmin(currentUser), <Alert variant="filled" severity="warning">
        <AlertTitle>{phrases.modules.user.note}</AlertTitle>
        {phrases.modules.user.editingDisabled}
      </Alert>)}
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          error={errors.name}
          helperText={errors.name}
          id="name"
          name="name"
          label={phrases.forms.shared.fields.name}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={roleValues.name}
          margin="dense"
          required
          disabled={isRoleSystemAdmin(roleValues)}
        />
        <StyledSelect
          label="Inherit role"
          labelId="structure-label"
          id="structure-select"
          name="structure"
          value={parentRole}
          onChange={handleRoleInheritance}
          data={roles}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
        />
        <MultipleSelect
          label="Structures permissions"
          name="structures"
          value={roleValues.permissions.filter((permission) => permission.resource === 'structures')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  onMouseDown={chipMouseDown}
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'structures').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.machine.machinesPermissions}
          name="machines"
          value={roleValues.permissions.filter((permission) => permission.resource === 'machines')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  onMouseDown={chipMouseDown}
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'machines').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.sensor.sensorsPermission}
          name="sensors"
          value={roleValues.permissions.filter((permission) => permission.resource === 'sensors')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  onMouseDown={chipMouseDown}
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'sensors').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.user.usersPermissions}
          name="users"
          value={roleValues.permissions.filter((permission) => permission.resource === 'users')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  onMouseDown={chipMouseDown}
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'users').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.roles.rolesPermission}
          name="roles"
          value={roleValues.permissions.filter((permission) => permission.resource === 'roles')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  onMouseDown={chipMouseDown}
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'roles').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.sensor.sensorsPermission}
          name="sensor-types"
          value={roleValues.permissions.filter((permission) => permission.resource === 'sensor-types')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  onMouseDown={chipMouseDown}
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'sensor-types').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.machine.machineTypesPermission}
          name="machine-types"
          value={roleValues.permissions.filter((permission) => permission.resource === 'machine-types')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'machine-types').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.machine.machineStatusesPermission}
          name="machine_statuses"
          value={roleValues.permissions.filter((permission) => permission.resource === 'machine_statuses')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'machine_statuses').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.reasons.categoriesPermissions}
          name="categories"
          value={roleValues.permissions.filter((permission) => permission.resource === 'categories')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'categories').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.reasons.notesPermissions}
          name="notes"
          value={roleValues.permissions.filter((permission) => permission.resource === 'notes')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'notes').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.reasons.reasonsPermissions}
          name="reasons"
          value={roleValues.permissions.filter((permission) => permission.resource === 'reasons')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'reasons').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.quality.scrapTypesPermissions}
          name="scrap-types"
          value={roleValues.permissions.filter((permission) => permission.resource === 'scrap-types')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'scrap-types').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.schedule.dayTemplatePermissions}
          name="dayTemplates"
          value={roleValues.permissions.filter((permission) => permission.resource === 'dayTemplates')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'dayTemplates').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.schedule.weekTemplatePermissions}
          name="weekTemplates"
          value={roleValues.permissions.filter((permission) => permission.resource === 'weekTemplates')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'weekTemplates').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.schedule.schedulePermissions}
          name="schedules"
          value={roleValues.permissions.filter((permission) => permission.resource === 'schedules')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'schedules').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.orders.ordersPermissions}
          name="orders"
          value={roleValues.permissions.filter((permission) => permission.resource === 'orders')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'orders').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.orders.jobsPermissions}
          name="jobs"
          value={roleValues.permissions.filter((permission) => permission.resource === 'jobs')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'jobs').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.orders.productsPermissions}
          name="products"
          value={roleValues.permissions.filter((permission) => permission.resource === 'products')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'products').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.machine_shift.machineShiftPermission}
          name="machine_shift"
          value={roleValues.permissions.filter((permission) => permission.resource === 'machine_shift')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'machine_shift').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <MultipleSelect
          label={phrases.modules.order_export.label}
          name="order_export"
          value={roleValues.permissions.filter((permission) => permission.resource === 'order_export')}
          onChange={handlePermissionsChange}
          disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  color="primary"
                  disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
                  onMouseDown={chipMouseDown}
                  onDelete={(e) => handleDeletePermission(e, value.id)}
                  variant={value.action.includes('any') ? 'default' : 'outlined'}
                  style={value.action.includes('any') ? { backgroundColor: theme.palette.primary.light } : {}}
                  key={value.action}
                  label={value.action}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          data={
            permissions.filter((permission) => permission.resource === 'order_export').map((permission) => (
              <MenuItem key={permission.action} value={permission}>
                <Checkbox color="primary" checked={Boolean(roleValues.permissions.find((value) => value.id === permission.id))} />
                {permission.action}
              </MenuItem>
            ))
          }
        />
        <div className={classes.formButtons}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.formBtn}
            disabled={(isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)) || formSubmitting}

          >
            {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
            {formSubmitting
              && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
          </Button>
          {!isEdit && (
            <Button
              variant="outlined"
              disabled={isRoleSystemAdmin(roleValues) && !isSystemAdmin(currentUser)}
              className={classes.formBtn}
              onClick={handleClearForm}
            >
              {phrases.forms.shared.buttons.clear}
            </Button>
          )}
          {isEdit
            && (
              <Button
                variant="outlined"
                className={classes.formBtn}
                onClick={props.handleCloseModal}
              >
                {phrases.forms.shared.buttons.close}
              </Button>
            )}
        </div>
      </form>
    </>
  );
}
