/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Modal             from '@frontend/components/Modal';
import { usePhrases }     from '@frontend/utils/usePhrases';
import ReasonsCollapse            from '@frontend/modules/reasons/components/ReasonsCollapse';
import useStaticReasonAssignForm  from '@frontend/modules/reasons/hooks/useStaticReasonAssignForm';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  reasonTitle: {
    marginBottom: theme.spacing(1),
  },
}));

const StaticReasonAssignModal = (props) => {
  const {
    open,
    machine,
    handleCloseModal,
    isEdit,
    setStaticReason
  } = props;
  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const {
    handleChangeReason,
    submitStaticStatusReasonForm,
    handleClearForm,
    statusReasonValues,
    handleChangeNote,
    isSubmitting,
    deleteStatusReasonNote
  } = useStaticReasonAssignForm(machine, handleCloseModal);

  // ===== Effects =====

  // ===== Helper Methods =====
  const handleReasonClick = (_reason) => {
    handleChangeReason(_reason);
  };

  const handleCloseModalClick = () => {
    handleCloseModal();
  };

  const onModalClose = () => {
    handleCloseModal();
    handleClearForm();
  };

  const handleDeleteReason = () => {
    deleteStatusReasonNote();
  };

  

  
  // ===== Return =====
  return (
    <>
      <Modal
        modalTitle={isEdit ? phrases.forms.reason.misc.editStaticReason : phrases.forms.reason.misc.assignStaticReason}
        open={open}
        handleClose={onModalClose}
        content={(
          <Grid container spacing={1}>
            <form className={classes.root} noValidate onSubmit={() => {setStaticReason(true)}} autoComplete="off">
              <Grid item xs={12}>
                <Typography
                  variant="button"
                  display="block"
                  color="textSecondary"
                  className={classes.reasonTitle}
                >
                  {phrases.forms.reason.misc.reason}
                  <Divider />

                </Typography>
              </Grid>
              <ReasonsCollapse
                machineId={machine.id}
                modalOpen={open}
                statusReasonValues={statusReasonValues}
                handleDeleteReason={handleDeleteReason}
                handleChangeNote={handleChangeNote}
                handleReasonClick={handleReasonClick}
              />
              <Grid item xs={12}>
                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    // type="submit"
                    className={classes.formBtn}
                    disabled={isSubmitting}
                    onClick={() => {
                      setStaticReason(true);
                      submitStaticStatusReasonForm();
                      handleCloseModal();
                    }}
                  >
                    {phrases.forms.shared.buttons.submit}
                    {isSubmitting
                      && (
                        <CircularProgress
                          className={classes.loadingBtnIcon}
                          color="inherit"
                          size={15}
                        />
                      )}
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.formBtn}
                    onClick={handleCloseModalClick}
                  >
                    {phrases.forms.shared.buttons.close}
                  </Button>
                </div>
              </Grid>
            </form>
          </Grid>
        )}
      />
    </>
  );
};

export default React.memo(
  StaticReasonAssignModal,
  (prevProps, nextProps) => prevProps.open === nextProps.open,
);
