import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  MenuItem,
  Menu,
} from '@material-ui/core';

import useAlert                 from '@frontend/hooks/useAlert';
import useReloadTimes           from '@frontend/hooks/useReloadTimes';
import { RELOAD_INTERVALS }     from '@frontend/constants';
import { setReloadInterval, setLastReloadTime }    from '@frontend/utils/UIActions';
import { usePhrases }           from '@frontend/utils/usePhrases';
const ReloadIntervalMenu = (props) => {
  const {
    reloadIntervalAnchorEl,
    setReloadIntervalAnchorEl,
    open,
  } = props;

  const dispatch = useDispatch();
  const phrases = usePhrases().phrases();
  const { formatTime } = useReloadTimes();
  const { createAlert } = useAlert();

  const handleCloseReloadInterval = () => {
    setReloadIntervalAnchorEl(null);
  };

  const handleTimeClick = (interval) => {
    dispatch(setReloadInterval(interval));    
    dispatch(setLastReloadTime({}));
    createAlert(phrases.forms.reloadInterval.success, 'success');
    handleCloseReloadInterval();
  };

  const StyledMenuItem = withStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
    },
  })(MenuItem);

  return (
    <Menu
      id="menu-appbar"
      getContentAnchorEl={null}
      anchorEl={reloadIntervalAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleCloseReloadInterval}
      PaperProps={{
        square: true,
        variant: 'outlined',
      }}
    >
        <StyledMenuItem>
            <b>{phrases.nav.reload}</b>
        </StyledMenuItem>
        <MenuItem
          key={0}
          onClick={() => {handleTimeClick(0)}}
        >
            {phrases.nav.instant}
        </MenuItem>
        {RELOAD_INTERVALS.map((interval) => (
        <>
          <Divider />
          <MenuItem
            key={interval}
            onClick={() => {handleTimeClick(interval)}}
          >
            {formatTime(interval)}
          </MenuItem>
        </>
        ))}
    </Menu>
  );
};

ReloadIntervalMenu.propTypes = {
  setReloadIntervalAnchorEl: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ReloadIntervalMenu;
