const useMatomo = (siteID, user) => {
  const PiwikReactRouter = require('piwik-react-router');
  window._paq = window._paq || [];

  function setUserId(user) {
    if (user) {
      const userId = `${user.email} (${user.id})`;
      window._paq.push(['setUserId', userId]);
    }
  }

  const piwik = PiwikReactRouter({
    url: 'https://matomo.kmpcinnovations.de/',
    siteId: siteID,
    setUserId: setUserId(user),
    updateDocumentTitle: true,
  });

  return {
    piwik,
  };
};

export default useMatomo;
