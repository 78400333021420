/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({

  lastTableCell: {
    borderBottom: 'none',
  },
  cardCell: {
    padding: theme.spacing(1.5),
  },

}));

const AvailabilityHeaderCard = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  const formatPercentage = (percentage) => typeof percentage === 'number' ? `${Math.round(percentage)}%` : percentage;

  return (

    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.cardCell}>
            <Typography variant="body1" align="left">
              {phrases.modules.performance.oee}
            </Typography>
          </TableCell>

          <TableCell className={classes.cardCell}>
            <Typography variant="body1" align="right">
              {formatPercentage(props.oee)}
            </Typography>
          </TableCell>

        </TableRow>
      </TableHead>

      <TableBody>

        <TableRow>
          <TableCell className={classes.cardCell} align="left">
            {' '}
            {phrases.modules.performance.availability}
            :
            {' '}
          </TableCell>
          <TableCell className={classes.cardCell} align="right">
            {formatPercentage(props.availability)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.cardCell} align="left">
            {' '}
            {phrases.modules.performance.performance}
            :
            {' '}
          </TableCell>
          <TableCell className={classes.cardCell} align="right">
            {formatPercentage(props.performance)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="left">
            {' '}
            {phrases.modules.performance.quality}
            :
            {' '}
          </TableCell>
          <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="right">
            {' '}
            {formatPercentage(props.quality)}
          </TableCell>
        </TableRow>

      </TableBody>
    </Table>

  );
};

export default AvailabilityHeaderCard;
