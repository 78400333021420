import { useState, useEffect } from 'react';

/**
 * Hook for the structure dropdown.
 *
 * @param {Object[]} allStructures List of all structures within the system.
 */
const useMachinesMultiselect = (machines) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  /**
   * Sort structures by path.
   *
   * @param {Object[]} _machines List of structures.
   */
  const sortMachines = (_machines) => {
    if (Array.isArray(_machines)) {
      return _machines.sort((a, b) => {
        if (a.structure?.name > b.structure?.name) return 1;
        if (a.structure?.name < b.structure?.name) return -1;
        return 0;
      });
    }
    
    return _machines;
  };

  /**
   * Gets trigerred when a new structures props is passed.
   */
  useEffect(() => {
    setDropdownOptions(sortMachines(machines));
  }, [machines]);

  return {
    dropdownOptions,
    setDropdownOptions,
  };
};

export default useMachinesMultiselect;
