import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Slidedown from '@frontend/components/Slidedown';
import useIcon from '@frontend/hooks/useIcon';
import CustomLink from '@frontend/components/CustomLink';
import Icon from '@mdi/react';
import { mdiStarMinus, mdiStarPlus } from '@mdi/js';
import useFavs from '../modules/user/hooks/useFavs';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeListItem: {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  rightIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
    cursor: 'pointer',
  },
}));

const NavigationDrawerItem = (props) => {
  const {
    id,
    name,
    icon,
    iconType,
    data,
    openListDropdown,
    handleSideMenu,
    handleCloseSideMenu,
    toggleSidebarDropdown,
    sideMenuOpen,
    hadleCloseDrawer,
    enableFavs,
  } = props;

  const classes = useStyles();
  const drawerOpen = useSelector((state) => state.drawerOpen);
  const renderIcon = useIcon();
  const {
    toggleFav,
    favs
  } = useFavs(enableFavs);
  return (
    <>
      <ListItem
        id={id}
        button
        className={clsx({ [classes.activeListItem]: openListDropdown[id] })}
        onMouseEnter={handleSideMenu}
        onMouseLeave={handleCloseSideMenu}
        onClick={drawerOpen ? toggleSidebarDropdown: ()=>{}}
      >
        <ListItemIcon>
          {renderIcon(iconType, icon)}
        </ListItemIcon>
        <ListItemText primary={drawerOpen ? name : ''} />
        {openListDropdown[id] ? <ExpandLess /> : <ExpandMore />}
        <Slidedown
          data={data}
          close={toggleSidebarDropdown}
          open={sideMenuOpen[id]}
          enableFavs={enableFavs}
        />
      </ListItem>
      <Collapse in={openListDropdown[id]} timeout="auto" unmountOnExit>
        <List component="div" className={classes.nestedMenu} disablePadding>
          {data.map((item) => (
            <>
            <ListItem
              key={item.text}
              className={classes.nested}
            >
              <CustomLink
                className={clsx(classes.machineNameText)}
                object={item.object}
                to={item.to}
                onClick={hadleCloseDrawer}
              >
                {item.text}
              </CustomLink>
              {enableFavs ? (
              <ListItemIcon className={classes.rightIcon}>
              {favs.includes(item.favId) ? (
              <Icon path={mdiStarMinus} size={1} onClick={()=>{toggleFav(item.favId)}} sx={{zIndex: 1400}}></Icon>
              ): (

              <Icon path={mdiStarPlus} size={1} onClick={()=>{toggleFav(item.favId)}} sx={{zIndex: 1400}}></Icon>
              )}

              </ListItemIcon>) : ''}
            </ListItem>
            
            </>
          ))}
        </List>
      </Collapse>
    </>
  );
};

NavigationDrawerItem.propTypes = {
  id: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object)
  ]).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  openListDropdown: PropTypes.instanceOf(Object).isRequired,
  handleSideMenu: PropTypes.func.isRequired,
  handleCloseSideMenu: PropTypes.func.isRequired,
  toggleSidebarDropdown: PropTypes.func.isRequired,
  sideMenuOpen: PropTypes.instanceOf(Object).isRequired,
  hadleCloseDrawer: PropTypes.func.isRequired,
};

export default NavigationDrawerItem;
