import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  Grow,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';

import logo from '@frontend/static/logo.png';

const useStyles = makeStyles((theme) => ({
  imgLogo: {
    marginTop: theme.spacing(8),
    alignItems: 'center',
    margin: 'auto',
  },
  container: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardBody: {
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.container}
      >

        <Grow
          in
          timeout={400}
        >
          <Card
            raised
            className={classes.cardBody}
          >
            <img
              className={classes.imgLogo}
              src={logo}
              width="25%"
              alt="LF logo"
            />
            <CardContent>
              <Typography variant="h3" color="textPrimary" component="h2">
                Oh no...
              </Typography>
              <Typography variant="h6" color="textSecondary" component="h2">
                Internal error. Please refresh and try again. If that does not work, please contact management.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Button fullWidth size="large" color="primary" variant="outlined" onClick={() => window.location.reload()}>Refresh</Button>
            </CardActions>
          </Card>
        </Grow>

      </Grid>
    </>
  );
};

export default PageNotFound;
