import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import useSecurity from '@frontend/utils/useSecurity';
import useAlert from '@frontend/hooks/useAlert';
import { usePhrases } from '@frontend/utils/usePhrases';

/**
 * Wrapper component that takes user the authorization in
 * consideration before rendering the wrapped componnent.
 *
 * Usage example:
 *
 * <AuthorizedRoute resource="structures">
 *   <StructuresView />
 * </AuthorizedRoute>
 *
 * In the example above StructuresView component will only be rendered
 * if user has permission to access the 'structures' resource.
 * This component is mostly used in combination with PrivateRoute.
 *
 */
const AuthorizedRoute = (props) => {
  const { createAlert } = useAlert();
  const { getProfile } = useSecurity();
  const history = useHistory();
  const phrases = usePhrases().phrases();
  const { children, resource } = props;

  const canUserAccessResource = (_resource) => {
    const user = getProfile();
    if (!user) return false;
    const { permissions } = user.role;
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].resource === _resource) return true;
    }
    createAlert(phrases.errorMessages.notAuthorized, 'error');

    return false;
  };

  return (
    <>
      {canUserAccessResource(resource)
        ? children
        : history.push(getProfile().views.homeView)}
    </>
  );
};

AuthorizedRoute.propTypes = {
  /**
   * Resource that is being accessed with this route.
   */
  resource: PropTypes.string.isRequired,
  /**
   * Nodes that are rendered if the user has access to a specified resource.
   */
  children: PropTypes.node.isRequired,
};

export default AuthorizedRoute;
