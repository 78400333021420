import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import Icon from '@mdi/react';
import { 
  mdiStarMinus, 
  mdiStarPlus 
} from '@mdi/js';

import clsx from 'clsx';
import {
  ClickAwayListener,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Zoom,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomLink     from '@frontend/components/CustomLink';
import useIcon        from '@frontend/hooks/useIcon';
import useFavs        from '@frontend/modules/user/hooks/useFavs';

const closedDrawerWidth = 56;

const useStyles = makeStyles(() => ({
  slidedown: {
    position: 'absolute',
    right: closedDrawerWidth,
    minWidth: '300px'
  },
  list: {
    padding: 0,
  },
  nested: {
    display: 'flex',
    paddingRight: "1px",
    justifyContent: 'space-between',
  },
  rightIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
  },
  linktext: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '10px',
  },
  listitem: {
    display: 'flex',
    paddingRight: "1px",
    justifyContent: 'space-between',
  }

}));

const Slidedown = (props) => {
  const classes = useStyles();
  const {
    close,
    open,
    data,
    enableFavs,
  } = props;
  const renderIcon = useIcon();
  const {
    toggleFav,
    favs
  } = useFavs(enableFavs);
  return (
    <ClickAwayListener onClickAway={close}>
      <Zoom
        in={Boolean(open)}
        mountOnEnter
        unmountOnExit
        timeout={100}
      >
        <Paper
          style={{ top: 0 }}
          onMouseLeave={close}
          className={clsx(classes.slidedown)}
          elevation={5}
          square
        >
          <List component="nav" className={classes.list} aria-label="main mailbox folders">
            {data.map((item, index) => (
              <div key={uuid()}>
                <ListItem
                  className={classes.listitem}
                >
                  <div className={classes.nested}>
                  {item.icon
                    ? (
                      <ListItemIcon>
                        {renderIcon(item.iconType, item.icon)}
                      </ListItemIcon>
                    ) : ''}
                  <div className={classes.linktext}>
                    <CustomLink
                      object={item.object}
                      to={item.to}
                      onClick={close}
                    >
                      {item.text}
                    </CustomLink>
                  </div>
                  </div>
                  {enableFavs === true ? (
                    favs.includes(item.favId) ? (
                    <ListItemIcon className={classes.rightIcon}>
                      <Icon path={mdiStarMinus} size={1} onClick={() => { toggleFav(item.favId)}} sx={{ zIndex: 1400 }} />
                    </ListItemIcon>) : 
                    (<ListItemIcon className={classes.rightIcon}>
                      <Icon path={mdiStarPlus} size={1} onClick={() => { toggleFav(item.favId) }} sx={{ zIndex: 1400 }} />
                    </ListItemIcon>
                    )
                  ): ''}
                </ListItem>
              </div>
            ))}
          </List>
        </Paper>
      </Zoom>
    </ClickAwayListener>
  );
};

Slidedown.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

export default Slidedown;
