/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import useValidateMachineStatusForm
  from '@frontend/modules/machine/machine.statuses/hooks/useValidateMachineStatusForm';

const emptyMachineStatus = {
  name: '',
  activity: '',
  color: '#999999',
  category: '',
  dataLayer: '',
};

const useMachineForm = (callback, machineStatus) => {
  const { validateMachineStatusForm } = useValidateMachineStatusForm();
  const [machineStatusValues, setMachineStatusValues] = useState(machineStatus || emptyMachineStatus);
  const [errors, setErrors] = useState({});
  const machineTypes = useSelector((state) => state.machineTypes);
  const [categories, setCategories] = useState([]);
  const [dataLayers, setDataLayers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
//   let cancelAxios = null;

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCategories([
      'planned_production_time',
      'shedule_loss',
      'runtime',
      'downtime',
      'net_productive_time',
      'performance_loss',
      'fully_productive_time',
      'quality_loss',
    ]);
    setDataLayers([
      'availability',
      'performance',
      'presentation']);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateMachineStatusForm(machineStatusValues));
  };

  const handleChange = (event) => {
    event.persist();
    if (errors[event.target.name]) {
      delete errors[event.target.name];
    }
    setMachineStatusValues((reasonValues) => ({
      ...reasonValues,
      [event.target.name]: event.target.value,
    }));
  };

  // const handleSwitch = (event) => {
  //   event.persist();
  //   const field = event.target.name;
  //   setMachineStatusValues((reasonValues) => ({
  //     ...reasonValues,
  //     [field]: !reasonValues[field]
  //   }));
  // };

  const handleColorChange = (color, event) => {
    event.persist();
    setMachineStatusValues((reasonValues) => ({
      ...reasonValues,
      color: color.hex,
    }));
  };

  const handleClearForm = () => {
    setIsSubmitting(false);
    setMachineStatusValues(emptyMachineStatus);
    setErrors({});
  };

  const setMachineStatusConfig = (config) => {
    setMachineStatusValues((machineStatusValues) => ({ ...machineStatusValues, config }));
  };

  return {
    handleChange,
    handleSubmit,
    handleColorChange,
    handleClearForm,
    setMachineStatusConfig,
    machineStatusValues,
    machineTypes,
    categories,
    dataLayers,
    errors,
  };
};

export default useMachineForm;
