import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import Modal                from '@frontend/components/Modal';
import LoadingIndicator     from '@frontend/components/LoadingIndicator';
import useStatesFilter      from '@frontend/hooks/useStatesFilter';
import useEasyScrap         from '@frontend/modules/quality/hooks/useEasyScrap';
import { usePhrases }   from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      width: '100%',
    },
    backdrop: {
      zIndex: 1,
      position: 'fixed',
      margin: 'auto',
    },
    header: {
        textAlign: 'center',
        display: 'flex',
        margin: 'auto',
    },
    scrapButton: {
        display: 'flex',
        margin: 'auto',
    },
    leftButton: {
        display: 'flex',
        float: 'right',
        marginRight: '0',
        marginLeft: 'auto',
    },
    rightButton: {
        display: 'flex',
        float: 'left',
        marginLeft: '0',
        marginRight: 'auto',
    },
    container: {
        marginBottom: "10px"
    }

  }));

const EasyScrapModal = (props) => {
    const classes = useStyles();
    const {
      open,
      selectedState,
      handleCloseModal,
      machine,
      refreshPulse,
    } = props;
    
    const phrases = usePhrases().phrases();
    const {
        scrapTypeText,
    } = useStatesFilter();

    const onModalClose = () => {
        handleCloseModal();
    }

    const onScrapClick = () => {
        handleCloseModal();
        refreshPulse();
    }
    const {
        isLoadingScrapTypes,
        scrapTypesByCategory,
        createQualityReportEasyScrap,
        changeScrapCount,
        scrapCount
    } = useEasyScrap(selectedState, open, machine, onScrapClick);
    
    return ( <>
        <Modal
        modalTitle={phrases.modules.quality.easyScrap}
        open={open}
        disableBackdropClick
        handleClose={onModalClose}
        content={(<>
                    <Grid container spacing={2} className={classes.container}>                    
                        <Grid item xs={12} className={classes.header}>
                            <Typography variant="h6" className={classes.header}>
                            {phrases.modules.quality.selectScrapType}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.header}>
                            <Button onClick={()=>{changeScrapCount(scrapCount-1)}} 
                                className={classes.leftButton} 
                                variant="outlined" 
                                color="primary"
                                >
                                {"-"}
                            </Button>  
                        </Grid>
                        <Grid item xs={4} className={classes.header}>
                            <TextField
                                id="count"
                                name="count"
                                label={phrases.modules.quality.scrapCountTextfield}
                                variant="outlined"
                                fullWidth
                                type="number"
                                className={classes.inputField}
                                onChange={()=>{}}
                                value={scrapCount}
                                margin="dense"
                            />
                            <Divider />
                        </Grid>
                        <Grid item xs={4} className={classes.header}>
                            <Button onClick={()=>{changeScrapCount(scrapCount+1)}} 
                                className={classes.rightButton} 
                                variant="outlined" 
                                color="primary"
                                >
                                {"+"}
                            </Button>
                        </Grid>
                        {isLoadingScrapTypes ? (<LoadingIndicator />) : (
                            <>                            
                            {scrapTypesByCategory.map((scrap) => {
                                let gridWidth = 3;
                                if(scrapTypesByCategory.length <=4)
                                    gridWidth = parseInt(12/scrapTypesByCategory.length);

                                return (                    
                                    <Grid item xs={gridWidth}>
                                        <Button onClick={()=>{createQualityReportEasyScrap(scrap)}} 
                                            className={classes.scrapButton} 
                                            variant="contained" 
                                            color="primary"
                                            >
                                            {scrapTypeText(scrap.identifier)}
                                        </Button>                        
                                    </Grid>
                                )
                            })}
                            </>
                            )}                                
                    </Grid>
            </>)}
        />
    </> );
}
 
export default EasyScrapModal;