import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateRoleForm = () => {
  const phrases = usePhrases().phrases();
  const validateRoleForm = (values) => {
    const errors = {};
    if (values.name.trim() === '') {
      errors.name = phrases.forms.user.errors.roleNameRequired;
    }

    return errors;
  };

  return {
    validateRoleForm,
  };
};

export default useValidateRoleForm;
