import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import {
    setTimespanStart,
    setTimespanEnd,
    setIsRelativeTimespan,
    setSelectedTimespanText,
    setSelectedRelativeTimespan,
    setIsCustomPreset,
  } from '@frontend/utils/UIActions';
import useAlert                     from '@frontend/hooks/useAlert';
import useApi                       from '@frontend/utils/useApi';
import useDateTimeFormat            from '@frontend/utils/useDateTimeFormat';
import { usePhrases }               from '@frontend/utils/usePhrases';

const useTimePresets = (selectTimeframe, onTimespanSelect) => {

    // ===== Redux Selectors =====
    const machines = useSelector((state) => state.machines);
    const timespanStart = useSelector((state) => state.timespanStart);
    const timespanEnd = useSelector((state) => state.timespanEnd);
    const structureInUse = useSelector((state) => state.structureInUse);        //ID-String

    // ===== State Variables =====
    // Relative timeframe that is selected in the dialog
    const [customTimespan, setCustomTimespan] = useState({ title: "", begin: timespanStart, end: timespanEnd, structure: structureInUse });
    const [presets, setPresets] = useState([{ title: "Test", begin: timespanStart, end: timespanEnd, structure: structureInUse }])
    const [customDay, setCustomDay] = useState(moment().toDate());

    // ===== Imports =====
    const dispatch = useDispatch();
    const api = useApi()
    const phrases = usePhrases().phrases();
    const { formatTime } = useDateTimeFormat();
    const { createAlert } = useAlert();

    // ===== Effects =====
    useEffect(() => {
        const cancelAxios = axios.CancelToken.source();
        api(`/api/time_presets?structure=${structureInUse}`, {
            method: 'get',
            cancelToken: cancelAxios.token,
        }).then((response) => {
            if(response.status === 200 || response.status === 304){
                setPresets([...response.data]);
            }

        }).catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ===== Helper Methods =====
    const handleBeginTimeChange = (_begin) => {
        setCustomTimespan({
          ...customTimespan,
          begin: _begin
        })
    }

    const handleEndTimeChange = (_end) => {
        setCustomTimespan({
            ...customTimespan,
            end: _end
        })
    }

    const handleTitleChange = (event) => {
        setCustomTimespan({
            ...customTimespan,
            title: event.target.value
        })
    }

    const handleCustomPresetClick = (timespan) => {
        // Use the current date
        let day = moment().format("YYYY-MM-DD");
        let tsBegin = moment(day + ` ${formatTime(timespan.begin)}`);
        let tsEnd = moment(day + ` ${formatTime(timespan.end)}`);
        setCustomTimespan({
            ...customTimespan,
            title: timespan.title,
            begin: tsBegin,
            end: tsEnd
        });
    };

    const validateNewPreset = (newPreset) => {
        let flag = true;
        presets.forEach((elem) => {
            if(elem.title === newPreset.title){
                createAlert(phrases.timespanSelection.ctpTitleAlreadyPresent, "error");
                flag = false;
            }

        });
        if (!newPreset.title || newPreset.title === ""){
            createAlert(phrases.timespanSelection.ctpTitleEmpty, "error");
            flag = false;
        }
        return flag;
    }

    const handleAddNewPreset = () => {
        if(!validateNewPreset(customTimespan)){
            return ;
        }
        const cancelAxios = axios.CancelToken.source();
        api(`/api/time_presets/`, {
            method: 'post',
            cancelToken: cancelAxios.token,
            data: {
                ...customTimespan
            }
        }).then((response) => {
            if (response.status === 201 || response.status === 304) {
                setPresets([...presets, response.data]);
            }
        }).catch((error) => {
            if (!axios.isCancel(error)){
                console.log(error);
            }
        });
    }

    const handleDeletePreset = (preset) => {
        const cancelAxios = axios.CancelToken.source();
        api(`/api/time_presets/`, {
            method: 'delete',
            cancelToken: cancelAxios.token,
            data: {
                ...preset
            }
        }).then((response) => {
            if (response.status === 204 || response.status === 304) {
                const myshifts = presets.filter((item) => {
                    return item.title !== preset.title
                })
                setPresets(myshifts);
            }
        }).catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
        });
    }

    const handlePresetDayChange = (newDate) => {
        const day = moment(newDate).format("YYYY-MM-DD")
        const newEnd = moment(day + ` ${formatTime(customTimespan.end)}`)
        const newStart = moment(day + ` ${formatTime(customTimespan.begin)}`)
        console.log(newStart, newEnd);
        setCustomDay(newDate);
        setCustomTimespan({
            ...customTimespan,
            end: newEnd.toDate(),
            begin: newStart.toDate()
        });

    }

    const selectedCustomTimeframeIsEqual = (timespan) => {
        // Compare using the unix time! Object Equality not working using Date objects
        return (customTimespan.title === timespan.title
            && formatTime(customTimespan.begin) === formatTime(timespan.begin)
            && formatTime(customTimespan.end) === formatTime(timespan.end))
    };

    const formatSelectedTimespanText = (ts, start, end) => {
        // This has to be weak check since the value in the field is a String
        // eslint-disable-next-line eqeqeq
        return `${ts.title} - ${start.format("YYYY-MM-DD")} ${formatTime(start)}-${formatTime(end)}`;
    };

    const validateCustomTimespan = () => {
        const now = moment();
        const day = moment(customDay).format("YYYY-MM-DD");
        let momentTsStart = moment(day + ` ${formatTime(customTimespan.begin)}`);
        let momentTsEnd = moment(day + ` ${formatTime(customTimespan.end)}`)

        if(momentTsStart.isSameOrAfter(momentTsEnd, "seconds")){
            momentTsEnd.add(1, "days");
        }

        if(momentTsEnd.isSameOrAfter(now, "seconds") && momentTsStart.isSameOrAfter(now, "seconds")){
            momentTsEnd.subtract(1, "days");
            momentTsStart.subtract(1, "days");
        } else if(momentTsEnd.isSameOrAfter(now, "seconds")){
            // Only the end is in the future, set the current time as end
            momentTsEnd = now;
        }

        return {
            momentTsStart,
            momentTsEnd
        };
    }

    const updatePulse = () => {
        // Set the Shift time
        const {
            momentTsStart,
            momentTsEnd
        } = validateCustomTimespan(customTimespan);
        dispatch(setSelectedTimespanText(formatSelectedTimespanText(customTimespan, momentTsStart, momentTsEnd)));
        setTimespanStart(momentTsStart.toDate());
        setTimespanEnd(momentTsEnd.toDate());

        dispatch(setIsCustomPreset(true));
        dispatch(setIsRelativeTimespan(false));
        dispatch(setSelectedRelativeTimespan({ amount: 0, unit: '' }));

        if (selectTimeframe) selectTimeframe(machines, momentTsStart.toDate(), momentTsEnd.toDate());
        if (onTimespanSelect) { onTimespanSelect(momentTsStart.toDate(), momentTsEnd.toDate(), undefined, undefined, false); }
    };
    return {
        handleBeginTimeChange,
        handleEndTimeChange,
        handleCustomPresetClick,
        handleTitleChange,
        handlePresetDayChange,
        handleDeletePreset,
        selectedCustomTimeframeIsEqual,
        formatSelectedTimespanText,
        validateCustomTimespan,
        updatePulse,
        presets,
        customTimespan,
        handleAddNewPreset,
        customDay
    };
}

export default useTimePresets;
