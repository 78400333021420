/* eslint-disable react/display-name */
import React, { useState }  from 'react';
import { useSelector }      from 'react-redux';
import { Link }             from 'react-router-dom';

import Accordion        from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Hidden           from '@material-ui/core/Hidden';
import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import {
  Grid,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LaunchIcon     from '@material-ui/icons/Launch';

import { FontAwesomeIcon }from '@fortawesome/react-fontawesome';
import { 
  faInfoCircle,
  faWaveSquare,
  faWifi
} from '@fortawesome/free-solid-svg-icons';

import Modal                  from '@frontend/components/Modal'; 
import useStatesFilter        from '@frontend/hooks/useStatesFilter';
import useMachineKPIs         from '@frontend/hooks/useMachineKPIs';
import useMachineStats        from '@frontend/hooks/useMachineStats';
import useUnitCalculation     from '@frontend/hooks/useUnitCalculation';
import loader                 from '@frontend/loader/loader';
import StatusesModalContent   from '@frontend/modules/machine/machine.statuses/StatusesModalContent';
import MachinePulseBar        from '@frontend/modules/machine/MachinePulseBar';
import MachineIndicator       from '@frontend/modules/machine/MachineIndicator';
import useMachineJob          from '@frontend/modules/orders/hooks/useMachineJob'
import EasyScrapButton        from '@frontend/modules/quality/components/EasyScrapButton';
import QualityReportsList     from '@frontend/modules/quality/QualityReportsList';
import SensorIndicator        from '@frontend/modules/sensor/SensorIndicator';
import StructureIndicator     from '@frontend/modules/structure/StructureIndicator';
import useConditionalRender   from '@frontend/utils/useConditionalRender';
import useDateTimeFormat      from '@frontend/utils/useDateTimeFormat';
import { usePhrases }         from '@frontend/utils/usePhrases';
import useSecurity            from '@frontend/utils/useSecurity';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  machinePulse: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(0),
    width: '100%',
    textAlign: 'left',
  },
  machineCollapseDivider: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  machineKPI: {
    display: 'inline',
    float: 'right',
    paddingLeft: theme.spacing(1),
    paddingTop: "4pt",
  },
  machineKPIOEE: {
    display: 'inline',
    float: 'right',
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    paddingTop: "4pt",
  },
  easyScrapButton: {
    float: 'right',
    fontWeight: 'bold',
  },
  jobs: {
    fontWeight: 'bold',
    display: 'inline',
    paddingLeft: theme.spacing(2),
  },
  orange: {
    color: theme.palette.primary.main,
  },
  green: {
    color: '#3bb273',
  },
  red: {
    color: '#d62828CC',
  },
  yellow: {
    color: '#FED766',
  },
  inline: {
    display: 'inline',
  },
  sensorList: {
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
  loadingIndicator: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: 100,
    position: 'absolute',
    margin: 'auto',
  },
  marginTop: {
    marginTop: theme.spacing(-1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1.5),
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  partsProducedLine: {
    display: 'inline',
    marginLeft: theme.spacing(2),
  },
  comment: {
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  },
}));

const ExpansionPanel = withStyles((theme) => ({
  root: {
    boxShadow: 3,
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '4px 0',

    },

  },
  expanded: {},
}))(Accordion);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    marginBottom: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '5px 0',

    },
  },
  expanded: {},
}))(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

  },
}))(AccordionDetails);

const MachineCollapse = ({
  key, structure, machine, sensors, refreshPulse, overview
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const theme = useTheme();
  const { getProfile, isSystemAdmin } = useSecurity();
  const { conditionalRender } = useConditionalRender();
  const { formatDate, formatHoursMinutesDuration, toLocaleNumber } = useDateTimeFormat();
  const { moduleLoaded } = loader();  

  const {
    jobOfMachine,
  } = useMachineJob(machine);

  const machineStates = useSelector((state) => state.machineStates);
  const { stateText, stateColor } = useStatesFilter();

  const {
    getMachineOEE,
    getMachineAvailability,
    getMachinePerformance,
    getMachineQuality,
    getAvgPartsPerMinute
  } = useMachineKPIs();

  const {
    getMachineDowntimePercentage,
    getMachineRuntimePercentage,
    getMachineLastState,
    getMachineActualCount,
    getMachineIdealCount,
  } = useMachineStats();

  const { 
    renderUnit,
    renderUnitName
  } = useUnitCalculation();
  const handleCloseModal = () => {
    setModalOpen(false);
  }
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const formatPercentage = (percentage) => typeof percentage === 'number' ? `${Math.round(percentage)}%` : percentage;

  function getRssiString(rssi) {
    if (rssi < -80) {
      return phrases.modules.sensor.rssiWeak;
    }
    if (rssi <= -70 && rssi > -80) {
      return phrases.modules.sensor.rssiFair;
    }
    if (rssi <= -50 && rssi > -70) {
      return phrases.modules.sensor.rssiGood;
    }
    return phrases.modules.sensor.rssiExcellent;
  }

  function getRssiClassname(rssi) {
    if (rssi == null) {
      return classes.red;
    }

    if ((rssi < -80) || (rssi <= -70 && rssi > -80)) {
      return classes.yellow;
    }

    return classes.green;
  }

  return (
    <div key={key}>
      <ExpansionPanel
        expanded={expanded}
        elevation={2}
        square
      >
        <ExpansionPanelSummary
          style={{
            cursor: 'default',
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{ onClick: handleExpand }}
          aria-controls="panel-content"
          id="panela-header"
        >
          <div className={classes.machinePulse}>
            <Hidden xsDown>
              <MachineIndicator link machine={machine} />
              <StructureIndicator structure={structure} machineStructure={machine.structure} />
              { machine.config?.externalLink && (
              <IconButton href={machine.config.externalLink} target="_blank" variant="outlined" size="small">
                <LaunchIcon fontSize={'small'}/>
              </IconButton>
              )}
              <div className={classes.sensorList}>
                {/* <Typography className={classes.inline} variant="body2" color="textSecondary">
                  {phrases.forms.shared.fields.sensors}
                              :
                            </Typography> */}
                {sensors.map((sensor) => (sensor.machine.id === machine.id
                  ? <SensorIndicator sensor={sensor} />
                  : ''))}
              </div>
              <FontAwesomeIcon 
                className={classes.comment}
                icon={faInfoCircle} 
                onClick={() => {
                    setModalOpen(true)
              }}/> 
              <Typography className={classes.partsProducedLine} variant="body2">
                {phrases.modules.performance.partsProduced}
                {' '}
                /
                {' '}
                {phrases.modules.performance.target}
                {': '}
                {toLocaleNumber(Math.round(getMachineActualCount(machine, machineStates)))} / {toLocaleNumber(Math.round(getMachineIdealCount(machine, machineStates)))}
              </Typography>
              <Typography className={classes.partsProducedLine} variant="body2">
                {phrases.modules.performance.avgSign}
                {': '}
                {renderUnit(getAvgPartsPerMinute(machine, machineStates), "PPM")}
                {' '}
                {renderUnitName()}
              </Typography>
              {jobOfMachine.id !== undefined ? (
              <Typography className={classes.jobs} variant="body2">
                {phrases.modules.performance.jobs}
                {': '}
                <Link to={`/orders/job/${jobOfMachine.businessJobId}`} key={`${jobOfMachine.businessJobId}-lnk`} className={classes.orange}>
                  {jobOfMachine.businessJobId}             
                </Link>
              </Typography>)
              : ''}
              <Typography className={classes.machineKPI} variant="body2">
                {phrases.modules.performance.availabilityShort}
                            :
                            {' '}
                {formatPercentage(getMachineAvailability(machine, machineStates))}
              </Typography>
              {conditionalRender(moduleLoaded('performance'), (<Typography className={classes.machineKPI} variant="body2">
                {phrases.modules.performance.performanceShort}
                            :
                {' '}
                {formatPercentage(getMachinePerformance(machine, machineStates))}
              </Typography>))}
              {conditionalRender(moduleLoaded('performance'), (<Typography className={classes.machineKPI} variant="body2">
                {phrases.modules.performance.qualityShort}
                            :
                {' '}
                {formatPercentage(getMachineQuality(machine, machineStates))}
              </Typography>))}
              <Typography className={classes.machineKPIOEE} variant="body2">
                OEE:
                            {' '}
                {formatPercentage(getMachineOEE(machine, machineStates))}
              </Typography>
              <EasyScrapButton
                  machine={machine}
                  machineStates={machineStates}
                  refreshPulse={refreshPulse}
              />
              <MachinePulseBar
                machine={machine}
                data={machineStates[machine.id] ? machineStates[machine.id].states : []}
                refreshPulse={refreshPulse}
                overview={overview}
              />
            </Hidden>

            <Hidden smUp>

              <Grid
                container
                spacing={2}
              // style={{
              //   borderTop: '2px solid',
              //   borderColor: stateColor(getMachineLastState(machine, machineStates)),
              // }}

              >

                <Grid
                  item
                  xs={7}
                  align="left"

                >
                  <MachineIndicator
                    machine={machine}
                    link
                  />

                </Grid>

                <Grid
                  item
                  xs={5}
                  align="right"
                  style={{ paddingTop: theme.spacing(2) }}
                >

                  <Typography
                    variant="body2"
                    style={{ color: stateColor(getMachineLastState(machine, machineStates)) }}
                  >
                    {stateText(getMachineLastState(machine, machineStates))}
                  </Typography>

                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={8}
                >
                  <Typography variant="body2" display="inline">
                    A
                                {' : '}
                    {formatPercentage(getMachineAvailability(machine, machineStates))}
                  </Typography>

                  {conditionalRender(moduleLoaded('performance'), (<Typography variant="body2" display="inline">
                    {' '}
                                P
                    {' : '}
                    {formatPercentage(getMachinePerformance(machine, machineStates))}
                  </Typography>))}

                  {conditionalRender(moduleLoaded('performance'), (<Typography variant="body2" display="inline">
                    {' '}
                                Q
                    {' : '}
                    {formatPercentage(getMachineQuality(machine, machineStates))}
                  </Typography>))}

                </Grid>

                <Grid
                  item
                  xs={4}
                >

                  <Typography className={classes.machineKPIOEE} variant="body2" display="inline">
                    OEE
                                {': '}
                    {formatPercentage(getMachineOEE(machine, machineStates))}
                  </Typography>
                </Grid>
              </Grid>
            </Hidden>

          </div>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails>

          <Grid container spacing={0}>

            <Hidden xsDown>
              <Grid item xs={12} md>

                <List subheader={(
                  <ListSubheader disableGutters>
                    {phrases.tables.shared.machineInformation}
                    {' '}
                    {conditionalRender(isSystemAdmin(getProfile()), `(${machine.id})`)}
                  </ListSubheader>
                )}
                >

                  <Divider />

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="machine-name" primary={phrases.forms.shared.fields.name} />
                    <ListItemSecondaryAction>
                      {machine.name}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="machine-alias" primary={phrases.forms.shared.fields.alias} />
                    <ListItemSecondaryAction>
                      {machine.alias ? machine.alias : '-'}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="machine-type" primary={phrases.forms.shared.fields.type} />
                    <ListItemSecondaryAction>
                      {machine.type ? machine.type.name : '-'}
                    </ListItemSecondaryAction>
                  </ListItem>

                </List>

              </Grid>

              <Divider orientation="vertical" variant="middle" className={classes.machineCollapseDivider} />

              <Grid item xs={12} md>
                <List subheader={<ListSubheader disableGutters>{phrases.forms.shared.fields.sensors}</ListSubheader>}>
                  <Divider />
                  {sensors.map((sensor) => (sensor.machine.id === machine.id
                    ? (
                      <>
                        <ListItem disableGutters dense>
                          <ListItemIcon>
                            <FontAwesomeIcon
                              icon={faWaveSquare}
                              size="lg"
                              className={sensor.online ? classes.green : classes.red}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={sensor.sensorId}
                            secondary={sensor.type ? sensor.type.name : ''}
                          />
                          <ListItemSecondaryAction>
                            {sensor.online ? phrases.modules.machine.online : `${phrases.modules.machine.lastSeen}: ${sensor.lastSeenAt ? formatDate(sensor.lastSeenAt) : phrases.modules.machine.never} `}
                          </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem disableGutters dense>
                          <ListItemIcon>
                            <FontAwesomeIcon
                              icon={faWifi}
                              size="lg"
                              className={sensor.rssi ? getRssiClassname(sensor.rssi.rssi) : classes.red}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={sensor.ssid ? sensor.ssid.ssid : phrases.modules.sensor.notConnected}
                            secondary={(
                              <Typography display="block" variant="body2">
                                {sensor.rssi ? sensor.rssi.rssi : 0}
                                {' '}
                                            dBm
                              </Typography>
                            )}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="body2" display="block">
                              {phrases.modules.sensor.signalStrength}
                                          :
                                        </Typography>
                            <Typography variant="body2" display="block" className={sensor.rssi ? getRssiClassname(sensor.rssi.rssi) : classes.red} align="right">
                              {
                                sensor.rssi

                                  ? getRssiString(sensor.rssi.rssi)

                                  : phrases.modules.sensor.noSignal
                              }
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </>
                    )
                    : ''))}

                </List>

              </Grid>

              <Divider orientation="vertical" variant="middle" className={classes.machineCollapseDivider} />

              <Grid item xs={12} md>
                {moduleLoaded('quality')
                  && (
                    <QualityReportsList
                      machine={machine}
                      actualCount={getMachineActualCount(machine, machineStates)}
                      refreshPulse={refreshPulse}
                      parts
                      addButton
                    />
                  )}
              </Grid>
            </Hidden>

            <Hidden smUp>

              <Grid item xs={12}>

                <List
                  subheader={(
                    <Typography variant="body1" disableGutters className={classes.marginTop}>
                      {phrases.tables.shared.machineInformation}
                    </Typography>
                  )}
                >

                  <Divider className={classes.marginBottom} />

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="machine-path" primary="Structure" />
                    <ListItemSecondaryAction>
                      {machine.structure.path}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="machine-name" primary={phrases.forms.shared.fields.name} />
                    <ListItemSecondaryAction>
                      {machine.name}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="machine-alias" primary={phrases.forms.shared.fields.alias} />
                    <ListItemSecondaryAction>
                      {machine.alias ? machine.alias : '-'}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="machine-type" primary={phrases.forms.shared.fields.type} />
                    <ListItemSecondaryAction>
                      {machine.type ? machine.type.name : '-'}
                    </ListItemSecondaryAction>
                  </ListItem>

                </List>

              </Grid>

              <Grid item xs={12}>
                <List
                  subheader={(
                    <Typography variant="body1" disableGutters>
                      {phrases.misc.availability}
                    </Typography>
                  )}
                >

                  <Divider className={classes.marginBottom} />

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="switch-list-label-wifi" primary={phrases.misc.availability} />
                    <ListItemSecondaryAction>
                      {formatPercentage(getMachineAvailability(machine, machineStates))}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="switch-list-label-wifi" primary={phrases.modules.machine.runtime} />
                    <ListItemSecondaryAction>
                      {formatHoursMinutesDuration(getMachineRuntimePercentage(machine, machineStates))}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem disableGutters dense>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }} id="switch-list-label-wifi" primary={phrases.modules.machine.downtime} />
                    <ListItemSecondaryAction>
                      {formatHoursMinutesDuration(getMachineDowntimePercentage(machine, machineStates))}
                    </ListItemSecondaryAction>
                  </ListItem>

                </List>

              </Grid>

              <Divider />

              <Grid item xs={12}>
                <List
                  subheader={(
                    <Typography variant="body1" disableGutters>
                      {phrases.forms.shared.fields.sensors}
                    </Typography>
                  )}
                >

                  <Divider className={classes.marginBottom} />

                  {sensors.map((sensor) => (sensor.machine.id === machine.id
                    ? (
                      <>
                        <ListItem disableGutters dense>
                          <ListItemIcon>
                            <FontAwesomeIcon
                              icon={faWaveSquare}
                              size="lg"
                              className={sensor.online ? classes.green : classes.red}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={sensor.sensorId}
                            secondary={sensor.type ? sensor.type.name : ''}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="body2" display="block" align="right">
                              {sensor.online ? phrases.modules.machine.online : phrases.modules.machine.lastSeen}
                              {' '}
                            </Typography>
                            <Typography variant="body2" display="block" align="right">
                              {
                                sensor.online
                                  ? ''
                                  : sensor.lastSeenAt
                                    ? formatDate(sensor.lastSeenAt)
                                    : phrases.modules.machine.never
                              }
                            </Typography>

                          </ListItemSecondaryAction>

                        </ListItem>

                        <ListItem disableGutters dense>
                          <ListItemIcon>
                            <FontAwesomeIcon
                              icon={faWifi}
                              size="lg"
                              className={sensor.rssi ? getRssiClassname(sensor.rssi.rssi) : classes.red}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={sensor.ssid ? sensor.ssid.ssid : phrases.modules.sensor.notConnected}
                            secondary={(
                              <Typography display="block" variant="body2">
                                {sensor.rssi ? sensor.rssi.rssi : 0}
                                {' '}
                                            dBm
                              </Typography>
                            )}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="body2" display="block">
                              {phrases.modules.sensor.signalStrength}
                                          :
                                        </Typography>
                            <Typography variant="body2" display="block" className={sensor.rssi ? getRssiClassname(sensor.rssi.rssi) : classes.red} align="right">
                              {
                                sensor.rssi

                                  ? getRssiString(sensor.rssi.rssi)

                                  : phrases.modules.sensor.noSignal
                              }
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </>
                    )
                    : ''))}

                </List>

              </Grid>

            </Hidden>

          </Grid>

        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Modal
        modalTitle={phrases.modules.machine.legendModalTitle}
        open={
            modalOpen
        }
        className={classes.statusesModal}
        handleClose={handleCloseModal}
        content={(
            <StatusesModalContent
            machineId={machine.id} />                   
        )}
      />
    </div>
  );
};

export default MachineCollapse;
