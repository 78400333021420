import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const useStatusesModalContent = (machineId) => {
    const [pulseStates, setPulseStates] = useState([]);
    const [pulseReasons, setPulseReasons] = useState([]);
    const machineStates = useSelector((state) => state.machineStates)

    const collectPulseStatesInfo = (key) => {
        const cleanStates = machineStates[machineId]?.states.filter((state) => {
            return state[key] !== "";            
        });
        const statesOnly = Array.from(
            new Set(cleanStates.map((state) => {
                    return state[key];
                })
                )
        )
        return statesOnly;        
    }
    
    useEffect(() => {
        setPulseStates(collectPulseStatesInfo("presentation.status"));
        setPulseReasons(collectPulseStatesInfo("presentation.reason"));
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [machineStates]);

    return {
        pulseReasons,
        pulseStates
    };
}
 
export default useStatusesModalContent;