import React, { Component } from 'react';
import {
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@material-ui/core'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
am4core.addLicense("AM5C377488971");
class TimespanClock extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.phrases = props.phrases;
        this.timespanStart = props.timespanStart;
        this.formatTime = props.formatTime.bind(this);
        this.classes = props.classes;
    }
    componentDidMount(){
        this.chart = am4core.create(this.props.clockid, am4charts.GaugeChart);
        this.chart.exporting.menu = new am4core.ExportMenu();
        this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        this.chart.startAngle = -90;
        this.chart.endAngle = 270;

        let axis = this.chart.xAxes.push(new am4charts.ValueAxis());
        axis.min = 0;
        axis.max = 12;
        axis.strictMinMax = true;

        axis.renderer.line.strokeWidth = 8;
        axis.renderer.line.strokeOpacity = 1;
        axis.renderer.minLabelPosition = 0.05; // hides 0 label
        axis.renderer.inside = true;
        axis.renderer.labels.template.radius = 35;
        axis.renderer.axisFills.template.disabled = true;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.ticks.template.length = 12;
        axis.renderer.ticks.template.strokeOpacity = 1;

        // serves as a clock face fill
        let range = axis.axisRanges.create();
        range.startValue = 0;
        range.endValue = 12;
        range.grid.visible = false;
        range.tick.visible = false;
        range.label.visible = false;

        let axisFill = range.axisFill;
        axisFill.fillOpacity = 1;
        axisFill.disabled = false;
        axisFill.fill = am4core.color("#FFFFFF");

        // hands
        let hourHand = this.chart.hands.push(new am4charts.ClockHand());
        hourHand.radius = am4core.percent(60);
        hourHand.startWidth = 10;
        hourHand.endWidth = 10;
        hourHand.rotationDirection = "clockWise";
        hourHand.pin.radius = 8;
        hourHand.zIndex = 0;

        let minutesHand = this.chart.hands.push(new am4charts.ClockHand());
        minutesHand.rotationDirection = "clockWise";
        minutesHand.startWidth = 7;
        minutesHand.endWidth = 7;
        minutesHand.radius = am4core.percent(78);
        minutesHand.zIndex = 1;

        let secondsHand = this.chart.hands.push(new am4charts.ClockHand());
        secondsHand.fill = am4core.color("#DD0000");
        secondsHand.stroke = am4core.color("#DD0000");
        secondsHand.radius = am4core.percent(85);
        secondsHand.rotationDirection = "clockWise";
        secondsHand.zIndex = 2;
        secondsHand.startWidth = 1;
    }

    /**
     * Author: Julian Dreyer
     * This lifecycle method is called whenever the component is unmounted. It's important to
     * dispose the chart so that it will not be rendered on top of an old one.
     */
    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
    render() { 
        return ( 
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="button" className={this.classes.timespan} align="left" component="p">{this.props.heading}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <div id={this.props.clockid} style={{height: "300px"}}></div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>)
    }
}

export default TimespanClock;