import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useSecurity from '@frontend/utils/useSecurity';

import MDEditor from '@uiw/react-md-editor';
import useKnowledgeView from './hooks/useKnowledgeView';

const KnowledgeView = () => {
  const { knowledgeName } = useParams();
  const { isSystemAdmin } = useSecurity();
  const loggedInUser = useSelector((state) => state.user);  
  const { 
    content,
    setContent
  } = useKnowledgeView(knowledgeName);
  
  console.log("CONTENT: ", content);
  if(isSystemAdmin(loggedInUser)){
    return (
      <div className="container">
          <MDEditor
          value={content}
          onChange={setContent}
        />
        <MDEditor.Markdown source={content} />
      </div>
    );
  } else {
    return (
      <div className="container">
        <MDEditor.Markdown source={content} />
      </div>
    );
  }
};

export default KnowledgeView;
