export const storeListOfMachines = (machines) => ({
  type: 'STORE_LIST_MACHINES',
  machines,
});

export const storeMachineStates = (machineStates) => ({
  type: 'STORE_MACHINE_STATES',
  machineStates,
});

export const storeSingleMachine = (machine) => ({
  type: 'STORE_SINGLE_MACHINE',
  machine,
});

export const storeListOfMachineTypes = (machineTypes) => ({
  type: 'STORE_LIST_MACHINE_TYPES',
  machineTypes,
});

export const removeListOfMachineTypes = () => ({
  type: 'REMOVE_LIST_MACHINE_TYPES',
});
