import moment from 'moment';
import React, { useState, useEffect } from 'react';
import useApi from '@frontend/utils/useApi';
import { TERMINAL_PING_INTERVAL } from '@frontend/constants';
import { usePhrases } from '@frontend/utils/usePhrases';
import { 
    Divider,
    Typography,
  } from '@material-ui/core';
const useOnlineIndicator = () => {
    const [onlineStatus, setOnlineStatus] = useState({
        systems: {                    
            browserOnline: navigator.onLine,
            influxOnline: true,
            mongoOnline: true,
            postgresOnline: true,
            lfBackendOnline: true,
        },
        lastChecked: new Date(),
        health: 5
    });

    const api = useApi();
    const phrases = usePhrases().phrases();

    async function checkInfluxStatus() {
        const response = api('/api/shared/influx-status', {
            method: 'get',
        })
        .then((response) => {
            return response.status === 200                
        }).catch((_error) => {
            return false;
        });
        return response;
    }

    async function checkMongoStatus() {
        const response = api('/api/shared/mongo-status', {
            method: 'get',
        })
        .then((response) => {
            return response.status === 200                
        }).catch((_error) => {
            return false;
        });
        return response;
    }

    async function checkPostgresStatus() {
        const response = api('/api/shared/postgres-status', {
            method: 'get',
        })
        .then((response) => {
            return response.status === 200                
        }).catch((_error) => {
            return false;
        });
        return response;
    }

    async function checkLFStatus() {
        const response = api('/api/shared/lf-status', {
            method: 'get',
        })
        .then((response) => {
            return response.status === 200                
        }).catch((_error) => {
            return false;
        });
        return response;
    }

    const getTooltipText = () => (
        <>
            <Typography color="inherit" variant="body2">
            {phrases.modules.terminal.internet}: {onlineStatus.systems.browserOnline ? phrases.modules.terminal.connected : phrases.modules.terminal.mongodbData}
            </Typography>
            <Divider />
            <Typography color="inherit" variant="body2">
            {phrases.modules.terminal.machineData}: {onlineStatus.systems.influxOnline ? phrases.modules.terminal.connected : phrases.modules.terminal.mongodbData}
            </Typography>
            <Divider />
            <Typography color="inherit" variant="body2">
                {phrases.modules.terminal.mongodbData}: {onlineStatus.systems.mongoOnline ? phrases.modules.terminal.connected : phrases.modules.terminal.mongodbData}
            </Typography>
            <Divider />
            <Typography color="inherit" variant="body2">
            {phrases.modules.terminal.postgresData}: {onlineStatus.systems.postgresOnline ? phrases.modules.terminal.connected : phrases.modules.terminal.mongodbData}
            </Typography>
            <Divider />
            <Typography color="inherit" variant="body2">
            {phrases.modules.terminal.lfBackend}: {onlineStatus.systems.lfBackendOnline ? phrases.modules.terminal.connected : phrases.modules.terminal.mongodbData}
            </Typography>
            <Divider />
            <Typography color="inherit" variant="body2">
            {phrases.modules.terminal.lastCheck}: {moment(onlineStatus.lastChecked).format() }
            </Typography>
        </>
      );

    const checkOnlineStatus = async () => {
        let condition = {
            systems: {                    
                browserOnline: navigator.onLine,
                influxOnline: await checkInfluxStatus(),
                mongoOnline: await checkMongoStatus(),
                postgresOnline: await checkPostgresStatus(),
                lfBackendOnline: await checkLFStatus(),
            },
            lastChecked: new Date(),
        }
        condition.health = Object.keys(condition.systems).length;

        Object.keys(condition.systems).forEach((key) => {
            if (!condition.systems[key]) {
                condition.health -= 1;
            }
        });
        
        // condition.health = parseInt(Math.random()*10) % 6
        setOnlineStatus(condition);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkOnlineStatus();
        }, TERMINAL_PING_INTERVAL);
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        onlineStatus,
        setOnlineStatus,
        getTooltipText
    };
}
 
export default useOnlineIndicator;