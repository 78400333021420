import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateJobEditForm = () => {
  const phrases = usePhrases().phrases();

  const isNullish = (value) => value === undefined || value === null || value === "" || value === " "
  const validateJobEditForm = (values) => {
    const errors = {};

    if (isNullish(values.handCount)) {
      errors.handCount = phrases.forms.jobs.validate.handCountRequired;
    }

    if (!values.description) {
      errors.description = phrases.forms.jobs.validate.descriptionRequired;
    }

    if (!values.completionStatus) {
      errors.completionStatus = phrases.forms.jobs.validate.completionStatusRequired;
    }

    if (!values.intendedMachine) {
      errors.intendedMachine = phrases.forms.jobs.validate.intendedMachineRequired;
    }

    if (isNullish(values.targetQuantity)) {
        errors.targetQuantity = phrases.forms.jobs.validate.targetQuantityRequired;
    }

    if (isNullish(values.targetSetupTime)) {
        errors.targetSetupTime = phrases.forms.jobs.validate.targetSetupTimeRequired;
    }

    if (isNullish(values.targetCycleTime)) {
        errors.targetCycleTime = phrases.forms.jobs.validate.targetCycleTimeRequired;
    }

    if (!values.product) {
        errors.product = phrases.forms.jobs.validate.productRequired;
    }

    return errors;
  };

  return {
    validateJobEditForm,
  };
};

export default useValidateJobEditForm;
