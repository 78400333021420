import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ContactlessIcon from '@material-ui/icons/Contactless';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import BlockIcon from '@material-ui/icons/Block';
import { withStyles } from '@material-ui/core/styles';

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: '24px',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
};

class CustomToolbarSelect extends React.Component {
  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleResendMachineConfig = () => {
    this.props.resendMachineConfig(this.props.selectedRows);
  };

  handleDeactivateMachines = () => {
    this.props.deactivateMachines(this.props.selectedRows);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title="Deselect ALL">
          <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll}>
            <IndeterminateCheckBoxIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Deactivate machines">
          <IconButton className={classes.iconButton} onClick={this.handleDeactivateMachines}>
            <BlockIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Resend machine config">
          <IconButton className={classes.iconButton} onClick={this.handleResendMachineConfig}>
            <ContactlessIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: 'CustomToolbarSelect' })(CustomToolbarSelect);
