/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MachineStatusForm from '@frontend/modules/machine/machine.statuses/MachineStatusForm';
import Table from '@frontend/components/Table';
import Modal from '@frontend/components/Modal';
import useApi from '@frontend/utils/useApi';
import useAlert from '@frontend/hooks/useAlert';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity from '@frontend/utils/useSecurity';
import { usePhrases } from '@frontend/utils/usePhrases';
import { persistTableState } from '@frontend/utils/UIActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  colorButton: {
    height: theme.spacing(3),
    width: theme.spacing(10),
    cursor: 'default',
    border: '1px solid grey',
  },
}));

export default function MachineStatusesManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const api = useApi();
  const phrases = usePhrases()
    .phrases();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const { createAlert } = useAlert();
  // let cancelAxios = null;

  const [machineStatusToEdit, setMachineStatusToEdit] = useState({});
  const [machineStatuses, setMachineStatuses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const tableState = useSelector((state) => state.tableState);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    dispatch(persistTableState({}));
    return () => {
      cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editMachineStatus = (machineStatus) => {
    setMachineStatusToEdit(machineStatus);
    handleOpenModal();
  };

  const columns = [
    {
      label: phrases.forms.machineStatus.fields.name,
      name: 'name',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'name' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.machineStatus.fields.activity,
      name: 'activity',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'activity' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>
        ),
      },
    },
    {
      label: phrases.forms.machineStatus.fields.color,
      name: 'color',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'color' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : (
              <div>
                <Button
                  disableRipple
                  className={classes.colorButton}
                  style={{ backgroundColor: value }}
                />
              </div>
            )

        ),
      },
    },
    {
      label: phrases.forms.machineStatus.fields.category,
      name: 'category',
      options: {
        filter: true,
        filterList: tableState.filterList ? tableState.filterList[3] : [],
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'category' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.machineStatus.fields.dataLayer,
      name: 'dataLayer',
      options: {
        filter: true,
        filterList: tableState.filterList ? tableState.filterList[4] : [],
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'dataLayer' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    ...conditionalElement(isAuthorized('machine_statuses', ['update:any']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => editMachineStatus(machineStatuses.find((machineStatus) => machineStatus.id === value))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    }),
  ];

  useEffect(() => {
    setIsLoading(true);
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines/machine-statuses', {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setMachineStatuses(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const machineStatus = machineStatuses[index];
      api(`/api/machines/machineStatuses/${machineStatus.id}`, {
        method: 'delete',
        cancelToken: cancelAxios.token,
      })
        .then((response) => {
          if (response.status === 204) {
            machineStatuses.splice(index, 1);
            setMachineStatuses([...machineStatuses]);
            createAlert(phrases.forms.machineStatus.success.machineStatusDeleted, 'success');
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
        });
    });
    return false;
  };

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machines/machine-statuses', {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setMachineStatuses(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
  };

  // const handleCreate = () => {
  //   cancelAxios = axios.CancelToken.source();
  //   api('/api/machines/machine-statuses', {
  //     method: 'get',
  //     cancelToken: cancelAxios.token,
  //   })
  //     .then((response) => {
  //       setMachineStatuses(response.data);
  //     })
  //     .catch((error) => {
  //       if (!axios.isCancel(error)) console.log(error);
  //     });
  // };

  const getDirection = (direction) => {
    switch (direction) {
      case 'ascending':
        return 'asc';
      case 'descending':
        return 'desc';
      default:
        return 'asc';
    }
  };

  const customOptions = {
    filterType: 'checkbox',
    print: false,
    download: false,
    elevation: 0,
    selectableRows: isAuthorized('reasons', ['delete:any']) ? 'multiple' : 'none',
    onRowsDelete: (obj) => handleDelete(obj),
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({
        ...tableState,
        filterList,
      }));
    },
    onChangePage: (page) => {
      dispatch(persistTableState({
        ...tableState,
        page,
      }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
  };

  return (
    <div className={classes.root}>
      <Table
        modalTitle={phrases.tables.shared.machineStatuses}
        data={machineStatuses}
        resource="machine_statuses"
        onDelete={handleDelete}
        columns={columns}
        customOptions={customOptions}
        isLoading={isLoading}
      />
      <Modal
        modalTitle={phrases.modal.EditMachine}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <MachineStatusForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            machineStatus={machineStatusToEdit}
          />
        )}
      />
    </div>
  );
}
