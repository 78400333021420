/* eslint-disable import/prefer-default-export */
import {GROUP_STATE_LIMITS} from '@frontend/constants'
const flatten = (array) => {
  let flattenArray = [];
  for (let i = 0; i < array.length; i++) {
    if (Array.isArray(array[i])) {
      flattenArray = flattenArray.concat(flatten(array[i]));
    } else {
      flattenArray.push(array[i]);
    }
  }
  return flattenArray;
};

const cycleTimeToPartsPerMinute = (cycleTime) => {
  if (!cycleTime) return 'N/A';
  return `${Math.abs(60 / cycleTime).toFixed(2)}`;
};

const cycleTimeToSecondsPerPart = (cycleTime) => {
  if (!cycleTime) return 'N/A';
  return `${Math.abs(cycleTime).toFixed(2)}`;
};
const groupOverviewStatuses = {
  "excellent":{
    name: "OEE: excellent",
    color: "#388e3c",
    activity: "excellent"
  },
  "good": {
    name: "OEE: good",
    color: "#889e4f",
    activity: "good"
  },  
  "medium":{
    name: "OEE: medium",
    color: "#e7b416",
    activity: "medium"
  },
  "bad": {
    name: "OEE: bad",
    color: "#db7b2b",
    activity: "bad"
  },
  "fatal": {
    name: "OEE: fatal",
    color: "#d32f2f",
    activity: "bad"
  },
  "none": {
    name: "OEE: none",
    color: "#999999",
    activity: "none"
  }

}

const needsGrouping = (duration) => {
  return duration > 1440;
}

const getGroupedState = (state) => {
  if(state.oee > GROUP_STATE_LIMITS.EXCELLENT){
    return groupOverviewStatuses.excellent;      
  } else if(state.oee > GROUP_STATE_LIMITS.GOOD){
    return groupOverviewStatuses.good;
  } else if(state.oee > GROUP_STATE_LIMITS.MEDIUM){
    return groupOverviewStatuses.medium;
  } else if(state.oee > GROUP_STATE_LIMITS.BAD){
    return groupOverviewStatuses.bad;
  } else if(state.oee > GROUP_STATE_LIMITS.FATAL){
    return groupOverviewStatuses.fatal;
  } else {
    return groupOverviewStatuses.none;
  }
}

const getDirection = (direction) => {
  switch (direction) {
    case 'ascending': return 'asc';
    case 'descending': return 'desc';
    default: return 'asc';
  }
};

export default {
  cycleTimeToPartsPerMinute,
  cycleTimeToSecondsPerPart,
  getGroupedState,
  flatten,
  needsGrouping,
  getDirection
}