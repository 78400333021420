/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

import { usePhrases } from '@frontend/utils/usePhrases';
import { ROUTES } from '@frontend/constants';

const useStyles = makeStyles((theme) => ({
  fieldset: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    color: '#757575',
    borderColor: '#F0F0F0',
    borderRadius: '5px',
  },
  helperText: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  legend: {
    marginTop: theme.spacing(4),
    color: '#757575',
  },
  formControl: {
    marginX: 'auto',
    minWidth: 120,
    width: '90%',
  },
  vAlign: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

function ViewsSelect(props) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div>
      <FormControl {...props} margin="dense" required={!!props.required} variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          {props.label}
        </InputLabel>
        <Select
          labelWidth={labelWidth}
          {...props}
        >
          {props.data && props.data.map((data) => <MenuItem key={data.name} value={data.view}>{data.name}</MenuItem>)}
        </Select>
        {props.helperText ? <FormHelperText className={classes.errorText}>{props.helperText}</FormHelperText> : ''}
      </FormControl>
    </div>
  );
}

function IdSelect(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div>
      <FormControl {...props} margin="dense" required={!!props.required} variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          {props.label}
        </InputLabel>
        <Select
          labelWidth={labelWidth}
          {...props}
        >
          <MenuItem key="none" value="">{phrases.forms.shared.fields.none}</MenuItem>
          {props.data && props.data.length ? props.data.map((data) => <MenuItem key={data.path ? data.path : data.name} value={data.id}>{data.path ? data.path : data.name}</MenuItem>)
            : <MenuItem key="none" value="" disabled>{phrases.modules.user.noStructures}</MenuItem>}
        </Select>
        {props.helperText ? <FormHelperText className={classes.errorText}>{props.helperText}</FormHelperText> : ''}
      </FormControl>
    </div>
  );
}

function HomeViewSelect(props) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div>
      <FormControl {...props} margin="dense" required={!!props.required} variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          {props.label}
        </InputLabel>
        <Select
          labelWidth={labelWidth}
          {...props}
        >
          {props.data && props.data.length ? props.data.map((data) => <MenuItem key={data.name} value={data.route}>{data.name}</MenuItem>)
            : <MenuItem key="none" disabled>No views for structure or machine assigned to user.</MenuItem>}
        </Select>
        {props.helperText ? <FormHelperText className={classes.errorText}>{props.helperText}</FormHelperText> : ''}
      </FormControl>
    </div>
  );
}



const _data = {
  structures: [],
  machines: [],
};

export default function DefaultViewsForm(props) {
  const phrases = usePhrases().phrases();
  const classes = useStyles();
  const [data, setData] = useState(_data);
  const {
    setUserValues,
    userValues,
    errors,
  } = props;

  const _views = {
    structures: [
      { name: phrases.modules.user.oeeOverview, view: ROUTES.STRUCTURE_OVERVIEW }, 
      { name: phrases.modules.user.andonBoard, view: ROUTES.ANDON_BOARD }, 
      { name: phrases.modules.user.comparisonOverview, view: ROUTES.COMPARISON},
      { name: phrases.modules.user.reportingOverview, view: ROUTES.REPORTING_VIEW},
      { name: phrases.modules.user.scheduleOverview, view: ROUTES.SCHEDULES},
    ],
    machines: [{ name: phrases.modules.user.machineOverview, view: ROUTES.MACHINE_OVERVIEW }],
    homeViews: [
      { name: phrases.modules.user.structureManagement, route: ROUTES.STRUCTURES_MANAGEMENT },
      { name: phrases.modules.user.machineManagement, route: ROUTES.MACHINES_MANAGEMENT },
      { name: phrases.modules.user.sensorManagement, route: ROUTES.SENSORS_MANAGEMENT },
      { name: phrases.modules.user.userManagement, route: ROUTES.USERS_MANAGEMENT },
    ],
  };

  const [views, setViews] = useState(_views);
  
  useEffect(() => {
    setData({
      structures: userValues.structures,
      machines: fetchMachinesFromStructures(userValues.structures.filter((structure) => structure)),
    });
    setHomeViewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userValues]);

  const fetchMachinesFromStructures = (structures) => _.flatten(structures.map((structure) => structure.machines)).filter((machine) => machine);

  // TODO: REFACTOR DOCUMENT
  const handleViewChange = (event) => {
    event.persist();
    const { target } = event;
    if (!target.value) return;
    if (errors.defaultView) { delete errors.defaultView; }

    setUserValues((userValues) => ({
      ...userValues,
      views: {
        ...userValues.views,
        [target.name]: {
          ...userValues.views[target.name],
          view: target.value,
        },
      },
    }));
  };

  // TODO: REFACTOR DOCUMENT
  const handleIdChange = (event) => {
    event.persist();
    const { target } = event;
    const { value } = target;
    if (errors.defaultView) { delete errors.defaultView; }

    setUserValues((userValues) => ({
      ...userValues,
      views: {
        ...userValues.views,
        [target.name]: {
          ...userValues.views[target.name],
          id: value,
        },
      },
    }));
  };

  // TODO: REFACTOR DOCUMENT
  const handleHomeViewChange = (event) => {
    event.persist();
    const { target } = event;
    const { value } = target;
    if (!value) return;
    if (errors.defaultView) { delete errors.defaultView; }

    setUserValues((userValues) => ({
      ...userValues,
      views: {
        ...userValues.views,
        [target.name]: value,
      },
    }));
  };

  const setHomeViewData = () => {
    setViews({
      ...views,
      homeViews: [
        ..._views.homeViews,
        ...Object.keys(userValues.views).map((resource) => {
          if (userValues.views[resource].view) {
            return { name: getViewName(userValues.views[resource].view), route: `${userValues.views[resource].view}/${userValues.views[resource].id}` };
          }

          return null;
        }).filter((view) => view)],
    });
  };

  const getViewName = (view) => {
    switch (view) {
      default: return '';
      case ROUTES.STRUCTURE_OVERVIEW: return 'OEE Overview';
      case ROUTES.ANDON_BOARD: return 'Andon Board';
      case ROUTES.MACHINE_OVERVIEW: return 'Machine Overview';
      case ROUTES.COMPARISON: return 'Comparison Overview';
      case ROUTES.REPORTING_VIEW: return 'Reporting Overview';
      case ROUTES.SCHEDULES: return 'Schedule Overview';

    }
  };

  return (
    <fieldset className={classes.fieldset}>
      <legend className={classes.legend}>{phrases.forms.user.fields.defaultView}</legend>
      <Grid container>
        <Grid xs="2" className={classes.vAlign}>
          <Typography variant="body1">
            {phrases.nav.structures}
            :
          </Typography>
        </Grid>
        <Grid xs="5">
          <ViewsSelect
            error={errors.structureView}
            helperText={errors.structureView}
            label={phrases.modules.user.view}
            labelId="default-view-label"
            id="structureView"
            name="structure"
            value={userValues.views.structure.view}
            onChange={handleViewChange}
            data={views.structures}
          />
        </Grid>
        <Grid xs="5">
          <IdSelect
            error={errors.structureView}
            helperText={errors.structureView}
            label={phrases.modules.user.structure}
            labelId="default-view-label"
            id="structureId"
            name="structure"
            value={userValues.views.structure.id}
            onChange={handleIdChange}
            data={data.structures}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs="2" className={classes.vAlign}>
          <Typography variant="body1">
            {phrases.modules.machine.machine}
            :
          </Typography>
        </Grid>
        <Grid xs="5">
          <ViewsSelect
            error={errors.machineView}
            helperText={errors.machineView}
            label={phrases.modules.user.view}
            labelId="default-view-label"
            id="machineView"
            name="machine"
            value={userValues.views.machine.view}
            onChange={handleViewChange}
            data={views.machines}
          />
        </Grid>
        <Grid xs="5">
          <IdSelect
            error={errors.machineId}
            helperText={errors.machineId}
            label={phrases.modules.user.machine}
            labelId="default-view-label"
            id="machineId"
            name="machine"
            value={userValues.views.machine.id}
            onChange={handleIdChange}
            data={data.machines}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs="2" className={classes.vAlign}>
          <Typography variant="body1">
            {phrases.nav.home}
            :
          </Typography>
        </Grid>
        <Grid xs="5">
          <HomeViewSelect
            error={errors.homeView}
            helperText={errors.homeView}
            label={phrases.modules.user.homeview}
            labelId="default-view-label"
            id="homeView"
            name="homeView"
            value={userValues.views.homeView}
            onChange={handleHomeViewChange}
            data={views.homeViews}
            required
          />
        </Grid>
      </Grid>
    </fieldset>
  );
}
