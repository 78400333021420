import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import { usePhrases } from '@frontend/utils/usePhrases';
import LOADING_PROPS from '@frontend/modules/orders/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '85%',
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  noteText: {
    paddingLeft: theme.spacing(2),
  },
  addNote: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

export default function NotesAccordion(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { notes, loadingPropsObject } = props;

  return (
    <div className={classes.root}>
      <Typography variant="button" display="block" className={classes.addNote}>
        {phrases.modules.orders.existingNotes} ({notes.length})
      </Typography>

      {notes.map((note) => (
        <Accordion key={note.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>{note.user}</Typography>
            <Typography className={classes.secondaryHeading}>
              {note.createdAt}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.noteText}>{note.text}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      {loadingPropsObject[LOADING_PROPS.ADDING_NOTE] && (
        <Skeleton variant="rect" width="100%" height={40} component="div" />
      )}
    </div>
  );
}
