import React, { Component } from 'react';
import ModuleNotFound from '@frontend/components/ModuleNotFound';
//TODO: For the love of god please somehow make this a functional component
export default class ModuleComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Component: null,
      moduleExists: true,
    };
  }

  componentDidMount() {
    const { path } = this.props;

    import(`../modules/${path}`)
      .then(module => this.setState({ Component: module.default }))
      .catch((error) => {
        console.log('Module not found');
      });
  }
  render() {
    const { Component, moduleExists } = this.state;
    const { componentProps } = this.props;
    return (
      <>
        {Component && moduleExists && <Component {...componentProps} />}
        {!moduleExists && <ModuleNotFound />}
      </>
    );
  }
};