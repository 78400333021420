/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from '@material-ui/core';

import { usePhrases } from '@frontend/utils/usePhrases';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({

  lastTableCell: {
    borderBottom: 'none',
  },
  cardCell: {
    padding: theme.spacing(1.5),
  },

}));

const StructureAvailabilityMachinesCard = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan)

  return (

    <Grid item xs={12} style={{ opacity: !isRelativeTimespan ? 0.1 : 1 }}>
      <Table aria-label="simple table" size="small">
        <TableHead>

          <TableRow>
            <TableCell className={classes.cardCell} align="left">
              <Typography variant="body1">
                {phrases.modules.structure.machines}
              </Typography>
            </TableCell>

            <TableCell className={classes.cardCell}>
              <Typography variant="body1" align="right">
                {isRelativeTimespan ? props.machines : '-'}
              </Typography>
            </TableCell>
          </TableRow>

        </TableHead>

        <TableBody>

          <TableRow>
            <TableCell className={classes.cardCell} align="left">
              {phrases.modules.machine.running}
              :
              {' '}
            </TableCell>
            <TableCell className={classes.cardCell} align="right">
              {isRelativeTimespan ? (
                <>
                  {' '}
                  {props.runningMachines ? props.runningMachines : 0}
                  {' '}
                  {'\u00A0'}
                  (
                  {props.runningMachinesPercentage ? props.runningMachinesPercentage : 0}
                  )
                  {' '}
                </>
              ) : '-'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.cardCell} align="left">
              {' '}
              {phrases.modules.machine.notRunning}
              :
              {' '}
            </TableCell>
            <TableCell className={classes.cardCell} align="right">
              {isRelativeTimespan ? (
                <>
                  {props.idleMachines ? props.idleMachines : 0}
                  {'\u00A0'}
                  (
                  {props.idleMachinesPercentage ? props.idleMachinesPercentage : 0}
                  )
                </>
              ) : '-'}
            </TableCell>

          </TableRow>
          <TableRow>
            <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="left">
              &nbsp;
          </TableCell>
            <TableCell className={`${classes.lastTableCell} ${classes.cardCell}`} align="right">
              &nbsp;
          </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </Grid>
  );
};

export default StructureAvailabilityMachinesCard;
