import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
    Button,
    ButtonGroup,
    CircularProgress,
    ClickAwayListener,
    Divider,
    FormControl,
    FormHelperText,
    Grid,    
    InputLabel,    
    MenuItem,    
    Paper,
    Popper,
    Select,
    Typography
}                       from '@material-ui/core';
import { makeStyles }   from '@material-ui/core/styles';
import {
    axisBottom,
    scaleTime,
    select
} from 'd3';

import { usePhrases }           from "@frontend/utils/usePhrases";
import useCurrentBreakpoint     from '@frontend/hooks/useCurrentBreakpoint';
import useStatesFilter          from '@frontend/hooks/useStatesFilter';
import useMergeStatesModal      from '@frontend/modules/machine/hooks/useMergeStatesModal';
import Modal                    from '@frontend/components/Modal';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      width: '100%',
    },
    formBtn: {
      marginRight: theme.spacing(1),
      alignSelf: 'flex-end',
    },
    addBtn: {
        justifyContent: 'center',
        margin: "auto",
        alignSelf: 'center',
        display: 'flex',
    },
    formButtons: {
      flexGrow: 1,
      display: 'flex',
      marginTop: theme.spacing(3),
    },
    loadingBtnIcon: {
      marginLeft: theme.spacing(1),
    },
    bold: {
      fontWeight: 900,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    statusTitle: {
      marginLeft: theme.spacing(1),
    },
    pulseWrapper: {
      marginTop: theme.spacing(1),
    },
    popupWrapper: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2),
      zIndex: 9999,
    },
    pulsePopup: {
      paddingTop: 0,
      padding: theme.spacing(1),
      paddingBottom: 0,
      marginBottom: 0,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  

const MergeStateModal = (props) => {
    const {
        open,
        selectedState,
        handleCloseModal,
        refreshPulse,        
      } = props;
      
    // ===== State Objects =====

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // ===== Redux Selectors =====
    // ===== Imports =====
    const classes   = useStyles();
    const phrases   = usePhrases().phrases();
    const svgRef        = useRef();
    const svgWrapperRef = useRef();
    const currentBreakpoint = useCurrentBreakpoint();
    const { 
        stateColor,
        stateText
    } = useStatesFilter();
    const {
        getPreviousState,
        getNextState,
        removeLastStateFromSelectedStates,
        removeFirstStateFromSelectedStates,
        selectedStates,
        getMinTime,
        getMaxTime,
        resetToSelectedState,
        newStatus,
        setNewStatus,
        createNewState,
        currentStatusValues,
    } = useMergeStatesModal(selectedState, refreshPulse);
    
    // ===== Effects =====
    useEffect(() => {
        renderDynamicState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStates, open]);

    // ===== Helper Methods =====
    const handleSetNewStatus = (event) => {
        setNewStatus(event.target.value);
    }
    const handleReset = () => {
        resetToSelectedState();
    };

    const onModalClose = () => {
        handleCloseModal();
    };
    const onModalOpen = () => {
        renderDynamicState();
    }

    const handleSubmit = () => {
        setIsSubmitting(true);
        createNewState();
        setIsSubmitting(false);
        onModalClose();
        refreshPulse();
    }

    const handleClose = () => {
        setTooltipOpen(false);
        setAnchorEl(null);
    }

    const handleCloseModalClick = () => {
        onModalClose();
    }

    // will be called initially and on every data change
    const renderDynamicState = () => {
        const svg = select(svgRef.current);
        const svgWrapper = svgWrapperRef.current;
        let wrapperWidth = svgWrapper ? svgWrapper.clientWidth : 0;

        // Erase chart so it can be redrawn with new data
        svg.selectAll('rect').remove();

        // No States provided, nothing to draw
        if (!selectedStates) return;

        // Min and Max time on the timeline
        const minTime = new Date(getMinTime());
        const maxTime = new Date(getMaxTime());

        // x scale, time scale
        const xScale = scaleTime()
            .domain([minTime, maxTime])
            .range([0, 400]);

        // x axis
        const xAxisBottom = axisBottom(xScale)
            .ticks(6)

        // position x axis
        const xAxis = svg.select('.x-axis')
            .transition()
            .duration(250)
            .style('transform', `translate(${wrapperWidth / 2 - 200}px,${50 - 7}px)`)
            .call(xAxisBottom);

        if (currentBreakpoint().down('sm')) {
            xAxis
                .selectAll('text')
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '.15em')
                .attr('transform', 'rotate(-45)');
        } else if (currentBreakpoint().up('sm')) {
            xAxis
                .selectAll('text')
                .style('text-anchor', 'end')
                .attr('dx', '1.46em')
                .attr('y', 9)
                .attr('dy', '0.71em')
                .attr('transform', 'rotate(0)');
        }

        // draw states
        svg.selectAll('rect')
            .data(selectedStates)
            .enter().append('rect')
            .attr('x', (state) => xScale(moment(state.startTime)))
            .attr('width', (_state) => {
                const stateStart = xScale(new Date(_state.startTime));
                const stateEnd = xScale(new Date(_state.endTime));
                const width = stateEnd - stateStart;
                return width < 0 ? 0 : width;
            })
            .style('transform', `translate(${wrapperWidth / 2 - 200}px, 0px)`)
            .attr('height', `${50 / 1.21}px`)
            .attr('fill', (state) => stateColor(state.state, true));
    };

    return (         
      <Modal
        modalTitle={phrases.modules.machine.mergeState}
        open={open}
        handleClose={onModalClose}
        onEnter={onModalOpen}
        content={(
          <Grid container spacing={1}>
            <form className={classes.root} noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid item xs={12}>
                <Typography variant="button" display="inline" color="textSecondary">
                  {phrases.forms.reason.misc.machineStatus}
                  :
                </Typography>

                <Typography
                  variant="button"
                  align="left"
                  display="inline"
                  style={{ color: stateColor(selectedState.state) }}
                  className={`${classes.bold} ${classes.uppercase} ${classes.statusTitle}`}
                >
                  {stateText(selectedState.state)}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Popper
                    id="popup"
                    open={tooltipOpen}
                    className={classes.popupWrapper}
                    anchorEl={anchorEl}
                    placement="top"
                >
                    <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                    <Paper
                        id="popup-menu"
                        className={classes.pulsePopup}
                        elevation={3}
                        square
                        variant="outlined"
                    >
                        <div className={classes.tooltipContent} id="tooltipContent" />
                    </Paper>
                    </ClickAwayListener>
                </Popper>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.pulseWrapper} ref={svgWrapperRef}>
                    <svg ref={svgRef} width="100%" height={60}>
                    <g className="x-axis" />
                    </svg>
                </Grid>
                <Grid item xs={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.addBtn}>
                            <ButtonGroup variant="contained">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={getPreviousState}
                                    > + </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={removeFirstStateFromSelectedStates}
                                    > - </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} className={classes.addBtn}>                    
                            <Typography
                                variant="button"
                                align="left"
                            >
                                {phrases.modules.machine.addPreviousState}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className={classes.addBtn}>
                    <Typography
                        variant="button"
                        align="left"
                    >
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="select-state-label">Status</InputLabel>
                                <Select
                                labelId="select-state-label"
                                id="select-state"
                                value={newStatus}
                                label="New Status"
                                onChange={handleSetNewStatus}
                                >
                                    {currentStatusValues.sort().map((key) => <MenuItem value={key}>{phrases.machineStatusesText[key]}</MenuItem>)}
                                </Select>
                            <FormHelperText>Select a new Status</FormHelperText>
                        </FormControl>
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.addBtn}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.addBtn}>
                            <ButtonGroup variant="contained">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={getNextState}
                                    > + </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={removeLastStateFromSelectedStates}
                                    > - </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} className={classes.addBtn}>                    
                            <Typography
                                variant="button"
                                align="left"
                            >
                                {phrases.modules.machine.addNextState}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    className={classes.formBtn}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {phrases.forms.shared.buttons.merge}
                    {isSubmitting
                      && (
                        <CircularProgress
                          className={classes.loadingBtnIcon}
                          color="inherit"
                          size={15}
                        />
                      )}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.formBtn}
                    onClick={handleReset}
                  >
                    {phrases.forms.shared.buttons.reset}
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.formBtn}
                    onClick={handleCloseModalClick}
                  >
                    {phrases.forms.shared.buttons.close}
                  </Button>
                </div>
              </Grid>
            </form>
          </Grid>
        )}
      />
    
     );
}
 
export default React.memo(
    MergeStateModal,
    (prevProps, nextProps) => prevProps.open === nextProps.open,
);