import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import useSensorForm      from '@frontend/modules/sensor/hooks/useSensorForm';
import { usePhrases }     from '@frontend/utils/usePhrases';
import StyledSwitch       from '@frontend/components/StyledSwitch';
import StyledSelect       from '@frontend/components/StyledSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const SensorForm = (props) => {
  const {
    isEdit,
    handleCloseModal,
  } = props;

  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const {
    handleChange,
    handleSubmit,
    handleSwitch,
    handleClearForm,
    sensorValues,
    sensorTypes,
    isSubmitting,
    errors,
  } = useSensorForm(props);

  return (

    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        error={errors.sensorId}
        helperText={errors.sensorId}
        id="sensorId"
        name="sensorId"
        label={phrases.forms.sensor.fields.sensorID}
        variant="outlined"
        fullWidth
        onChange={handleChange}
        value={sensorValues.sensorId}
        margin="dense"
        required
      />
      <TextField
        error={errors.alias}
        helperText={errors.alias}
        id="alias"
        name="alias"
        label={phrases.forms.shared.fields.alias}
        variant="outlined"
        fullWidth
        onChange={handleChange}
        value={sensorValues.alias}
        margin="dense"
      />
      <div>
        <StyledSwitch
          error={errors.isActive}
          helperText={errors.isActive}
          name="isActive"
          checked={sensorValues.isActive}
          onChange={handleSwitch}
          value={sensorValues.isActive}
          onLabel={phrases.forms.shared.fields.active}
          offLabel={phrases.forms.shared.fields.inactive}
        />
      </div>
      <StyledSelect
        required
        error={errors.sensorType}
        helperText={errors.sensorType}
        label={phrases.forms.shared.fields.type}
        labelId="sensor-type-label"
        id="type"
        name="type"
        value={sensorValues.type}
        onChange={handleChange}
        data={sensorTypes}
      />
      <TextField
        error={errors.config}
        helperText={errors.config}
        id="config"
        name="config"
        label={phrases.forms.shared.fields.config}
        variant="outlined"
        fullWidth
        onChange={handleChange}
        multiline
        value={sensorValues.config}
        margin="dense"
      />
      <div className={classes.formButtons}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.formBtn}
          disabled={isSubmitting}
        >
          {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
          {isSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
        </Button>
        {!isEdit && <Button
          variant="outlined"
          className={classes.formBtn}
          onClick={handleClearForm}
        >
          {phrases.forms.shared.buttons.clear}
        </Button>}
        {isEdit
          && (
            <Button
              variant="outlined"
              className={classes.formBtn}
              onClick={handleCloseModal}
            >
              {phrases.forms.shared.buttons.close}
            </Button>
          )}
      </div>
    </form>
  );
};

SensorForm.propTypes = {
  isEdit: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

SensorForm.defaultProps = {
  isEdit: false,
  handleCloseModal: () => { },
};

export default SensorForm;
