import React from 'react';
import { 
    Grid,
    Paper,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import { faChartBar, faClock } from '@fortawesome/free-solid-svg-icons';
import {usePhrases} from '@frontend/utils/usePhrases';
import LoadingIndicator from '@frontend/components/LoadingIndicator';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    loadingReasonsIndicator: {
      marginLeft: theme.spacing(1),
    },
    headingDisplay: {
        flex: 'auto',
    },
    bold: {
        fontWeight: 'bold',
    }
}));

const PlannedOutputCard = (props) => {
    const {
        plannedOutputData,
        isLoadingPerformance 
    } = props;
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    const { formatHoursMinutesDuration } = useDateTimeFormat();
    const { reasonText } = useStatesFilter();
    return (<>
        <Grid item xs={12}>            
            <Paper variant="outlined" square>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <List>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <FontAwesomeIcon
                                    icon={faChartBar}
                                    size="2x"
                                    />
                                </ListItemAvatar>
                                <Typography variant="body1">
                                    {phrases.modules.machinePerformance.scheduledOutput}
                                </Typography>
                                <Typography variant="body1" className={classes.loadingReasonsIndicator}>
                                    {isLoadingPerformance ? <LoadingIndicator size={20} /> : ''}
                                </Typography>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </List>
                        <Table aria-label="simple table">                    
                            <TableBody>
                                {plannedOutputData ? 
                                <>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{`${phrases.modules.machinePerformance.maxCycleTime}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span>
                                        {parseFloat(plannedOutputData.details.maxCycleTime).toFixed(2)}
                                        {' '}
                                        {phrases.modules.machinePerformance.unitMaxCycleTime}
                                        </span>
                                    </TableCell>
                                </TableRow> 
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{`${phrases.modules.machinePerformance.scheduledWorkingTime}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span>
                                        {formatHoursMinutesDuration(plannedOutputData.details.scheduledOutputTime)}
                                        </span>
                                    </TableCell>
                                </TableRow>
                                {
                                    plannedOutputData.details.overtime !== 0 ?  
                                    (
                                        <TableRow>
                                            <TableCell align="left">
                                                <Typography variant="body2">{`${phrases.modules.machinePerformance.overtime}:`}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <span>
                                                {formatHoursMinutesDuration(plannedOutputData.details.overtime)}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ): ""
                                }
                                
                                </>    
                                :
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>{phrases.modules.machinePerformance.noPerformance}</Typography>
                                    </TableCell>
                                </TableRow>  
                                }              
                            </TableBody>                    
                        </Table>
                    </Grid>
                    <Grid item xs={4}>
                        <List>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <FontAwesomeIcon
                                    icon={faClock}
                                    size="2x"
                                    />
                                </ListItemAvatar>
                                <Typography variant="body1">
                                    {phrases.modules.machinePerformance.plannedReasonsHeading}
                                </Typography>
                                <Typography variant="body1" className={classes.loadingReasonsIndicator}>
                                    {isLoadingPerformance ? <LoadingIndicator size={20} /> : ''}
                                </Typography>
                                <Typography variant="body1" flex align="right" className={classes.headingDisplay}>
                                    {formatHoursMinutesDuration(plannedOutputData.details.sortedReasons?.reduce((prev, cur) => {
                                        return prev+cur[1];
                                    }, 0))}
                                </Typography>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <Table aria-label="simple table">                    
                                <TableBody>                                
                                {
                                    plannedOutputData.details.sortedReasons?.map((reason, i) => {
                                        return (
                                        <TableRow>
                                            <TableCell align="left">
                                                <Typography variant="body2">{`${i+1}. ${reasonText(reason[0])}:`}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <span>
                                                {formatHoursMinutesDuration(reason[1])}
                                                </span>
                                            </TableCell>
                                        </TableRow>)
                                    })
                                }  
                                </TableBody>                    
                            </Table>
                            
                        </List>
                    </Grid>
                    <Grid item xs={4}>
                    <Table aria-label="simple table">                    
                            <TableBody>
                                {plannedOutputData ? 
                                <>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{`${phrases.modules.machinePerformance.delta}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span>
                                        {parseFloat(plannedOutputData.delta).toFixed(2)}
                                        {' '}
                                        {phrases.modules.machinePerformance.unit}
                                        </span>
                                    </TableCell>
                                </TableRow>  
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2" className={classes.bold}>{`${phrases.modules.machinePerformance.total}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body2" className={classes.bold}>
                                            <span>
                                            {parseFloat(plannedOutputData.value).toFixed(2)}
                                            {' '}
                                            {phrases.modules.machinePerformance.unit}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow> 
                                </>    
                                :
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>{phrases.modules.machinePerformance.noPerformance}</Typography>
                                    </TableCell>
                                </TableRow>  
                                }              
                            </TableBody>                    
                        </Table>
                    </Grid>
                </Grid>
            </Paper>            
        </Grid>
    </>);

}

export default PlannedOutputCard;
