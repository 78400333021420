import React from 'react';
import { usePhrases } from '@frontend/utils/usePhrases';
import {
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    cells: {
        margin: "auto"
    }
  }));
const TargetSpeedCard = (props) => {
    const { 
        shiftKPIs
    } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    return (
        <>
        <Grid item xs={6}>            
            <Paper className={classes.paper} variant="outlined" square>
                <Grid container spacing={1}>
                    <Grid item xs={6} className={classes.cells}>
                        <Typography align='left' variant="h4" display="inline" color="textSecondary">
                            {phrases.modules.simple_machine.timeGoal}
                            {": "}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.cells}>
                        <Typography align='left' variant="h3" display="inline" color="textSecondary">
                            <b>{shiftKPIs.timeTargetNumber}</b>
                            {" "}
                            {phrases.modules.simple_machine.unit}
                        </Typography>
                    </Grid>
                </Grid>               
            </Paper>
        </Grid>
        <Grid item xs={6}>            
            <Paper className={classes.paper} variant="outlined" square>
                <Grid container spacing={1}>                    
                    <Grid item xs={6} className={classes.cells}>
                        <Typography align='left' variant="h4" display="inline" color="textSecondary">
                            {phrases.modules.simple_machine.shiftGoal}
                            {": "}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.cells}>
                        <Typography align='left' variant="h3" display="inline" color="textSecondary">
                            <b>{shiftKPIs.targetNumber}</b>
                            {" "}
                            {phrases.modules.simple_machine.unit}
                        </Typography>  
                    </Grid>
                </Grid>               
            </Paper>
        </Grid>
        </>
        
        );
}
 
export default TargetSpeedCard;