import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePhrases } from "@frontend/utils/usePhrases";
const dummyMachineShift = {
    "shiftName": "",
    "machines": [],
    "startTime": "00:00:00",
    "endTime": "00:00:00",
    "targetNumber": 0,          // Deprecated
    "isActive": true,
    "monday": true,
    "tuesday": true,
    "wednesday": true,
    "thursday": true,
    "friday": true,
    "saturday": false,
    "sunday": false
}

const useMachineShiftForm = (callback, shiftToEdit) => {
    const [machineShift, setMachineShift] = useState(shiftToEdit ? {...shiftToEdit, machines: shiftToEdit.machines?.map((machine)=> machine.id)}: dummyMachineShift);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({ });
    const [machines, setMachines] = useState([]);
    const [selectedMachines, setSelectedMachines] = useState(shiftToEdit ? shiftToEdit.machines : []);
    const phrases = usePhrases().phrases();
    const structures = useSelector(state => {return state.selectionStructures})
    const getStructuresMachines = (_structures) => {
        return _.flatten(
            _structures.map((structure) => {
                if (structure.machines) {
                    return structure.machines.map((machine) => {
                        return {
                            ...machine,
                            structure,
                        };
                    });
                }
                return [];
            })
        );        
    }
    useEffect(() => {        
        setMachines(getStructuresMachines(structures))
    }, [structures]);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
          callback();
        } else {
          setIsSubmitting(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const validateMachineShiftForm = (values) => {
        const myerrors = {};

        if (!values.shiftName || values.shiftName === "") {
            myerrors.shiftName = phrases.modules.machine_shift.errors.nameRequired;
        }
        
        if (!values.machines || _.isEmpty(values.machines) || values.machines.length === 0) {
            myerrors.machines = phrases.modules.machine_shift.errors.machineRequired;
        }

        if (!values.startTime || values.startTime === "") {
            myerrors.startTime = phrases.modules.machine_shift.errors.startTimeRequired;
        }

        if (!values.endTime || values.endTime === "") {
            myerrors.endTime = phrases.modules.machine_shift.errors.endTimeRequired;
        }
        // if(moment(values.endTime, "HH:mm:ss").isBefore(moment(values.startTime, "HH:mm:ss"), "seconds")){
        //     myerrors.endTime = phrases.modules.machine_shift.errors.endTimeLargerStartTime;
        // } 
        if (values.isActive === undefined) {
            myerrors.isActive = phrases.modules.machine_shift.errors.isActiveRequired;
        }

        if (values.monday === undefined) {
            myerrors.monday = phrases.modules.machine_shift.errors.mondayRequired;
        }
        if (values.tuesday === undefined) {
            myerrors.tuesday = phrases.modules.machine_shift.errors.tuesdayRequired;
        }
        if (values.wednesday === undefined) {
            myerrors.wednesday = phrases.modules.machine_shift.errors.wednesdayRequired;
        }
        if (values.thursday === undefined) {
            myerrors.thursday = phrases.modules.machine_shift.errors.thursdayRequired;
        }
        if (values.friday === undefined) {
            myerrors.friday = phrases.modules.machine_shift.errors.fridayRequired;
        }
        if (values.saturday === undefined) {
            myerrors.saturday = phrases.modules.machine_shift.errors.saturdayRequired;
        }
        if (values.sunday === undefined) {
            myerrors.sunday = phrases.modules.machine_shift.errors.sundayRequired;
        }
        return myerrors;
    }

    const handleClearForm = () => {
        setMachineShift(dummyMachineShift);
    };

    const handleChangeShift = (event) => {
        setMachineShift(
            {
                ...machineShift, 
                [event.target.id]: event.target.value
            })
    };
    
    const handleChangeMachine = (event, value) => {
        if(!value) return;
        setSelectedMachines(value);
        setMachineShift({
            ...machineShift,
            machines: value.map((machine) => machine.id)
        });
    };

    const handleCheckboxChange = (event) => {
        console.log(event.target.id, event.target.checked);
        setMachineShift(
            {
                ...machineShift, 
                [event.target.id]: event.target.checked
            })
    }

    const handleSwitch = (event) => {
        setMachineShift(
            {
                ...machineShift, 
                [event.target.id]: event.target.checked
            })
    }

    const handleSubmit = (event) => {
        if (event) {
          event.preventDefault();
        }
        setIsSubmitting(true);
        setErrors(validateMachineShiftForm(machineShift));
    }
    return {
        machineShift,
        setMachineShift,
        handleChangeShift,
        handleClearForm,
        handleCheckboxChange,
        handleChangeMachine,
        handleSwitch,
        handleSubmit,
        selectedMachines,
        errors,
        machines
    };
}
 
export default useMachineShiftForm;