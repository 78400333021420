/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import axios from 'axios';

import useAlert               from '@frontend/hooks/useAlert';
import useValidateSensorForm  from '@frontend/modules/sensor/hooks/useValidateSensorForm';
import { usePhrases }         from '@frontend/utils/usePhrases';
import useApi                 from '@frontend/utils/useApi';

const emptySensor = {
  sensorId: '',
  alias: '',
  isActive: true,
  machine: null,
  type: null,
  config: '',
};
const useSensorForm = (props) => {
  const {
    sensor,
    isEdit,
    onEdit,
    handleCloseModal,
  } = props;

  // ===== State Variables =====
  const [sensorValues, setSensorValues] = useState(sensor || emptySensor);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sensorTypes, setSensorTypes] = useState([]);

  // ===== Imports =====
  const { validateSensorForm } = useValidateSensorForm();
  const { createAlert } = useAlert();
  const api = useApi();
  const phrases = usePhrases().phrases();

  // ===== Effects =====
  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors/sensor-types', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setSensorTypes(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * On errors change, this effect gets trigerred. This is only going to be trigerred
   * when submitting a form.
   */
   useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitForm();
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  
  // ===== Helper Methods =====
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateSensorForm(sensorValues));
  };

  const handleChange = (event) => {
    event.persist();
    if (errors[event.target.name]) {
      delete errors[event.target.name]; 
    }
    setSensorValues({ ...sensorValues, [event.target.name]: event.target.value });
  };

  const handleSwitch = (event) => {
    event.persist();
    const field = event.target.name;
    setSensorValues({ ...sensorValues, [field]: !sensorValues[field] });
  };

  const handleClearForm = () => {
    setIsSubmitting(false);
    setSensorValues(emptySensor);
    setErrors({});
  };

  const submitForm = () => {
    setIsSubmitting(true);
    api('/api/sensors', {
      data: sensorValues,
      method: isEdit ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 201) {
          createAlert(phrases.forms.sensor.success.sensorAdded, 'success');
          setIsSubmitting(false);
          handleClearForm();
        } else if (response.status === 200) {
          if (isEdit) {
            onEdit(response.data);
            setIsSubmitting(false);
            createAlert(phrases.forms.sensor.success.sensorUpdated, 'success');
            handleCloseModal();
          }
        }
      }).catch((error) => {
        const errorMessage = phrases.forms.sensor.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            setIsSubmitting(false);
            createAlert(errorMessage, 'error');
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            setIsSubmitting(false);
            createAlert(errorMessage, 'error');
          }
        }
      });
  };

  return {
    handleChange,
    handleSubmit,
    handleSwitch,
    handleClearForm,
    sensorValues,
    sensorTypes,
    isSubmitting,
    errors,
  };
};

export default useSensorForm;
