/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box, 
  Chip,
  MenuItem,
  InputLabel,
  FormControl,
  InputBase,
  Checkbox,
  styled,
  Select,
} from '@material-ui/core';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    marginLeft: 'auto',
  },
  inputField: {
    width: '100%',
  },
  structureName: {
    fontWeight: 550,
  },
}));

const MachinesDropdownSelect = (props) => {
  const classes = useStyles();
  const {
    options,
    value,
    onChange,
    label,
    error,
    required,
    helperText,
  } = props;
  const [sortedMachines, setSortedMachines] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  useEffect(() => {
      if (Array.isArray(options)) {
        setSortedMachines(options.sort((a, b) => {
          if (a.structure?.name > b.structure?.name) return 1;
          if (a.structure?.name < b.structure?.name) return -1;
          return 0;
        }))
      }      
  }, [options]);

  return (
    <div>
      <FormControl {...props} margin="dense" required={!!required} variant="standard" className={classes.formControl}>
          <InputLabel 
              shrink={true}
              id="machinedropdown-checkbox-label"
          >                
              {label}
          </InputLabel>
          <Select
              labelId="structuredropdown-checkbox-label"
              label= {label}
              id="structuredropdown-select"
              value={value}
              multiple
              helperText={helperText}
              onChange={onChange}
              input={<BootstrapInput />}
              error={!!error}
              renderValue={(selected) =>  {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                   {
                    Array.isArray(selected) ? 
                      selected.map((machine) => (
                        <Chip color="primary" key={machine.id} label={machine.name} />
                      )) : 
                      <Chip color="primary" key={selected.id} label={selected.name} />
                
                  }
                  </Box>)}}
              >
              {sortedMachines?.map((machine) => (
              <MenuItem key={machine.id} value={machine}>
                <div key={uuid()}>
                  <Checkbox 
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    color='primary'
                    checked={value.find((item) => (item.id === machine.id))} 
                  />
                  {machine.name}
                </div>
              </MenuItem>
              ))}
          </Select>
      </FormControl>
    </div>
  );
};

MachinesDropdownSelect.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default MachinesDropdownSelect;
