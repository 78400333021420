
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePhrases } from "@frontend/utils/usePhrases";
import useAlert from '@frontend/hooks/useAlert';
import useApi             from '@frontend/utils/useApi';

const useMergeStatesModal = (selectedState, callback) => {

    const CancelToken = axios.CancelToken;
    let cancelMachines;
    const machineStates = useSelector((state) => state.machineStates)?.[selectedState.machineId]?.states;
    const [selectedStates, setSelectedStates] = useState([selectedState]);
    const [statesBefore, setStatesBefore] = useState([]);
    const [statesAfter, setStatesAfter] = useState([]);
    const phrases = usePhrases().phrases();
    const [newStatus, setNewStatus] = useState(selectedState.state["presentation.status"]);
    const { createAlert } = useAlert();
    const api = useApi();
    const [currentStatusValues, setCurrentStatusValues] = useState([...new Set(machineStates.map(state => state["presentation.status"]))]);
    
    useEffect(() => {
        setCurrentStatusValues([...new Set(machineStates.map(state => state["presentation.status"]))])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineStates]);

    useEffect(() => {
        if (!_.isEmpty(machineStates)) {
            resetToSelectedState()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState]);
    
    const getPreviousState = () => {
        if(!_.isEmpty(statesBefore)) {
        const newState = statesBefore[statesBefore.length - 1];
        const formattedState = {
            startTime: newState.time,
            endTime: selectedStates[0].startTime,
            machineId: selectedStates[0].machineId,
            state: newState
        };

        setSelectedStates([
            formattedState,
            ...selectedStates])
        setStatesBefore([...statesBefore.slice(0, statesBefore.length - 1)]);
        } else{
            createAlert(phrases.modules.machine.noMoreStates, 'error');
        }
    };
    
    const getNextState = () => {
        if(!_.isEmpty(statesAfter)) {
            const newState = statesAfter[0];
            const formattedState = {
                startTime: newState.time,
                endTime: statesAfter[1]?.time ?? moment().format(),
                machineId: selectedStates[0].machineId,
                state: newState
            };
            setSelectedStates([
                ...selectedStates,
                formattedState]);
            
            setStatesAfter([...statesAfter.slice(1)]);
        } else{
            // Display Banner?
            createAlert(phrases.modules.machine.noMoreStates, 'error');
        }
    };
    
    const removeLastStateFromSelectedStates = () => {
        if(selectedStates.length > 1) {
            const lastState = selectedStates[selectedStates.length - 1].state;
            setStatesAfter([lastState, ...statesAfter]);
            setSelectedStates([...selectedStates.slice(0, selectedStates.length - 1)]);
        }
    };

    const removeFirstStateFromSelectedStates = () => {
        if(selectedStates.length > 1) {
            setStatesBefore([...statesBefore, selectedStates[0].state]);
            setSelectedStates([...selectedStates.slice(1)]);
        }
    };

    const getMinTime = () => {
        return selectedStates[0].startTime;
    }

    const getMaxTime = () => {
        return selectedStates[selectedStates.length - 1].endTime;
    }

    const resetToSelectedState = () => {
        setSelectedStates([selectedState]);
        setStatesBefore(machineStates.filter((state) => moment(state.time).isBefore(moment(selectedState.startTime))));
        setStatesAfter(machineStates.filter((state) => moment(state.time).isSameOrAfter(moment(selectedState.endTime))));            
    };

    const createNewState = () => {
        const startTime = selectedStates[0].startTime;
        const endTime = selectedStates[selectedStates.length - 1].endTime;        
        const newState = selectedState.state;
        newState["presentation.status"] = newStatus
        newState["presentation.reason"] = selectedStates[0].state["presentation.reason"] ?? ""
        newState["deviation"] = selectedStates.reduce((acc, state) => {
            return acc + state.state.deviation;
        }, 0);
        newState["actualCount"] = selectedStates.reduce((prev, curr) => {
            return prev + curr.state.actualCount;
        }, 0);
        newState["targetCount"] = selectedStates.reduce((prev, curr) => {
            return prev + curr.state.targetCount;
        }, 0);

        const formattedState = {
            startTime,
            endTime,
            machineId: selectedStates[0].machineId,
            state: newState
        };
        cancelMachines && cancelMachines();
        api(`/api/machines/mergeStates`, {
            method: 'post',
            data: {
                formattedState,
                selectedStates,
            },
            cancelToken: new CancelToken(function executor(c) {
              cancelMachines = c;
            }),
        }).then((response) => {
            if (response.status === 201 || response.status === 304) {
                api(`/api/machines/refresh-pulses?machine=${formattedState.machineId}`, {
                    method: 'post',                    
                    cancelToken: new CancelToken(function executor(c) {
                      cancelMachines = c;
                    }),
                }).then(() => {
                    // All Ok
                    callback();
                }).catch((error) => {
                    console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
    return {
        getPreviousState,
        getNextState,
        removeLastStateFromSelectedStates,
        removeFirstStateFromSelectedStates,
        selectedStates,
        getMinTime,
        getMaxTime,
        resetToSelectedState,
        newStatus,
        setNewStatus,
        createNewState,
        currentStatusValues,
    };
}
 
export default useMergeStatesModal;