/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import { ROUTES } from '@frontend/constants';
import StructuresDrawer from '@frontend/modules/structure/StructuresDrawer';
import MainAppbar from '@frontend/components/MainAppbar';
import TerminalAppBar from '@frontend/modules/terminal/TerminalAppBar';

import { closeDrawer, closeSelectionDrawer } from '@frontend/utils/UIActions';
import NavigationDrawer from '@frontend/components/NavigationDrawer';

export default function ManagementDrawer() {
  const dispatch = useDispatch();

  const handleCloseDrawers = (e) => {
    e.stopPropagation();
    dispatch(closeDrawer());
    dispatch(closeSelectionDrawer());
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseDrawers}>
        <div>
          <MainAppbar minified={window.location.pathname.includes(ROUTES.SIMPLE_MACHINE)}/>
          {!window.location.pathname.includes(ROUTES.SIMPLE_MACHINE) && (
          <>
          <NavigationDrawer />
          <TerminalAppBar /> 
          <StructuresDrawer />
          </>)}
          
        </div>
      </ClickAwayListener>
    </>
  );
}
