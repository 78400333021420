import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateMachineTypeForm = () => {
  const phrases = usePhrases().phrases();
  const validateMachineTypeForm = (values) => {
    const errors = {};
    if (values.name.trim() === '') {
      errors.name = phrases.forms.machine.errors.nameCannotBeBlank;
    }

    return errors;
  };

  return {
    validateMachineTypeForm,
  };
};

export default useValidateMachineTypeForm;
