import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  MenuItem,
  Menu,
} from '@material-ui/core';

import useSecurity from '@frontend/utils/useSecurity';
import { usePhrases } from '@frontend/utils/usePhrases';
import ReloadIntervalButton from '@frontend/components/ReloadIntervalButton';

const UserMenu = (props) => {
  const {
    userMenuAnchorEl,
    setUserMenuAnchorEl,
    open,
  } = props;

  const { getProfile, logout } = useSecurity();
  const user = getProfile();
  const phrases = usePhrases().phrases();

  const handleCloseUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const StyledMenuItem = withStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
    },
  })(MenuItem);

  return (
    <Menu
      id="menu-appbar"
      getContentAnchorEl={null}
      anchorEl={userMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleCloseUserMenu}
      PaperProps={{
        square: true,
        variant: 'outlined',
      }}
    >
      <StyledMenuItem><b>{user.name}</b></StyledMenuItem>
      <StyledMenuItem>{user.email}</StyledMenuItem>
      <Divider />
      <StyledMenuItem>
        <ReloadIntervalButton />
      </StyledMenuItem>
      <Divider />
      {/* <MenuItem onClick={handleCloseUserMenu}>{phrases.nav.profile}</MenuItem>
      <MenuItem onClick={handleCloseUserMenu}>{phrases.nav.settings}</MenuItem>
      <Divider /> */}
      <MenuItem onClick={handleLogout}>{phrases.nav.logout}</MenuItem>
    </Menu>
  );
};

UserMenu.propTypes = {
  setUserMenuAnchorEl: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UserMenu;
