const sensorTypesReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_LIST_SENSOR_TYPES':
      return action.sensorTypes;
    case 'REMOVE_LIST_SENSOR_TYPES':
      return [];
    default:
      return state;
  }
};

export default sensorTypesReducer;
