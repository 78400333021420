/* eslint-disable no-nested-ternary */
import React from 'react';
import { 
  Divider,
  ListItem,
  Typography 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useStatesFilter from '@frontend/hooks/useStatesFilter';
import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 900,
  },
  reason: {
    marginRight: theme.spacing(1),
  },
}));

const ReasonTooltipIndicator = (props) => {
  const {
    selectedState
  } = props;

  const phrases = usePhrases().phrases();
  const classes = useStyles();
  const {
    reasonColor,
    reasonText,
  } = useStatesFilter();

  const stateHasReason = (state) => {
    return state.state['presentation.reason'];
  };

  return (
    <>
      {stateHasReason(selectedState) && (
        <>
          <Divider variant="middle" />
          <ListItem>
            <Typography variant="subtitle2" className={classes.reason}>
              {phrases.forms.reason.misc.reason}
              :
            </Typography>
            <Typography
              variant="body2"
              style={{ color: reasonColor(selectedState.state) }}
              className={`${classes.bold}`}
            >
              {reasonText(selectedState.state)}
            </Typography>
          </ListItem>
        </>
      )}
    </>
  );
};

export default ReasonTooltipIndicator;
