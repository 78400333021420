import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Hidden,
} from '@material-ui/core';
import useStructureOverview from '@frontend/modules/structure/hooks/useStructureOverview';
import BreadcrumbsNavigation from '@frontend/components/BreadcrumbsNavigation';
import StructureInsightsView from './StructureInsightsView';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  machinePulsesContainer: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  lastTableCell: {
    borderBottom: 'none',
  },
  pushDiv: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(9),
    },
    marginTop: theme.spacing(5),
  },
  topCardsContainer: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  cardCell: {
    padding: theme.spacing(1.5),
  },
  cardCellWithIcon: {
    padding: theme.spacing(1),
  },
  progress: {
    textAlign: 'center',
    margin: 'auto',
  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    // left: 0,
    width: 'calc(100% - 56px)',
    textAlign: 'center',
  },
}));

const AdvancedReportingView = () => {
  const { structureId } = useParams();
  const {
    sensors,
    setMachineStates,
    fetchNextBatch,
    pageCount,
    refreshPulse,
  } = useStructureOverview(structureId);
  const classes = useStyles();

  // Redux store
  const structure = useSelector((state) => state.structure);
  const machines = useSelector((state) => state.machines);
  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.root}>
        <Hidden xsDown>
          <Grid item xs={12}>
            <BreadcrumbsNavigation selectedStructure={structure} />
          </Grid>
        </Hidden>
        </Grid>
        <Grid
          container
          spacing={2}
          className={classes.topCardsContainer}
          variant="outlined"
        >
        {/* Added by Julian -- Extended Reporting */}
        <StructureInsightsView
        structure={structure}
        machines={machines}
        sensors={sensors}
        fetchNextBatch={fetchNextBatch}
        pageCount={pageCount}
        refreshPulse={refreshPulse}
        setMachineStates={setMachineStates}
        />
        </Grid>

        {/* END JULIAN CONTENT */}
    </div>
  );
};

export default AdvancedReportingView;
