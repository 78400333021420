import React from 'react';
import { Link } from 'react-router-dom';
import {
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useDateTimeFormat   from '@frontend/utils/useDateTimeFormat';
import { usePhrases }      from '@frontend/utils/usePhrases';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    bold: {
      fontWeight: 900,
    },
    orange: {
      color: theme.palette.primary.main,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    listItemText: {
      marginRight: theme.spacing(1),
    },
    popupListItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemButton: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  }));
const OrderPopper = (props) => {
    const {
        order
    } = props;
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    const { 
        formatDate, 
        formatHoursMinutesDuration 
    } = useDateTimeFormat();
    return (    
        <List disablePadding dense>
            <ListItem dense className={classes.popupListItem}>
                <Typography
                    align="center"
                    className={`${classes.bold} ${classes.uppercase}`}
                >
                    <Link to={`/orders/job/${order?.businessJobId}`} key={`${order?.businessJobId}-lnk`} className={classes.orange}>
                    {order?.businessJobId}           
                    </Link>                    
                </Typography>
            </ListItem>            
            <Divider variant="middle" />
            <ListItem dense className={classes.popupListItem}>
                <ListItemText style={{
                    flex: 1,
                    display: 'block',
                }}
                >
                    <Typography variant="subtitle2" className={classes.listItemText}>
                    {phrases.modules.orders.startTime}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {formatDate(order?.time, true)}
                </Typography>
            </ListItem>
            <ListItem dense className={classes.popupListItem}>
                <ListItemText style={{
                    flex: 1,
                    display: 'block',
                }}
                >
                    <Typography variant="subtitle2" className={classes.listItemText}>
                    {phrases.modules.orders.endTime}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {order.endTime? formatDate(order?.endTime, true): "-"}
                </Typography>
            </ListItem>            
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.modules.orders.duration}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {order.duration ? formatHoursMinutesDuration(order?.duration): formatHoursMinutesDuration(moment().diff(order?.time, 'minutes'))}
                </Typography>
            </ListItem> 
            <Divider variant="middle" /> 
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.modules.orders.sensorCount}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {order?.partsProduced}
                </Typography>
            </ListItem> 
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.modules.orders.status}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {order?.endTime ? phrases.modules.orders.ENDED: phrases.modules.orders.STARTED}
                </Typography>
            </ListItem>   
            <Divider variant="middle" /> 
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.modules.orders.product}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {order?.product.name}
                </Typography>
            </ListItem> 
        </List>
        )
}
 
export default OrderPopper;