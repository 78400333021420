/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Grid,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
} from '@material-ui/core';
import useSecurity from '@frontend/utils/useSecurity';

import { usePhrases } from '@frontend/utils/usePhrases';
import LOADING_PROPS from '@frontend/modules/orders/constants';

const JobStatus = (props) => {
  const { jobTracker, updateJob, loadingPropsObject } = props;
  const phrases = usePhrases().phrases();
  const { isSystemAdmin, isManager } = useSecurity();
  const loggedInUser = useSelector((state) => state.user); 
  const [statusEdit, setStatusEdit] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(
    jobTracker.completionStatus,
  );

  useEffect(() => {
    setCompletionStatus(jobTracker.completionStatus);
  }, [jobTracker]);

  const handleCancelStatusEdit = () => {
    setStatusEdit(false);
    setCompletionStatus(jobTracker.completionStatus);
  };

  const handleUpdateJob = () => {
    updateJob({
      ...jobTracker,
      completionStatus,
    });
    setStatusEdit(false);
  };

  return (
    <>
      <Grid container component={Paper} spacing={1} variant="outlined" square>
        <Grid item xs={12}>
          <Typography align="center">
            {phrases.modules.orders.jobStatus}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {statusEdit ? (
            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {phrases.modules.orders.status}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={completionStatus}
                onChange={(e) => setCompletionStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="COMPLETED">
                  {phrases.modules.orders.COMPLETED}
                </MenuItem>
                <MenuItem value="PAUSED">
                  {phrases.modules.orders.PAUSED}
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Typography align="center" variant="h5">
              {loadingPropsObject[LOADING_PROPS.UPDATING_JOB] ? (
                <CircularProgress size={24} />
              ) : (
                phrases.modules.orders[jobTracker?.completionStatus] || '---'
              )}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {statusEdit ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleUpdateJob}
                  fullWidth
                >
                  {phrases.forms.shared.buttons.save}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleCancelStatusEdit}
                  fullWidth
                >
                  {phrases.forms.shared.buttons.cancel}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={4}>
                {/* This should do the trick */}
                {(!jobTracker.MES || isSystemAdmin(loggedInUser) || isManager(loggedInUser)) && (
                  <Button
                    disabled={
                      !jobTracker.completionStatus ||                      
                      jobTracker.completionStatus === 'ACTIVE'
                    }
                    onClick={() => setStatusEdit(true)}
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                  >
                    {phrases.forms.shared.buttons.edit}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default JobStatus;
