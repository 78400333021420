import { useEffect, useState } from "react";
import axios from 'axios';
import useApi from '@frontend/utils/useApi';


const useReasonCollapse = (modalOpen, machineId) => {
    const api = useApi();
    
    const [reasonsByCategory, setReasonsByCategory] = useState({});
    const [loadingReasons, setLoadinReasons] = useState(false);

    useEffect(() => {
        if (modalOpen) {
            setLoadinReasons(true);

            api(`/api/reasons?mongoQuery={"$or": [{"machineTypes": {"$in": ["${machineId}"]}},{"machineTypes": {"$size": 0}}], "enabled": true }`, {
                method: 'get',
            })
            .then((response) => {
                setReasonsByCategory(divideReasonsByCategory(response.data));
                setLoadinReasons(false);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) console.log(error);
                setLoadinReasons(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen]);

    const divideReasonsByCategory = (_reasons) => {
        const uniqueCategories = Array.from(new Set(_reasons.map((reason) => reason.category.name)));
        return uniqueCategories.reduce((o, category) => Object.assign(o, {
        [category]: _reasons.filter((reason) => reason.category.name === category),
        }), {});
    };

    return {
        reasonsByCategory,
        loadingReasons
    };
}
 
export default useReasonCollapse;