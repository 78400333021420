
import Axios from 'axios';

const useApi = () => {
  const api = (url, options) => {
    // Retrieves the user token from localStorage
    const getToken = () => localStorage.getItem('accessToken');

    // performs api calls sending the required authentication headers
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    headers.Authorization = `Bearer ${getToken()}`;
    return Axios(url, {
      headers,
      ...options,
    }).then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  return api;
};


export default useApi;
