import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import moment from 'moment';

import useApi from '@frontend/utils/useApi';

const useTopQualityReports = (machine, qualityReportModalOpen, setTotalReports, disabled=false) => {
  const [qualityReports, setQualityReports] = useState([]);
  const [loadingQualityReports, setLoadingQualityReports] = useState(true);
  const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan);
  const timespanEnd = useSelector((state) => state.timespanEnd);
  const machineStates = useSelector((state) => state.machineStates);
  const api = useApi();


  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    setLoadingQualityReports(true);

    if (machineStates && !disabled) {
      const start_time = moment(machineStates[machine]?.states[0]?.time).subtract(1, "second");
      // The Machine will get a start_time and a timeDelta field to include the last state in the timespan!
      const mymachine = {
        id: machine,
        start_time: start_time.toDate(),
        timeDelta: moment(timespanEnd).diff(start_time, "minutes")+1
      }      
      
      api('/api/quality/quality-reports', {
        method: 'get',
        params: {
          machine: mymachine ?? {},
          ...(isRelativeTimespan ? {} : { to: timespanEnd }),
        },
      })
        .then((response) => {
          setQualityReports(response.data);
          setLoadingQualityReports(false);
          setTotalReports(response.data?.length)
        })
        .catch((error) => {
          setLoadingQualityReports(false);
        });
    }
    setLoadingQualityReports(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStates, qualityReportModalOpen, machine]);

  return {
    qualityReports,
    loadingQualityReports,
    setQualityReports
  };
};

export default useTopQualityReports;
