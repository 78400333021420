import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import {
  Paper,
  Divider,
  Box,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const CustomTabs = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    value,
    onChange,
    tabs,
    panels,
    variant,
    scrollButtons,
    orientation
  } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(value);

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  useEffect(() => {
    setActiveTabIndex(value);
  }, [value]);

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={activeTabIndex}
        onChange={onChange || handleChange}
        indicatorColor="primary"
        textColor="primary"
        orientation={orientation || 'horizontal'}
        scrollButtons={scrollButtons || 'auto'}
        variant={variant || theme.breakpoints.width('sm') >= window.innerWidth ? 'fullWidth' : 'scrollable'}
        aria-label="icon label tabs"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            icon={(
              <FontAwesomeIcon
                icon={tab.icon}
                size="lg"
              />
            )}
            wrapped
            label={tab.label}
          />
        ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeTabIndex}
        onChangeIndex={handleChange}
      >
        {panels.map((panel, i) => (
          <TabPanel key={uuid()} value={activeTabIndex} index={i}>
            {panel.content}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Paper>
  );
};

CustomTabs.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  tabs: PropTypes.instanceOf(Array).isRequired,
  panels: PropTypes.instanceOf(Array).isRequired,
};

CustomTabs.defaultProps = {
  value: 0,
  onChange: null,
  variant: '',
};

export default CustomTabs;
