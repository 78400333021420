/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  useTheme,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import StructureForm from '@frontend/modules/structure/StructureForm';
import Table from '@frontend/components/Table';
import Modal from '@frontend/components/Modal';
import CustomLink from '@frontend/components/CustomLink';
import { persistTableState } from '@frontend/utils/UIActions';
import useAlert from '@frontend/hooks/useAlert';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity from '@frontend/utils/useSecurity';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import StructureMultiselectToolbar from '@frontend/modules/structure/StructureMultiselectToolbar';
import { ROUTES } from '@frontend/constants';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const StructuresList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [structureToEdit, setStructureToEdit] = useState([]);
  const [structures, setStructures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tableState = useSelector((state) => state.tableState);
  const api = useApi();
  const phrases = usePhrases().phrases();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const { createAlert } = useAlert();
  const { formatDate } = useDateTimeFormat();
  // let cancelAxios = null;

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    dispatch(persistTableState({}));
    return () => {
      if (cancelAxios) cancelAxios.cancel('Compontent unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editStructure = (structure) => {
    setStructureToEdit(structure);
    handleOpenModal();
  };

  const columns = [
    {
      label: phrases.forms.shared.fields.businessID,
      name: 'businessId',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'businessId' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.name,
      name: 'name',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'name' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <CustomLink style={{ color: theme.palette.primary.main }} to={ROUTES.STRUCTURE_OVERVIEW} id={structures[tableMeta.rowIndex].id} object="structure">{value}</CustomLink>
          </div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.description,
      name: 'description',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'description' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.city,
      name: 'city',
      options: {
        filter: true,
        filterType: 'multiselect',
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'city' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[3] : [],
      },
    },
    {
      label: phrases.forms.structure.fields.country,
      name: 'country',
      options: {
        filter: true,
        filterType: 'multiselect',
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'country' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[4] : [],
      },
    },
    {
      label: phrases.forms.shared.fields.timezone,
      name: 'timezone',
      options: {
        filter: true,
        filterType: 'multiselect',
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'timezone' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[5] : [],
      },
    },
    {
      label: phrases.forms.structure.fields.parentStructure,
      name: 'structure',
      options: {
        filter: true,
        filterType: 'multiselect',
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'structure' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[6] : [],
        sortCompare: (order) => {
          return (item1, item2) => {
            if (!item1.data) return 1;
            if (!item2.data) return -1;
            const res = item1.data.name.localeCompare(item2.data.name);
            return res * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          names: _.uniq(_.map(structures, 'path').filter((path) => path)),
          logic: (structure, filters, row) => {
            if (!structure) return true;
            if (filters.length && structure) {
              const foundStructure = structures.find((str) => structure.id === str.id);

              return !filters.includes(foundStructure.path);
            }
            return true;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value.name}</div>
        ),
      },
    },
    {
      label: phrases.tables.shared.active,
      name: 'isActive',
      options: {
        filter: true,
        filterList: tableState.filterList ? tableState.filterList[7] : ['Active'],
        display: 'excluded',
        filterType: 'checkbox',
        filterOptions: {
          names: ['Active', 'Inactive'],
          logic: (isActive, filters) => {
            if (filters.includes('Active') && filters.includes('Inactive')) return false;
            if (filters.includes('Active')) return !isActive;
            if (filters.includes('Inactive')) return isActive;
            return false;
          },
        },
      },
    },
    ...conditionalElement(isAuthorized('structures', ['update:any', 'update:own']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => editStructure(structures.find((structure) => structure.id === value))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    }),
  ];

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    setIsLoading(true);
    api('/api/structures?populate=structure', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setStructures(response.data);
      setIsLoading(false);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeactivateStructures = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const structure = structures[index];
      api('/api/structures', {
        method: 'put',
        data: {
          ...structure,
          isActive: false,
        },
        cancelToken: cancelAxios.token,
      }).then((response) => {
        if (response.status === 200) {
          handleEdit();
          createAlert(phrases.forms.structure.success.structureUpdated, 'success');
        }
      }).catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    });
    
    return false;
  };

  const handleDelete = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const structure = structures[index];
      api(`/api/structures/${structure.id}`, {
        method: 'delete',
        cancelToken: cancelAxios.token,
      }).then((response) => {
        if (response.status === 204) {
          structures.splice(index, 1);
          setStructures([...structures]);
          createAlert(phrases.forms.structure.success.structureDeleted, 'success');
        }
      }).catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    });
    return false;
  };

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/structures?populate=structure', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setStructures(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  // const handleCreate = () => {
  //   cancelAxios = axios.CancelToken.source();
  //   api('/api/structures?populate=structure', {
  //     method: 'get',
  //     cancelToken: cancelAxios.token,
  //   }).then((response) => {
  //     setStructures(response.data);
  //   }).catch((error) => {
  //     if (!axios.isCancel(error)) console.log(error);
  //   });
  // };

  const getDirection = (direction) => {
    switch (direction) {
      case 'ascending': return 'asc';
      case 'descending': return 'desc';
      default: return 'asc';
    }
  };

  const customOptions = {
    filterType: 'checkbox',
    print: false,
    download: true,
    elevation: 0,
    selectableRows: isAuthorized('machines', ['delete:any', 'delete:own']) ? 'multiple' : 'none',
    onRowsDelete: (obj) => handleDelete(obj),
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({ ...tableState, filterList }));
    },
    downloadOptions: {
      filename: `${phrases.forms.shared.fields.structures} - ${formatDate(new Date())}.csv`,
      separator: ',',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    onChangePage: (page) => {
      dispatch(persistTableState({ ...tableState, page }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <StructureMultiselectToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        deactivateStructures={handleDeactivateStructures}
      />
    ),
    onDownload: (buildHead, buildBody, columns, data) => {
      data.map((structureArray) => {
        structureArray.data[6] = structureArray.data[6] ? structureArray.data[6].name : '';
        return [...structureArray.data];
      });
      return buildHead(columns) + buildBody(data);
    },
  };

  return (
    <div className={classes.root}>
      <Table
        modalTitle={phrases.forms.shared.fields.structures}
        data={structures}
        resource="structures"
        onDelete={handleDelete}
        columns={columns}
        customOptions={customOptions}
        isLoading={isLoading}
      />
      <Modal
        modalTitle={phrases.modal.EditStructure}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <StructureForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            structures={structures}
            structure={structureToEdit}
          />
        )}
      />
    </div>
  );
};

export default StructuresList;
