/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { faCog, faCogs} from '@fortawesome/free-solid-svg-icons';
import CustomTabs from '@frontend/components/Tabs';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity from '@frontend/utils/useSecurity';
import { usePhrases } from '@frontend/utils/usePhrases';
import MachineStatusForm from '@frontend/modules/machine/machine.statuses/MachineStatusForm';
import MachineStatusesList from '@frontend/modules/machine/machine.statuses/MachineStatusesList';
// import ConfirmationModal from '@frontend/components/ConfirmationModal';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    colorButton: {
        height: theme.spacing(3),
        width: theme.spacing(10),
        cursor: 'default',
        border: '1px solid grey',
    },
    scrapTypesList: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    }
}));

export default function ReasonsManagement() {
    const classes = useStyles();

    const phrases = usePhrases()
        .phrases();
    const { isAuthorized } = useSecurity();
    const { conditionalElement } = useConditionalArrayElement();

    return (
        <div className={classes.root}>
            <CustomTabs
                tabs={[
                    ...conditionalElement(isAuthorized('machine_statuses', ['read:any']), {
                        label: phrases.tabs.ExistingMachineStatuses,
                        icon: faCogs,
                    }),
                    ...conditionalElement(isAuthorized('machine_statuses', ['create:any']), {
                        label: phrases.tabs.CreateMachineStatus,
                        icon: faCog,
                    }),
                ]}
                panels={[
                    ...conditionalElement(isAuthorized('machine_statuses', ['read:any']), {
                        content: <MachineStatusesList />,
                    }),
                    ...conditionalElement(isAuthorized('machine_statuses', ['create:any']), {
                        content: <MachineStatusForm />,
                    }),
                ]}
            />
        </div>
    );
}
