/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  fade, makeStyles, withStyles,
} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Collapse,
  Typography,
} from '@material-ui/core';

import { closeSelectionDrawer } from '@frontend/utils/UIActions';
import { usePhrases } from '@frontend/utils/usePhrases';
import CustomLink from '@frontend/components/CustomLink';

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    // eslint-disable-next-line react/destructuring-assignment
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states.
   */
  in: PropTypes.bool,
};

TransitionComponent.defaultProps = {
  in: false,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.1,
    },
    padding: theme.spacing(0.5),
  },
  root: {
    paddingLeft: 0,
  },
  content: {
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
  },
  group: {
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.1)}`,
  },
}))((props) => <TreeItem component="div" {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  structureListItem: {
    textDecoration: 'none',
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  treeItem: {
    paddingLeft: theme.spacing(0.5),
  },
}));

const StructureTreeView = (props) => {
  const {
    structures,
    openMachinesDrawer,
    closeMachinesDrawer,
    view,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const structureInUse = useSelector((state) => state.structureInUse);
  const [expandedTreeItems, setExpandedTreeItems] = useState([structureInUse]);

  const phrases = usePhrases().phrases();

  const findParentStructures = (structureId, _structures) => _.flatten(_structures.map((_structure) => {
    if (_structure.id === structureId && !_structure.structure) return _structure.id;
    if (_structure.id === structureId) return [structureId, ...findParentStructures(_structure.structure.id, _structures)];
    return [];
  })).filter((_structure) => _structure);

  useEffect(() => {
    const allParents = findParentStructures(structureInUse, structures);
    setExpandedTreeItems(allParents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureInUse, structures]);

  // First child structure
  const getStructureChildStructures = (structureId, _structures) => {
    let arr = [];
    for (let i = 0; i < _structures.length; i++) {
      if (_structures[i].structure != null && _structures[i].structure.id === structureId) {
        arr = arr.concat(_structures.splice(i, 1));
        i -= 1;
      }
    }

    return arr;
  };

  const getStructuresRecursive = (structure, _structures) => {
    const childStructures = getStructureChildStructures(structure.id, _structures);
    if (childStructures && childStructures.length) {
      return {
        ...structure,
        structures: [..._.flatten(childStructures
          .map((s) => getStructuresRecursive(s, _structures)))],
      };
    }
    return structure;
  };

  const prepStructures = (_structures) => {
    if (!_structures) return [];
    return _structures
      .map((structure) => getStructuresRecursive(structure, _structures))
      .filter((structure) => structure !== null);
  };

  const selectStructure = (e) => {
    e.stopPropagation();
    closeMachinesDrawer();
    dispatch(closeSelectionDrawer());
  };

  const printStructuresTree = (_structures) => _structures.map((structure) => (
    <StyledTreeItem
      component="div"
      nodeId={structure.id}
      key={structure.id}
      label={(
        <List disablePadding dense>
          <ListItem dense className={classes.treeItem} component={CustomLink} to={view} object="structure" id={structure.id} key={structure.id} onClick={(e) => selectStructure(e, structure)}>
            <ListItemText className={classes.structureListItem}>
              <Typography variant="inherit" color="textPrimary" className={structure.id === structureInUse ? classes.selected : ''}>
                {structure.name}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Tooltip title={phrases.misc.showMachines}>
                <IconButton size="small" onClick={(e) => openMachinesDrawer(e, structure)}>
                  <ChevronRightIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
    >
      {structure.structures && printStructuresTree(structure.structures)}
    </StyledTreeItem>
  ));

  return (
    <TreeView
      className={classes.root}
      expanded={expandedTreeItems}
      onNodeToggle={(event, nodeIds) => setExpandedTreeItems(nodeIds)}
      defaultCollapseIcon={<IconButton size="small"><IndeterminateCheckBoxIcon /></IconButton>}
      defaultExpandIcon={<IconButton size="small"><AddBoxIcon /></IconButton>}
      defaultEndIcon={(
        <></>
      )}
    >
      {printStructuresTree(prepStructures(_.map(structures, _.clone)))}
    </TreeView>
  );
};

StructureTreeView.propTypes = {
  structures: PropTypes.instanceOf(Array).isRequired,
  openMachinesDrawer: PropTypes.func.isRequired,
  closeMachinesDrawer: PropTypes.func.isRequired,
};

export default StructureTreeView;
