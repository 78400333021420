
import { useEffect, useState } from 'react';
import axios from 'axios';
import useApi from '@frontend/utils/useApi';
import useSecurity from '@frontend/utils/useSecurity';

const useKnowledgeView = (knowledgeName) => {
    const [content, setContent] = useState("");
    const { getProfile } = useSecurity();
    const api = useApi()
    const [lang, ] = useState(getProfile()?.lang || 'EN');
    useEffect(() => {
        const cancelAxios = axios.CancelToken.source();
        console.log("AXIOS REQ:", knowledgeName);
        api(`/api/knowledge/${knowledgeName}`, {
            method: 'get',
            cancelToken: cancelAxios.token,
        }).then((response) => {

            console.log("AXIOS RES: ", response);
            if(response.data[0]){
                if(lang[0] === "DE"){
                    setContent(response.data[0].content_de);
                } else {
                    setContent(response.data[0].content_en);
                }
            }
        }).catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [knowledgeName]);
    
    return {
        content,
        setContent
    };
}
 
export default useKnowledgeView;