/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import axios from 'axios';
import useAlert          from '@frontend/hooks/useAlert';
import useApi             from '@frontend/utils/useApi';
import { usePhrases }     from '@frontend/utils/usePhrases';
import moment from 'moment';

const emptyStatusReason = {
  name: '',
  identifier: '',
  description: '',
  color: '',
  note: '',
};

const useStaticReasonAssignForm = (machine, handleCloseModal) => {

  const [statusReasonValues, setStatusReasonValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const api = useApi();
  const phrases = usePhrases().phrases();
  const { createAlert } = useAlert();
  // let cancelAxios = null;

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitStaticStatusReasonForm();
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  function submitStaticStatusReasonForm() {
    const data = statusReasonValues.noteId ? {
      id: statusReasonValues.noteId,
      text: statusReasonValues.note,
    } : { text: statusReasonValues.note };
    api('/api/shared/notes', {
      data,
      method: statusReasonValues.noteId ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          submitStaticStatusReason(response.data.id);
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  }

  const submitStaticStatusReason = (noteId) => {   
    const timeNow = moment(); 
    api('/api/reasons/static-status-reason', {
      data: {
        MPUData: {
          state: {
            startTime: timeNow,
          },
          staticReason: statusReasonValues,
          machine: machine.id,
        },
        reasonData: {
          machineId: machine.id,
          note: noteId,
          reason: statusReasonValues.identifier,
          startTime: timeNow,
        },
      },
      method: 'post',
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsSubmitting(false);
          createAlert(phrases.forms.reason.success.staticReasonAdded, 'success'); 
          api(`/api/machines/refresh-pulses?machine=${statusReasonValues.machineId}`, {
            method: 'post'
          }).then((response) => {         
              handleCloseModal();
            }
          ).catch((err) => {
            console.log(err);
            handleCloseModal();
          })
        }
      })
      .catch((error) => {
        console.log(error)
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  };

  function deleteStaticStatusReasonNote() {
    const { noteId } = statusReasonValues;
    if (noteId) {
      api(`/api/shared/notes/${noteId}`, {
        method: 'delete',
      })
        .then((response) => {
          if (response.status === 204) {
            deleteStaticStatusReason(noteId);
          }
        })
        .catch((error) => {
          const errorMessage = phrases.forms.structure.errors.genericError;
          if (error.response) {
            if (error.response.status === 404) {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            } else if (error.response.status === 403) {
              setIsSubmitting(false);
              createAlert(phrases.errorMessages.notAuthorized, 'error');
            } else {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            }
          }
        });
    }
  }
  const deleteStaticStatusReason = () => {
    const timeNow = moment(); 
    api('/api/reasons/static-status-reason', {
      data: {
        MPUData: {
          state: {
            startTime: timeNow,
          },
          staticReason: null,
          machine: machine.id,
        },
        reasonData: {
          machineId: machine.id,
          note: '',
          reason: '',
          startTime: timeNow,
        },
      },
      method: 'post',
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsSubmitting(false);
          createAlert(phrases.forms.reason.success.staticReasonDeleted, 'success');
          handleCloseModal();
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.structure.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          } else if (error.response.status === 403) {
            setIsSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setIsSubmitting(false);
          }
        }
      });
  };

  const handleChangeReason = (reason) => {
    setStatusReasonValues({
      note: statusReasonValues.note,
      noteId: statusReasonValues.noteId,
      ...reason,
    });
  };

  const handleChangeNote = (event) => {
    event.persist();
    if (errors[event.target.name]) {
      delete errors[event.target.name];
    }
    setStatusReasonValues((reasonValues) => ({
      ...reasonValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClearForm = () => {
    setStatusReasonValues(emptyStatusReason);
    setErrors({});
  };

  return {
    handleChangeReason,
    handleChangeNote,
    submitStaticStatusReasonForm,
    handleClearForm,
    statusReasonValues,
    errors,
    isSubmitting,
    setIsSubmitting,
    deleteStaticStatusReasonNote,
    deleteStaticStatusReason,
  };
};

export default useStaticReasonAssignForm;
