/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
  Chip,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { mdiRobotIndustrial } from '@mdi/js';
import useAlert from '@frontend/hooks/useAlert';
import StyledSwitch from '@frontend/components/StyledSwitch';
import StyledSelect from '@frontend/components/StyledSelect';
import MachineConfigForm from '@frontend/modules/machine/MachineConfigForm';
import useApi from '@frontend/utils/useApi';
import useMachineForm from '@frontend/modules/machine/hooks/useMachineForm';
import { usePhrases } from '@frontend/utils/usePhrases';
import StructuresDropdown from '@frontend/modules/structure/StructuresDropdown';
import useIcon from '@frontend/hooks/useIcon';
import { MATERIAL_DESIGN_ICON } from '@frontend/constants';
import useSecurity from '@frontend/utils/useSecurity';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
  machineConfig: {
    marginBottom: theme.spacing(2),
  },
}));

const MachineForm = (props) => {
  const classes = useStyles();

  const phrases = usePhrases().phrases();
  const { createAlert } = useAlert();
  const { canDoAction } = useSecurity();
  const api = useApi();
  const renderIcon = useIcon();

  const {
    handleChange,
    handleSubmit,
    handleSwitch,
    handleClearForm,
    handleStructureChange,
    setMachineConfig,
    machineValues,
    machineTypes,
    structures,
    sensors,
    handleSensorChange,
    errors,
  } = useMachineForm(submitForm, props.machine);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const isEdit = !!props.isEdit;

  // let cancelAxios = null;

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
  }, []);

  function submitForm() {
    setFormSubmitting(true);
    const cancelAxios = axios.CancelToken.source();

    const machine = {
      ...machineValues,
    };
    api('/api/machines', {
      data: machine,
      method: isEdit ? 'put' : 'post',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        if (response.status === 201) {
          if (props.onCreate) props.onCreate();
          createAlert(phrases.forms.machine.success.machineAdded, 'success');
          setFormSubmitting(false);
          handleClearForm();
        } else if (response.status === 200) {
          if (isEdit) {
            if (props.onEdit) props.onEdit(response.data);
            createAlert(phrases.forms.machine.success.machineUpdated, 'success');
            setFormSubmitting(false);
            props.handleCloseModal();
          }
        }
      }).catch((error) => {
        const errorMessage = phrases.forms.machine.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setFormSubmitting(false);
          } else if (error.response.status === 403) {
            setFormSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setFormSubmitting(false);
          }
        }
      });
  }

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit} autoComplete="off">
      <TextField
        id="name"
        name="name"
        label={phrases.forms.shared.fields.name}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineValues.name}
        error={errors.name}
        margin="dense"
        helperText={errors.name}
        required
      />
      <TextField
        id="sortIndex"
        name="sortIndex"
        label={phrases.forms.shared.fields.sortIndex}
        variant="outlined"
        fullWidth
        type="number"
        className={classes.inputField}
        onChange={handleChange}
        value={machineValues.sortIndex}
        margin="dense"
      />
      <TextField
        id="businessId"
        name="businessId"
        label={phrases.forms.shared.fields.businessID}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineValues.businessId}
        margin="dense"
      />
      <TextField
        id="placeNumber"
        name="placeNumber"
        label={phrases.forms.machine.fields.placeNumber}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineValues.placeNumber}
        margin="dense"
      />
      <TextField
        id="manufacturer"
        name="manufacturer"
        label={phrases.forms.machine.fields.manufacturer}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineValues.manufacturer}
        margin="dense"
      />
      <TextField
        id="alias"
        name="alias"
        label={phrases.forms.shared.fields.alias}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineValues.alias}
        margin="dense"
      />
      <TextField
        id="description"
        name="description"
        label={phrases.forms.shared.fields.description}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineValues.description}
        margin="dense"
      />
      <div>
        <StyledSwitch
          id="isActive"
          name="isActive"
          checked={machineValues.isActive}
          onChange={handleSwitch}
          value={machineValues.isActive}
          onLabel={phrases.forms.shared.fields.active}
          offLabel={phrases.forms.shared.fields.inactive}
        />
      </div>

      <Autocomplete
        multiple
        id="sensors"
        name="sensors"
        filterSelectedOptions
        className={classes.inputField}
        options={sensors}
        onChange={handleSensorChange}
        value={machineValues.sensors}
        getOptionLabel={(option) => `${option.sensorId} - ${option.alias}`}
        renderOption={(option) => (
          <>
            <Typography>
              {option.sensorId} - {option.alias}
            </Typography>
            <Typography>
              {option.machine ? <>({renderIcon(MATERIAL_DESIGN_ICON, mdiRobotIndustrial, { size: 0.5 })} {option.machine.name})</> : ''}
            </Typography>
          </>
        )}
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip color="primary" className={classes.chip} label={`${option.sensorId} - ${option.alias}`} {...getTagProps({ index })} />
        ))}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label="Sensors"
              name="sensors"
              className={classes.inputField}
              error={errors.sensors}
              helperText={errors.sensors || phrases.forms.machine.errors.sensorPerMachine}
              variant="outlined"
              margin="dense"
            />
          </>
        )}
      />

      <StructuresDropdown
        options={structures}
        label={phrases.forms.shared.fields.structure}
        onChange={handleStructureChange}
        value={machineValues.structure}
        error={errors.structure}
        helperText={errors.structure}
        required
      />
      <StyledSelect
        label={phrases.forms.shared.fields.type}
        labelId="type-label"
        id="type"
        name="type"
        error={errors.type}
        helperText={errors.type}
        value={machineValues.type}
        onChange={handleChange}
        data={machineTypes}
        required
      />

      {(canDoAction('update:own', 'machines') || canDoAction('update:any', 'machines')) && (
        <div className={classes.machineConfig}>
          <MachineConfigForm
            id="config"
            isEdit={isEdit}
            machine={machineValues}
            setMachineConfig={setMachineConfig}
          />
        </div>
      )}

      <div className={classes.formButtons}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.formBtn}
          disabled={formSubmitting}
        >
          {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
          {formSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
        </Button>
        {!isEdit && (
          <Button
            variant="outlined"
            className={classes.formBtn}
            onClick={handleClearForm}
          >
            {phrases.forms.shared.buttons.clear}
          </Button>
        )}
        {isEdit
          && (
            <Button
              variant="outlined"
              className={classes.formBtn}
              onClick={props.handleCloseModal}
            >
              {phrases.forms.shared.buttons.close}
            </Button>
          )}
      </div>
    </form>
  );
};

MachineForm.displayName = 'HeyHey';

export default MachineForm;
