import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateMachineStatusForm = () => {
  const phrases = usePhrases().phrases();

  const validateMachineStatusForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = phrases.forms.machine.errors.machineStatusName;
    }

    if (!values.activity) {
      errors.activity = phrases.forms.machine.errors.machineStatusActivity;
    }

    if (!values.color) {
      errors.color = phrases.forms.machine.errors.machineStatusColor;
    }

    if (!values.category) {
      errors.category = phrases.forms.machine.errors.machineStatusCategory;
    }

    if (!values.dataLayer) {
      errors.dataLayer = phrases.forms.machine.errors.machineStatusDataLayer;
    }

    return errors;
  };

  return {
    validateMachineStatusForm,
  };
};

export default useValidateMachineStatusForm;
