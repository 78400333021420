const machineTypesReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_LIST_MACHINE_TYPES':
      return action.machineTypes;
    case 'REMOVE_LIST_MACHINE_TYPES':
      return [];
    default:
      return state;
  }
};

export default machineTypesReducer;
