/* eslint-disable no-nested-ternary */
import { usePhrases } from '@frontend/utils/usePhrases';
import { Button, Divider, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  popupListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemButton: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

const ReasonTooltipButton = (props) => {
  const {
    handleAssignReason,
    selectedState,
  } = props;

  const phrases = usePhrases().phrases();
  const classes = useStyles();

  const stateHasReason = (state) => {
    if (state.state)
      return state.state['presentation.reason'];

    return false;
  };

  return (
    <>
      <Divider variant="middle" />
      <ListItem dense className={classes.popupListItem}>
        <Button
          size="small"
          onClick={stateHasReason(selectedState) ? handleAssignReason : handleAssignReason}
          className={classes.listItemButton}
          variant="contained"
          disableElevation
        >
          {stateHasReason(selectedState) ? phrases.forms.reason.misc.editReason : phrases.forms.reason.misc.assignReason}
        </Button>
      </ListItem>
    </>
  );
};

export default ReasonTooltipButton;
