import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import StyledSelect from '@frontend/components/StyledSelect';
import { usePhrases } from '@frontend/utils/usePhrases';
import { useEffect } from 'react';
import MachinesMultiselect from '../machine/MachinesMultiselect';
import useJobEditForm from './hooks/useJobEditForm';
import ProductsAutocomplete from './ProductsAutocomplete';
import OrdersAutocomplete from './OrdersAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
  colorInputField: {
    width: '33%',
  },
  reasonConfig: {
    marginBottom: theme.spacing(2),
  },
  colorMenuPopup: {
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  colorButton: {
    height: theme.spacing(5),
    width: theme.spacing(15),
    cursor: 'default',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  endAdornment: {
    paddingRight: 0,
  },
}));

const JobEditForm = (props) => {
    const {
        existingJobs,
        jobIndexToEdit,
        handleCloseModal,
        isEdit
    } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const [completionSelect, setCompletionSelect] = useState(<></>);
    const {
      handleChange,
      handleMachinesChange,
      handleProductsChange,
      handleOrdersChange,
      handleSubmitValidate,
      machinesForJob,
      jobToEdit,
      ordersForJob,
      productsForJob,
      formSubmitting,
      errors
    } = useJobEditForm(jobIndexToEdit, existingJobs, handleCloseModal);

    console.log(machinesForJob)
    useEffect(() => {
      if(jobToEdit.completionStatus){
      setCompletionSelect(
        <StyledSelect
                label={phrases.forms.jobs.completionStatus}
                labelId="completionStatus-Label"
                id="completionStatus"
                name="completionStatus"
                error={errors.completionStatus}
                helperText={errors.completionStatus}
                value={jobToEdit.completionStatus}
                onChange={handleChange}
                data={["OPEN", "ENDED"]}
                required
                plain
            />)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobToEdit])

        
    return (
         <form className={classes.root} noValidate autoComplete="off">
            <TextField
                disabled = {isEdit}
                id="scanCode"
                name="scanCode"
                label={phrases.forms.jobs.scanCode}
                variant="outlined"
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                value={jobToEdit.scanCode}
                error={errors.name}
                margin="dense"
                helperText={errors.name}
                required
            /> 
            <TextField
                id="handCount"
                name="handCount"
                type='number'
                label={phrases.forms.jobs.handCount}
                variant="outlined"
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                value={jobToEdit.handCount}
                error={errors.handCount}
                margin="dense"
                helperText={errors.handCount}
                required
            />    
            <TextField
                id="description"
                name="description"
                label={phrases.forms.jobs.description}
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                value={jobToEdit.description}
                error={errors.description}
                margin="dense"
                helperText={errors.description}
                required
            />
    
            {completionSelect}
            <MachinesMultiselect
                limitTags={1}
                label={phrases.modules.orders.intendedMachine}
                options={machinesForJob}
                onChange={handleMachinesChange}
                value={jobToEdit.intendedMachine}
                disableCloseOnSelect={false}
                defaultValue={isEdit ? existingJobs[jobIndexToEdit]?.intendedMachine : null}
                error={errors.intendedMachine}
                helperText={errors.intendedMachine}
                required
            />
            {isEdit ? "": 
            <OrdersAutocomplete
              limitTags={1}
              label={phrases.modules.orders.orders}
              options={ordersForJob}
              onChange={handleOrdersChange}
              value={jobToEdit.order}
              defaultValue={""}
              error={errors.order}
              helperText={errors.order}
              required
            />
            }
            <TextField
                id="targetQuantity"
                name="targetQuantity"
                type='number'
                label={phrases.forms.jobs.targetQuantity}
                variant="outlined"
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                value={jobToEdit.targetQuantity}
                error={errors.targetQuantity}
                margin="dense"
                helperText={errors.targetQuantity}
                required
            />
            <TextField
                id="targetSetupTime"
                name="targetSetupTime"
                type='number'
                label={phrases.forms.jobs.targetSetupTime}
                variant="outlined"
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                value={jobToEdit.targetSetupTime}
                error={errors.targetSetupTime}
                margin="dense"
                helperText={errors.targetSetupTime}
                required
            />
            <TextField
                id="targetCycleTime"
                name="targetCycleTime"                
                step={0.1}                
                type='number'
                label={phrases.forms.jobs.targetCycleTime}
                variant="outlined"
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                value={jobToEdit.targetCycleTime}
                error={errors.targetCycleTime}
                margin="dense"
                helperText={errors.targetCycleTime}
                required
            />
            
            <ProductsAutocomplete
              options={productsForJob}
              onChange={handleProductsChange}
              value={jobToEdit.product}
              defaultValue={isEdit ? existingJobs[jobIndexToEdit]?.product: null}              
              error={errors.product}
              helperText={errors.product}
              required
            />
            <div className={classes.formButtons}>
                <Button
                    variant="contained"
                    color="primary"
                    // type="submit"
                    onClick={handleSubmitValidate}
                    className={classes.formBtn}
                    disabled={formSubmitting}
                >
                    {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
                    {formSubmitting
                    && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
                </Button>
                <Button
                    variant="outlined"
                    className={classes.formBtn}
                    onClick={handleCloseModal}
                >
                    {phrases.forms.shared.buttons.close}
                </Button>                
            </div>
  </form> );
}
 
export default JobEditForm;