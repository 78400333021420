import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveSquare, faWifi } from '@fortawesome/free-solid-svg-icons';
import {
  Typography,
  Grid,
  Divider,
  ListItemIcon,
} from '@material-ui/core';
import { usePhrases } from '@frontend/utils/usePhrases';
import useApi from '@frontend/utils/useApi';
// eslint-disable-next-line import/no-unresolved
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import CustomLink from '@frontend/components/CustomLink';
import LoadingIndicator from '@frontend/components/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  floatRight: {
    float: 'right',
    display: 'inline',
  },
  green: {
    color: '#3bb273',
  },
  red: {
    color: '#d62828CC',
  },
  yellow: {
    color: '#FED766',
  },
  typography: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

  },
  header: {
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    margin: 'auto',
  },
}));

const SensorHealth = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { formatDate } = useDateTimeFormat();
  const [sensorsOnline, setSensorsOnline] = useState([]);
  const [isLoadingSensors, setIsLoadingSensors] = useState(false);
  const [sensors, setSensors] = useState([]);
  const api = useApi();
  // let cancelAxios = null;

  useEffect(() => {
    setIsLoadingSensors(true);
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors?populate=machine', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setSensors(response.data);
      setIsLoadingSensors(false);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
      setIsLoadingSensors(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoadingSensors(true);
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors/online', {
      method: 'post',
      data: {
        sensors,
        cancelToken: cancelAxios.token,
      },
    })
      .then((response) => {
        setSensorsOnline(response.data);
        setIsLoadingSensors(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
        setIsLoadingSensors(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors]);

  function getRssiClassname(rssi) {
    if (rssi == null) {
      return classes.red;
    }

    if ((rssi < -80) || (rssi <= -70 && rssi > -80)) {
      return classes.yellow;
    }

    return classes.green;
  }

  return (
    <div>

      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >

        {sensorsOnline.map((sensor) => (
          <Grid item xs={4}>

            <Card variant="outlined">
              <CardContent>
                <div className={classes.floatRight}>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faWaveSquare}
                      size="sm"
                      className={sensor.online ? classes.green : classes.red}
                    />
                  </ListItemIcon>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faWifi}
                      size="sm"
                      className={sensor.rssi ? getRssiClassname(sensor.rssi.rssi) : classes.red}
                    />
                  </ListItemIcon>
                </div>
                <Typography variant="h6">
                  {sensor.id}
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  {phrases.tables.shared.machine}
                  {' '}
                  {sensor.machine ? (
                    <CustomLink className={classes.link} id={sensor.machine.id} object="machine">
                      {sensor.machine.name}
                      {' '}
                    </CustomLink>
                  )
                    : phrases.modules.sensor.noMachine}
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  {phrases.modules.sensor.lastSeenAt}
                  {' '}
                  {sensor.lastSeenAt ? formatDate(sensor.lastSeenAt) : 'Never.'}
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  {phrases.modules.sensor.online}
                  {' '}
                  {sensor.online ? 'Yes' : 'No'}
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  RSSI:
                  {' '}
                  {sensor.rssi.rssi ? sensor.rssi.rssi : 0}
                  {' '}
                  dBm
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  {phrases.modules.sensor.rssiAverage}
                  {' '}
                  Not implemented yet.
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  {phrases.modules.sensor.version}
                  {' '}
                  {sensor.version ? sensor.version.version : 'Not available.'}
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  {phrases.modules.sensor.hardware}
                  {' '}
                  {sensor.module ? sensor.module.module : 'Not available.'}
                </Typography>

                <Divider />

                <Typography className={classes.typography}>
                  SSID:
                  {' '}
                  {sensor.ssid ? sensor.ssid.ssid : 'Not connected.'}
                </Typography>

                <Divider />

              </CardContent>
            </Card>
          </Grid>
        ))}

      </Grid>

      {isLoadingSensors ? (
        <div style={{ height: '100px' }}>
          {' '}
          {<LoadingIndicator light text="Loading sensors..." />}
          {' '}
        </div>
      ) : ''}

    </div>

  );
};

export default SensorHealth;
