import { useState, useEffect } from 'react';
import axios from 'axios';
import useApi from '@frontend/utils/useApi';
import { usePhrases }               from '@frontend/utils/usePhrases';
import useAlert       from '@frontend/hooks/useAlert';

const useJobsManagement = (modalOpen, jobToDelete) => {
    const [isLoading, setIsLoading] = useState(false);
    const [existingJobs, setExistingJobs] = useState([]);
    const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState(false);
    const api = useApi();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();
    
    const handleDeleteJob = () => {
      // Delete Call hand over to hook
      setIsLoading(true);
      const cancelAxios = axios.CancelToken.source();
        api('/api/orders/jobs?', {
          method: 'delete',
          cancelToken: cancelAxios.token,
          data: {
            ...existingJobs[jobToDelete]
          }
        })
          .then((response) => {
            setIsLoading(false);
            setConfirmDeletionModalOpen(false);
            createAlert(phrases.forms.jobs.jobDeleteSuccess, 'success');
          })
          .catch((error) => {
            setIsLoading(false);
            createAlert(phrases.forms.jobs.jobDeleteError, 'error');
          });
    }
    useEffect(() => {
        setIsLoading(true);
        const cancelAxios = axios.CancelToken.source();
        api('/api/orders/jobs/existingJobs?populate=product intendedMachine', {
          method: 'get',
          cancelToken: cancelAxios.token,
        })
          .then((response) => {
            setExistingJobs(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
            setIsLoading(false);
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen, confirmDeletionModalOpen]);

    return {
        handleDeleteJob,
        isLoading,
        existingJobs,
        confirmDeletionModalOpen,
        setConfirmDeletionModalOpen
    };
}
 
export default useJobsManagement;