import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Chartjs from 'chart.js';

/**
 * Wrapper/Container component for ChartJS.
 */
const KPIChart = (props) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const { chartConfig } = props;

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy();
    }

    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, props.chartConfig);
      setChartInstance(newChartInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer, chartConfig]);

  return (
    <canvas ref={chartContainer} />
  );
};

KPIChart.propTypes = {
  /**
   * ChartJS config object.
   */
  chartConfig: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.array,
    options: PropTypes.array,
  }).isRequired,
};

export default KPIChart;
