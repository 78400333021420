import { useEffect, useState } from 'react';
import axios from 'axios';
import useApi from '@frontend/utils/useApi';

const useInfoView = () => {
    const [content, setContent] = useState("");
    const api = useApi()
    useEffect(() => {
        const cancelAxios = axios.CancelToken.source();
        api(`/api/config/info`, {
            method: 'get',
            cancelToken: cancelAxios.token,
        }).then((response) => {
            console.log("AXIOS RES: ", response);
            setContent(response.data);
            console.log("Content: ", response.data);
        }).catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return {
        content,
        setContent
    };
}
 
export default useInfoView;
