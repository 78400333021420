import { useState, useEffect, useCallback } from 'react';
import useApi from '@frontend/utils/useApi';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import LOADING_PROPS from '@frontend/modules/orders/constants';
import { usePhrases } from '@frontend/utils/usePhrases';

const useJob = (jobId, structureInUse) => {
  const api = useApi();
  const phrases = usePhrases().phrases();
  const [jobData, setJobData] = useState({});
  const [activations, setActivations] = useState([]);
  const [jobsOfStructure, setJobsOfStructure] = useState([]);
  const [notes, setNotes] = useState([]);
  const { formatDate, formatHoursMinutesDuration } = useDateTimeFormat();
  const [structure, setStructure] = useState(true);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [loadingPropsObject, setLoadingPropsObject] = useState({});

  /**
   *
   * @param {String} machineId The unique id of the machine
   * @returns all the Jobs of the machine, that it is engaged in
   */
  const getJobsOfMachineWithinStructure = useCallback((machineId) => {
    // Lets filter them for the machines
    let machJobs = [];
    jobsOfStructure.forEach(job => {
      // Only display all currently active (OPEN, ACTIVE, PAUSED) jobs
      if(job.intendedMachine.id === machineId &&
         job.completionStatus === phrases.modules.orders.ACTIVE ){
        // Machine is contained within the engaged machines
        machJobs.push(job);
      }
    });
    return machJobs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsOfStructure]);
  /**
   * Fetch all the Jobs of the current Structure. If there are none (undefined), this method will return an emtpy array
   */

  const getJobsOfStructure = () => {

      if(structureInUse !== undefined){
        api(`/api/orders/jobs?mongoQuery={"structure": "${structureInUse}"}&populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&sensorData=true`, {
          method: 'get',
        })
        .then((response) => {
          if (response.status === 200 || response.status === 304) {
            setJobsOfStructure(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
      }

  };

  useEffect(()=>{
    getJobsOfStructure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structure])

  const handleSetLoadingProps = (prop, isLoading) => {
    setLoadingPropsObject({
      ...loadingPropsObject,
      [prop]: isLoading,
    });
  };

  const updateHandCount = useCallback((job, handCount) => {
    job.handCount = parseInt(handCount);
    updateJob({...job, handCount});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const updateJob = useCallback((job) => {
    handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, true);
    console.log(job);
    api(
      '/api/orders/jobs?populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product&sensorData=true&activationsData=true',
      {
        method: 'put',
        data: {
          ...job,
        },
      },
    )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setJobData({ ...jobData, ...response.data });
        }
        handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
      })
      .catch(() => {
        handleSetLoadingProps(LOADING_PROPS.UPDATING_JOB, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getActivationRows(_jobData) {
    
    const activations = [];
    Object.keys(_jobData.activations).forEach((key) => {
      activations.push(..._jobData.activations[key]);
    });
    const rows = [];
    for (let i = 0; i < activations.length; i++) {
      const row = {
        id: i,
        status:
          phrases.modules.orders[activations[i].activationStatus],
        machine: activations[i].machine
          ? activations[i].machine.name
          : '',
        intended:
        activations[i].machine === _jobData.intendedMachine
            ? phrases.modules.orders.intendedTable
            : phrases.modules.orders.unintendedTable,
        user: activations[i].user
          ? activations[i].user.name ? 
              activations[i].user.name : 
              activations[i].user
          : '',
        startTime: formatDate(activations[i].time),
        endTime: activations[i].endTime
          ? formatDate(activations[i].endTime)
          : 'N/A',
        duration: activations[i].duration
          ? formatHoursMinutesDuration(activations[i].duration)
          : 'N/A',
        sensorCount: activations[i].partsProduced,
        handCount: activations[i].handCount ?? 0,
      };

      rows.push(row);
    }

    return rows;
  }

  useEffect(() => {
    if(structureInUse){
      api(`/api/structures/${structureInUse}?populate=structure`, {
        method: 'get',
      })
      .then((response) => {
        setStructure(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureInUse]);

  function getNotes(_jobData) {
    const _notes = [];
    if (_jobData.notes) {
      for (let i = 0; i < _jobData.notes.length; i++) {
        const note = {
          createdAt: formatDate(_jobData.notes[i].createdAt),
          user: `${_jobData.notes[i].user.name} (${_jobData.notes[i].user.email})`,
          text: _jobData.notes[i].text,
        };

        _notes.push(note);
      }
    }
    return _notes;
  }

  useEffect(() => {
    setNotes(getNotes(jobData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  useEffect(() => {
    setIsLoadingJob(true);
    if(jobId){
      api(
        `/api/orders/jobs/business-job-id/${jobId}?sensorData=true&activationsData=true&populate=intendedMachine notes product`,
        {
          method: 'get',
        },
      )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setJobData(response.data);
          setActivations(getActivationRows(response.data));
          setNotes(getNotes(response.data));
          setIsLoadingJob(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingJob(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  const addNote = useCallback((note) => {
    handleSetLoadingProps(LOADING_PROPS.ADDING_NOTE, true);
    api(
      `/api/orders/jobs/${jobData.id}/notes?populate=currentlyEngagedMachines previouslyEngagedMachines order structrue notes intendedMachine product`,
      {
        method: 'post',
        data: {
          note,
        },
      },
    )
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setJobData({ ...jobData, ...response.data });
        }
        handleSetLoadingProps(LOADING_PROPS.ADDING_NOTE, false);
      })
      .catch(() => {
        handleSetLoadingProps(LOADING_PROPS.ADDING_NOTE, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  return {
    jobData,
    activations,
    notes,
    jobsOfStructure,
    structure,
    isLoadingJob,
    updateJob,
    loadingPropsObject,
    addNote,
    getJobsOfMachineWithinStructure,
    updateHandCount,
  };
};

export default useJob;
