import React from 'react';
import { 
    Grid,
    Paper,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import {usePhrases} from '@frontend/utils/usePhrases';
import LoadingIndicator from '@frontend/components/LoadingIndicator';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    headingDisplay: {
        flex: 'auto',
    },
    loadingReasonsIndicator: {
      marginLeft: theme.spacing(1),
    },
    bold: {
        fontWeight: 'bold',
    }
}));

const QualityLossCard = (props) => {
    const {
        qualityLossData,
        isLoadingPerformance 
    } = props;
    const { scrapTypeText } = useStatesFilter();
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    return (<>
        <Grid item xs={12}>
            <Paper variant="outlined" square>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <List>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <FontAwesomeIcon
                                    icon={faRecycle}
                                    size="2x"
                                    />
                                </ListItemAvatar>
                                <Typography variant="body1">
                                    {phrases.modules.machinePerformance.qualityLoss}
                                </Typography>
                                <Typography variant="body1" className={classes.loadingReasonsIndicator}>
                                    {isLoadingPerformance ? <LoadingIndicator size={20} /> : ''}
                                </Typography>
                            </ListItem>
                            <Divider variant='inset' component="li" />
                        </List>                      
                        <Table aria-label="simple table">                    
                            <TableBody>
                                {qualityLossData  ? 
                                <>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{``}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span>
                                        &nbsp;
                                        </span>
                                    </TableCell>
                                </TableRow>                               
                                </>    
                                :
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>{phrases.modules.machinePerformance.noPerformance}</Typography>
                                    </TableCell>
                                </TableRow>  
                                }              
                            </TableBody>                    
                        </Table> 
                    </Grid>
                    <Grid item xs={4}>
                        <List>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <FontAwesomeIcon
                                    icon={faRecycle}
                                    size="2x"
                                    />
                                </ListItemAvatar>
                                <Typography variant="body1">
                                    {phrases.modules.machinePerformance.qualityLossesHeading}
                                </Typography>
                                <Typography variant="body1" className={classes.loadingReasonsIndicator}>
                                    {isLoadingPerformance ? <LoadingIndicator size={20} /> : ''}
                                </Typography>
                                <Typography variant="body1" flex align="right" className={classes.headingDisplay}>
                                    {qualityLossData.details?.sortedQualityLosses?.reduce((prev, cur) => {
                                        return prev+cur[1];
                                    }, 0)}
                                    {" "}
                                    {phrases.modules.machinePerformance.unit}
                                </Typography>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <Table aria-label="simple table">                    
                                <TableBody>                                
                                {
                                    qualityLossData.details?.sortedQualityLosses?.map((loss, i) => {
                                        return (
                                        <TableRow>
                                            <TableCell align="left">
                                                <Typography variant="body2">{`${i+1}. ${scrapTypeText(loss[0])}:`}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <span>
                                                {loss[1]}
                                                {" "}
                                                {phrases.modules.machinePerformance.unit}
                                                </span>
                                            </TableCell>
                                        </TableRow>)
                                    })
                                }  
                                </TableBody>                    
                            </Table>                            
                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <Table aria-label="simple table">                    
                            <TableBody>
                                {qualityLossData  ? 
                                <>                                  
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2">{`${phrases.modules.machinePerformance.delta}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span>
                                        {parseFloat(qualityLossData.delta).toFixed(2)}
                                        {' '}
                                        {phrases.modules.machinePerformance.unit}
                                        </span>
                                    </TableCell>
                                </TableRow> 
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="body2" className={classes.bold}>{`${phrases.modules.machinePerformance.total}:`}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body2" className={classes.bold}>
                                            <span>
                                            {parseFloat(qualityLossData.value).toFixed(2)}
                                            {' '}
                                            {phrases.modules.machinePerformance.unit}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow> 
                                </>    
                                :
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>{phrases.modules.machinePerformance.noPerformance}</Typography>
                                    </TableCell>
                                </TableRow>  
                                }              
                            </TableBody>                    
                        </Table>
                    </Grid>
                </Grid>
            </Paper>            
        </Grid>
    </>);
}
 
export default QualityLossCard;