/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { faUserTag, faTag } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import useAlert from '@frontend/hooks/useAlert';
import { persistTableState } from '@frontend/utils/UIActions';
import { useSelector, useDispatch } from 'react-redux';
import CustomTabs from '@frontend/components/Tabs';
import Table from '@frontend/components/Table';
import useApi from '@frontend/utils/useApi';
import Modal from '@frontend/components/Modal';
import { usePhrases } from '@frontend/utils/usePhrases';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity from '@frontend/utils/useSecurity';
import { setTitle } from '@frontend/utils/usePageTitle';
import RoleForm from './RoleForm';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function RolesView() {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { isAuthorized, isRoleSystemAdmin } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  const { createAlert } = useAlert();
  const [data, setData] = useState([]);
  const [roleToEdit, setRoleToEdit] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const tableState = useSelector((state) => state.tableState);
  const api = useApi();
  // let cancelAxios = null;
  setTitle(phrases.pageTitles.rolesManagement);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    dispatch(persistTableState({}));

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editRole = (role) => {
    setRoleToEdit(role);
    handleOpenModal();
  };

  const columns = [
    {
      label: phrases.forms.shared.fields.name,
      name: 'name',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'name' ? tableState.sortOrder.direction : 'none',
      },
    },
    ...conditionalElement(isAuthorized('roles', ['update:any']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => editRole(data.find((role) => role.id === value))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    }),
  ];

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/users/roles', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setData(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    let deleted = true;
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const role = data[index];
      if (isRoleSystemAdmin(role)) {
        createAlert(phrases.forms.user.errors.adminCannotBeDeleted, 'warning');
        deleted = false;
        return;
      }
      api(`/api/users/roles/${role.id}`, {
        method: 'delete',
        cancelToken: cancelAxios.token,
      }).then((response) => {
        if (response.status === 204) {
          data.splice(index, 1);
          setData([...data]);
          createAlert(phrases.forms.user.success.roleDeleted, 'success');
        }
      }).catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
    });
    return deleted;
  };

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/users/roles', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setData(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  const handleCreate = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/users/roles', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setData(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  const getDirection = (direction) => {
    switch (direction) {
      case 'ascending': return 'asc';
      case 'descending': return 'desc';
      default: return 'asc';
    }
  };

  const customOptions = {
    print: false,
    download: false,
    filter: false,
    elevation: 0,
    selectableRows: isAuthorized('machines', ['delete:any', 'delete:own']) ? 'multiple' : 'none',
    onRowsDelete: (obj) => handleDelete(obj),
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({ ...tableState, filterList }));
    },
    onChangePage: (page) => {
      dispatch(persistTableState({ ...tableState, page }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
  };

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          ...conditionalElement(isAuthorized('roles', ['read:any']), { label: phrases.tabs.ExistingRoles, icon: faUserTag }),
          ...conditionalElement(isAuthorized('roles', ['create:any']), { label: phrases.tabs.CreateRole, icon: faTag }),
        ]}
        panels={[
          ...conditionalElement(isAuthorized('roles', ['read:any']), {
            content: <Table
              modalTitle={phrases.nav.roles}
              data={data}
              resource="roles"
              onDelete={handleDelete}
              columns={columns}
              customOptions={customOptions}
            />,
          }),
          ...conditionalElement(isAuthorized('roles', ['create:any']), { content: <RoleForm onCreate={handleCreate} /> }),
        ]}
      />
      <Modal
        modalTitle={phrases.modal.EditRole}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <RoleForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            role={roleToEdit}
          />
        )}
      />
    </div>
  );
}
