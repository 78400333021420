/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import useApi from '@frontend/utils/useApi';
import useValidateRoleForm from './useValidateRoleForm';
import { storeListOfRoles } from '../actions';

const useRoleForm = (callback, role) => {
  const emptyRole = {
    name: '',
    permissions: [],
  };
  const [roleValues, setRoleValues] = useState(role || emptyRole);
  const roles = useSelector((state) => state.roles);
  const [parentRole, setParentRole] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { validateRoleForm } = useValidateRoleForm();
  const dispatch = useDispatch();
  const api = useApi();
  // let cancelAxios = null;

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/users/permissions', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      setPermissions(response.data);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/users/roles', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfRoles(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (errors[event.target.name]) { delete errors[event.target.name]; }

    setIsSubmitting(true);
    setErrors(validateRoleForm(roleValues));
  };

  const handleRoleInheritance = (event) => {
    event.persist();
    const { value } = event.target;
    setParentRole(value);
    setRoleValues((roleValues) => ({ ...roleValues, permissions: value.permissions }));
  };

  const handleChange = (event) => {
    event.persist();
    setRoleValues((roleValues) => ({ ...roleValues, [event.target.name]: event.target.value }));
  };

  const handlePermissionsChange = (event) => {
    event.persist();

    const resource = event.target.name;
    const { value } = event.target;
    const newArr = filterOutDuplicates(value);
    roleValues.permissions = filterElementsWithProp(roleValues.permissions, 'resource', resource).concat(newArr);
    setRoleValues((roleValues) => ({ ...roleValues, permissions: [...roleValues.permissions] }));
  };

  const filterElementsWithProp = (arr, prop, value) => {
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      if (el[prop] === value) {
        arr.splice(i, 1);
        i--;
      }
    }

    return arr;
  };

  const filterOutDuplicates = (value) => {
    const newArr = value;
    let h; let i; let
      j;
    for (h = 0; h < value.length; h++) {
      const currentItem = value[h];
      let foundCount = 0;
      // search the array for an item
      for (i = 0; i < value.length; i++) {
        if (value[i].resource === currentItem.resource && value[i].action === currentItem.action) { foundCount++; }
      }
      if (foundCount > 1) {
        // remove the repeated item from the new array
        for (j = 0; j < newArr.length; j++) {
          if (newArr[j].resource === currentItem.resource && newArr[j].action === currentItem.action) {
            newArr.splice(j, 1);
            j--;
          }
        }
      }
    }
    return newArr;
  };

  const handleDeletePermission = (event, id) => {
    const index = roleValues.permissions.map((e) => e.id).indexOf(id);
    roleValues.permissions.splice(index, 1);
    setRoleValues((roleValues) => ({ ...roleValues }));
  };

  const handleClearForm = () => {
    Object.keys(errors).forEach((key) => {
      delete errors[key];
    });
    setRoleValues(emptyRole);
    setParentRole('');
  };

  return {
    handleChange,
    handleSubmit,
    handleClearForm,
    handlePermissionsChange,
    handleDeletePermission,
    handleRoleInheritance,
    permissions,
    roleValues,
    roles,
    parentRole,
    errors,
  };
};

export default useRoleForm;
