/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';

import {
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { mdiRobotIndustrial } from '@mdi/js';
import { usePhrases } from '@frontend/utils/usePhrases';
import CustomLink from '@frontend/components/CustomLink';
import useIcon from '@frontend/hooks/useIcon';
import { MATERIAL_DESIGN_ICON, ROUTES } from '@frontend/constants';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      float: 'none',
      whiteSpace: 'pre-line',
      paddingTop: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.primary.main,
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      float: 'none',
      whiteSpace: 'pre-line',
      paddingTop: theme.spacing(1),
    },
  },
  linkIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

export default function MachineIndicator({ machine, link }) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const renderIcon = useIcon();

  const getMachineTooltip = (machine) => (
    <>
      <Typography color="inherit" variant="body2">
        {phrases.forms.shared.fields.alias}
        :
        {' '}
        {machine.alias ? machine.alias : '-'}
      </Typography>
      <Typography color="inherit" variant="body2">
        {' '}
        {phrases.forms.shared.fields.type}
        :
        {' '}
        {machine.type ? machine.type.name : '-'}
      </Typography>
    </>
  );

  return (

    <Tooltip
      enterTouchDelay={50}
      leaveTouchDelay={3000}
      title={getMachineTooltip(machine)}
      placement="top-start"
      arrow
    >
      <span>
        {link ? (
          <CustomLink className={classes.link} id={machine.id} object="machine" to={ROUTES.MACHINE_OVERVIEW}>
            {renderIcon(MATERIAL_DESIGN_ICON, mdiRobotIndustrial, {
              size: 0.5,
              className: classes.linkIcon,
            })}
            {machine.name}
          </CustomLink>
        )
          : (
            <Typography className={classes.text} variant="inline">
              {renderIcon(MATERIAL_DESIGN_ICON, mdiRobotIndustrial, {
                size: 0.5,
                className: classes.linkIcon,
              })}
              {machine.name}
            </Typography>
          )}
      </span>

    </Tooltip>
  );
}
