import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { usePhrases } from '@frontend/utils/usePhrases';

const defaultToolbarStyles = {
  iconButton: {
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    minWidth: 120,
    padding: 0,

  },
}));

const CustomToolbar = (props) => {
  const { applySort, selectedSort } = props;
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  const handleChange = (event) => {
    applySort(event.target.value);
  };

  return (
    <>
      <FormControl variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{phrases.misc.sortBy}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedSort}
          onChange={handleChange}
          label={phrases.misc.sortBy}
          style={{ borderRadius: 0 }}
        >
          <MenuItem value={phrases.machineFilters.default}>{phrases.misc.default}</MenuItem>
          <MenuItem value={phrases.machineFilters.availabilityminmax}>
            {phrases.modules.machine.availabilityLowToHigh}
            {' '}
          </MenuItem>
          <MenuItem value={phrases.machineFilters.availabilitymaxmin}>
            {phrases.modules.machine.availabilityHighToLow}
            {' '}
          </MenuItem>
          <MenuItem value={phrases.machineFilters.performanceminmax}>
            {phrases.modules.machine.performanceLowToHigh}
            {' '}
          </MenuItem>
          <MenuItem value={phrases.machineFilters.performancemaxmin}>
            {phrases.modules.machine.performanceHighToLow}
            {' '}
          </MenuItem>
          <MenuItem value={phrases.machineFilters.qualityminmax}>
            {phrases.modules.machine.qualityLowToHigh}
            {' '}
          </MenuItem>
          <MenuItem value={phrases.machineFilters.qualitymaxmin}>
            {phrases.modules.machine.qualityHighToLow}
            {' '}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(CustomToolbar);
