import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  Grid,
  Grow,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';

import logo from '@frontend/static/logo.png';
import useApi from '@frontend/utils/useApi';
import useAlert from '@frontend/hooks/useAlert';

const useStyles = makeStyles((theme) => ({
  imgLogo: {
    marginTop: theme.spacing(8),
    alignItems: 'center',
    margin: 'auto',
  },
  container: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardBody: {
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const ReactError = ({ error, errorInfo }) => {
  const classes = useStyles();
  const api = useApi();
  const { createAlert } = useAlert();

  useEffect(() => {
    api('/api/shared/errors', {
      data: { error: error.toString(), errorInfo, url: window.location.href },
      method: 'post',
    })
      .then((response) => {
        if (response.status === 200) {
          createAlert('The error has been recorded and we will investigate!', 'success');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.container}
      >

        <Grow
          in
          timeout={400}
        >
          <Card
            raised
            className={classes.cardBody}
          >
            <img
              className={classes.imgLogo}
              src={logo}
              width="25%"
              alt="LF logo"
            />
            <CardContent>
              <Typography variant="h5" color="textPrimary" component="h2">
                Sorry, an unexpected error has occurred.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Button fullWidth size="large" color="primary" variant="outlined" onClick={handleRefreshPage}>REFRESH PAGE</Button>
            </CardActions>
          </Card>
        </Grow>

      </Grid>
    </>
  );
};

export default ReactError;
