import React from 'react';
import { usePhrases } from '@frontend/utils/usePhrases';
import {
    Grid,
    Typography,
    FormGroup,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    Switch,
  } from '@material-ui/core';
import { ORDERS_TIMEFRAME_VALUES } from "@frontend/constants"
const OrderTimeFramePicker = (props) => {

    const phrases = usePhrases().phrases();
    const{
        fullTimeSelect,
        setFullTimeSelect,
        timeFrame,
        setTimeFrame,
        timeFrameDisabled,
        setTimeFrameDisabled
    } = props;
    const handleTimeFrameChange = (event) => {
        setTimeFrame(event.target.value);
    }
    
    const handleFullTimeSelectChange = (event) => {
        setFullTimeSelect(event.target.checked);        
        // No Timeframe Select if full time
        setTimeFrameDisabled(event.target.checked)        
    }

    return ( 
        <>
            <Grid item xs={2}>
                <Typography align="left">
                <FormGroup>
                    <FormControlLabel control={
                    <Switch 
                        checked={fullTimeSelect}
                        color='primary'
                        onChange={handleFullTimeSelectChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    >

                    </Switch>
                    } label={fullTimeSelect ? phrases.modules.orders.fulltime: phrases.modules.orders.timeframe}></FormControlLabel>
                </FormGroup>       
                </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="left"> 
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    color='primary'
                    defaultValue={ORDERS_TIMEFRAME_VALUES.TWOWEEKS}
                    
                    value={timeFrame}
                    onChange={handleTimeFrameChange}
                  >
                    <FormControlLabel value={ORDERS_TIMEFRAME_VALUES.TWOWEEKS} checked={timeFrame === ORDERS_TIMEFRAME_VALUES.TWOWEEKS} disabled={timeFrameDisabled} control={<Radio color='primary' />} label={phrases.modules.orders.pastTwoWeeks} />
                    <FormControlLabel value={ORDERS_TIMEFRAME_VALUES.MONTH} checked={timeFrame === ORDERS_TIMEFRAME_VALUES.MONTH} disabled={timeFrameDisabled} control={<Radio color='primary' />} label={phrases.modules.orders.pastMonth} />
                    <FormControlLabel value={ORDERS_TIMEFRAME_VALUES.SIXMONTHS} checked={timeFrame === ORDERS_TIMEFRAME_VALUES.SIXMONTHS} disabled={timeFrameDisabled} control={<Radio color='primary' />} label={phrases.modules.orders.pastSixMonths} />
                    <FormControlLabel value={ORDERS_TIMEFRAME_VALUES.YEAR} checked={timeFrame === ORDERS_TIMEFRAME_VALUES.YEAR} disabled={timeFrameDisabled} control={<Radio color='primary' />} label={phrases.modules.orders.pastYear} />                    
                  </RadioGroup>
                </FormControl>           
            </Typography>
          </Grid>
        </>
     );
}
 

export default OrderTimeFramePicker;