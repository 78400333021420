import {
    timeFormatLocale,
    timeSecond,
    timeMinute,
    timeHour,
    timeDay,
    timeWeek,
    timeMonth,
    timeYear,
  } from 'd3';
import useBreakpoint      from '@frontend/hooks/useCurrentBreakpoint';

const useTicks = (localeDef) => {
    const currentBreakpoint = useBreakpoint();
    const timeFormatter     = timeFormatLocale(localeDef);
    const timeFormat = timeFormatter.format;

    const formatMillisecond = timeFormat('.%L');
    const formatSecond = timeFormat(':%S');
    const formatMinute = timeFormat('%H:%M');
    const formatHour = timeFormat('%H:%M');
    const formatDay = timeFormat('%a %d');
    const formatWeek = timeFormat('%b %d');
    const formatMonth = timeFormat('%B %d');
    const formatYear = timeFormat('%Y');

    function multiFormat(date) {
        return (timeSecond(date) < date ? formatMillisecond
          : timeMinute(date) < date ? formatSecond
            : timeHour(date) < date ? formatMinute
              : timeDay(date) < date ? formatHour
                : timeMonth(date) < date ? (timeWeek(date) < date ? formatDay : formatWeek)
                  : timeYear(date) < date ? formatMonth
                    : formatYear)(date);
      }
    
      function ticksTime(_duration) {
        return (_duration <= 1 ? timeMinute.every(currentBreakpoint()
          .down('sm') ? 15 : 15)
          : _duration <= 12 ? timeHour.every(currentBreakpoint()
            .down('sm') ? 2 : 1)
            : _duration <= 24 ? timeHour.every(currentBreakpoint()
              .down('sm') ? 4 : 2)
              : _duration <= 2 * 24 ? timeHour.every(currentBreakpoint()
                .down('sm') ? 8 : 4)
                : _duration <= 7 * 24 ? timeHour.every(currentBreakpoint()
                  .down('sm') ? 24 : 12)
                  : _duration <= 14 * 24 ? timeDay.every(currentBreakpoint()
                    .down('sm') ? 2 : 1)
                    : _duration <= 21 * 24 ? timeDay.every(currentBreakpoint()
                      .down('sm') ? 4 : 2)
                      : _duration <= 30 * 24 ? timeDay.every(currentBreakpoint()
                        .down('sm') ? 6 : 3)
                        : _duration < 6 * 30 * 24 ? timeWeek.every(currentBreakpoint()
                          .down('sm') ? 2 : 1)
                          : timeMonth.every(currentBreakpoint()
                            .down('sm')? 2 : 1));
      }
      
    return {
        ticksTime,
        multiFormat,
    };
}
 
export default useTicks;