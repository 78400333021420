import React from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { mdiRobotIndustrial } from '@mdi/js';

import CustomLink from '@frontend/components/CustomLink';
import { closeSelectionDrawer } from '@frontend/utils/UIActions';
import { usePhrases } from '@frontend/utils/usePhrases';
import useCurrentBreakpoint from '@frontend/hooks/useCurrentBreakpoint';
import { ROUTES, MATERIAL_DESIGN_ICON } from '@frontend/constants';
import useIcon from '@frontend/hooks/useIcon';

const useStyles = makeStyles((theme) => ({
  machinesDrawer: {
    zIndex: theme.zIndex.appBar - 2,
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.appBar - 1,
    },
    position: 'absolute',
    width: 'auto',
  },
  machinesDrawerPaper: {
    minWidth: 300,
    width: 'auto',
    overflowY: 'auto',
    boxShadow: '5px 8px 19px 0px rgba(0,0,0,0.22)',
  },
  toolbar: {
    marginTop: theme.spacing(1),
    ...theme.mixins.toolbar,
  },
  indented: {
    marginLeft: 25,
  },
  closeDrawerBtn: {
    padding: theme.spacing(1.45),
    borderRadius: 0,
  },
  machinesList: {
    paddingTop: 0,
  },
  listSubheader: {
    position: 'relative',
  },
}));

const MachinesDrawer = (props) => {
  const classes = useStyles();
  const {
    machines,
    closeMachineDrawer,
    selectedStructure,
    structureDrawerWidth,
    open,
  } = props;
  const phrases = usePhrases().phrases();
  const currentBreakpoint = useCurrentBreakpoint();
  const dispatch = useDispatch();
  const renderIcon = useIcon();

  const onSelectMachine = (e) => {
    dispatch(closeSelectionDrawer());
    closeMachineDrawer();
  };

  const selectStructureOverview = (e) => {
    closeMachineDrawer();
    dispatch(closeSelectionDrawer());
  };

  return (
    <Drawer
      className={classes.machinesDrawer}
      variant="persistent"
      anchor="left"
      open={open}
      PaperProps={currentBreakpoint().down('sm') ? {
        style: {
          marginLeft: 0,
          minWidth: structureDrawerWidth,
        },
      }
        : {
          style: {
            marginLeft: structureDrawerWidth,
            minWidth: structureDrawerWidth,
          },
        }}
      classes={{
        paper: classes.machinesDrawerPaper,
      }}
    >
      <Divider />
      <div className={classes.toolbar} />

      <ListItem
        key={uuid()}
        button
        className={classes.closeDrawerBtn}
        onClick={closeMachineDrawer}
      >
        <ListItemIcon>
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="lg"
          />
        </ListItemIcon>
        <ListItemText primary={phrases.forms.shared.buttons.close} />
      </ListItem>

      <Divider />

      <List className={classes.machinesList}>
        <ListItem
          key={uuid()}
          to={`${ROUTES.STRUCTURE_OVERVIEW}/${selectedStructure.id}`}
          button
          onClick={selectStructureOverview}
          component={CustomLink}
        >
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faIndustry}
              size="2x"
            />
          </ListItemIcon>
          <ListItemText primary={`${selectedStructure.name} ${phrases.misc.overview}`} />
        </ListItem>

        <Divider variant="middle" />

        {Object.keys(machines).length ? Object.keys(machines).map((title) => (
          <div key={uuid()}>
            <ListSubheader className={classes.listSubheader}>{title}</ListSubheader>
            {machines[title].map((machine) => (
              <ListItem
                key={machine.id}
                className={classes.indented}
                button
                onClick={onSelectMachine}
                component={CustomLink}
                object="machine"
                id={machine.id}
              >
                <ListItemIcon>
                  {renderIcon(MATERIAL_DESIGN_ICON, mdiRobotIndustrial)}
                </ListItemIcon>
                <ListItemText primary={machine.name} />
              </ListItem>
            ))}
          </div>
        ))
          : (

            <ListItem key="no-machines">
              <ListItemText primary={phrases.misc.noMachinesInThisStructure} />
            </ListItem>
          )}
      </List>
    </Drawer>
  );
};

MachinesDrawer.propTypes = {
  machines: PropTypes.instanceOf(Object).isRequired,
  closeMachineDrawer: PropTypes.func.isRequired,
  selectedStructure: PropTypes.instanceOf(Object).isRequired,
  structureDrawerWidth: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
};

export default MachinesDrawer;
