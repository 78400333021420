import { useEffect, useState } from 'react';
import axios from 'axios';
import useApi from '@frontend/utils/useApi';

const useSimpleMachine2 = (machineId) => {
    const [timeLocation, setTimeLocation] = useState({});
    const [speedLow, setSpeedLow] = useState(0.0);
    const [speedMedium, setSpeedMedium] = useState(0.0);
    const api = useApi();  
    
    useEffect(() => {
        const cancelAxios = axios.CancelToken.source();
        api(`/api/machines/${machineId}?populate=structure`,  {
            method: 'get',
            cancelToken: cancelAxios.token,
        })
        .then((response) => {
            setSpeedLow(parseFloat(response.data.config?.speedLow) ?? 0.0);
            setSpeedMedium(parseFloat(response.data.config?.speedMedium) ?? 0.0);
            setTimeLocation({
                machineName: response.data.name,
                timezone: response.data.timezone,
                city: response.data.structure.city
            });
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        timeLocation,
        speedLow,
        speedMedium,
    };
}
 
export default useSimpleMachine2;