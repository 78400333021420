/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';

import useAlert from '@frontend/hooks/useAlert';
import { usePhrases } from '@frontend/utils/usePhrases';
import useApi from '@frontend/utils/useApi';
import useSensorTypeForm from './hooks/useSensorTypeForm';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  dBlock: {
    display: 'block',
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
}));

export default function SensorTypeForm(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { createAlert } = useAlert();
  const {
    handleChange, handleSubmit, handleClearForm, sensorTypeValues, errors,
  } = useSensorTypeForm(submitForm, props.sensorType);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const isEdit = !!props.isEdit;
  const api = useApi();

  function submitForm() {
    setFormSubmitting(true);

    const sensorType = {
      ...sensorTypeValues,
    };

    api('/api/sensors/sensor-types', {
      data: sensorType,
      method: isEdit ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 201) {
          props.onCreate(response.data);
          setFormSubmitting(false);
          handleClearForm();
          createAlert(phrases.forms.sensor.success.sensorTypeAdded, 'success');
        } else if (response.status === 200) {
          if (isEdit) {
            props.onEdit(response.data);
            setFormSubmitting(false);
            createAlert(phrases.forms.sensor.success.sensorTypeUpdated, 'success');
            props.handleCloseModal();
          }
        }
      }).catch((error) => {
        const errorMessage = phrases.forms.sensor.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            setFormSubmitting(false);
            createAlert(errorMessage, 'error');
          } else if (error.response.status === 403) {
            setFormSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            setFormSubmitting(false);
            createAlert(errorMessage, 'error');
          }
        }
      });
  }

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        id="name-field"
        name="name"
        label={phrases.forms.shared.fields.name}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={sensorTypeValues.name}
        margin="dense"
        error={errors.name}
        helperText={errors.name}
        required
      />
      <TextField
        id="name-field"
        name="measurementUnit"
        label={phrases.forms.sensor.fields.measurementUnit}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={sensorTypeValues.measurementUnit}
        margin="dense"
        error={errors.measurementUnit}
        helperText={errors.measurementUnit}
        required
      />
      <div className={classes.formButtons}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.formBtn}
          disabled={formSubmitting}

        >
          {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
          {formSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
        </Button>
        {!isEdit && <Button
          variant="outlined"
          className={classes.formBtn}
          onClick={handleClearForm}
        >
          {phrases.forms.shared.buttons.clear}
        </Button>}
        {isEdit
          && (
            <Button
              variant="outlined"
              className={classes.formBtn}
              onClick={props.handleCloseModal}
            >
              {phrases.forms.shared.buttons.close}
            </Button>
          )}
      </div>
    </form>
  );
}
