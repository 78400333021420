import React from 'react';
import { usePhrases } from '@frontend/utils/usePhrases';
import {
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useUnitCalculation   from '@frontend/hooks/useUnitCalculation';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    redText: {
        color: "red"
    },
    greenText: {
        color: "green"
    },
    yellowText: {
        color: "yellow"
    }
  }));
const ActualSpeedCard = (props) => {
    const { 
        actualSpeed,
        speedLow,
        speedMedium,
        targetCycleTime,
        unitOverride
    } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const { 
        renderUnit,
        renderUnitName
      } = useUnitCalculation(unitOverride); 
    return (
        <Grid item xs={4}>
            <Paper className={classes.paper} variant="outlined" square>
                <Typography align='center' variant="h4" display="block" color="textSecondary">
                    {phrases.modules.simple_machine2.currentSpeed}
                    {"-"}
                    {renderUnitName(true)}
                </Typography>
                <br />
                <Typography align='center' variant="h2" display="block" className={(actualSpeed/targetCycleTime) <= speedLow ? classes.redText : (actualSpeed/targetCycleTime)<= speedMedium ? classes.yellowText: classes.greenText}>
                    <b>{actualSpeed ? parseFloat(renderUnit(actualSpeed, "PPM", true)).toFixed(0): "--"}</b>                    
                </Typography>
            </Paper>
        </Grid>
        );
}
 
export default ActualSpeedCard;
