const sensorsReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_LIST_SENSORS':
      return action.sensors;
    case 'REMOVE_LIST_SENSORS':
      return [];
    default:
      return state;
  }
};

export default sensorsReducer;
