/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

import useApi from '@frontend/utils/useApi';
import {
  openSelectionDrawer, setMachineInUse, setMachineViewInUse, setStructureViewInUse
} from '@frontend/utils/UIActions';
import { setTitle } from '@frontend/utils/usePageTitle';

import useAlert from '@frontend/hooks/useAlert';
import { usePhrases } from '@frontend/utils/usePhrases';
import useSecurity from '@frontend/utils/useSecurity';
import { storeListOfMachines, storeSingleMachine } from '@frontend/modules/machine/actions';
import useMachinesWithStates from './useMachinesWithStates';

const useMachine = (machineID) => {
  const placeholderMachine = {
    name: '',
    businessId: '',
    placeNumber: '',
    manufacturer: '',
    alias: '',
    description: '',
    isActive: false,
    config: {},
    machineKPI: {
      availability: '0%',
      runtime: 0,
      downtime: 0,
      running: false,
    },
    structure: null,
    type: null,
  };

  const machineInUse = useSelector((state) => state.machineInUse);
  const machine = useSelector((state) => state.machine);
  const machines = useSelector((state) => state.machines);
  const user = useSelector((state) => state.user);
  const [machineId, setMachineId] = useState(machineID || machineInUse || user.views.machine.id);
  const {
    setMachineStates,
    isLoadingMachines,
    sensors,
    refreshPulse,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
    changeDataLayer,
  } = useMachinesWithStates();
  const dispatch = useDispatch();
  const history = useHistory();
  const { createAlert } = useAlert();
  const api = useApi();
  // let cancelAxios = null;
  const { getProfile } = useSecurity();
  const phrases = usePhrases().phrases();

  useEffect(() => {
    const cancelAxios = Axios.CancelToken.source();

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    if (machine.id && machineId && machineInUse !== machineId) {
      dispatch(setMachineInUse(machine.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machine]);

  useEffect(() => {
    const cancelAxios = Axios.CancelToken.source();
    if (!machineId) {
      dispatch(openSelectionDrawer());
      createAlert(phrases.misc.selectMachineYouWantToView, 'info', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
          preventDuplicate: true,
          autoHideDuration: 100,
        },
      });
      return;
    }
    api(`/api/machines/${machineId}?populate=structure`, {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      if (response.status === 200 || response.status === 304) {
        setTitle(`${response.data.name} (${response.data.id})`);
        /*
        * useMachinesWithStates only works with arrays,
        * we need to wrap this response(which is a single machine) into an array
        * */
        dispatch(storeListOfMachines([response.data]));
        setMachineStates([response.data]);
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          createAlert(phrases.errorMessages.notAuthorizedToViewMachine, 'error');
        } else {
          createAlert(phrases.errorMessages.machineDoesNotExist, 'warning');
        }
        history.push(getProfile().views.homeView);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
    * Because useMachineWithStates hook works with an array,
    * here we need to use the first element from that array.
    *
    * When used on MachineOverview, since we are interested in a single machine,
    * this array will always have only one machine in it, therefore we can
    * have this hardcoded.
    * */
  useEffect(() => {
    const tempMachine = _.first(machines);
    if (tempMachine) { dispatch(storeSingleMachine((_.first(machines)))); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines]);

  return {
    machine,
    sensors,
    isLoadingMachines,
    refreshPulse,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
    changeDataLayer,
  };
};

export default useMachine;
