import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';

import CustomLink from '@frontend/components/CustomLink';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  structureTitleOnProdcutionPulse: {
    display: 'inline',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const StructureIndicator = (props) => {
  const classes = useStyles();
  const {
    structure,
    machineStructure,
  } = props;

  const structurePathFormat = (_structure, _machineStructure) => {
    if (!_machineStructure.path) return _machineStructure.name;
    const structurePath = _machineStructure.path.replace(_structure.path ? _structure.path : _structure.name, '');
    const arr = structurePath.split('/');
    const path = arr.slice(1, arr.length - 1);
    const name = _.last(arr);

    return (
      <>
        {name && (
        <Typography className={classes.inline} variant="body2" color="textSecondary">
          (
          {`${path.join('/')}${path.length ? '/' : ''}`}
          <Typography component={CustomLink} object="structure" id={_machineStructure.id} variant="button" color="primary">
            {name}
          </Typography>
          )
        </Typography>
        )}
      </>
    );
  };

  return (
    <>

      {machineStructure

        ? (
          <Typography className={classes.structureTitleOnProdcutionPulse} variant="body2">
            {structurePathFormat(structure, machineStructure)}
          </Typography>
        ) : ''}
    </>

  );
};

StructureIndicator.propTypes = {
  structure: PropTypes.instanceOf(Object).isRequired,
};

export default StructureIndicator;
