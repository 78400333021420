/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  Menu,
} from '@material-ui/core';
import useAlert from '@frontend/hooks/useAlert';
import StyledSelect from '@frontend/components/StyledSelect';
import useApi from '@frontend/utils/useApi';
import useMachineStatusForm
  from '@frontend/modules/machine/machine.statuses/hooks/useMachineStatusForm';
import { usePhrases } from '@frontend/utils/usePhrases';
import ConfigObjectForm from '@frontend/components/ConfigObjectForm';
import useSecurity from '@frontend/utils/useSecurity';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
  colorInputField: {
    width: '33%',
  },
  machineStatusConfig: {
    marginBottom: theme.spacing(2),
  },
  colorMenuPopup: {
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  colorButton: {
    height: theme.spacing(5),
    width: theme.spacing(15),
    cursor: 'default',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  endAdornment: {
    paddingRight: 0,
  },
}));

export default function MachineStatusForm(props) {
  const classes = useStyles();

  const phrases = usePhrases()
    .phrases();
  const { createAlert } = useAlert();
  const { isSystemAdmin, getProfile } = useSecurity();
  const api = useApi();

  const {
    handleChange,
    handleSubmit,
    handleColorChange,
    machineStatusValues,
    setMachineStatusConfig,
    handleClearForm,
    dataLayers,
    categories,
    errors,
  } = useMachineStatusForm(submitForm, props.machineStatus);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState();
  const isEdit = !!props.isEdit;

  function submitForm() {
    setFormSubmitting(true);

    const machineStatus = {
      ...machineStatusValues,
    };
    api('/api/machines/machine-statuses', {
      data: machineStatus,
      method: isEdit ? 'put' : 'post',
    })
      .then((response) => {
        if (response.status === 201) {
          props.onCreate();
          createAlert(phrases.forms.machineStatus.success.machineStatusAdded, 'success');
          setFormSubmitting(false);
          handleClearForm();
        } else if (response.status === 200) {
          if (isEdit) {
            props.onEdit(response.data);
            createAlert(phrases.forms.machineStatus.success.machineStatusUpdated, 'success');
            setFormSubmitting(false);
            props.handleCloseModal();
          }
        }
      })
      .catch((error) => {
        const errorMessage = phrases.forms.machine.errors.genericError;
        if (error.response) {
          if (error.response.status === 404) {
            createAlert(errorMessage, 'error');
            setFormSubmitting(false);
          } else if (error.response.status === 403) {
            setFormSubmitting(false);
            createAlert(phrases.errorMessages.notAuthorized, 'error');
          } else {
            createAlert(errorMessage, 'error');
            setFormSubmitting(false);
          }
        }
      });
  }

  const handleOpenColorPicker = (event) => setColorPickerAnchorEl(event.currentTarget);
  const handleCloseColorPicker = () => setColorPickerAnchorEl(null);

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit} autoComplete="off">
      <TextField
        id="name"
        name="name"
        label={phrases.forms.machineStatus.fields.name}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineStatusValues.name}
        error={errors.name}
        margin="dense"
        helperText={errors.name}
        required
      />
      <TextField
        id="activity"
        name="activity"
        label={phrases.forms.machineStatus.fields.activity}
        variant="outlined"
        fullWidth
        className={classes.inputField}
        onChange={handleChange}
        value={machineStatusValues.activity}
        error={errors.activity}
        margin="dense"
        helperText={errors.activity}
        required
      />
      <TextField
        id="color"
        name="color"
        label={phrases.forms.machineStatus.fields.color}
        variant="outlined"
        className={classes.colorInputField}
        value={machineStatusValues.color}
        margin="dense"
        required
        onClick={handleOpenColorPicker}
        InputProps={{
          endAdornment:
            <InputAdornment style={{ paddingRight: 0 }} disablePointerEvents variant="standard"
              position="end">
              <Button
                style={{ backgroundColor: machineStatusValues.color }}
                disableRipple
                className={classes.colorButton}
                aria-controls="color-menu"
                aria-haspopup="true" />
            </InputAdornment>,
          classes: {
            adornedEnd: classes.endAdornment
          }
        }}
      />

      <Menu
        id="color-menu"
        classes={{
          list: classes.colorMenuPopup,
        }}
        anchorEl={colorPickerAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(colorPickerAnchorEl)}
        onClose={handleCloseColorPicker}
      >
        <SwatchesPicker
          onChange={handleColorChange}
          onChangeComplete={() => setColorPickerAnchorEl(null)}
        />
      </Menu>
      <StyledSelect
        label={phrases.forms.reason.fields.category}
        labelId="type-label"
        id="category"
        name="category"
        error={errors.category}
        helperText={errors.category}
        value={machineStatusValues.category}
        onChange={handleChange}
        data={categories}
        required
      />

      <StyledSelect
        label={phrases.forms.machineStatus.fields.dataLayer}
        labelId="type-label"
        id="dataLayer"
        name="dataLayer"
        error={errors.dataLayer}
        helperText={errors.dataLayer}
        value={machineStatusValues.dataLayer}
        onChange={handleChange}
        data={dataLayers}
        required
      />

      {isSystemAdmin(getProfile) && <div className={classes.machineStatusConfig}>
        <ConfigObjectForm
          id="config"
          modalTitle={phrases.forms.machineStatus.fields.machineStatusConfiguration}
          isEdit={isEdit}
          object={machineStatusValues}
          setObjectConfig={setMachineStatusConfig}
        />
      </div>}

      <div className={classes.formButtons}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.formBtn}
          disabled={formSubmitting}
        >
          {isEdit ? phrases.forms.shared.buttons.save : phrases.forms.shared.buttons.submit}
          {formSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
        </Button>
        {!isEdit && <Button
          variant="outlined"
          className={classes.formBtn}
          onClick={handleClearForm}
        >
          {phrases.forms.shared.buttons.clear}
        </Button>}
        {isEdit
          && (
            <Button
              variant="outlined"
              className={classes.formBtn}
              onClick={props.handleCloseModal}
            >
              {phrases.forms.shared.buttons.close}
            </Button>
          )}
      </div>
    </form>
  );
}
