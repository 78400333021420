const useConditionalRender = () => {
  const conditionalRender = (condition, component) => {
    if (condition) return component;
    return '';
  };

  return {
    conditionalRender,
  };
};

export default useConditionalRender;
