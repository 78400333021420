
import { useDispatch, useSelector } from 'react-redux';

import useApi from '@frontend/utils/useApi';
import { setLoadedModules } from './loaderActions';

const Loader = () => {
  const dispatch = useDispatch();
  const loadedModules = useSelector(state => state.loadedModules);
  const api = useApi();

  const loadModuleComponents = (moduleDescriptors) => {
    return Promise.all(moduleDescriptors.map(async (moduleDescriptor) => {
      const path = moduleDescriptor.frontendModuleComponent.entryPoint;
      const component = await import(`../modules/${moduleDescriptor.name}/${path}`);
      return {
        component: component.default,
        route: moduleDescriptor.frontendModuleComponent.route,
      };
    }));
  };

  const loadModuleDescriptors = () => {
    return api('/api/config', {
      method: 'get',
    })
      .then((response) => {
        dispatch(setLoadedModules(response.data))
      }).catch((error) => console.log(error))
  };

  const moduleLoaded = (moduleName) => {
    return loadedModules.find((module) => moduleName === module.name);
  };

  return {
    loadModuleComponents,
    loadModuleDescriptors,
    moduleLoaded
  };

};

export default Loader;