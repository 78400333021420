import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import useInfoView from './hooks/useInfoView';

const InfoView = () => {

  const { 
    content
  } = useInfoView();
 
  //console.log("CONTENT: ", content);

  var moduleList = content.modules?.map(function(module) {
    return <ListItem>
      <ListItemText primary={module.name} secondary={module.version} />
      </ListItem>
  })

  return (
    //<span>{JSON.stringify(content)}</span>
    <List sx={{ width: '100%', maxWidth: 360 }}>
      <ListItem>
        <ListItemText primary="leanfocus system" secondary={content.system?.version} />
      </ListItem>
      <ListItem>
        <ListItemText primary="leanfocus git" secondary={content.system?.git} />
      </ListItem>
      {moduleList}
    </List>
  );
};

export default InfoView;
