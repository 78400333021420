/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import useValidateMachineTypeForm from './useValidateMachineTypeForm';

const useMachineTypeForm = (callback, machineType) => {
  const emptyMachineType = {
    name: '',
    measurementUnit: '',
  };

  const [machineTypeValues, setMachineTypeValues] = useState(machineType || emptyMachineType);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { validateMachineTypeForm } = useValidateMachineTypeForm();

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validateMachineTypeForm(machineTypeValues));
  };

  const handleChange = (event) => {
    event.persist();
    if (errors[event.target.name]) { delete errors[event.target.name]; }
    setMachineTypeValues((machineValues) => ({ ...machineValues, [event.target.name]: event.target.value }));
  };

  const handleClearForm = () => {
    Object.keys(errors).forEach((key) => {
      delete errors[key];
    });
    setMachineTypeValues(emptyMachineType);
  };

  return {
    handleChange,
    handleSubmit,
    handleClearForm,
    machineTypeValues,
    errors,
  };
};

export default useMachineTypeForm;
