import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  CardActions,
  IconButton,
  Collapse,
  CircularProgress,
  Button,
  TextField,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { usePhrases } from '@frontend/utils/usePhrases';
import useLogin from '@frontend/modules/terminal/hooks/useTerminalLogin';
import { setTitle } from '@frontend/utils/usePageTitle';

const useStyles = makeStyles((theme) => ({

  signInHeader: {
    paddingBottom: theme.spacing(0),
  },
  cardContent: {
    paddingTop: theme.spacing(0),
  },
  loginAlert: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(2),
  },
  buttonLogin: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  keyboard: {
    marginTop: theme.spacing(0),
  },
  vertical: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  keyboardButtons: {
   color: '#F67130',
  },

}));

const UserLoginForm = (props) => {
  const { 
    isSimpleMachineLogin
  } = props;
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  // **** keyboard ****

  const [inputs, setInputs] = useState({ username: '', pin: '' });
  const [inputName, setInputName] = useState('');
  const keyboard = useRef();

  const onChangeAll = (inputs) => {
    setInputs({ ...inputs });
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;

    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = (inputName) => inputs[inputName] || '';

  // **** end keyboard ****

  const {
    errors,
    handleSubmit,
    loginErrorText,
    openAlert,
    setOpenAlert,
    isSubmitting,
  } = useLogin(inputs, isSimpleMachineLogin);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  setTitle(phrases.pageTitles.signIn);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (isEmpty(inputs)) return;

    if (inputs.username && inputs.pin) {
      if (inputs.username.trim() && inputs.pin.trim()) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  }, [inputs]);

  return (
    <form autoComplete="on" onSubmit={handleSubmit}>

      <Collapse in={openAlert} className={classes.loginAlert}>
        <Alert
          variant="filled"
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        >
          {loginErrorText}
        </Alert>
      </Collapse>

      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>

          <Grid item xs={6}>

            <div className={classes.vertical}>
              <TextField
                error={!!errors.username}
                helperText={errors.username}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="username"
                label={phrases.forms.terminal.fields.username}
                name="username"
                type="text"
                autoFocus
                value={getInputValue('username')}
                onFocus={() => setInputName('username')}
                onChange={onChangeInput}
              />

              <TextField
                error={!!errors.pin}
                helperText={errors.pin}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="pin"
                label={phrases.forms.terminal.fields.pin}
                type="password"
                id="pin"
                value={getInputValue('pin')}
                onFocus={() => setInputName('pin')}
                onChange={onChangeInput}

              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              inputName={inputName}
              onChangeAll={onChangeAll}
              layout={{
                default: [
                  '0 1 2 3 4 5 6 7 8 9',
                  'q w e r t y u i o p',
                  'a s d f g h j k l',
                  'z x c v b n m',
                  '{backspace}',
                ],
              }}
              buttonTheme={[
                {
                  class: classes.keyboardButtons,
                  buttons:  '0 1 2 3 4 5 6 7 8 9 q w e r t y u i o p a s d f g h j k l z x c v b n m {backspace}'
                }
              ]}
              display={{
                '{backspace}': '⌫',
              }}
            />
          </Grid>

        </Grid>

      </CardContent>

      <CardActions>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonLogin}
          disabled={isButtonDisabled ? true : isSubmitting}
        >
          {phrases.modules.user.signIn}
          {isSubmitting
            && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={17} />}
        </Button>

      </CardActions>

    </form>
  );
};

export default UserLoginForm;
