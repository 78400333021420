const useConditionalArrayElement = () => {
  const conditionalElement = (condition, element) => {
    if (condition) return [element];
    return [];
  };

  return {
    conditionalElement,
  };
};

export default useConditionalArrayElement;
