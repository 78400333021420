/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Table,
  TableBody,
  TextField,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { usePhrases } from '@frontend/utils/usePhrases';
import StyledSelect from '@frontend/components/StyledSelect';
import {MACHINE_CONFIG_VALUES} from '@frontend/constants';

const useStyles = makeStyles((theme) => ({
  inputField: {
    display: 'flex',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
    },
    margin: 0,
  },
  selectField: {
    marginRight: theme.spacing(1),
  },

  table: {
    margin: 0,
    marginTop: theme.spacing(2),
    padding: 0,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  addBtn: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  removeBtn: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      float: 'right',
      marginRight: 0,
    },
  },
  machineConfigLabel: {
    color: '#757575',
    fontSize: 16,
  },
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '&:nth-child(1)': {
        borderBottom: 'none',
      },
    },
  },
  configTable:{
    color: '#757575',
    fontSize: 16,
    marginTop: theme.spacing(4),
  }
}));

export default function MachineConfigForm(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();

  const [tempObjects, setTempObjects] = useState([]);
  const [disabledAddField, setDisabledAddField] = useState(true);
  const [selectedConfigField, setSelectedConfigField] = useState('');
  const [selectLabelText, setSelectLabelText] = useState(phrases.forms.machine.fields.selectConfigurationValue);
  const [customSelectFieldValue, setCustomSelectFieldValue] = useState('');
  const [selectFields, setSelectFields] = useState([]);
  const [placeHolderSelectValue, setPlaceHolderSelectValue] = useState("");

  const handleChange = (event) => {
    setSelectedConfigField(event.target.value);
    setPlaceHolderSelectValue(`${MACHINE_CONFIG_VALUES[event.target.value.value]?.default} [${phrases.forms.machine.configUnits[event.target.value.value]}]`);
    setSelectLabelText(phrases.forms.machine.configLabels[event.target.value.value]);
  };

  useEffect(() => {;
    let tmpArr = Object.keys(MACHINE_CONFIG_VALUES).map((field) => ({name: phrases.forms.machine.configLabels[field], value: field}));    
    tempObjects.forEach((obj) => {
      tmpArr = tmpArr.filter((field) => field.value !== obj.name)   
    });
    setSelectFields(tmpArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempObjects]);

  useEffect(() => {
    const tempArr = [];
    if (props.machine.config) {
      Object.keys(props.machine.config).forEach((key) => {
        tempArr.push({
          name: key,
          value: props.machine.config[key],
        });
        
      });
      setDisabledAddField(false);
    }
    // if (!tempArr.length) {
    //   tempArr.push({ name: '', value: '' });
    //   setDisabledAddField(true);
    // }
    setTempObjects(tempArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.machine]);

  const updateConfig = () => {
    const temp = {};
    tempObjects.forEach((obj) => {
      if (obj.name) { temp[obj.name] = obj.value; }
    });
    props.setMachineConfig(temp);
  };

  const addField = () => {
    tempObjects.push({ name: '', value: '' });
    setTempObjects([...tempObjects]);
    setDisabledAddField(true);
  };
  
  const addFieldSelect = () => {
    setCustomSelectFieldValue('');
    setSelectedConfigField('');
    tempObjects.push({ name: selectedConfigField.value, value: customSelectFieldValue });
    setTempObjects([...tempObjects]);
    setDisabledAddField(true);
    updateConfig();
  };

  const deleteField = (i) => {
    tempObjects.splice(i, 1);
    setTempObjects([...tempObjects]);
    setDisabledAddField(false);
    updateConfig();
  };

  const handleValueChange = (event, i) => {
    const { value } = event.target;
    const { name } = event.target;
    const temp = tempObjects[i] || {};
    temp[name] = value;
    tempObjects[i] = { ...temp };
    setTempObjects([...tempObjects]);
    if (temp.name && temp.value) {
      setDisabledAddField(false);
    }
  };
  const handleCustomFieldChange = (event) => {
    const { value } = event.target;
    setCustomSelectFieldValue(value);
  };

  return (
    <TableContainer style={props.isEdit ? { width: '100%' } : {}} className={classes.table}>
      <Table size="small" padding="none" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.machineConfigLabel} colSpan={3} align="left">{phrases.forms.machine.fields.machineConfiguration}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tempObjects.map((obj, i) => (
            <TableRow key={i}>
              <TableCell className={classes.tableCell} component="th" scope="row">
                <TextField
                  id="name"
                  label={phrases.forms.machine.fields.configurationName}
                  variant="outlined"
                  name="name"
                  color="primary"
                  onBlur={updateConfig}
                  className={classes.inputField}
                  onChange={(e) => handleValueChange(e, i)}
                  value={obj.name}
                  error={false}
                  margin="dense"
                  helperText={phrases.forms.machine.configLabels[obj.name] || ' '}
                  required
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" scope="row" align="left">
                <TextField
                  id="value"
                  label={phrases.forms.machine.fields.configurationValue}
                  variant="outlined"
                  name="value"
                  color="primary"
                  disabled={!obj.name}
                  onBlur={updateConfig}
                  className={classes.inputField}
                  onChange={(e) => handleValueChange(e, i)}
                  value={obj.value}
                  error={false}
                  margin="dense"
                  helperText={phrases.forms.machine.configUnits[obj.name] || ' '}
                  required
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" scope="row" align="center">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => deleteField(i)}
                  className={classes.removeBtn}
                  startIcon={<RemoveCircleOutlineIcon />}
                >
                  {phrases.forms.machine.buttons.removeField}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        
          <TableRow>
              <TableCell style={{paddingTop: "10px"}} className={classes.configTable} colSpan={3} align="left">{phrases.forms.machine.fields.newMachineConfiguration}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell} component="th" scope="row" align="left">
            <StyledSelect
              label={phrases.forms.shared.fields.configSelect}
              labelId="select-label"
              id="config-select"
              name="config-select"
              className={classes.selectField}
              plain={true}
              value={selectedConfigField}
              onChange={handleChange}
              data={selectFields}
            />
            </TableCell>
            <TableCell className={classes.tableCell} component="th" scope="row" align="left">
             <TextField
              id="customFieldValue"
              label={selectLabelText}
              variant="outlined"
              name="customFieldValue"
              color="primary"
              disabled={false}
              className={classes.inputField}
              onBlur={updateConfig}
              onChange={(e) => handleCustomFieldChange(e)}
              value={customSelectFieldValue}
              error={false}
              margin="dense"
              helperText=""
              placeholder={placeHolderSelectValue}
            />
            </TableCell>
            
            <TableCell className={classes.tableCell} component="th" scope="row" align="center">
              <Button
                variant="contained"
                size="small"
                disabled={disabledAddField}
                onClick={addFieldSelect}
                className={classes.addBtn}
                startIcon={<AddCircleOutlineIcon />}
              >
                {phrases.forms.machine.buttons.addField}
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} align="left">
              <Button
                variant="contained"
                size="small"
                disabled={disabledAddField}
                onClick={addField}
                className={classes.addBtn}
                startIcon={<AddCircleOutlineIcon />}
              >
                {phrases.forms.machine.buttons.addCustomField}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}
