import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Drawer,
  Divider,
  InputAdornment,
  IconButton,
  TextField,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';

import { openMachinesDrawer, closeMachinesDrawer } from '@frontend/utils/UIActions';
import StructureTreeView from '@frontend/modules/structure/StructureTreeView';
import useStructureSelection from '@frontend/utils/useStructureSelection';
import { usePhrases } from '@frontend/utils/usePhrases';
import MachinesDrawer from '@frontend/modules/machine/MachinesDrawer';
import ViewSelection from '@frontend/components/ViewSelection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    zIndex: theme.zIndex.appBar - 1,
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.appBar - 2,
    },
    position: 'absolute',
    width: 'auto',
    flexShrink: 0,
  },
  drawerPaper: {
    width: 'auto',
    overflowY: 'auto',
    boxShadow: '5px 8px 19px 0px rgba(0,0,0,0.22)',
  },
  machinesDrawer: {
    zIndex: theme.zIndex.appBar - 2,
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.appBar - 1,
    },
    position: 'absolute',
    width: 'auto',
  },
  drawerBoxShadow: {
    boxShadow: '5px 8px 19px 0px rgba(0,0,0,0.22)',
  },
  noDrawerBoxShadow: {
    boxShadow: 'none',
  },
  machinesDrawerPaper: {
    minWidth: 300,
    width: 'auto',
    overflowY: 'auto',
  },
  toolbar: {
    marginTop: theme.spacing(1),
    ...theme.mixins.toolbar,
  },
  indented: {
    paddingLeft: 25,
  },
  textField: {
    margin: theme.spacing(1),
  },
  list: {
    overflowY: 'auto',
  },
  boldText: {
    fontWeight: 900,
  },
  lightText: {
    fontWeight: 100,
  },
}));

export default function SelectionDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    filteredStructures, searchValue, setSearchValue, onSearchChange,
  } = useStructureSelection();
  const drawer = useRef(null);
  const phrases = usePhrases().phrases();

  // Redux store
  const selectionDrawerOpen = useSelector((state) => state.selectionDrawerOpen);
  const structureViewInUse = useSelector((state) => state.structureViewInUse);
  const machineDrawerOpen = useSelector((state) => state.machineDrawerOpen);
  // States
  const [machines, setMachines] = useState([]);
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [selectedStructure, setSelectedStructure] = useState({});
  const [view, setView] = useState(structureViewInUse);

  const closeMachineDrawer = () => {
    dispatch(closeMachinesDrawer());
  };

  const handleSetView = (route) => {
    setView(route);
  };

  useEffect(() => {
    setView(structureViewInUse);
  }, [structureViewInUse]);

  useEffect(() => {
    if (!selectionDrawerOpen) closeMachineDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionDrawerOpen]);

  const getMachinesRecursive = (structures) => _.flatten(structures.map((structure) => {
    if (structure.structures) {
      return [
        ...structure.machines.map((machine) => ({ ...machine, structure })),
        ...getMachinesRecursive(structure.structures),
      ];
    }
    return structure.machines.map((machine) => ({ ...machine, structure }));
  }));

  const _openMachinesDrawer = (event, structure) => {
    event.stopPropagation();
    if (structure) {
      setMachines(_.groupBy(getMachinesRecursive([structure]), (n) => n.structure.path));
      setSelectedStructure(structure);
      dispatch(openMachinesDrawer());
    } else {
      closeMachineDrawer();
    }
  };

  useEffect(() => {
    if (selectionDrawerOpen && drawer.current.firstChild.offsetWidth) {
      setDrawerWidth(drawer.current.firstChild.offsetWidth);
    }
  }, [selectionDrawerOpen]);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        ref={drawer}
        open={selectionDrawerOpen}
        transitionDuration={150}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerBoxShadow]: !machineDrawerOpen,
            [classes.noDrawerBoxShadow]: machineDrawerOpen,
          }),
        }}
      >
        <Divider />
        <div className={classes.toolbar} />
        <ViewSelection callback={handleSetView} />
        <TextField
          label={phrases.misc.search}
          margin="dense"
          value={searchValue}
          id="outlined-start-adornment"
          onChange={onSearchChange}
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            endAdornment:
              (
                <>
                  <InputAdornment position="end">
                    {searchValue ? <IconButton
                      aria-label="clear"
                      size="small"
                      onClick={() => setSearchValue('')}
                    >
                      <HighlightOffIcon />
                    </IconButton> :
                      <SearchIcon />
                    }
                  </InputAdornment>
                </>
              ),
          }}

          variant="outlined"
        />
        <Divider />
        <StructureTreeView
          view={view}
          openMachinesDrawer={_openMachinesDrawer}
          closeMachinesDrawer={closeMachineDrawer}
          structures={filteredStructures}
        />
      </Drawer>

      <MachinesDrawer
        machines={machines}
        open={machineDrawerOpen}
        selectedStructure={selectedStructure}
        closeMachineDrawer={closeMachineDrawer}
        structureDrawerWidth={drawerWidth}
      />
    </div>
  );
}
