/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Link }                       from 'react-router-dom';
import { useDispatch, useSelector }   from 'react-redux';

import axios from 'axios';
import _ from 'lodash';
import { makeStyles }  from '@material-ui/core/styles';
import { 
  IconButton,
  Typography,
  useTheme }    from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import ConfirmationModal  from '@frontend/components/ConfirmationModal';
import Modal              from '@frontend/components/Modal';
import Table              from '@frontend/components/Table';
import { ROUTES }         from '@frontend/constants';
import useAlert           from '@frontend/hooks/useAlert';
import { storeListOfSensors }   from '@frontend/modules/sensor/actions';
import SensorForm               from '@frontend/modules/sensor/SensorForm';
import SensorMultiselectToolbar from '@frontend/modules/sensor/SensorMultiselectToolbar';
import utils from '@frontend/utils/utils';  
import { persistTableState }      from '@frontend/utils/UIActions';
import useApi                     from '@frontend/utils/useApi';
import { usePhrases }             from '@frontend/utils/usePhrases';
import useSecurity                from '@frontend/utils/useSecurity';
import { setTitle }               from '@frontend/utils/usePageTitle';
import useDateTimeFormat          from '@frontend/utils/useDateTimeFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  sensorsList: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export default function SensorsList() {
  //===== State Variables =====
  // sensor health required data
  const [isLoadingSensors, setIsLoadingSensors] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [sensorToEdit, setSensorToEdit] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [sensors, setSensors] = useState([]);  
  //===== Redux Selectors =====
  const _sensors = useSelector((state) => state.sensors);
  const tableState = useSelector((state) => state.tableState);
  const structures = useSelector((state) => state.selectionStructures);

  
  //===== Imports =====
  const classes   = useStyles();
  const theme     = useTheme();
  const dispatch  = useDispatch();
  const phrases   = usePhrases().phrases();
  const api       = useApi();
  const { isAuthorized }  = useSecurity();
  const { createAlert }   = useAlert();
  const { formatDate }    = useDateTimeFormat();

  //===== Effects =====
  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();

    dispatch(persistTableState({}));
    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is required because the JSON Needs to be flat for the table. The nested Structure field is cumbersome to deal with
   */
  useEffect(() => {
    if(!_.isEmpty(_sensors)){
      setSensors(_sensors.map((sensor) => {
        return {
          ...sensor,
          structure: sensor.machine?.structure
        }
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_sensors]);

  useEffect(() => {
    setIsLoadingSensors(true);
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors?populate=machine', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfSensors(response.data));
      setIsLoadingSensors(false);
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
      setIsLoadingSensors(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //===== Helper Methods =====
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmationModalOpen = (selectedRows) => {
    setSelectedRows(selectedRows);
    setConfirmModalOpen(true);
  }

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
  }

  const editSensor = (sensor) => {
    setSensorToEdit(sensor);
    handleOpenModal();
  };

  const prepareModalText = (obj) => {
    if (!obj || !obj.data) return;
    return (
      <>
        {obj.data.length > 1 ?
          <Typography display="block" gutterBottom>{phrases.confirmationModal.areYouSureDeactivateSensors}:</Typography>
          : <Typography display="block" gutterBottom>{phrases.confirmationModal.areYouSureDeactivateSensor}:</Typography>}
        {obj.data.map((dataObj) => {
          const index = dataObj.dataIndex;
          const sensor = sensors[index];
          return <Typography className={classes.sensorsList} display="block">{sensor.alias} - {sensor.sensorId} </Typography>
        })
        }
      </>
    )
  }

  const handleDeactivateSensors = (obj) => {
    const cancelAxios = axios.CancelToken.source();
    obj.data.forEach((dataObj) => {
      const index = dataObj.dataIndex;
      const sensor = sensors[index];
      api('/api/sensors', {
        method: 'put',
        data: {
          ...sensor,
          isActive: false,
        },
        cancelToken: cancelAxios.token,
      })
        .then((response) => {
          if (response.status === 200) {
            handleEdit();
            createAlert(phrases.forms.sensor.success.sensorUpdated, 'success');
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
        });
    });
    handleCloseConfirmationModal();
  };

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/sensors?populate=machine', {
      method: 'get',
      cancelToken: cancelAxios.token,
    }).then((response) => {
      dispatch(storeListOfSensors(response.data));
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });
  };

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
  }

  //===== Local Variables =====
  const columns = [
    {
      label: phrases.forms.sensor.fields.sensorID,
      name: 'sensorId',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'sensorId' ? tableState.sortOrder.direction : 'none',
      },
    },    
    {
      label: phrases.forms.shared.fields.alias,
      name: 'alias',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'alias' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (

          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>

        ),
      },
    },
    {
      label: phrases.forms.shared.fields.type,
      name: 'type',
      options: {
        filter: true,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'type' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[2] : [],
        sortCompare: (order) => {
          return (item1, item2) => {
            if (!item1.data) return 1;
            if (!item2.data) return -1;
            const res = item1.data.name.localeCompare(item2.data.name);
            return res * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          names: _.uniq(_.map(sensors, 'type.name')),
          logic: (type, filters, row) => {
            if (filters.length) return !filters.includes(type.name);
            return false;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value.name}
          </div>
        ),
      },
    },
    {
      label: phrases.tables.shared.machine,
      name: 'machine',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'machine' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[3] : [],
        sortCompare: (order) => {
          return (item1, item2) => {
            if (!item1.data) return 1;
            if (!item2.data) return -1;
            const res = item1.data.name.localeCompare(item2.data.name);
            return res * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          names: _.uniq(_.map(sensors, 'machine.name')),
          logic: (machine, filters, row) => {
            if (filters.length) return !filters.includes(machine.name);
            return false;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {sensors[tableMeta.rowIndex].machine && value ? <Link style={{ color: theme.palette.primary.main }} to={`${ROUTES.MACHINE_OVERVIEW}/${sensors[tableMeta.rowIndex].machine.id}`}>{value.name}</Link> : 'None'}
          </div>
        ),
      },
    },
    {
      label: phrases.tables.sensor.registrationDate,
      name: 'registeredAt',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'registeredAt' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? formatDate(value) : '-'
        ),

      },
    },
    {
      label: phrases.tables.sensor.lastSeen,
      name: 'lastSeenAt',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'lastSeenAt' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? formatDate(value) : '-'
        ),

      },
    },
    {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const sensor = sensors.find((sensor) => sensor.id === value);
          if (isAuthorized('sensors', ['update:own', 'update:any']) || !sensor.assigned){
            return (
            <div>
              <IconButton
                aria-label="edit icon for current row"
                aria-haspopup="true"
                color="primary"
                size="small"
                onClick={() => editSensor(sensors.find((sensor) => sensor.id === value))}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </div>)
          }
        } 
      },
    },
    {
      label: phrases.tables.shared.active,
      name: 'isActive',
      options: {
        filter: true,
        filterList: tableState.filterList ? tableState.filterList[7] : [phrases.tables.shared.active],
        display: 'excluded',
        filterType: 'checkbox',
        filterOptions: {
          names: [phrases.tables.shared.active, phrases.tables.shared.inactive],
          logic: (isActive, filters) => {
            if (filters.includes(phrases.tables.shared.active) && filters.includes(phrases.tables.shared.inactive)) return false;
            if (filters.includes(phrases.tables.shared.active)) return !isActive;
            if (filters.includes(phrases.tables.shared.inactive)) return isActive;
            return false;
          },
        },
      },
    },
    {
      label: phrases.tables.shared.assigned,
      name: 'assigned',
      options: {
        filter: true,
        filterList: tableState.filterList ? tableState.filterList[8] : [phrases.tables.shared.assigned],
        display: 'excluded',
        filterType: 'checkbox',
        filterOptions: {
          names: [phrases.tables.shared.assigned, phrases.tables.shared.unassigned],
          logic: (assigned, filters) => {
            if (filters.includes(phrases.tables.shared.assigned) && filters.includes(phrases.tables.shared.unassigned)) return false;
            if (filters.includes(phrases.tables.shared.assigned)) return !assigned;
            if (filters.includes(phrases.tables.shared.unassigned)) return assigned;
            return false;
          },
        },
      },
    },
    {
      label: phrases.forms.shared.fields.structure,
      name: 'structure',
      options: {
        display: 'excluded',
        download: false,
        filter: true,
        filterType: 'multiselect',
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'structure' ? tableState.sortOrder.direction : 'none',
        filterList: tableState.filterList ? tableState.filterList[9] : [],
        sortCompare: (order) => {
          return (item1, item2) => {
            const res = item1.data.name.localeCompare(item2.data.name);
            return res * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          names: _.uniq(_.map(structures, 'path').filter((path) => path)),
          logic: (structure, filters, row) => {
            if (!structure) return true;
            if (filters.length && structure) {
              const foundStructure = structures.find((str) => structure === str.id);
              return !filters.includes(foundStructure?.path);
            }
            return true;
          },
        },
      },
    },
  ];

  const customOptions = {
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({ ...tableState, filterList }));
    },
    onChangePage: (page) => {
      dispatch(persistTableState({ ...tableState, page }));
    },
    download: true,
    downloadOptions: {
      filename: `${phrases.forms.shared.fields.sensors} - ${formatDate(new Date())}.csv`,
      separator: ',',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      data.map((sensorArray) => {
        sensorArray.data[2] = sensorArray.data[2] ? sensorArray.data[2].name : '';
        sensorArray.data[3] = sensorArray.data[3] ? sensorArray.data[3].name : '';
        sensorArray.data[4] = isValidDate(new Date(sensorArray.data[4])) ? formatDate(new Date(sensorArray.data[4])) : '';
        sensorArray.data[5] = isValidDate(new Date(sensorArray.data[5])) ? formatDate(new Date(sensorArray.data[5])) : '';
        return [...sensorArray.data];
      });
      return buildHead(columns) + buildBody(data);
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: utils.getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <SensorMultiselectToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        deactivateSensors={() => handleConfirmationModalOpen(selectedRows)}
      />
    ),
  };

  setTitle(phrases.pageTitles.sensorsManagement);
  return (
    <div className={classes.root}>
      <Table
        modalTitle={phrases.forms.shared.fields.sensors}
        data={sensors}
        resource="sensors"
        columns={columns}
        customOptions={customOptions}
        isLoading={isLoadingSensors}
      />
      <Modal
        modalTitle={phrases.modal.EditSensor}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <SensorForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            sensor={sensorToEdit}
          />
        )}
      />
      <ConfirmationModal
        modalTitle={phrases.confirmationModal.deactivateSensors}
        text={prepareModalText(selectedRows)}
        openModal={confirmModalOpen}
        handleClose={handleCloseConfirmationModal}
        callback={() => handleDeactivateSensors(selectedRows)}
      />
    </div>
  );
}
