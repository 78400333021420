import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { usePhrases }   from '@frontend/utils/usePhrases';

import {
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  statusBar: {
    display: 'flex',
    height: theme.spacing(5),
  },
  textLeft: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(1),
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },

  },
  textRight: {
    marginRight: theme.spacing(10),
    padding: theme.spacing(1),
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  textCenter: {
    margin: 'auto',
    padding: theme.spacing(1),
  },
  inlineTypography: {
    display: 'inline-block',
  },
  bold: {
    fontWeight: 'bold',
  },

  sticky: {
    position: 'fixed',
    left: 0,
    top: 64,
    width: '100%',
    zIndex: 9999,
    visibility: 'visible',
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },
    boxShadow: '0 0.2rem 2rem rgba(0, 0, 0, 0.55)',
  },
  invisible: {
    visibility: 'hidden',
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    height: theme.spacing(0),
  },
}));

const StructureSticky = (props) => {
  const { structure, structureCalculations } = props;
  const [sticky, setSticky] = useState(false);
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  useEffect(() => {
    const header = document.getElementById('structureSticky');
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset + 64 > sticky) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  function returnStatusIndicator(oee) {
    if (oee < 40) {
      return '#d32f2f';
    }
    return '#388e3c';
  }

  const statusIndicator = returnStatusIndicator(structureCalculations.oee);
  const lighterIndicator = returnStatusIndicator(structureCalculations.oee).concat("80");

  return (
    <div id="structureSticky" className={`${sticky ? classes.sticky : classes.invisible}`}>
      <Paper>

        <div style={
          {
            minHeight: 7,
            background: 'repeating-linear-gradient(-55deg,'
              + lighterIndicator + ','
              + lighterIndicator + ','
              + '3px,' + statusIndicator + ' 3px,'
              + statusIndicator + ' 18px)',
          }
        }
        />

        <Grid className={classes.statusBar}>

          <Grid item xs={3} align="left">
            <Typography variant="body2" className={(classes.inlineTypography, classes.textLeft)}>

              {structure.name}

            </Typography>
          </Grid>

          <Grid item xs={6} align="center">
            <Typography variant="body2" className={(classes.inlineTypography, classes.textCenter)}>
              <span className={classes.bold}>
                OEE:
                {' '}
                {structureCalculations.oee}
                %
              </span>
              {' '}
                (
                A
                {': '}
              {structureCalculations.availability}
              {'% '}
                P
                {': '}
              {structureCalculations.performance}
              {'% '}
                Q
                {': '}
              {structureCalculations.quality}
              {'%'}
                )
            </Typography>
          </Grid>

          <Grid item xs={3} align="right">
            <Typography variant="body2" className={(classes.inlineTypography, classes.textRight)}>

              <Hidden xsDown>
                {structureCalculations.machinesRunning}
                {' '}
                {phrases.modules.structure.of}
                {' '}
                {structureCalculations.totalMachines}
                {' '}
                {phrases.modules.structure.machinesRunning}
              </Hidden>
            </Typography>
          </Grid>

        </Grid>

      </Paper>

    </div>
  );
};

export default StructureSticky;
