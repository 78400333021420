import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  CssBaseline,
} from '@material-ui/core';

import ManagementDrawer from '@frontend/components/ManagementDrawer';
import Content from '@frontend/components/Content';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import loader from '@frontend/loader/loader';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export default function Main() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const { loadModuleDescriptors } = loader();
  const { initialSetup } = useStatesFilter();

  useEffect(() => {
    loadModuleDescriptors();
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <CssBaseline />
      <Content />
      <ManagementDrawer />
    </Container>
  );
}
