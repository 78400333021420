import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default function ConfirmationModal({ title, text, openModal, cancelText, confirmText, handleClose, callback }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const phrases = usePhrases().phrases();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCallback = () => {
    setIsSubmitting(true);
    handleClose();
    callback();
  }

  useEffect(() => {
    setIsSubmitting(false);
  }, [text, callback]);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          square: true,
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="default">
            {cancelText || phrases.confirmationModal.cancel}
          </Button>
          <Button onClick={handleCallback} disabled={isSubmitting} variant="contained" color="primary" autoFocus>
            {confirmText || phrases.confirmationModal.confirm}
            {isSubmitting
              && <CircularProgress className={classes.loadingBtnIcon} color="inherit" size={15} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}