/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {  
  Button,
  DialogActions,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from '@material-ui/pickers';
import { makeStyles }     from '@material-ui/core/styles';
import AccessTimeIcon     from '@material-ui/icons/AccessTime';
import AddIcon            from '@material-ui/icons/Add';
import DeleteIcon         from '@material-ui/icons/Delete';
import DateFnsUtils   from '@date-io/date-fns';

import ConfirmationModal  from '@frontend/components/ConfirmationModal';
import useTimePresets     from '@frontend/hooks/useTimePresets';
import useDateTimeFormat  from '@frontend/utils/useDateTimeFormat';
import { usePhrases }     from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  selectField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  presetList: {
    width: '100%',
  },
  listTitle: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 'bold',
  },
  selectedPreset: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const TimespanCustomSelection = (props) => {
  const {
    onTimespanSelect,
    selectTimeframe,
    handleCloseDialog,
  } = props;

  // ===== State Objects =====
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationAddModalOpen, setConfirmationAddModalOpen] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState(null);

  // ===== Redux Selectors =====
  
  // ===== Imports =====
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { formatTime, getLocale } = useDateTimeFormat();
  const { 
    handleBeginTimeChange,
    handleEndTimeChange,
    handleTitleChange,
    handleDeletePreset,
    handlePresetDayChange,
    handleCustomPresetClick,
    selectedCustomTimeframeIsEqual,
    updatePulse,
    presets,
    customTimespan,
    handleAddNewPreset,
    customDay
  } = useTimePresets(selectTimeframe, onTimespanSelect);

  // ===== Helper Methods =====
  const handleCloseConfirmationModal = () => {
    setPresetToDelete(null);
    setConfirmationModalOpen(false);   
  }

  const handleOpenDeleteModal = (preset) => {
    setPresetToDelete(preset);
    setConfirmationModalOpen(true);
  }

  const renderCustomListItems = (limit=0) => {
    let ret = []
    if(!presets || presets.length === 0){
      return ret;
    }
    presets.forEach((preset) =>{
      ret.push(
        <ListItem button onClick={() => handleCustomPresetClick(preset)}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <ListItemText classes={selectedCustomTimeframeIsEqual(preset) ? { primary: classes.selectedPreset } : {}} primary={preset.title} secondary={`${formatTime(preset.begin)} - ${formatTime(preset.end)}`} />
            </Grid>  
            <Grid item xs={2}>
              <IconButton
                      color="primary"
                      onClick={() => handleOpenDeleteModal(preset)}
                    >
                  <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </ListItem>
      )
    })
    return ret;
  }

  const renderConfirmationAddModalText = () => {    
    return (
      <>        
        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Grid item xs="4"> 
            <TextField
              size="small"
              label={phrases.timespanSelection.newPresetTitle}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={handleTitleChange}
              value={customTimespan.title}
            />
          </Grid>
          <Grid item xs="3">              
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
              <TimePicker
                autoOk
                ampm={false}
                disableFuture
                inputVariant="outlined"
                margin="dense"
                className={classes.inputField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
                value={customTimespan.begin}
                onChange={handleBeginTimeChange}
                label={phrases.timespanSelection.CustomBegin}
              />
            </MuiPickersUtilsProvider>          
          </Grid>
          <Grid item xs="3">
            {""}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
              <TimePicker
                autoOk
                ampm={false}
                disableFuture
                inputVariant="outlined"
                margin="dense"
                className={classes.inputField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
                value={customTimespan.end}
                onChange={handleEndTimeChange}
                label={phrases.timespanSelection.CustomEnd}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs="2">
              <Fab size="small" color="primary" aria-label="add" onClick={handleAddNewPreset}>
                <AddIcon />
              </Fab>
            </Grid>

        </Grid>
      </>
    );
  };

  const confirmationModalText = () => {
    if (!presetToDelete) return '';
    return (
      <>
        <Typography>
          {phrases.timespanSelection.areYouSureDeleteTimePreset}
        </Typography>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography>{presetToDelete.title}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.secondary}>
                  {`${formatTime(presetToDelete.begin)} - ${formatTime(presetToDelete.end)}`}
                </Typography>
              </TableCell>            
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };
  
  // ===== Return =====
  return (
    <div>
      <List>      
        <ListItem>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
            <DatePicker              
              variant="inline"
              label={phrases.timespanSelection.dayOfPreset}
              disableFuture
              inputVariant="outlined"
              format={phrases.timespanSelection.dateFormat}
              margin="dense"
              className={classes.inputField}
              InputAdornmentProps={{ position: "start" }}
              value={customDay}
              onChange={handlePresetDayChange}
            />
          </MuiPickersUtilsProvider>
        </ListItem>
        <Divider />
        <ListItem>
          {/* Fetch Shifts from Mongo */}
          <List className={classes.presetList}>
            <div className={classes.listTitle}>
              {phrases.timespanSelection.CustomPresets}
            </div>
            {renderCustomListItems()}
          </List>
        </ListItem>
        <Divider />
        <ListItem>
          {confirmationAddModalOpen ? renderConfirmationAddModalText() : ""}
        </ListItem>
        <ListItem>
          {/* Fetch Shifts from Mongo */}
          <div className={classes.listTitle}>
          <Button 
           size='small' 
           onClick={() => {
             setConfirmationAddModalOpen(!confirmationAddModalOpen)}
           } 
           variant="contained" 
           color={confirmationAddModalOpen ? "default": "primary"}> 
            {confirmationAddModalOpen ? phrases.forms.shared.buttons.close: phrases.timespanSelection.addNewPreset}
          </Button>
          </div>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="contained" color="default">
          {phrases.forms.shared.buttons.cancel}
        </Button>
        <Button onClick={() => {
            updatePulse();
            handleCloseDialog()
          }} variant="contained" color="primary">
          {phrases.forms.shared.buttons.updatePulse}
        </Button>
      </DialogActions>
      <ConfirmationModal
        title={phrases.timespanSelection.deletePresetModalTitle}
        text={confirmationModalText()}
        openModal={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        callback={() => handleDeletePreset(presetToDelete)}
      />
    </div>
    
  );
};

export default TimespanCustomSelection;
