import Loader from '@frontend/loader/loader';

const useMachineKPIs = () => {
    const { moduleLoaded } = Loader();

    const isKPIValueAbsent = (machine, states, module, kpi) => {
        if (!moduleLoaded(module)) return true;
        if (!states) return true;
        if (!states[machine.id]) return true;
        if (!states[machine.id].machineKPIs) return true;
        if (states[machine.id].machineKPIs[kpi] === 0) return false;
        if (!states[machine.id].machineKPIs[kpi]) return true;
        if (states[machine.id].machineKPIs[kpi] === null) return true;
    }

    const getAvgPartsPerMinute = (machine, states) => {
        if (isKPIValueAbsent(machine, states, 'performance', 'partsPerMinute')) return 'N/A';
        else return parseFloat(states[machine.id].machineKPIs.partsPerMinute).toFixed(2);
    }
    const getMachinePerformance = (machine, states) => {
        if (isKPIValueAbsent(machine, states, 'performance', 'performance')) return 'N/A';
        else return Math.round(states[machine.id].machineKPIs.performance);
    }

    const getMachineAvailability = (machine, states) => {
        if (isKPIValueAbsent(machine, states, 'availability', 'availability')) return 'N/A';
        else return Math.round(states[machine.id].machineKPIs.availability);
    }

    const getMachineQuality = (machine, states) => {
        if (isKPIValueAbsent(machine, states, 'quality', 'quality')) return 'N/A';
        else return Math.round(states[machine.id].machineKPIs.quality);
    }

    const getMachineOEE = (machine, states) => {
        if (isKPIValueAbsent(machine, states, 'availability', 'oee')) return 'N/A';
        else return Math.round(states[machine.id].machineKPIs.oee);
    }

    const getMachineRuntime = (machine, states) => {
        if (isKPIValueAbsent(machine, states, 'availability', 'runtime')) return 'N/A';
        else return Math.round(states[machine.id].machineKPIs.runtime);
    }

    const getMachineDowntime = (machine, states) => {
        if (isKPIValueAbsent(machine, states, 'availability', 'downtime')) return 'N/A';
        else return Math.round(states[machine.id].machineKPIs.downtime);
    }
    return {
        getMachineAvailability,
        getMachinePerformance,
        getMachineQuality,
        getMachineOEE,
        getMachineRuntime,
        getMachineDowntime,
        getAvgPartsPerMinute
    };
};

export default useMachineKPIs;
