/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Checkbox,
} from '@material-ui/core';
import Autocomplete                 from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon     from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon                 from '@material-ui/icons/CheckBox';

import { usePhrases } from '@frontend/utils/usePhrases';
import useOrdersAutocomplete from '@frontend/modules/orders/hooks/useOrdersAutocomplete';

const useStyles = makeStyles(() => ({
  inputField: {
    width: '100%',
  },
  structureName: {
    fontWeight: 550,
  },
  indented: {
    marginLeft: 25,
  },
}));

const OrdersAutocomplete = (props) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const {
    options,
    value,
    onChange,
    error,
    required,
    helperText,
  } = props;
  
  const { dropdownOptions } = useOrdersAutocomplete(options);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  console.log(dropdownOptions)
  return (
    <div>
      <Autocomplete
        id={`orders-autoselect-${uuid()}`}
        name="orders"
        className={classes.inputField}
        options={dropdownOptions}
        onChange={onChange}
        value={value}
        error={error}
        helperText={helperText}
        groupBy={(option) => option.structure?.path}
        getOptionLabel={(option) => option.businessOrderId}
        renderOption={(option, { selected }) => (
          <div key={uuid()}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              color="primary"
            />
            {option.businessOrderId}
          </div>
        )}

        {...props}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              {...props}
              label={phrases.modules.orders.orders}
              name="orders"
              className={classes.inputField}
              error={!!error}
              helperText={helperText}
              variant="outlined"
              margin="dense"
              required={required}
            />
          </>
        )}
      />
    </div>
  );
};

OrdersAutocomplete.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default OrdersAutocomplete;
