import { useEffect, useState } from "react";
import useAlert        from '@frontend/hooks/useAlert';
import { usePhrases }  from '@frontend/utils/usePhrases';
import useApi          from '@frontend/utils/useApi';
import moment from "moment";

const useOrderExportQualityReportModal = (activation) => {
    
    // ===== States =====
    const [loadingQualityReports, setLoadingQualityReports] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState({});
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [qualityReports, setQualityReports] = useState([]);
    const [selectedState, setSelectedState] = useState({});

    // ===== Imports =====
    const phrases = usePhrases().phrases();
    const api = useApi();
    const { createAlert } = useAlert();


    // ===== Helpers =====
    const handleEditReport = (_report) => {
        setSelectedReport({
            report: _report,
            machine: activation?.machine,
        });
        setEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
    };

    const handleOpenConfirmationModal = (report) => {
        setSelectedReport(report);
        setDeleteModalOpen(true);
    }

    const handleCloseConfirmationModal = () => {
        setSelectedReport({});
        setDeleteModalOpen(false);
    }

    const handleDeleteQualityReport = (_selectedReport = selectedReport) => {
        const scrapType = _selectedReport['quality.scrapType'];
        api('/api/quality/delete-quality-report', {
          data: {
            machine: activation?.machine.id,
            oldQualityReportData: {
              scrapType,
              machineId: activation?.machine.id,
              wasteCount: _selectedReport[`quality.${scrapType}.wasteCount`],
              noteId: _selectedReport[`quality.${scrapType}.note`],
              startTime: _selectedReport[`quality.${scrapType}.startTime`],
              endTime: _selectedReport[`quality.${scrapType}.endTime`],
            },
            qualityReportData: null,
          },
          method: 'post',
        })
        .then((response) => {
        if (response.status === 204) {
            createAlert(phrases.modules.quality.qualityReportDeleted, 'success');
            reloadQRData();
        }
        })
        .catch((error) => {
            console.log(error);
        });
    };    

    const reloadQRData = () => {
        setLoadingQualityReports(true);
        const mymachine = {
            id: activation?.machine?.id,
            start_time: activation.time,
        }  
        api('/api/quality/quality-reports', {
            method: 'get',
            params: {
                machine: mymachine ?? {},
                to: activation.endTime === null ? moment().toDate(): new Date(activation.endTime),
            },
        })
        .then((response) => {
            setQualityReports(response.data);
            setLoadingQualityReports(false);
        })
        .catch((error) => {
            setLoadingQualityReports(false);
        });        
        setLoadingQualityReports(false);
    }
    

    // ===== Effects =====
    useEffect(() => {
        reloadQRData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Have to explain this one: So the QualityReportEditModal will just use the start and end time of the selectedState. So, if we
     * want to change the time to the times of the activation, we need to create a dict containing these values and pass it to the
     * QualityReportEditModal using the selectedState prop.
     */
    useEffect(() => {
        if (activation) {
            setSelectedState({
                startTime: activation.time,
                endTime: activation.endTime ?? moment().toDate(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activation]);

    return { 
        qualityReports,
        loadingQualityReports,
        deleteModalOpen,
        selectedReport,
        editModalOpen,
        selectedState,
        reloadQRData,
        handleOpenConfirmationModal,
        handleCloseConfirmationModal,
        handleDeleteQualityReport,
        handleEditReport,       
        handleCloseEditModal,
    };
}
 
export default useOrderExportQualityReportModal;