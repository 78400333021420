import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link }             from 'react-router-dom';
import axios from 'axios';

import {
  Chip,
  IconButton,
} from '@material-ui/core';
import { makeStyles }     from '@material-ui/core/styles';
import EditIcon           from '@material-ui/icons/Edit';
import DeleteIcon         from '@material-ui/icons/Delete';

import { faBusinessTime, faHourglassHalf }  from '@fortawesome/free-solid-svg-icons';

import ConfirmationModal  from '@frontend/components/ConfirmationModal';
import Modal              from '@frontend/components/Modal';
import Table              from '@frontend/components/Table';
import CustomTabs         from '@frontend/components/Tabs';
import MachineShiftForm     from '@frontend/modules/machine_shift/MachineShiftForm';
import useApi                     from '@frontend/utils/useApi';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity                from '@frontend/utils/useSecurity';
import { usePhrases }             from '@frontend/utils/usePhrases';
import { persistTableState }      from '@frontend/utils/UIActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  colorButton: {
    height: theme.spacing(3),
    width: theme.spacing(10),
    cursor: 'default',
    border: '1px solid grey',
  },
  scrapTypesList: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  chip: {
    margin: theme.spacing(0.5),
    cursor: 'pointer',
  }
}));

export default function ShiftManagement() {

    // ===== Imports =====
    const classes = useStyles();
    const dispatch = useDispatch();
    const api = useApi();
    const phrases = usePhrases().phrases();
    const { isAuthorized } = useSecurity();
    const { conditionalElement } = useConditionalArrayElement();
    
    // ===== States =====
    const [shiftToEdit, setShiftToEdit] = useState({});
    const [shifts, setShifts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [shiftToDelete, setShiftToDelete] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    // ===== Selectors =====
    const tableState = useSelector((state) => state.tableState);

    // ===== Effects =====
    useEffect(() => {
        const cancelAxios = axios.CancelToken.source();
        dispatch(persistTableState({}));
        return () => {
            cancelAxios.cancel('Component unmounted');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prepareModalText = () => {
      if(!shiftToDelete) return '';
      let fullshift = shifts.find((shift) => { return shift._id === shiftToDelete; });
      if(fullshift === undefined) return '';
      let text = phrases.modules.machine_shift.deleteConfirmation
        .replace('{shiftName}', fullshift.shiftName)
        .replace('{startTime}', fullshift.startTime)
        .replace('{endTime}', fullshift.endTime);
        
      return text;
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmationModalOpen(false);
    }
    
    const handleEditClick = (shiftId) => {
        setShiftToEdit(shifts.find((shift) => {
          return shift._id === shiftId;
        }));
        setOpenModal(true);
    };

    const reloadData = () => {
      const cancelAxios = axios.CancelToken.source();
      api('/api/machine_shift', {
        method: 'get',
        cancelToken: cancelAxios.token,
      })
        .then((response) => {
          setShifts(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
          setIsLoading(false);
        });
    }

    const handleDelete = (shiftId) => {
        setIsLoading(true);
        if(typeof shiftId !== 'string') return;
        const cancelAxios = axios.CancelToken.source();
        api('/api/machine_shift/'+shiftId, {
          method: 'delete',
          cancelToken: cancelAxios.token,
        })
          .then((response) => {
            reloadData();
            setIsLoading(false);
            setShiftToDelete(null);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) console.log(error);
            setIsLoading(false);
          });
    };
    // ===== Variables =====
  const columns = [
    {
      label: phrases.modules.machine_shift.forms.name,
      name: 'shiftName',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'shiftName' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>
        ),
      },
    },
    {
      label: phrases.modules.machine_shift.forms.startTime,
      name: 'startTime',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'startTime' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>
        ),
      },
    },
    {
      label: phrases.modules.machine_shift.forms.endTime,
      name: 'endTime',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'endTime' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (
          !value
            ? <div>{phrases.forms.shared.fields.none}</div>
            : <div>{value}</div>
        ),
      },
    },
    {
      label: phrases.modules.machine_shift.forms.machines,
      name: 'machines',
      options: {
        filter: false,
        sortDirection: tableState.sortOrder && tableState.sortOrder.name === 'machines' ? tableState.sortOrder.direction : 'none',
        customBodyRender: (value, tableMeta, updateValue) => {
          if(!value) return <div>{phrases.forms.shared.fields.none}</div>;
          
          return <div>{value.map((machine) => {
            return <Link to={`/simple-machine2/${machine.id}`} ><Chip color="primary" key={machine.id} className={classes.chip} label={machine.name}/></Link>
          })}</div>
        }
      },
    },
    ...conditionalElement(isAuthorized('machines', ['update:any']), {
      label: phrases.tables.shared.actions,
      name: 'id',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {isAuthorized('machine_shift', ['update:any']) && (
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => {handleEditClick(value)}}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            )}
            {isAuthorized('machine_shift', ['delete:any']) && (
            <IconButton
              aria-label="edit icon for current row"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={() => {
                setShiftToDelete(value);
                setConfirmationModalOpen(true);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            )}
          </div>
        ),
      },
    }),
  ];


  useEffect(() => {
    setIsLoading(true);
    reloadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machine_shift/', {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setShifts(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
  };

  const handleCreate = () => {
    const cancelAxios = axios.CancelToken.source();
    api('/api/machine_shift', {
      method: 'get',
      cancelToken: cancelAxios.token,
    })
      .then((response) => {
        setShifts(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) console.log(error);
      });
  };

  const getDirection = (direction) => {
    switch (direction) {
      case 'ascending':
        return 'asc';
      case 'descending':
        return 'desc';
      default:
        return 'asc';
    }
  };

  const customOptions = {
    filterType: 'checkbox',
    print: false,
    download: false,
    elevation: 0,
    selectableRows: isAuthorized('reasons', ['delete:any']) ? 'multiple' : 'none',
    onFilterChange: (changedColumn, filterList) => {
      dispatch(persistTableState({
        ...tableState,
        filterList,
      }));
    },
    onChangePage: (page) => {
      dispatch(persistTableState({
        ...tableState,
        page,
      }));
    },
    onColumnSortChange: (column, direction) => {
      dispatch(persistTableState({
        ...tableState,
        sortOrder: {
          name: column,
          direction: getDirection(direction),
        },
      }));
    },
    page: tableState.page,
    onChangeRowsPerPage: (number) => {
      dispatch(persistTableState({
        ...tableState,
        rowsPerPage: number,
      }));
    },
    rowsPerPage: tableState.rowsPerPage || 10,
  };
  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          ...conditionalElement(isAuthorized('machine_shift', ['read:any']), {
            label: phrases.modules.machine_shift.existingMachineShifts,
            icon: faBusinessTime,
          }),
          ...conditionalElement(isAuthorized('machine_shift', ['create:any']), {
            label: phrases.modules.machine_shift.createNewMachineShift,
            icon: faHourglassHalf,
          }),
        ]}
        panels={[
          ...conditionalElement(isAuthorized('machine_shift', ['read:any']), {
            content: <Table
              modalTitle={phrases.modules.quality.scrapTypes}
              data={shifts}
              resource="machines"
              columns={columns}
              customOptions={customOptions}
              isLoading={isLoading}
            />,
          }),
          ...conditionalElement(isAuthorized('machine_shift', ['create:any']), {
            content: <MachineShiftForm onCreate={handleCreate} />,
          })          
        ]}
      />
      <Modal
        modalTitle={phrases.modules.machine_shift.editMachineShift}
        open={openModal}
        handleClose={handleCloseModal}
        content={(
          <MachineShiftForm
            isEdit
            onEdit={handleEdit}
            handleCloseModal={handleCloseModal}
            shiftToEdit={shiftToEdit}
          />
        )}
      />
      
      <ConfirmationModal
        title={phrases.modules.machine_shift.confirmDeleteTitle}
        text={prepareModalText()}
        openModal={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        callback={() => handleDelete(shiftToDelete)}
      />
    </div>
  );
}
