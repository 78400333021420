/* eslint-disable react/jsx-key */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { usePhrases } from '@frontend/utils/usePhrases';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import LoadingIndicator from '@frontend/components/LoadingIndicator';
import useTopQualityLosses from '@frontend/modules/quality/hooks/useTopQualityLosses';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%'
  },
  loadingReasonsIndicator: {
    marginLeft: theme.spacing(1),
  },
}));

export default function TopQualityLosses(props) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const {
    machines,
  } = props;
  const {
    qualityLosses,
    loadingQualityLosses,
  } = useTopQualityLosses(machines);
  const { scrapTypeText } = useStatesFilter();

  return (
    <Paper className={classes.paper} variant="outlined" square>
      <List>

        <ListItem alignItems="center">
          <ListItemAvatar>
            <FontAwesomeIcon
              icon={faRecycle}
              size="2x"
            />
          </ListItemAvatar>
          <Typography variant="body1">
            {phrases.modules.quality.qualityLosses}
          </Typography>
          <Typography variant="body1" className={classes.loadingReasonsIndicator}>
            {loadingQualityLosses ? <LoadingIndicator size={20} /> : ''}
          </Typography>
        </ListItem>
        <Divider variant="inset" component="li" />

      </List>
      <Table aria-label="simple table">

        <TableBody>
          {Object.keys(qualityLosses).map((scrapType, i) => (
            <TableRow>
              <TableCell align="left">
                <Typography variant="body2">{`${i + 1}. ${scrapTypeText(scrapType)}:`}</Typography>
              </TableCell>
              <TableCell align="right">
                <span>
                  {qualityLosses[scrapType]}
                  {' '}
                  {phrases.modules.quality.parts}
                </span>
              </TableCell>
            </TableRow>
          ))}
          {!Object.keys(qualityLosses).length && !loadingQualityLosses ? <Typography>{phrases.modules.quality.noQualityReportSubmitted}</Typography> : ''}

        </TableBody>

      </Table>
    </Paper>
  );
}
