import { useSelector } from 'react-redux';
import { usePhrases } from '@frontend/utils/usePhrases';


const useMachineFilters = () => {

    const phrases = usePhrases().phrases();
    const filterList = useSelector((state) => state.productionPulseFilters.filterList);
    const searchQuery = useSelector((state) => state.productionPulseFilters.searchQuery);
    const sortType = useSelector((state) => state.productionPulseFilters.sortType);

    const applySort = (_sortType = sortType, allMachines) => {
        if(phrases !== undefined){
            switch (_sortType) {
                default:
                case phrases.machineFilters.default: return allMachines;
                case phrases.machineFilters.availabilityminmax: return [...allMachines].sort((a, b) => a.machineKPIs.availability - b.machineKPIs.availability);
                case phrases.machineFilters.availabilitymaxmin: return [...allMachines].sort((a, b) => b.machineKPIs.availability - a.machineKPIs.availability);
                case phrases.machineFilters.performanceminmax: return [...allMachines].sort((a, b) => a.machineKPIs.performance - b.machineKPIs.performance);
                case phrases.machineFilters.performancemaxmin: return [...allMachines].sort((a, b) => b.machineKPIs.performance - a.machineKPIs.performance);
                case phrases.machineFilters.qualityminmax: return [...allMachines].sort((a, b) => a.machineKPIs.quality - b.machineKPIs.quality);
                case phrases.machineFilters.qualitymaxmin: return [...allMachines].sort((a, b) => b.machineKPIs.quality - a.machineKPIs.quality);
            }
        }
    };

    const applySearch = (_searchValue = searchQuery, allMachines) => [
        ...allMachines.filter((machine) => machine.name.toLowerCase().includes(_searchValue.toLowerCase())),
    ];

    const applyFilters = (_filterList = filterList, allMachines) => {
        const STRUCTURE_COLUMN_INDEX = 1;
        const MACHINE_TYPE_COLUMN_INDEX = 2;
        const MIN_AVAILABILITY_COLUMN_INDEX = 5;
        const MAX_AVAILABILITY_COLUMN_INDEX = 6;
        const MIN_PERFORMANCE_COLUMN_INDEX = 7;
        const MAX_PERFORMANCE_COLUMN_INDEX = 8;
        const MIN_QUALITY_COLUMN_INDEX = 9;
        const MAX_QUALITY_COLUMN_INDEX = 10;
        const RUNNING_COLUMN_INDEX = 11;

        const filteredMachinesByStructure = _filterList[STRUCTURE_COLUMN_INDEX] && _filterList[STRUCTURE_COLUMN_INDEX].length ? allMachines.filter((machine) => _filterList[STRUCTURE_COLUMN_INDEX].includes(machine.structure.path)) : allMachines;
        const filteredMachinesByType = _filterList[MACHINE_TYPE_COLUMN_INDEX] && _filterList[MACHINE_TYPE_COLUMN_INDEX].length ? filteredMachinesByStructure.filter((machine) => _filterList[MACHINE_TYPE_COLUMN_INDEX].includes(machine.type.name)) : filteredMachinesByStructure;
        const filteredMachinesByMinAvailability = _filterList[MIN_AVAILABILITY_COLUMN_INDEX] && _filterList[MIN_AVAILABILITY_COLUMN_INDEX].length ? filteredMachinesByType.filter((machine) => machine.machineKPIs.availability > _filterList[MIN_AVAILABILITY_COLUMN_INDEX]) : filteredMachinesByType;
        const filteredMachinesByMaxAvailability = _filterList[MAX_AVAILABILITY_COLUMN_INDEX] && _filterList[MAX_AVAILABILITY_COLUMN_INDEX].length ? filteredMachinesByMinAvailability.filter((machine) => machine.machineKPIs.availability < _filterList[MAX_AVAILABILITY_COLUMN_INDEX]) : filteredMachinesByMinAvailability;
        const filteredMachinesByMinPerformance = _filterList[MIN_PERFORMANCE_COLUMN_INDEX] && _filterList[MIN_PERFORMANCE_COLUMN_INDEX].length ? filteredMachinesByMaxAvailability.filter((machine) => machine.machineKPIs.performance > _filterList[MIN_PERFORMANCE_COLUMN_INDEX]) : filteredMachinesByMaxAvailability;
        const filteredMachinesByMaxPerformance = _filterList[MAX_PERFORMANCE_COLUMN_INDEX] && _filterList[MAX_PERFORMANCE_COLUMN_INDEX].length ? filteredMachinesByMinPerformance.filter((machine) => machine.machineKPIs.performance < _filterList[MAX_PERFORMANCE_COLUMN_INDEX]) : filteredMachinesByMinPerformance;
        const filteredMachinesByMinQuality = _filterList[MIN_QUALITY_COLUMN_INDEX] && _filterList[MIN_QUALITY_COLUMN_INDEX].length ? filteredMachinesByMaxPerformance.filter((machine) => machine.machineKPIs.quality > _filterList[MIN_QUALITY_COLUMN_INDEX]) : filteredMachinesByMaxPerformance;
        const filteredMachinesByMaxQuality = _filterList[MAX_QUALITY_COLUMN_INDEX] && _filterList[MAX_QUALITY_COLUMN_INDEX].length ? filteredMachinesByMinQuality.filter((machine) => machine.machineKPIs.performance < _filterList[MAX_QUALITY_COLUMN_INDEX]) : filteredMachinesByMinQuality;
        const filteredMachinesByRunning = _filterList[RUNNING_COLUMN_INDEX] && _filterList[RUNNING_COLUMN_INDEX].length ? filteredMachinesByMaxPerformance.filter((machine) => ((_filterList[RUNNING_COLUMN_INDEX].includes('Running') || _filterList[RUNNING_COLUMN_INDEX].includes('Laufend')) && machine.machineKPIs.isRunning) || ((_filterList[RUNNING_COLUMN_INDEX].includes('Not Running') || _filterList[RUNNING_COLUMN_INDEX].includes('Stillstand')) && !machine.machineKPIs.isRunning)) : filteredMachinesByMaxQuality;

        return filteredMachinesByRunning;
    };


    return {
        applyFilters,
        applySearch,
        applySort
    }
}

export default useMachineFilters;