/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import useApi from './useApi';
import { storeSelectionStructures } from '../modules/structure/actions';

let CancelToken = axios.CancelToken;
let cancel;

const useStructureSelection = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const structures = useSelector((state) => state.selectionStructures);
  const [filteredStructures, setFilteredStructures] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  // let cancelAxios = null;

  useEffect(() => {
    return () => {
      cancel && cancel();
      cancel = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    cancel && cancel();
    api('/api/structures?populate=structure machines', {
      method: 'get',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      params: {
        mongoQuery: { isActive: true },
      },
    }).then((response) => {
      if (response) {
        dispatch(storeSelectionStructures(response.data));
        setFilteredStructures(sortStructures(response.data));
      }
    }).catch((error) => {
      if (!axios.isCancel(error)) console.log(error);
    });

    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortStructures = (structures) => {
    if (Array.isArray(structures)) {
      return structures.sort((a, b) => {
        if (a.path > b.path) return 1;
        if (a.path < b.path) return -1;
        return 0;
      });
    }
  };

  useEffect(() => {
    setFilteredStructures(structures
      .filter((structure) => structure.path.toLowerCase().includes(searchValue.toLowerCase())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onSearchChange = (e) => {
    const { value } = e.currentTarget;
    setSearchValue(value);
  };

  return {
    structures,
    filteredStructures,
    onSearchChange,
    searchValue,
    setSearchValue,
  };
};

export default useStructureSelection;
