import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateQualityReportForm = (actualCount) => {
  const phrases = usePhrases().phrases();
  const validateQualityReportForm = (values) => {
    const errors = {};

    if (!values.identifier) {
      errors.identifier = phrases.modules.quality.scrapTypeRequired
    }

    if (!values.endTime) {
        errors.endTime= phrases.modules.quality.endTimeRequired

    }

    if (!values.startTime) {
        errors.startTime = phrases.modules.quality.startTimeRequired

    }

    if(values.value < 0){
      errors.value = phrases.modules.quality.invalidScrapNumber;
    }
    else if(values.value > actualCount){
      errors.value = phrases.modules.quality.tooMuchScrap;
    }
    else if(actualCount === undefined){
      errors.value = phrases.modules.quality.actualCountInvalid;
    }
    return errors;
  };

  return {
    validateQualityReportForm,
  };
};

export default useValidateQualityReportForm;
