import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setUserFavs }  from "@frontend/utils/UIActions";
import useApi           from '@frontend/utils/useApi';

const useFavs = (open) => {

    const [favs, setFavs] = useState([]); // eslint-disable-line
    const api = useApi();
    const dispatch = useDispatch();

    useEffect(() => {
        getFavsOfUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [open])

    const toggleFav = (id) => {
        api('/api/users/favs', {
            method: 'put',
            data: {
                fav: id
            }
        }).then((response) => {
            setFavs(response.data);
            dispatch(setUserFavs(response.data));
        }).catch((error) => {
            setFavs([]);
        });
    }

    const getFavsOfUser = () => {
        api('/api/users/favs', {
            method: 'get',
        }).then((response) => {
            setFavs(response.data);
            dispatch(setUserFavs(response.data));
        }).catch((error) => {
            setFavs([]);
        });
    }
     
    return {
        toggleFav,
        favs
    }
}
 
export default useFavs;