import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Switch,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    cursor: 'pointer',
  },
}));

const StyledSwitch = (props) => {
  const classes = useStyles();
  const { offLabel, onLabel, disabled } = props;

  return (
    <Grid style={{ width: 'max-content' }} component="label" container alignItems="center" spacing={0}>
      {offLabel && <Grid item className={classes.label}><Typography display="inline" color={disabled ? 'textSecondary' : 'textPrimary'} variant="caption">{offLabel}</Typography></Grid>}
      <Grid item>
        <Switch
          disabled={disabled || false}
          color="primary"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Grid>
      {onLabel && <Grid item className={classes.label}><Typography display="inline" color={disabled ? 'textSecondary' : 'textPrimary'} variant="caption">{onLabel}</Typography></Grid>}
    </Grid>
  );
};

StyledSwitch.propTypes = {
  onLabel: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
};

export default StyledSwitch;
