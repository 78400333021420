/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { faUserPlus, faUsers, faUserTag, faTag } from '@fortawesome/free-solid-svg-icons';
import CustomTabs from '@frontend/components/Tabs';
import UserForm from '@frontend/modules/user/UserForm';
import { usePhrases } from '@frontend/utils/usePhrases';
import useSecurity from '@frontend/utils/useSecurity';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import { setTitle } from '@frontend/utils/usePageTitle';
import RolesList from '@frontend/modules/user/RolesList';
import RoleForm from '@frontend/modules/user/RoleForm';
import UsersList from '@frontend/modules/user/UsersList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  usersList: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }
}));

export default function UsersView() {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  setTitle(phrases.pageTitles.usersManagement);

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          ...conditionalElement(isAuthorized('users', ['read:any', 'read:own']), { label: phrases.tabs.ExistingUsers, icon: faUsers }),
          ...conditionalElement(isAuthorized('users', ['create:any', 'create:own']), { label: phrases.tabs.CreateUser, icon: faUserPlus }),
          ...conditionalElement(isAuthorized('roles', ['read:any']), { label: phrases.tabs.ExistingRoles, icon: faUserTag }),
          ...conditionalElement(isAuthorized('roles', ['create:any']), { label: phrases.tabs.CreateRole, icon: faTag }),
        ]}
        panels={[
          ...conditionalElement(isAuthorized('users', ['read:any', 'read:own']), {
            content: <UsersList />,
          }),
          ...conditionalElement(isAuthorized('users', ['create:any', 'create:own']), { content: <UserForm /> }),
          ...conditionalElement(isAuthorized('roles', ['read:any', 'read:own']), { content: <RolesList /> }),
          ...conditionalElement(isAuthorized('roles', ['create:any', 'create:own']), { content: <RoleForm /> }),
        ]}
      />
    </div>
  );
}
