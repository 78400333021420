// phrases.forms.user.errors.structureViewRequired
// phrases.forms.user.errors.structureRequired
// phrases.forms.user.errors.machineViewRequired
// phrases.forms.user.errors.machineRequired
import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateUserForm = () => {
  const phrases = usePhrases().phrases();
  const validateUserForm = (values, isEdit) => {
    const errors = {};

    if (values.name.trim() === '') {
      errors.name = phrases.forms.user.errors.fullNameRquired;
    }

    if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = phrases.forms.user.errors.invalidEmailFormat;
    }

    if (values.email.trim() === '') {
      errors.email = phrases.forms.user.errors.emailRequired;
    }

    if (!isEdit && values.password.length < 8) {
      errors.password = phrases.forms.user.errors.passwordLength;
    }
    
    if (!isEdit && values.password.trim() === '') {
      errors.password = phrases.forms.user.errors.passwordRequired;
    }

    if (!isEdit && values.password2.trim() === '') {
      errors.password2 = phrases.forms.user.errors.passwordRequired;
    }

    if (!isEdit && values.password !== values.password2) {
      errors.password = phrases.forms.user.errors.passwordMismatch;
    }

    if (!values.lang) {
      errors.lang = phrases.forms.user.errors.languageRequired;
    }

    if (!values.role) {
      errors.role = phrases.forms.user.errors.roleRequired;
    }

    if (values.structures.length === 0) {
      errors.structures = phrases.forms.user.errors.structuresRequired;
    }

    if (!values.views.homeView) {
      errors.homeView = phrases.forms.user.errors.homeViewRequired;
    }

    // edit user password validations

    if (isEdit) {
      if (values.password) {
        if (values.password.trim() === '') {
          errors.password = phrases.forms.user.errors.passwordRequired;
        }

        if (!values.password2 || values.password2.trim() === '') {
          errors.password2 = phrases.forms.user.errors.passwordRequired;
        }

        if (values.password !== values.password2) {
          errors.password = phrases.forms.user.errors.passwordMismatch;
        }
      }
      if (values.pin) {
        if (values.pin.trim() === '') {
          errors.pin = phrases.forms.user.errors.pinRequired;
        }

        if (!values.pin2 || values.pin2.trim() === '') {
          errors.pin2 = phrases.forms.user.errors.pinRequired;
        }

        if (values.pin !== values.pin2) {
          errors.pin = phrases.forms.user.errors.pinMismatch;
        }
      }
    }

    return errors;
  };

  return {
    validateUserForm,
  };
};

export default useValidateUserForm;
