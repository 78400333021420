/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { faCog, faIndustry, faPlusCircle, faCogs } from '@fortawesome/free-solid-svg-icons';

import StructureForm from '@frontend/modules/structure/StructureForm';
import CustomTabs from '@frontend/components/Tabs';
import { usePhrases } from '@frontend/utils/usePhrases';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import useSecurity from '@frontend/utils/useSecurity';
import { setTitle } from '@frontend/utils/usePageTitle';
import StructuresList from '@frontend/modules/structure/StructuresList';
import MachineTypeForm from '@frontend/modules/machine/MachineTypeForm';
import MachineForm from '@frontend/modules/machine/MachineForm';
import MachinesList from '@frontend/modules/machine/MachinesList';
import MachineTypesList from '@frontend/modules/machine/MachineTypesList';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const StructuresManagement = () => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();
  setTitle(phrases.pageTitles.structuresManagement);

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          ...conditionalElement(isAuthorized('structures', ['read:any', 'read:own']), { label: phrases.tabs.ExistingStructures, icon: faIndustry }),
          ...conditionalElement(isAuthorized('structures', ['create:any', 'create:own']), { label: phrases.tabs.CreateStructure, icon: faPlusCircle }),
          ...conditionalElement(isAuthorized('machines', ['read:any', 'read:own']), {
            label: phrases.tabs.ExistingMachines,
            icon: faCogs,
          }),
          ...conditionalElement(isAuthorized('machines', ['create:any', 'create:own']), {
            label: phrases.tabs.CreateMachine,
            icon: faCog,
          }),
          ...conditionalElement(isAuthorized('machine-types', ['read:any']), { label: phrases.tabs.ExistingMachineTypes, icon: faCog }),
          ...conditionalElement(isAuthorized('machine-types', ['create:any']), { label: phrases.tabs.CreateMachineType, icon: faPlusCircle }),
        ]}
        panels={[
          ...conditionalElement(isAuthorized('structures', ['read:any', 'read:own']), {
            content: <StructuresList />,
          }),
          ...conditionalElement(isAuthorized('structures', ['create:any', 'create:own']), {
            content: <StructureForm />,
          }),
          ...conditionalElement(isAuthorized('machines', ['read:any', 'read:own']), {
            content: <MachinesList />,
          }),
          ...conditionalElement(isAuthorized('machines', ['create:any', 'create:own']), {
            content: <MachineForm />,
          }),
          ...conditionalElement(isAuthorized('machine-types', ['create:any']), { content: <MachineTypesList /> }),
          ...conditionalElement(isAuthorized('machine-types', ['create:any']), { content: <MachineTypeForm /> }),
        ]}
      />
    </div>
  );
};

export default StructuresManagement;
