/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-use-before-define */
import decode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';

import { removeUser } from '../modules/user/actions';

const useSecurity = () => {
  const loggedInUser = useSelector((state) => state.user);
  const dispatch = useDispatch();

  
  const loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // Getting token from localstorage
    return !!token && !isTokenExpired(token);
  };

  const isSystemAdmin = (user) => {
    if (!user) return false;
    if (!user.role) return false;
    if (user.role.name === 'System Admin') return true;

    return false;
  };

  const isManager = (user) => {
    if (!user) return false;
    if (!user.role) return false;
    if (user.role.name === 'Manager') return true;

    return false;
  };

  const isRoleSystemAdmin = (role) => {
    if (!role) return false;
    if (role.name === 'System Admin') return true;

    return false;
  };

  const isTokenExpired = (token) => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const setToken = (token) => {
    // Saves user token to localStorage
    localStorage.setItem('accessToken', token);
  };

  const getToken = () =>
  // Retrieves the user token from localStorage
    localStorage.getItem('accessToken');
  const getLang = () =>
    // Retrieves the lang from localStorage
    loggedInUser.lang;
  const logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('accessToken');
    dispatch(removeUser());
  };

  const getProfile = () => loggedInUser;
  const _getUserRole = (user) => user.role;
  const _getUserRolesPermissions = (user) => {
    const role = _getUserRole(user);
    return role.permissions;
  };

  const _getUserActions = (user, resource) => {
    const userPermissions = _getUserRolesPermissions(user);
    const userActions = [];
    userPermissions.forEach((permission) => {
      const { action } = permission;
      if (permission.resource === resource) { userActions.push(action); }
    });
    return userActions;
  };

  const _getUserResources = (user) => {
    const userPermissions = _getUserRolesPermissions(user);
    const userResources = [];
    userPermissions.forEach((permission) => {
      const { resource } = permission;
      userResources.push(resource);
    });
    return userResources;
  };

  const canAccessResource = (resource) => {
    const user = getProfile();
    if (!user) return false;
    // Filtering out duplicates
    const userResources = _getUserResources(user).filter((resource, index, arr) => arr.indexOf(resource) === index);
    return userResources.includes(resource);
  };

  const canDoAction = (actions, resource) => {
    const user = getProfile();
    if (!user) return false;
    const userActions = _getUserActions(user, resource);

    return userActions.some((el) => actions.includes(el));
  };

  const isAuthorized = (resource, actions) => {
    const user = getProfile();
    if (!user) return false;
    const canUserAccessResource = canAccessResource(resource);
    const canUserDoAction = canDoAction(actions, resource);
    if (canUserAccessResource && canUserDoAction) return true;

    return false;
  };

  return {
    loggedIn,
    isTokenExpired,
    setToken,
    getToken,
    logout,
    getProfile,
    getLang,
    isAuthorized,
    canAccessResource,
    canDoAction,
    isSystemAdmin,
    isManager,
    isRoleSystemAdmin,
  };
};

export default useSecurity;
