/* eslint-disable react/jsx-props-no-spreading */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import moment from 'moment-timezone';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import {
    MenuItem,
    InputLabel,
    FormControl,
    InputBase,
    styled,
    Select,
  Typography,
  Checkbox,
  useTheme
} from '@material-ui/core';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

const useStyles = makeStyles((theme) => ({
 
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    marginLeft: 'auto',
  },
 
  inputField: {
    width: '100%',
    color: 'primary'
  },
  whiteColor: {
    color: '#FFFFFF'
  },
  structureName: {
    fontWeight: 550,
  },
}));

const StructuresDropdownSelect = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const {
        options,
        value,
        onChange,
        label,
        error,
        required,
        showTimezone,
    } = props;
    const [sortedStructures, setSortedStructures] = useState([]);  

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    useEffect(() => {
        if (Array.isArray(options)) {
            setSortedStructures(options.sort((a, b) => {
            if (a.path > b.path) return 1;
            if (a.path < b.path) return -1;
            return 0;
            }));
        }        
    }, [options]);

    const structureTitleFormat = (structure) => {
        if(structure === null || structure === undefined || structure.path === undefined) return;
        const structureName = structure.path;
        const arr = structureName.split('/');
        const path = arr.splice(0, arr.length - 1);
        const name = arr[0];
        return (
        <>
            <Typography display="inline" className={classes.indented} color="textSecondary">
            {`${path.join('/')}${path.length < arr.length ? '' : '/'}`}
            <Typography variant="span" color="primary">
                {name}
            </Typography>
            {showTimezone && (
                <Typography style={{ marginLeft: theme.spacing(2)}} variant="span">
                (
                {moment().tz(structure.timezone).format('Z z')}
                )
                </Typography>
            )}
            </Typography>
        </>
        );
    };
    
    return (
        <div>
            <FormControl {...props} margin="dense" required={!!required} variant="standard" className={classes.formControl}>
                <InputLabel 
                    id="structuredropdown-checkbox-label"
                >                
                    {label}
                </InputLabel>
                <Select
                    labelId="structuredropdown-checkbox-label"
                    label= {label}
                    id="structuredropdown-select"
                    value={value}
                    onChange={onChange}
                    input={<BootstrapInput />}
                    error={!!error}
                    renderValue={(selected) =>  {return selected.props ? structureTitleFormat(selected.props?.value) : structureTitleFormat(selected)}}
                >
                    {sortedStructures?.map((structure) => (
                    <MenuItem key={structure.id} value={structure}>
                        <div key={uuid()}>
                            <Checkbox 
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={value?.id === structure?.id} 
                                color="primary"
                            />
                            {structureTitleFormat(structure)}
                        </div>
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

StructuresDropdownSelect.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default StructuresDropdownSelect
