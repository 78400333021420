import moment from 'moment';

// The customer logo needs to be stored in the static directory
export const CUSTOMER_LOGO = {
  filename: "customerLogo.png",
  enabled: true,
  height: 1637,      // Not used yet
  width: 2253,       // Not used yet
  altText: "<DEFAULT TEXT CHANGE ME>"
}
// In seconds
export const RELOAD_INTERVALS = [
  10,
  60,   // 1 Min
  300,  // 5 Min
  3600, // 1 Hour
];
export const DEFAULT_TIMESPAN_START = moment().subtract(12, 'hours');

export const ROUTES = {
  HOME: '/',
  STRUCTURE_OVERVIEW: '/structure-overview',
  STRUCTURE_OVERVIEW_ID: '/structure-overview/:structureId',
  STRUCTURES_MANAGEMENT: '/structures-management',
  MACHINE_OVERVIEW: '/machine-overview',
  MACHINE_OVERVIEW_ID: '/machine-overview/:machineId',
  MACHINES_MANAGEMENT: '/machines-management',
  MACHINE_TYPES_MANAGEMENT: '/machine-types-management',
  SENSORS_MANAGEMENT: '/sensors-management',
  SENSOR_TYPES_MANAGEMENT: '/sensor-types-management',
  USERS_MANAGEMENT: '/users-management',
  ROLES_MANAGEMENT: '/roles-management',
  ANDON_BOARD: '/andon-board',
  ANDON_BOARD_ID: '/andon-board/:structureId',
  REPORTING_VIEW: '/reporting',
  ADVANCED_REPORTING_VIEW: '/advanced_reporting',
  KNOWLEDGE: '/knowledge/:knowledgeName',
  INFO: '/info',
  REASONS_MANAGEMENT: '/reasons-management',
  REASONS_CATEGORIES_MANAGEMENT: '/reasons-categories-management',
  SCRAP_TYPES_CATEGORIES_MANAGEMENT: '/scrap-types-categories-management',
  MACHINE_STATUSES_MANAGEMENT: '/machine-statuses-management',
  TERMINAL_OVERVIEW: '/terminal-overview',
  TERMINAL_OVERVIEW_ID: '/terminal-overview/:machineId',
  SCHEDULES: '/schedules',
  SCHEDULE_MANAGEMENT: '/schedule-management',
  SHIFT_MANAGEMENT: '/shift-management',
  COMPARISON: '/comparison',
  SCRAP_TYPES_MANAGEMENT: '/scrap-types-management',
  ORDERS: '/orders',
  ORDERS_WITH_ID: '/orders/:structureId',
  ORDERS_EXPORT_VALIDATION: '/order-validation',
  ORDERS_EXPORT_VALIDATION_WITH_ID: '/order-validation/:structureId',
  JOB: '/orders/job/:jobId',
  JOB_MANAGEMENT: '/orders/jobs-management',
  APP_TERMINAL_OVERVIEW: '/app-terminal-view',
  APP_TERMINAL_OVERVIEW_ID: '/app-terminal-view/:machineId',
  MACHINE_PERFORMANCE: '/machine-performance',
  SIMPLE_MACHINE: '/simple-machine',
  SIMPLE_MACHINE2: '/simple-machine2',
  MACHINE_SHIFT_MANAGEMENT: '/machine-shift-management',
};

export const GROUP_STATE_LIMITS = {
  EXCELLENT: 80,
  GOOD: 60,
  MEDIUM: 40,
  BAD: 20,
  FATAL: 0
}
export const ORDERS_TIMEFRAME_VALUES = {
  TWOWEEKS: "14",
  MONTH: "30",
  SIXMONTHS: "183",
  YEAR: "366",
}
export const UNDEFINED_STATES = [
  'not_defined',
  'break_creep'
]
export const RUNNING_STATES = [
  'running',
  'slow_running',
]

export const CompletionStatusEnum = {
  OPEN: 'OPEN',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  COMPLETED: 'COMPLETED',
  STARTED: 'STARTED',
  ENDED: 'ENDED',
};
export const CustomCompletionStatusEnum = {
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
};

export const MACHINE_CONFIG_VALUES = {
  "targetCycleTime": {
    type: "number", 
    default: 1.0
  },
  
  "maxCycleTime": { 
    type: "number",       
    default: 1.0
  },
  "partsChartSimple": { 
    type: "boolean",  
    default: true
  },
  "partsChart": { 
    type: "boolean",    
    default: true
  },
  "speedLow": { 
    type: "number",        
    default: 2.0
  },
  "speedMedium": { 
    type: "number",       
    default: 1.5
  },
  "speedMax": { 
    type: "number",        
    default: 1.0
  },  
  "handCountStartEnd": { 
    type: "boolean",        
    default: true
  },
  "defaultActivationStatus": {
    type: "string", 
    default: "ENDED"
  },
}
export const HANDCOUNT_THRESHOLD = 0.2; // 20% of the produced parts
export const TERMINAL_PING_INTERVAL = 10000;
export const ALLOW_MERGE_STATE = true;
export const DEFAULT_JOB_ID_LENGTH = 9;
export const MATERIAL_UI_ICON = 'material-ui';
export const MATERIAL_DESIGN_ICON = 'material-design';
export const FONTAWESOME_ICON = 'fontawesome';
export const ALL_ICON_TYPES = [MATERIAL_UI_ICON, MATERIAL_DESIGN_ICON, FONTAWESOME_ICON];
export const DEFAULT_JOB_ID_FORMAT = '[JID]';   // SID = Structure ID, JID = Job ID

export const FAV_IDS = {
  OEE: 'oee',
  REPORT: 'report',
  ANDON: 'andon',
  SCHEDULES: 'schedules',
  ORDERS: 'orders',
  ADVANCED_REPORT: 'advanced_report',
  TERMINAL: 'terminal',
  REVIEW: 'review',
}
