export const storeListOfSensors = (sensors) => ({
  type: 'STORE_LIST_SENSORS',
  sensors,
});

export const removeListOfSensors = () => ({
  type: 'REMOVE_LIST_SENSORS',
});

export const storeListOfSensorTypes = (sensorTypes) => ({
  type: 'STORE_LIST_SENSOR_TYPES',
  sensorTypes,
});

export const removeListOfSensorTypes = () => ({
  type: 'REMOVE_LIST_SENSOR_TYPES',
});
