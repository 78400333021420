import { useTheme } from '@material-ui/core';

const useCurrentBreakpoint = () => {
  const theme = useTheme();
  const currentWidth = window.innerWidth;
  const breakpoints = theme.breakpoints.values;

  const currentBreakpoint = () => {
    const up = (breakpoint) => {
      switch (breakpoint) {
        case 'xs': return currentWidth >= breakpoints.xs;
        case 'sm': return currentWidth >= breakpoints.sm;
        case 'md': return currentWidth >= breakpoints.md;
        case 'lg': return currentWidth >= breakpoints.lg;
        case 'xl': return currentWidth >= breakpoints.xl;
        default: return false;
      }
    };

    const down = (breakpoint) => {
      switch (breakpoint) {
        case 'xs': return currentWidth <= breakpoints.xs;
        case 'sm': return currentWidth <= breakpoints.sm;
        case 'md': return currentWidth <= breakpoints.md;
        case 'lg': return currentWidth <= breakpoints.lg;
        case 'xl': return currentWidth <= breakpoints.xl;
        default: return false;
      }
    };

    return {
      up,
      down,
    };
  };

  return currentBreakpoint;
};

export default useCurrentBreakpoint;
