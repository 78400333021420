import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import useAlert         from '@frontend/hooks/useAlert';
import useApi           from '@frontend/utils/useApi';
import { usePhrases }   from '@frontend/utils/usePhrases';
import socket           from '@frontend/utils/useSocket';

const useEasyScrap = (selectedState, modalOpen, machine, onModalClose) => {
    const api = useApi();
    const phrases = usePhrases()
      .phrases();
    const { createAlert } = useAlert();

    const [selectedStateFull, setSelectedStateFull] = useState({});
    const [scrapTypesByCategory, setScrapTypesByCategory] = useState([]);
    const [isLoadingScrapTypes, setLoadingScrapTypes] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);    
    const [scrapCount, setScrapCount] = useState(1);

    useEffect(() => {        
        setSelectedStateFull({
            machineId: machine.id,
            machineType: machine.type,
            startTime: moment().startOf('minute').subtract(1, 'minute'),
            endTime: moment().startOf('minute'),
            state: selectedState,
          });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState, machine]);

    useEffect(() => {
        if (modalOpen && selectedStateFull) {
          setLoadingScrapTypes(true);
    
          api(`/api/quality/scrap-types?mongoQuery={"$or": [{"machineTypes": {"$in": ["${machine.type.id}"]}},{"machineTypes": {"$size": 0}}], "enabled": true }`, {
            method: 'get',
          })
            .then((response) => {
              setScrapTypesByCategory(response.data);
              setLoadingScrapTypes(false);
            })
            .catch((error) => {
              if (!axios.isCancel(error)) console.log(error);
              setLoadingScrapTypes(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen, selectedStateFull]);
    
    const changeScrapCount = (val) => {
        if(val < 0) return;
        
        setScrapCount(val);
    }

    const createQualityReportEasyScrap = (scrap) => {
        const startTime = moment().utc(0).subtract(1, 'minute').format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]");
        const endTime = moment().utc(0).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]");
        const qualityReportValues = {
            name: 'Easy Scrap',
            identifier: scrap.identifier,
            description: phrases.modules.quality.easyScrapDescription,
            value: scrapCount,
            note: phrases.modules.quality.easyScrapNote,
            startTime,
            endTime,
        }
        const noteData = { text: phrases.modules.quality.easyScrapNote };
        api('/api/shared/notes', {
            data: noteData,
            method: 'post',
        })
        .then((response) => {
            if (response.status === 200 || response.status === 201) {
                const noteId = response.data.id;
                api('/api/quality/easy-scrap', {
                    data: {
                        MPUData: {
                            state: {
                                startTime,
                                endTime,
                                status: selectedStateFull.state['presentation.status'],
                              },
                              qualityReport: qualityReportValues,
        
                              machine: {
                                "id": machine.id
                              },
                            },
                            machine: machine.id,
                            qualityReportData: {
                              machineId: machine.id,
                              note: noteId,
                              wasteCount: scrapCount,        // Changed this to scrapCount
                              scrapType: scrap.identifier,
                              timestamp: startTime,
                              startTime,
                              endTime,
                            },
                          },
                          method: 'post',
                })
                .then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        onModalClose();
                        createAlert(phrases.modules.quality.qualityReportAdded, 'success');
                        socket.emit('JOB_QUALITY_UPDATE', {});
                    }
                })
                .catch((error) => {
                    const errorMessage = phrases.forms.structure.errors.genericError;
                    if (error.response) {
                        if (error.response.status === 404) {
                            createAlert(errorMessage, 'error');
                            setIsSubmitting(false);
                        } else if (error.response.status === 403) {
                            setIsSubmitting(false);
                            createAlert(phrases.errorMessages.notAuthorized, 'error');
                        } else {
                            createAlert(errorMessage, 'error');
                            setIsSubmitting(false);
                        }
                    }
                })
            }
        })
        .catch((error) => {
            const errorMessage = phrases.forms.structure.errors.genericError;
            if (error.response) {
            if (error.response.status === 404) {
                createAlert(errorMessage, 'error');
                setIsSubmitting(false);
            } else if (error.response.status === 403) {
                setIsSubmitting(false);
                createAlert(phrases.errorMessages.notAuthorized, 'error');
            } else {
                createAlert(errorMessage, 'error');
                setIsSubmitting(false);
            }
            }
        });
    }

    return { 
        scrapTypesByCategory,
        isLoadingScrapTypes,
        createQualityReportEasyScrap,
        isSubmitting,
        changeScrapCount,
        scrapCount
     };
}
 


export default useEasyScrap;