import { usePhrases } from '@frontend/utils/usePhrases';

const useValidateStatusReasonForm = () => {
  const phrases = usePhrases().phrases();
  const validateStatusReasonForm = (values) => {
    const errors = {};

    if (!values.identifier) {
      errors.identifier = phrases.forms.reason.errors.reasonRequired;
    }

    return errors;
  };

  return {
    validateStatusReasonForm,
  };
};

export default useValidateStatusReasonForm;
