/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  faWaveSquare, 
  faMicrochip, 
  faHeartbeat 
} from '@fortawesome/free-solid-svg-icons';

import CustomTabs from '@frontend/components/Tabs';
import SensorForm       from '@frontend/modules/sensor/SensorForm';
import SensorHealth     from '@frontend/modules/sensor/SensorHealth';
import SensorsList      from '@frontend/modules/sensor/SensorsList';
import SensorTypesList  from '@frontend/modules/sensor/SensorTypesList';
import SensorTypeForm   from '@frontend/modules/sensor/SensorTypeForm';
import useConditionalArrayElement from '@frontend/utils/useConditionalArrayElement';
import { usePhrases }             from '@frontend/utils/usePhrases';
import useSecurity                from '@frontend/utils/useSecurity';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  sensorsList: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }
}));

export default function SensorsView() {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { isAuthorized } = useSecurity();
  const { conditionalElement } = useConditionalArrayElement();

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          ...conditionalElement(isAuthorized('sensors', ['read:any', 'read:own']), {
            label: phrases.tabs.ExistingSensors, icon: faMicrochip,
          }),
          ...conditionalElement(isAuthorized('sensors', ['create:any', 'create:own']), {
            label: phrases.tabs.CreateSensor, icon: faWaveSquare,
          }),
          ...conditionalElement(isAuthorized('sensor-types', ['read:any']), { label: phrases.tabs.ExistingSensorTypes, icon: faMicrochip }),
          ...conditionalElement(isAuthorized('sensor-types', ['create:any']), { label: phrases.tabs.CreateSensorType, icon: faWaveSquare }),
          ...conditionalElement(isAuthorized('sensors', ['read:any', 'read:own']), {
            label: phrases.tabs.SensorHealth, icon: faHeartbeat,
          }),

        ]}
        panels={[
          ...conditionalElement(isAuthorized('sensors', ['read:any', 'read:own']), {
            content: <SensorsList />
          }),
          ...conditionalElement(isAuthorized('sensors', ['create:any', 'create:own']), {
            content: <SensorForm />,
          }),
          ...conditionalElement(isAuthorized('sensor-types', ['read:any']), { content: <SensorTypesList /> }),
          ...conditionalElement(isAuthorized('sensor-types', ['create:any']), { content: <SensorTypeForm /> }),
          ...conditionalElement(isAuthorized('sensors', ['create:any', 'create:own']), {
            content: <SensorHealth />,
          }),
        ]}
      />
    </div>
  );
}
